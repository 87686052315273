import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewResourcesIcon = () => (
    <SvgIcon width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
            <path d="M3.17959 27.6525H1.91792V1.95215H2.75366C3.30595 1.95215 3.75366 2.39986 3.75366 2.95215V26.3736C3.75366 27.0799 3.49667 27.6525 3.17959 27.6525Z" fill="#9DE0F8"/>
            <path d="M16.7925 1.03418V5.99727H21.7556" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M16.7923 1.03418H1V29.008H21.755V5.99727L16.7923 1.03418Z" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M7.31725 21.3381V24.9489H2.8053V20.437H5.51261" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 24.4963H20.4023" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 22.6917H20.4023" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 20.887H14.5365" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M7.31722 13.2166H2.8053V17.7285H7.31722V13.2166Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 17.2773H20.4023" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 15.4724H20.4023" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M8.67114 13.6677H14.5365" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <path d="M7.31722 5.99731H2.8053V10.5092H7.31722V5.99731Z" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M8.67114 10.0581H20.4023" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M8.67114 8.25342H20.4023" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M8.67114 6.44849H14.5365" stroke="currentColor" strokeMiterlimit="10"/>
            <path d="M3.70789 21.3381L4.9713 23.1431L6.86633 19.9846" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
            <rect x="2.83545" y="11.1309" width="24.7825" height="14.6859" rx="5" fill="white"/>
            <path d="M23.9843 26.8213H6.90205C5.83705 26.8213 4.81571 26.3978 4.06268 25.644C3.30965 24.8902 2.8866 23.8679 2.8866 22.8018V14.488C2.8866 13.4219 3.30967 12.3996 4.06268 11.6458C4.81568 10.892 5.83702 10.4685 6.90205 10.4685H23.9843C25.0493 10.4685 26.0707 10.892 26.8237 11.6458C27.5767 12.3995 27.9998 13.4219 27.9998 14.488V22.8018C27.9998 23.8678 27.5767 24.8902 26.8237 25.644C26.0707 26.3978 25.0493 26.8213 23.9843 26.8213ZM6.90205 11.4461C6.096 11.4461 5.3231 11.7666 4.75319 12.3371C4.18327 12.9077 3.86303 13.6813 3.86303 14.4882V22.8019C3.86303 23.6088 4.18324 24.3824 4.75319 24.9529C5.32313 25.5234 6.096 25.844 6.90205 25.844H23.9843C24.7904 25.844 25.5633 25.5235 26.1332 24.9529C26.7031 24.3824 27.0233 23.6088 27.0233 22.8019V14.4882C27.0233 13.6813 26.7031 12.9076 26.1332 12.3371C25.5632 11.7666 24.7904 11.4461 23.9843 11.4461H6.90205Z" fill="currentColor"/>
            <path d="M12.8146 14.335L20.2159 18.6085L12.8146 22.882V14.335ZM12.3274 12.8777C12.1662 12.8777 12.0117 12.9417 11.8978 13.0559C11.7837 13.1699 11.7197 13.3246 11.7197 13.4858V23.7311C11.7197 23.8923 11.7837 24.047 11.8978 24.161C12.0117 24.2752 12.1662 24.3392 12.3274 24.3392C12.4333 24.3393 12.5372 24.3109 12.6285 24.257L21.4969 19.1563C21.6814 19.0454 21.7942 18.8458 21.7942 18.6304C21.7942 18.415 21.6814 18.2154 21.4969 18.1044L12.6285 12.9818C12.5372 12.9279 12.4333 12.8995 12.3274 12.8996V12.8777Z" fill="currentColor"/>
            <path d="M13.5629 22.1452H12.9321V14.8022C13.439 14.8022 13.85 15.2132 13.85 15.7201V21.7798C13.85 21.9816 13.7215 22.1452 13.5629 22.1452Z" fill="#9DE0F8"/>
        </svg>
  </SvgIcon>
)

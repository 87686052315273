import AirtableTable from "../../AirtableTable";

export class DayPlansTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Day Plans");

    this.fields = {
      "ID": "fldrVRt6pQGwcl0vm",
      "Day Planned": "fldbgEhEinWhb4dv9",
      "Submitted By": "fldIl1IEhZQdXDoMw",
      "Topic": "fldL0J1bsnfRv4VRq",
      "Alignment": "fldjTJOTV3FsP3XF9",
      "Support": "fldLeLAly2qk08B2q",
      "Support Needed?": "fld3VxPgNhF4xT2ix",
    };
  }

}

import React, { useEffect, useState, useMemo, Fragment } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  Input
} from 'reactstrap'
import airtable from '../../../airtables';
import Loading from '../../../components/Loading.js';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import { GradeLevels, IS_WISHALL } from '../../../utils/constants';
import { getFullDateString } from '../../../utils/time';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { analytics } from '../../../firebase';
import './styles.scss';
import Select from 'react-select';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useSessionBook from '../../../libs/hooks/use-session-book/index.js';
import SessionFullTimePicker from '../../../components/SessionFullTimePicker/index.js';

const RequestForm = ({
  session,
  onToggle,
  onSubmitted,
  edit,
  requestedSession
}) => {
  const labelForMe = "No, this request is for my class";
  const { userType, appLocale, userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [alterDate, setAlterDate] = useState(null);
  const [notes, setNotes] = useState("");
  const [school, setSchool] = useState(null);
  const [grades, setGrades] = useState(GradeLevels.map(item => ({ name: item, checked: false })));
  const [students, setStudents] = useState();
  const [studentsError, setStudentsError] = useState("");
  //const [preferredTimeChanged, setPreferredTimeChanged] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState();
  const [timeValid, setTimeValid] = useState("");
  const { t } = useTranslation();
  const [sessionLength, setSessionLength] = useState(session["Length (Minutes)"]);

  const {
    loading: bookSessionLoading,
    provider,
    suppliesOption,
    dayRestriction,
    dayLimitations,
    availableDates,
    sessionLengthOptions,
    isHandsOnSession,
    initialDates,
    isSchoolManager,
    shouldDisableDate
  } = useSessionBook({
    providerSession: session,
    bookType: edit ? "edit" : "create",
    session: requestedSession,
    teachers: selectedTeachers
  });

  useEffect(() => {
    (async () => {
      if (userType === 'Teacher') {
        setSelectedTeachers([userInfo]);
      }

      if (edit && requestedSession) {
        setNotes(requestedSession["Notes"]);
        setStudents(requestedSession["Students"]);
        //setPreferredTimeChanged(true);

        const gs = GradeLevels.map((grade) => ({
          name: grade,
          checked: (requestedSession["Grade(s)"] ? requestedSession["Grade(s)"].indexOf(grade) >= 0 : false)
        }));
        setGrades(gs);

        const teacherIds = requestedSession["Teacher"];
        const ts = await airtable.teachers.listByIds(teacherIds);
        setSelectedTeachers(ts);
      }

      if (userType === 'Teacher') {
        const school = await airtable.schools.select(userInfo['School Name'][0]);
        setSchool(school);

        if (isSchoolManager) {
          let allTeachers = [];
          if (Array.isArray(userInfo["School Name Text"])) {
            for (const schoolName of userInfo["School Name Text"]) {
              const schoolTeachers = await airtable.teachers.getSchoolTeachers(schoolName);
              schoolTeachers.forEach(t => {
                t.displayName = t["Teacher Name"];
              });
              allTeachers = [...allTeachers, ...schoolTeachers];
            }
          } else {
            const ts = await airtable.teachers.getSchoolTeachers(userInfo["School Name Text"][0]);
            allTeachers = ts;
          }
          allTeachers.sort((a, b) => {
            const nameA = a.displayName || a["Teacher Name"];
            const nameB = b.displayName || b["Teacher Name"];
            return nameA.localeCompare(nameB);
          });
          setTeachers(allTeachers.filter(t => t.id !== userInfo.id));
        }
      }
    })();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialDates.length === 0) return;

    setStartDate(initialDates[0]);
    setAlterDate(initialDates[1]);
  }, [initialDates])

  const onDone = async () => {
    setLoading(true);
    if (userType !== 'Team' && userType !== 'Teacher') {
      setLoading(false);
      return;
    }

    if (!selectedTeachers || selectedTeachers.length === 0) return;

    // Use the selected session length, falling back to the default if not set
    const sessionLengthToUse = sessionLength || session["Length (Minutes)"] || 60;

    const calcDiffDays = (date1, date2) => {
      if (typeof date1 === 'string') date1 = new Date(date1);
      if (typeof date2 === 'string') date2 = new Date(date2);

      var diff = date1.getTime() - date2.getTime();

      var diffDays = diff / (1000 * 3600 * 24);
      return diffDays
    }

    // if (!preferredTimeChanged) {
    //   setLoading(false);
    //   alert(t("sessionrequest.confirm-msg"));
    //   return;
    // }
    if (!students || students <= 0) {
      setLoading(false);
      setStudentsError(t("sessionrequest.students-num-msg"));
      return;
    }

    const gap1 = calcDiffDays(startDate, new Date());
    if (gap1 < dayRestriction) {
      setLoading(false);
      alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
      return;
    }

    const gap2 = calcDiffDays(alterDate, new Date());
    if (gap2 < dayRestriction) {
      setLoading(false);
      alert(t("sessionrequest.day-restrict-msg", { num: dayRestriction }));
      return;
    }

    let requestGrades = [];
    grades.forEach((grade) => {
      if (grade.checked) requestGrades.push(grade.name);
    })

    if (requestGrades.length === 0) {
      setLoading(false);
      alert(t("sessionrequest.grades-msg"));
      return;
    }

    // Is this a request, or adding to a wishlist based on Credits available?
    var creditCalc;
    var setStatus;
    var wishlisted;
    var allowFree;
    const providerGroups = session["Indigenous Group(s)"];
    const schoolGroups = selectedTeachers[0]["School Indigenous Group(s)"];
    if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
      creditCalc = selectedTeachers[0]['Credit Balance'] - session['Discounted Credits'];
      if (session['Discounted Credits'] === 0) {
        allowFree = true;
      }
    } else {
      creditCalc = selectedTeachers[0]['Credit Balance'] - session['Credits'];
      if (session['Credits'] === 0) {
        allowFree = true;
      }
    }
    if (creditCalc >= 0 || allowFree) {
      if (IS_WISHALL) {
        setStatus = 'Wish List';
        wishlisted = false;
      } else {
        setStatus = 'Requested';
        wishlisted = false;
      }
    } else {
      setStatus = 'Wish List';
      wishlisted = true;
    }

    var param;
    if (edit) {
      param = {
        'Session Start Date/Time': startDate,
        'Alternative Date/Time': alterDate,
        'Notes': notes,
        'Grade(s)': requestGrades,
        'Students': parseInt(students),
        'Length (Minutes)': sessionLengthToUse
      }

      airtable.sessions.update(requestedSession.id, param).then(async () => {
        logEvent(
          analytics,
          'update_request_session',
          { id: requestedSession.id }
        )
        toast.success(t("sessionrequest.request-updated-msg"));
        onSubmitted();
      }).catch(error => {
        toast.error(error.toString())
      }).finally(() => setLoading(false))
    } else {
      param = {
        'Session Start Date/Time': startDate,
        'Alternative Date/Time': alterDate,
        'Notes': notes,
        'Status': setStatus,
        'Teacher': selectedTeachers.map(t => t.id),
        'Cost': session['Cost'],
        'Session Title': [session.id],
        'Subject/Curriculum': session['Subject'],
        'Well-being Link': session['Well-being Link'],
        'Provider': [provider.id],
        'School(s)': selectedTeachers[0]["School Name"],
        'School Lead': school['TIG School Lead'],
        'Type of Session': session['Type of Session'],
        'Length (Minutes)': sessionLengthToUse,
        'Self-Requested through Platform': true,
        'Wishlisted': wishlisted,
        'Grade(s)': requestGrades,
        'Students': parseInt(students),
        'Presenters': session['Default Presenter'] || [],
        'Session Host Notes': session['Default Host Notes'] || '',
        'Send Meeting Invite to:': 'All'
      }

      const sessionData = await airtable.providerSessions.select(session.id);
      if (sessionData && sessionData["Tied Session"]) {
        param['Session Title'] = param['Session Title'] ?
          [...param['Session Title'], ...sessionData["Tied Session"]] :
          sessionData["Tied Session"];

        param['Provider'] = param['Provider'] ?
          [...param['Provider'], ...sessionData["Tied Session Provider"]] :
          sessionData["Tied Session Provider"];
      }

      const tIds = selectedTeachers.map(t => t.id);
      if (tIds.indexOf(userInfo.id) < 0) {
        param["Coordinating Teacher"] = [userInfo.id];
      }

      if (suppliesOption.startsWith("not-required")) {
        param["Supplies Shipment Not Required"] = true;
      }

      // Check if it meets March 31 free criteria
      const meetsFreeSessionCriteria = (
        new Date(startDate) <= marchDeadline &&
        new Date(alterDate) <= marchDeadline &&
        session["Indigenous?"] &&
        !(session?.Supplies?.length > 0)
      );

      // Only set funding fields if criteria are met
      if (meetsFreeSessionCriteria) {
        param['Credits Waived by Promotion'] = true;
        param['Assign to Funding Pool'] = ['receWQh7Il8JduNYn']; // Supports for Student Learning Program
      }

      setLoading(true);
      airtable.sessions.create(param).then(async res => {
        logEvent(
          analytics,
          'request_session',
          { id: res.id }
        );
        toast.success(t("sessionrequest.request-received-msg"))
        onSubmitted();
      }).catch(error => {
        console.log("error", error)
        toast.error(error.toString())
      }).finally(() => setLoading(false))
    }
  }

  const getDayLimitationString = () => {
    const limitations = provider["Day Limitations"];
    let translatedDays = limitations;

    if (userInfo["Preferred Language"] === "French") {
      const englishToFrench = {
        Mondays: "lundis",
        Tuesdays: "mardis",
        Wednesdays: "mercredis",
        Thursdays: "jeudis",
        Fridays: "vendredis"
      };
      translatedDays = limitations.map(day => englishToFrench[day] || day);
    }
    switch (limitations.length) {
      case 0:
        return "";
      case 1:
        return translatedDays[0];
      default:
        const subs = translatedDays.slice(0, translatedDays.length - 1);
        const last = translatedDays[translatedDays.length - 1];
        return subs.join(", ") + (userInfo["Preferred Language"] === "French" ? " et " : " and ") + last;
    }
  }

  // Check if supplies are out of stock with the specified conditions
  const suppliesOutOfStock = session["No Requests when Supplies OOS"] && dayRestriction === 60 && !suppliesOption.startsWith("not-required");

  const canSave = () => {
    if (loading) return false;
    if (timeValid !== "valid") return false;
    // Prevent form submission when supplies are out of stock
    if (suppliesOutOfStock) return false;
    return true;
  }

  // Free session eligibility checks
  const marchDeadline = new Date(2025, 2, 31, 23, 59, 59, 999); // End of March 31st
  const isBeforeDeadline = startDate && alterDate &&
    new Date(startDate) <= marchDeadline &&
    new Date(alterDate) <= marchDeadline;

  const providerGroups = session["Indigenous Group(s)"];
  const isIndigenousProvider = session["Indigenous?"];

  const hasNoSupplies = !(session?.Supplies?.length > 0);

  const creditCheck = (edit) => {
    if (!selectedTeachers || !selectedTeachers.length) return null;

    var creditCalc;
    var creditCost;
    const schoolGroups = selectedTeachers[0]["School Indigenous Group(s)"];
    const tName = selectedTeachers[0].id !== userInfo.id ? `${selectedTeachers[0]["Teacher Name"]}'s` : "My";

    if (isBeforeDeadline && isIndigenousProvider && hasNoSupplies) {
      creditCost = 0; // Free session
    } else if (suppliesOption.startsWith("not-required")) {
      creditCost = parseFloat(suppliesOption.slice(13));
    } else if (
      providerGroups &&
      schoolGroups &&
      Array.isArray(providerGroups) &&
      providerGroups.length > 0 &&
      providerGroups.some(group => schoolGroups.includes(group))
    ) {
      creditCost = session['Discounted Credits'];
    } else {
      creditCost = session['Credits'];
    }

    creditCalc = selectedTeachers[0]['Credit Balance'] - creditCost;

    if (edit) {
      return (
        <div className="credits">
          <Button
            color="primary"
            type="button"
            disabled={!canSave()}
            onClick={() => onDone()}
          >{loading && (<Loading size={14} />)}{userType === 'Team' ? t("edit-session") : t("edit-request")}</Button>
          <Button
            data-dismiss="modal"
            color="outline-primary"
            type="button"
            onClick={onToggle}
          >{t("cancel")}</Button>
        </div>
      )
    } else if (creditCalc >= 0 || creditCost === 0) {
      return (
        <div className="credits">
          <div className='credit-status'>
            <span className='text-left'>
              {t("available-credits", { name: tName })}: {selectedTeachers[0]['Credit Balance']}<br></br>
              {t("session-credits")}: {creditCost}<br></br>
              {selectedTeachers[0]["Preferred Language"] === 'French' && (
                <>{t("remaining-credits")}: {creditCalc}</>
              )}
            </span>
            {selectedTeachers[0]["Preferred Language"] !== 'French' && (
              <>
                <span>
                  {t("remaining-credits")}: {creditCalc}
                </span>
              </>
            )}
          </div>
          <Button
            color="primary"
            type="button"
            disabled={!canSave()}
            onClick={() => onDone()}
          >{loading && (<Loading size={14} />)}{t("submit-request")}</Button>
          <Button
            data-dismiss="modal"
            color="outline-primary"
            type="button"
            onClick={onToggle}
          >{t("cancel")}</Button>
        </div>
      )
    }

    return (
      <div className='credits-insufficient'>
        <div className='credit-status'>
          {t("insufficient-credits", { total: selectedTeachers[0]['Credit Balance'], session: creditCost })}
          <br />However, we invite you to request this Session as a Wish List booking.
          <br />We'll note this for possible future booking, pending funding availability.
        </div>
        <Button
          color="primary"
          type="button"
          disabled={!canSave()}
          onClick={() => onDone()}
        >{loading && (<Loading size={14} />)}{t("sessionrequest.submit")} Wish List {t("request")}</Button>
        <Button
          data-dismiss="modal"
          color="outline-primary"
          type="button"
          onClick={() => window.open(`/cn/credits/earn-more`)}
        >{t("learn-earn-credits")}</Button>
      </div>
    )
  }

  const filteredGrades = useMemo(() => {
    // Always include these special grades
    const specialGrades = ["Professional Development", "After School"];

    // If no grade range is set or user has multiple schools, show all grades
    if (!userInfo["Highest Grade"] || !userInfo["Lowest Grade"] ||
      (Array.isArray(userInfo["School Name"]) && userInfo["School Name"].length > 1)) {
      return grades;
    }

    // Convert array values to single values and strip spaces
    const lowestGradeValue = Array.isArray(userInfo["Lowest Grade"])
      ? userInfo["Lowest Grade"][0].replace(/\s+/g, '')
      : userInfo["Lowest Grade"]?.replace(/\s+/g, '');

    const highestGradeValue = Array.isArray(userInfo["Highest Grade"])
      ? userInfo["Highest Grade"][0].toString().replace(/\s+/g, '')
      : userInfo["Highest Grade"]?.toString().replace(/\s+/g, '');

    // Create an array of valid grades from lowest to highest
    let allowedGrades = [];

    // Start with K if it's in range
    if (lowestGradeValue === "K") {
      allowedGrades.push("K");
    }
    // Start with JK if it's in range
    if (lowestGradeValue === "JK") {
      allowedGrades.push("JK");
      allowedGrades.push("K");
    }

    // Add numeric grades
    const startNum = lowestGradeValue === "K" || lowestGradeValue === "JK" ? 1 : parseInt(lowestGradeValue);
    const highestNum = parseInt(highestGradeValue);
    for (let i = startNum; i <= highestNum; i++) {
      allowedGrades.push(i.toString());
    }

    // Filter grades but preserve their original objects with checked status
    const filtered = grades.filter(grade => {
      // Always include special grades
      if (specialGrades.includes(grade.name)) {
        return true;
      }

      return allowedGrades.includes(grade.name);
    });

    return filtered;
  }, [grades, userInfo]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      size='lg'
    >
      <div className="modal-header" style={{ paddingTop: '2.5rem', paddingBottom: 0 }}>
        <h3 className="modal-title" id="modal-title-default">
          {edit ? t("edit-request-for") : t("request-for")}
          &nbsp;
          {session['Provider Name Text'] || session['Session Provider Name Raw']}
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body" style={{ marginBottom: 0 }}>
        {(isBeforeDeadline && isIndigenousProvider && hasNoSupplies) && (
          <div className="day-limitations-msg warn-msg warn-msg-success">
            <span>While funding lasts, up to 3 new Session bookings per teacher with Indigenous content providers are available for 0 Credits! They must be booked to take place before March 31, and must not require a shipment of materials or supplies.</span>
          </div>
        )}
        {(!!provider && dayLimitations.length > 0) && (
          <div className="day-limitations-msg">
            <span>{t("sessionrequest.day-of-week-restrict-msg", { providername: provider.Name, daylimitation: getDayLimitationString() })}</span>
          </div>
        )}

        {(!!provider && provider['Other Limitations']) && (
          <div className="day-limitations-msg">
            <span>{provider['Other Limitations']}</span>
          </div>
        )}
        {session["No Requests when Supplies OOS"] && dayRestriction === 60 && !suppliesOption.startsWith("not-required") && (
          <div className="day-limitations-msg warn-msg warn-msg-red">
            <span>
              Unfortunately, the Supplies required for this Session are currently out of stock with no estimated availability date due to seasonal availability or other limitations. Please come back to try booking this Session next semester or school year!
            </span>
          </div>
        )}
        {!session["No Requests when Supplies OOS"] && (!!session?.Supplies?.length > 0) && (!session["Valid OAO"] || !session["Only Available On"] || availableDates?.length > 0) && !suppliesOption.startsWith("not-required") && (
          <div className="day-limitations-msg warn-msg">
            <span>
              {dayRestriction === 60
                ? "Due to low stock of required supplies for this Session, requests must be made 60 days in advance. The earliest available date is shown below."
                : "Due to the need for mailed Supplies for this Session, requests must be made 30 days in advance. The earliest available date is shown below."
              }
            </span>
          </div>
        )}
        {!!session["Availability End Date"] &&
          <div className="day-limitations-msg warn-msg warn-msg-red">
            <span>
              <AccessTimeIcon style={{ fontSize: '1.1em', marginRight: '4px', verticalAlign: 'text-bottom' }} />
              The Content Provider has made this Session available until {getFullDateString(session["Availability End Date"])}
            </span>
          </div>
        }

        {!!session["Only Available On"] && session["Valid OAO"] === "Valid" &&
          <div className="day-limitations-msg warn-msg warn-msg-red">
            {availableDates?.length > 0 ? (
              <>
                The Content Provider is only available on very specific dates due to their schedule. The first available date will be pre-selected below.
              </>
            ) : (
              <>
                The Content Provider is only available on very specific dates due to their schedule. As we await future date availability, this Session can not currently be requested.
              </>
            )}
          </div>
        }

        {(!session["Valid OAO"] || !session["Only Available On"] || availableDates?.length > 0) ? (
          <div className="">
            {isSchoolManager && (
              <FormGroup>
                <label>Is this request for another Teacher at your School?</label>
                <Select
                  className="form-style"
                  placeholder="Select a teacher"
                  value={(selectedTeachers && selectedTeachers.length) ? {
                    value: selectedTeachers[0].id,
                    label: (selectedTeachers[0].id === userInfo.id)
                      ? labelForMe
                      : selectedTeachers[0]["Teacher Name"]
                  } : null}
                  onChange={(selectedOption) => {
                    if (selectedOption.value === userInfo.id) {
                      setSelectedTeachers([userInfo]);
                    } else {
                      const t = teachers.find(ti => ti.id === selectedOption.value);
                      setSelectedTeachers([t]);
                    }
                  }}
                  options={[
                    { value: userInfo.id, label: labelForMe },
                    ...(teachers.map(t => ({
                      value: t.id,
                      label: t.displayName || t["Teacher Name"]
                    })))
                  ]}
                />
              </FormGroup>
            )}
            <FormGroup>
              <label>
                Session Length for <i>{session['Session Title Text']
                  ? session['Session Title Text']
                  : session['Session Title']}</i>
              </label>
              <Select
                className="form-style"
                options={sessionLengthOptions}
                value={sessionLengthOptions.find(option => option.value === sessionLength)}
                onChange={(selected) => setSessionLength(selected.value)}
                defaultValue={sessionLengthOptions[0]}
                isDisabled={isHandsOnSession}
              />
              {isHandsOnSession && (
                <small className="text-muted mt-2 d-block" style={{ fontSize: '14px' }}>
                  Hands-on Art/Science Sessions require the full Session time ({session["Length (Minutes)"]} minutes) to ensure completion.
                </small>
              )}
              {!isHandsOnSession && session["Minimum Length"] === session["Length (Minutes)"] && (
                <small className="text-muted mt-2 d-block" style={{ fontSize: '14px' }}>
                  This Provider is unfortunately not able to accommodate requests for reduced Session length.
                </small>
              )}
            </FormGroup>

            {!bookSessionLoading && (
              <SessionFullTimePicker
                preferTime={startDate}
                alterTime={alterDate}
                sessionLen={sessionLength}
                shouldDisableDate={shouldDisableDate}
                provider={provider}
                providerSession={session}
                bookedSessionId={edit ? requestedSession.id : null}
                teachers={selectedTeachers}
                labels={[
                  t("sessionrequest.prefer-time"),
                  t("sessionrequest.alternative-time")
                ]}
                onChange={(date, which) => {
                  if (which === "prefer") {
                    setStartDate(date);
                    //setPreferredTimeChanged(true);
                  } else {
                    setAlterDate(date);
                  }
                }}
                onValid={v => setTimeValid(v)}
                // Disable date pickers when supplies are out of stock
                disabled={suppliesOutOfStock}
              />
            )}

            <FormGroup>
              <div className="multicheck-container">
                <label>{t("grade-levels")}</label>
                <div className="multicheck">
                  {filteredGrades.map((grade, i) => (
                    <div
                      key={i}
                      className={grade.checked ? "multicheck-item item-checked" : "multicheck-item"}
                      onClick={() => {
                        const gs = [...grades];
                        const originalIndex = gs.findIndex(g => g.name === grade.name);
                        if (originalIndex !== -1) {
                          gs[originalIndex].checked = !gs[originalIndex].checked;
                          setGrades(gs);
                        }
                      }}
                    >
                      <span>
                        {
                          (grade.name === "Professional Development" && appLocale === 'fr')
                            ? "Développement professionnel"
                            : (grade.name === "Professional Development")
                              ? "Professional Dev."
                              : (grade.name === "After School" && appLocale === 'fr')
                                ? "Activité parascolaire"
                                : (grade.name === "K" && userInfo?.["School P/T Text"] === "ON")
                                  ? "SK"
                                  : grade.name
                        }
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </FormGroup>

            <FormGroup>
              <label>{t("sessionrequest.number-of-students")}</label>
              <Input
                defaultValue={students}
                onChange={(e) => {
                  setStudents(Math.max(0, e.target.value));
                  setStudentsError(""); // Clear error when user makes changes
                }}
                type="number"
                min="0"
                style={studentsError ? { borderColor: "#f5365c" } : {}}
              />
              {studentsError && (
                <div style={{ color: "#f5365c", fontSize: "0.875rem", marginTop: "0.25rem" }}>
                  {studentsError}
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <label>{t("sessionrequest.notes")}</label>
              <Input
                defaultValue={notes}
                placeholder={session["Custom Teacher Question"]
                  ? `${session["Custom Teacher Question"]}\n\n${t("sessionrequest.notes-msg")}`
                  : t("sessionrequest.notes-msg")
                }
                rows={session["Custom Teacher Question"] ? "4" : "2"}
                onChange={(e) => setNotes(e.target.value)}
                type="textarea"
              />
            </FormGroup>
          </div>
        ) : (
          <div className="modal-footer" style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              color="secondary"
              onClick={onToggle}
            >
              {t("close")}
            </Button>
          </div>
        )}
      </div>
      {(!session["Valid OAO"] || !session["Only Available On"] || availableDates?.length > 0) && (
        <div className="modal-footer" style={{ justifyContent: 'center', paddingTop: 0 }}>
          {creditCheck(edit)}
        </div>
      )}
    </Modal>
  )
}

export default RequestForm;
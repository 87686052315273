import { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap"
import { Divider } from "@mui/material"
import { School, MenuBook, Assignment, People, EmojiPeople, Explore, Group, HowToReg, Help } from "@mui/icons-material"
import "./styles.scss"

function App() {
  const [activeSection, setActiveSection] = useState("introduction")

  // Sections for navigation
  const sections = [
    { id: "introduction", label: "Intro", icon: <School /> },
    { id: "inuit-qaujimajatuqangit", label: "IQ & ISV", icon: <Explore /> },
    { id: "education-act", label: "Education Act", icon: <MenuBook /> },
    { id: "professional-standards", label: "Standards", icon: <Assignment /> },
    { id: "how-to-enroll", label: "Enroll", icon: <HowToReg /> },
    { id: "program", label: "Program", icon: <MenuBook /> },
    { id: "instructors", label: "Instructors", icon: <People /> },
    { id: "getting-started", label: "Get Started", icon: <EmojiPeople /> },
    { id: "support", label: "Support", icon: <Help /> },
  ]

  const isElementInView = (rect) => {
    const { top, bottom } = rect;
    const { innerHeight } = window;

    if (top < innerHeight) {
      return top < 0 ? bottom >= innerHeight : top >= 0;
    }
    return false;
  };

  // Handle scroll to update active section
  useEffect(() => {
    const handleScroll = () => {
      // Find the current section based on scroll position
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section.id);
        if (!element) return false;

        const rect = element.getBoundingClientRect();
        return isElementInView(rect);
      });
      if (currentSection) {
        setActiveSection(currentSection.id)
      }
    }

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Scroll to section function
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      setActiveSection(sectionId)
    }
  }

  return (
    <div className="sivuliaqtut-page">
      {/* Header */}
			<Container className="header">
				<Row className="align-items-center">
					<Col>
						<h1 className="display-4 text-white mb-0">Sivuliaqtut Program</h1>
						<h3 className="text-white mb-0">Government of Nunavut, Department of Education</h3>
					</Col>
				</Row>
			</Container>

      {/* Navigation */}
      <nav className="sub-navigation">
        <Container>
          <div className="nav-scroll-container">
            {sections.map((section) => (
              <Button
                key={section.id}
								id={`${section.id}-nav`}
                className={`nav-item ${activeSection === section.id ? "active" : ""}`}
                onClick={() => scrollToSection(section.id)}
              >
                <span className="nav-icon">{section.icon}</span>
                <span className="nav-label">{section.label}</span>
              </Button>
            ))}
          </div>
        </Container>
      </nav>

      {/* Main Content */}
      <Container className="main-content">
        {/* Introduction Section */}
        <section id="introduction" className="content-section">
          <SectionTitle title="Introduction" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <h3>Sivuliaqtut School Leaders Certification Program</h3>
                  <p>
                    Welcome to the Government of Nunavut Department of Education's Sivuliaqtut School Leaders
                    Certification Program.
                  </p>
                  <p>
                    <strong>Sivuliaqtut</strong> is an Inuktut word meaning 'moving forward together'. It is also a term
                    used for 'the bow of a boat' – a place where young children sit safely protected from the wind when
                    travelling long distances by boat. Both definitions provide beautiful metaphors for the knowledge
                    and skills our school leaders need to help our Nunavut schools move forward. Sometimes they need to
                    be 'the front of the boat (school)' and other times they are simply part of the boat (school) moving
                    forward together.
                  </p>
                  <p>
                    The Sivuliaqtut Program is for school leaders (current principals and vice-principals), and aspiring
                    leaders (educators). Through the completion of coursework focused on school leadership and the
                    priorities of the Nunavut education system, participants develop the knowledge and skills to enable
                    them to effectively lead Nunavut schools within the context of Inuit culture, students, families,
                    and communities.
                  </p>
                  <p>
                    By integrating leadership theories with practical skills, grounded in four key pillars of learning
                    (course work, practicum, mentorship, and shared leadership), participants receive actionable
                    guidance for their professional roles in Nunavut schools and communities. The core objective of the
                    Sivuliaqtut Program is to equip school leaders with targeted learning strategies that enable them to
                    effectively lead staff, manage schools, and achieve student success.
                  </p>
                  <p>
                    This document represents the information and processes for Nunavut educators to earn and meet the
                    certification requirements for school principal and vice-principal positions in Nunavut's education
                    system for kindergarten through grade 12.
                  </p>
                  <p>
                    The program launched in February 2025 and replaces all previous certification programs for
                    school leaders.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Inuit Qaujimajatuqangit Section */}
        <section id="inuit-qaujimajatuqangit" className="content-section">
          <SectionTitle title="Inuit Qaujimajatuqangit" />
          <Row>
            <Col md={8}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <p>
                    The Sivuliaqtut Program emphasizes the critical importance of Inuit Qaujimajatuqangit (IQ) and Inuit
                    Societal Values (ISV) in effectively leading schools and communities. Central to this program is the
                    integration of culturally relevant content, ensuring that participants not only understand but
                    embody the principles of IQ in their professional practice. This foundation guides school leaders to
                    lead with authenticity and respect for Inuit ways of being, knowing, and doing.
                  </p>
                  <p>
                    As a participant, you are encouraged to deeply engage with ISV and the guiding principles from an
                    Inuit perspective. While familiarity with the eight IQ principles is a starting point, true
                    leadership in Nunavut requires a profound understanding of Inuit ways of knowing and living.
                  </p>
                  <p>
                    This understanding involves dedicating time to build meaningful relationships with Inuit Elders and
                    community members. By immersing in these cultural practices and perspectives, you can effectively
                    integrate ISV into your leadership approach, fostering environments that are both respectful and
                    responsive to the unique needs of Nunavut's students and communities.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4 shadow-sm bg-light">
                <CardBody className="text-center">
                  <div className="">
                    <Explore style={{ fontSize: 80, color: "#59c6f2" }} />
                    <h4 className="mt-3">Inuit Societal Values</h4>
                    <p>Building meaningful relationships with Inuit Elders and community members</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Education Act Section */}
        <section id="education-act" className="content-section">
          <SectionTitle title="Nunavut's Education Act" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <p>Nunavut's education system is legislated through the Education Act.</p>
                  <p>
                    Certification of school leaders (principals and vice-principals), through the Sivuliaqtut Program,
                    is a mandatory requirement and a condition of employment. The Act requires that the school leader
                    certification program be a minimum of 240 hours.
                  </p>
                  <p>
                    Those who hold a principal or vice-principal role have four years from their date of hire to
                    complete the Sivuliaqtut Program, otherwise they must request a one-year extension to obtain the
                    certificate.
                  </p>
                  <p>Please refer to sections 111 and 112 of the Education Act for further information.</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Professional Standards Section */}
        <section id="professional-standards" className="content-section">
          <SectionTitle title="Nunavut Professional Standards for School Leaders" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <p>
                    The Nunavut Professional Standards for School Leaders provide guidance for effective leadership
                    within Nunavut's unique educational landscape. These standards are designed to support career-long
                    development, offering a benchmark for the competencies needed at various stages of a school leader's
                    career. They reflect the guiding principles and values of IQ, ISV, the Education Act, and the
                    provision of bilingual education in Nunavut's schools. These components ensure that the leadership
                    framework is not only comprehensive but also deeply rooted in the cultural and educational context
                    of Nunavut.
                  </p>
                  <p>The standards are structured around three core domains of leadership:</p>
                  <ul>
                    <li>Professional Knowledge</li>
                    <li>Professional Practice</li>
                    <li>Professional Engagement</li>
                  </ul>
                  <p>
                    Each of these domains encompasses specific standards that address the diverse aspects of effective
                    school leadership.
                  </p>
                  <p>There are five key standards specifically tailored to the Nunavut context.</p>

                  <div className="standards-container">
                    <div className="standard-item">Standard 1 - Leading the Management of the School</div>
                    <div className="standard-item">Standard 2 - Leading Teaching and Learning</div>
                    <div className="standard-item">Standard 3 - Developing Self and School Staff</div>
                    <div className="standard-item">Standard 4 - Leading Improvement, Innovation, and Change</div>
                    <div className="standard-item">Standard 5 - Leading and Working With the Community</div>
                  </div>

                  <p>
                    These standards ensure that leaders not only manage and innovate but also integrate Inuit values and
                    perspectives into all aspects of their practice.
                  </p>
                  <p>
                    In practice, effective school leadership in Nunavut draws on all three domains, reflecting the
                    holistic nature of the standards. By aligning leadership development with these standards, the
                    Sivuliaqtut Program fosters leaders who are well-prepared to meet the unique challenges of Nunavut's
                    educational environment while honoring its rich cultural heritage.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* How to Enroll Section */}
        <section id="how-to-enroll" className="content-section">
          <SectionTitle title="How to Enroll" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <p>
                    The Sivuliaqtut Program is designed to inspire and guide school leaders as they embark on their
                    journey towards becoming certified in Nunavut. Our approach is focused on removing barriers and
                    making the certification process rewarding and engaging, ensuring that your professional development
                    is both enriching and educational.
                  </p>
                  <p>
                    <strong>If you are an aspiring leader, this program is also for you!</strong> Although Nunavut's
                    Education Act requires all current school leaders to complete Nunavut's certification, the program
                    is also designed to support aspiring leaders.
                  </p>
                  <p>
                    Aspiring leaders are Nunavut educators who want to gain skills and knowledge in educational
                    leadership and are interested in becoming a principal or vice-principal in the future. If you are an
                    aspiring leader, we encourage you to speak to your school principal to discuss the application
                    process.
                  </p>
                  <p>
                    Whether you're currently working as a principal or vice-principal or looking to expand your
                    professional practice and aspire to be a school leader, we are here to support you every step of the
                    way, making your path to leadership a fulfilling and impactful experience.
                  </p>

                  <h3 className="mt-4">Who Can Apply?</h3>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody>
                          <h4>New school leaders</h4>
                          <ul>
                            <li>Current Nunavut principals and vice-principals working towards certification</li>
                            <li>
                              Current Nunavut principals and vice-principals recently hired with some level of school
                              leader certification and education from another Canadian or international jurisdiction
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody>
                          <h4>Aspiring school leaders</h4>
                          <ul>
                            <li>
                              Current Nunavut educators, with at least two years of successful full-time Nunavut
                              teaching experience, interested in pursing a career as a school leader, with a
                              recommendation from their school principal and superintendent
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <p className="mt-3">
                    By focusing on the needs and development of current and future leaders, the Sivuliaqtut Program aims
                    to enhance leadership capabilities and ensure that all school leaders are well-prepared to address
                    the unique demands of Nunavut's educational landscape.
                  </p>

                  <h3 className="mt-4">Certification Pathways</h3>
                  <p>The Sivuliaqtut Program has three certification pathways:</p>

                  <h4 className="mt-3">Two pathways for new school leaders</h4>
                  <Card className="mb-3 shadow-sm bg-light">
                    <CardBody>
                      <h5>New school leaders with no certification</h5>
                      <p>
                        If you are currently a school leader in Nunavut with no prior administration experience or
                        school leader certification and are working towards completing the certification requirements,
                        this is your pathway. You may receive credit for previously completed coursework in other
                        programs such as the Certificate of Educational Leadership in Nunavut (CELN).
                      </p>
                    </CardBody>
                  </Card>

                  <Card className="mb-3 shadow-sm bg-light">
                    <CardBody>
                      <h5>New school leaders with certification from another jurisdiction</h5>
                      <p>
                        If you are currently a school leader in Nunavut and have completed previous certification in
                        another jurisdiction, this is your pathway. You may receive credit for some of your previously
                        completed coursework and experience.
                      </p>
                    </CardBody>
                  </Card>

                  <h4 className="mt-3">One pathway for aspiring school leaders</h4>
                  <Card className="mb-3 shadow-sm bg-light">
                    <CardBody>
                      <h5>Aspiring school leaders currently working as educators in Nunavut</h5>
                      <p>
                        You are an educator drawn to school leadership and want to pursue a career as a principal or
                        vice-principal in Nunavut in the future. You have some experience with leadership in the school
                        and have received a recommendation from your school principal and superintendent. You must
                        complete all components of the program to receive a certificate.
                      </p>
                    </CardBody>
                  </Card>

                  <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th>Certification Requirements</th>
                          <th colSpan="2">New School Leaders</th>
                          <th>Aspiring Leaders</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>(no certification)</th>
                          <th>(certification from another jurisdiction)</th>
                          <th>(current educator)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Course work (120 hours)</td>
                          <td>May receive some program credits for CELN coursework</td>
                          <td>May receive some program credits for previous certification</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>Practicum (50 hours)</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>Mentorship (30 hours)</td>
                          <td>✓</td>
                          <td>May receive credit based on previous experience</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>Shared Leadership (40 hours)</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <strong>240 hours</strong>
                          </td>
                          <td>
                            <strong>240 hours</strong>
                          </td>
                          <td>
                            <strong>240 hours</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* The Program Section */}
        <section id="program" className="content-section">
          <SectionTitle title="The Program" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <h3>Overview</h3>
                  <p>
                    We're excited to help you become a dynamic leader in education. The Sivuliaqtut Program is designed
                    to combine formal learning with practical experience, personalized support, and continuous
                    professional growth to ensure you're prepared for your leadership role.
                  </p>

                  <h3 className="mt-4">The Four Pillars of Learning</h3>
                  <p>Our program is built around four key pillars that will guide your journey.</p>

                  <Row className="mt-3">
                    <Col md={6} lg={3}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody className="text-center">
                          <MenuBook style={{ fontSize: 50, color: "#59c6f2" }} />
                          <h4 className="mt-2">Course Work</h4>
                          <p>
                            Dive into engaging courses that lay the groundwork for your leadership skills and
                            theoretical knowledge.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={3}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody className="text-center">
                          <Assignment style={{ fontSize: 50, color: "#59c6f2" }} />
                          <h4 className="mt-2">Practicum</h4>
                          <p>
                            Put what you've learned into practice with hands-on experiences that let you apply theory in
                            real-world situations.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={3}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody className="text-center">
                          <People style={{ fontSize: 50, color: "#59c6f2" }} />
                          <h4 className="mt-2">Mentorship</h4>
                          <p>
                            Benefit from the guidance of experienced mentors who will offer support, feedback, and
                            valuable insights tailored to your growth.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={3}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody className="text-center">
                          <Group style={{ fontSize: 50, color: "#59c6f2" }} />
                          <h4 className="mt-2">Shared Leadership</h4>
                          <p>
                            Enhance your skills by working with learning coaches, student support teachers, and
                            Ilinniarvimmi Inuusilirjii to build practical skills in leading teaching and learning.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <h3 className="mt-4">Pillar 1 - Course Work</h3>
                  <p>
                    The coursework is designed to provide you with a comprehensive foundation in educational leadership
                    with a focus on developing specific competencies that are critical for effective school management
                    and community leadership in Nunavut.
                  </p>
                  <p>
                    Coursework represents half of your required certification hours and is offered in various formats
                    including in-person, online, or hybrid to suit different learning preferences and schedules.
                  </p>
                  <p>
                    This diverse and practical program is designed to prepare you with the skills and knowledge
                    necessary to be an effective school leader in Nunavut.
                  </p>

                  <div className="table-responsive mt-3">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th>Course</th>
                          <th>Length</th>
                          <th>Format</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Orientation to Culturally Responsive Leadership in Nunavut</td>
                          <td>10 hours</td>
                          <td>Online</td>
                        </tr>
                        <tr>
                          <td>Foundations of Culturally Responsive Leadership in Nunavut</td>
                          <td>40 hours</td>
                          <td>In-person</td>
                        </tr>
                        <tr>
                          <td>Leading Improvement, Innovation, and Change</td>
                          <td>40 hours</td>
                          <td>In-person</td>
                        </tr>
                        <tr>
                          <td>Cultivating Professional Growth in Education</td>
                          <td>30 hours</td>
                          <td>Hybrid</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <h3 className="mt-4">Pillar 2 - Practicum</h3>
                  <p>
                    The practicum component of the Sivuliaqtut Program is designed to provide practical, hands-on
                    experience in a leadership role. You will complete 50 hours of tailored practicum projects designed
                    to build competency in Professional Standard 5 – Leading and Working with the Community.
                  </p>
                  <p>
                    Each practicum project will involve identifying a school or community need, implementing a plan, and
                    reflecting on the outcomes. A standardized template will be provided to guide you through these
                    stages. Although you will have flexibility in choosing your projects, completing an on the land
                    practicum is a mandatory component of the Sivuliaqtut Program.
                  </p>
                  <p>
                    Practicum projects are aligned with the daily responsibilities of school leaders, focusing on
                    hands-on projects that benefit the school and community. Projects will be chosen from a variety of
                    categories including:
                  </p>
                  <ul>
                    <li>On the land (mandatory)</li>
                    <li>School events</li>
                    <li>Family-focus initiatives</li>
                    <li>Community outreach</li>
                    <li>School improvement</li>
                  </ul>
                  <p>
                    The Educator Development team will offer feedback, share resources, and facilitate connections
                    between school leaders and others across the territory to enhance the practicum experience.
                  </p>
                  <p>
                    Sivuliaqtut Program's practicum component encourages school leaders to develop meaningful projects
                    that integrate leadership with community engagement, creating lasting impact both inside and outside
                    the school environment.
                  </p>

                  <Row className="mt-3 align-items-center">
                    <Col md={7}>
                      <p>
                        On the land learning fosters connections with Inuit knowledge and practices. This practicum
                        focuses on cultural responsiveness through hands-on, land experiences. Leadership skills are
                        developed by learning and connecting with Elders, local guides, and the community.
                      </p>
                    </Col>
                    <Col md={5}>
                      <div className="land-image-placeholder">
                        <Explore style={{ fontSize: 80, color: "#59c6f2" }} />
                      </div>
                    </Col>
                  </Row>

                  <h3 className="mt-4">Pillar 3 - Mentorship</h3>
                  <p>
                    The mentorship component of the Sivuliaqtut Program is paired with the Cultivating Professional
                    Growth in Education course and is aimed at building competency in Professional Standard 3 –
                    Developing Self and School Staff Professional Learning.
                  </p>
                  <p>
                    Mentorship will commence at the Nunavut Principal's Conference and continue throughout the calendar
                    year, allowing for flexibility and real-world application within your daily responsibilities. The
                    mentorship is structured to complement course work, emphasizing strategies for providing impactful
                    feedback, modelling best practices, and supporting professional growth.
                  </p>
                  <p>
                    School leaders will be required to either receive or provide mentorship based on their experience
                    and prior completed course work and certification.
                  </p>

                  <h3 className="mt-4">Pillar 4 – Shared Leadership</h3>
                  <p>
                    The shared leadership component of the Sivuliaqtut Program is designed to foster collaboration
                    between you and key members of your team, including Learning Coaches (LCs), Student Support Teachers
                    (SSTs), and Ilinniarvimmi Inuusilirjii (IIs). Through collaborative online training modules, you
                    will build your competency in Professional Standard 2 – Leading Teaching and Learning, while working
                    alongside colleagues, LCs, SSTs, and IIs to support both teachers and students.
                  </p>
                  <p>
                    This component emphasizes shared leadership as a vital practice for school improvement, with LCs,
                    SSTs, and IIs leading the implementation of key strategies while you provide guidance and oversight.
                    The collaborative nature of this training aligns with professional development for you, LCs, SSTs,
                    and IIs while strengthening team dynamics and supporting a unified approach to leading teaching and
                    learning.
                  </p>

                  <h4 className="mt-3">Training Modules</h4>
                  <ol>
                    <li>
                      <strong>Leading Teaching:</strong> In this module, you will work with LCs to develop the skills to
                      effectively support teachers in the classroom. The focus will be on instructional leadership,
                      feedback, and fostering a collaborative teaching environment.
                    </li>
                    <li>
                      <strong>Leading Learning:</strong> In this module, you will work with SSTs and IIs to address
                      student needs, with a focus on differentiated instruction, inclusion, and student success
                      strategies.
                    </li>
                  </ol>
                  <p>
                    This course is delivered with LCs, SSTs, and IIs participating, incorporating both shared learning
                    and leadership practices. LCs, SSTs, and IIs will complete practicums related to these modules, with
                    you providing support and feedback, ensuring alignment with the overall goals of shared leadership
                    and school improvement.
                  </p>
                  <p>
                    This shared leadership component ensures that you are actively engaging with key members of your
                    staff to lead teaching and learning efforts collaboratively, strengthening the school's capacity to
                    improve outcomes for both teachers and students.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Instructors Section */}
        <section id="instructors" className="content-section">
          <SectionTitle title="Instructors" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <p>
                    The Sivuliaqtut Program features instructors who are carefully selected for their extensive
                    leadership experience and deep knowledge of Nunavut's educational landscape. Their expertise ensures
                    a rich and relevant learning experience, providing valuable insights and guidance specifically
                    tailored to the unique needs of Nunavut's schools and communities.
                  </p>

                  <Row className="mt-4">
                    <Col md={6}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody>
                          <h4>Highly Experienced</h4>
                          <ul>
                            <li>
                              Instructors have years of hands-on experience in educational leadership roles and adult
                              learning
                            </li>
                            <li>Chosen for their dynamic teaching styles and ability to engage and inspire learners</li>
                            <li>Committed to providing personalized support to participant</li>
                            <li>Proven track record of successfully leading schools and educational programs</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card className="mb-3 shadow-sm bg-light h-100">
                        <CardBody>
                          <h4>Expertise in Diverse Areas</h4>
                          <ul>
                            <li>
                              Specializations include curriculum development, teacher mentorship, educational policy,
                              and school management
                            </li>
                            <li>Practical experience in Nunavut schools</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <p className="mt-3">
                    We're excited to have dedicated professionals supporting your journey and look forward to the
                    positive impact they'll make on your development as a school leader.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Getting Started Section */}
        <section id="getting-started" className="content-section">
          <SectionTitle title="Getting Started" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <Row className="align-items-baseline">
                    <Col md={8}>
                      <p>
                        To enroll or apply for the program is a simple two-step process with the Educator Development
                        team available to help you along the way.
                      </p>

                      <div className="table-responsive mt-4">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th>New school leaders</th>
                              <th>Aspiring school leaders</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>✓ Review the courses & schedule and complete the application form</td>
                              <td>
                                ✓ Discuss the Sivuliaqtut Program with the principal and complete the application form
                              </td>
                            </tr>
                            <tr>
                              <td>✓ Submit the form to eduk-12@gov.nu.ca</td>
                              <td>✓ Submit an application to the principal</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="alert alert-info mt-3">
                        <p className="mb-0">
                          You can expect a response on your application from Educator Development within two weeks of
                          the application deadline.
                        </p>
                      </div>

                      <div className="alert alert-warning mt-3">
                        <p className="mb-0">
                          Enrollment and completion of the Sivuliaqtut Program is a condition of employment for
                          principals and vice-principals.
                        </p>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div className="info-box">
                        <div className="info-icon">
                          <HowToReg style={{ fontSize: 60, color: "#59c6f2" }} />
                        </div>
                        <div className="info-text">
                          <p>Sivuliaqtut is a complementary program for Nunavut educators.</p>
                          <p>
                            Acceptance into the program includes all your course materials and any related travels
                            costs.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <p className="mt-4">
                    To request an enrollment form, simply email the Educator Development support team at eduk-12@gov.nu.ca.
                  </p>

                  <Card className="mb-3 shadow-sm bg-light">
                    <CardBody>
                      <h5>Sivuliaqtut Program Enrollment + Application Form</h5>
                      <p>
                        This form must be completed by new school leaders enrolling in the program and aspiring school
                        leaders applying to the program. Aspiring school leaders require a recommendation from their
                        school principal and approval by the regional superintendent of schools or the Director General
                        (CSFN).
                      </p>
                    </CardBody>
                  </Card>

                  <Card className="mb-3 shadow-sm bg-light">
                    <CardBody>
                      <h5>Sivuliaqtut Program Course Deferral Request and Reschedule Form</h5>
                      <p>
                        This form is required when registered participants are unable to attend a course during the
                        scheduled timeframe and are requesting permission to defer (or de-register from) the course or
                        register for an alternative session to complete the program requirements. Course deferments
                        require approval from the regional superintendent of schools or the Director General (CSFN).
                      </p>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Support Section */}
        <section id="support" className="content-section">
          <SectionTitle title="Support" />
          <Row>
            <Col md={12}>
              <Card className="mb-4 shadow-sm">
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <h3>We're here to support you on your learning journey!</h3>
                      <p>
                        If you have any questions or need assistance regarding the Sivuliaqtut Program, we're here to
                        help.
                      </p>

                      <h4 className="mt-4">Contact Us for Support</h4>
                      <p>For inquiries related to:</p>
                      <ul>
                        <li>
                          <strong>Application and Registration:</strong> Need help with the application process or
                          registering for courses? We can help!
                        </li>
                        <li>
                          <strong>Academic or Program Curriculum:</strong> Questions about what you'll be learning or
                          the structure of the program? We're here to clarify.
                        </li>
                        <li>
                          <strong>Course and Program Scheduling:</strong> Need information on when courses are available
                          or how to fit them into your schedule? We can assist.
                        </li>
                      </ul>
                      <p>
                        Please reach out to the Educator Development division at{" "}
                        <a href="mailto:eduk-12@gov.nu.ca">eduk-12@gov.nu.ca</a>. Our team is dedicated to providing you
                        with the support you need to succeed.
                      </p>
                    </Col>
                    <Col md={4} className="text-center">
                      <div className="support-box">
                        <div className="support-icon">
                          <Help style={{ fontSize: 60, color: "#59c6f2" }} />
                        </div>
                        <div className="support-text">
                          <h4>Need help? Have questions?</h4>
                          <p>
                            We're excited to work with you and help you achieve your goals with the Sivuliaqtut Program.
                          </p>
                          <p>
                            Don't hesitate to contact us with any questions or concerns—your success is our priority!
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  )
}

// Section Title Component
function SectionTitle({ title }) {
  return (
    <div className="section-title">
      <h2>{title}</h2>
      <Divider className="section-divider" />
    </div>
  )
}

export default App


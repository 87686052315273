import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TimePicker from "./TimePicker";
import classNames from "classnames";
import { isSameTime } from "../../utils/time";

const SessionFullTimePicker = ({
  preferTime,
  alterTime,
  inRow = false,
  withAlterTime = true,
  controlAlterTime = false,
  sessionLen,
  shouldDisableDate,
  onChange,
  onValid,
  onChangeAlterDateShow,
  provider,
  providerSession,
  bookedSessionId,
  teachers,
  labels,
  isAddAlterTime = true,
  internalError
}) => {
  const [preferTimeValid, setPreferTimeValid] = useState("");
  const [alterTimeValid, setAlterTimeValid] = useState("");

  useEffect(() => {
    if (withAlterTime && isAddAlterTime && isSameTime(preferTime, alterTime)) {
      onValid("invalid");
    } else if (withAlterTime && isAddAlterTime) {
      onValid(preferTimeValid === "valid" && alterTimeValid === "valid" ? "valid" : "invalid");
    } else {
      onValid(preferTimeValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferTimeValid, alterTimeValid, isAddAlterTime]);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerInRow]: inRow
      })}
    >
      <div className={styles.timePicker}>
        <TimePicker
          label={labels[0]}
          sessionLen={sessionLen}
          onChange={(date) => onChange(date, "prefer")}
          shouldDisableDate={shouldDisableDate}
          date={preferTime}
          onValid={v => setPreferTimeValid(v)}
          provider={provider}
          providerSession={providerSession}
          bookedSessionId={bookedSessionId}
          teachers={teachers}
          internalError={internalError}
        />
      </div>
      {withAlterTime && (
        <div className={styles.timePicker}>
          <TimePicker
            label={labels[1]}
            sessionLen={sessionLen}
            onChange={date => onChange(date, "alter")}
            shouldDisableDate={shouldDisableDate}
            date={alterTime}
            onValid={v => setAlterTimeValid(v)}
            provider={provider}
            providerSession={providerSession}
            bookedSessionId={bookedSessionId}
            teachers={teachers}
            controlTime={controlAlterTime}
            isAddTime={isAddAlterTime}
            onChangeIsAddDate={onChangeAlterDateShow}
            avoidSameDay={preferTime}
          />
        </div>
      )}
    </div>
  )
}

export default SessionFullTimePicker;
import React from 'react'
import { Box, Tooltip } from "@mui/material";
import { monthNames, getSessionsForMonth } from "../utils/monthNames";
import { subjectInfo } from "../utils/subjectColors";

const MontlySessionCards = ({sessions, selectedMonth, userType}) =>  {
  const currentMonthIndex = selectedMonth;
  const monthName = monthNames[currentMonthIndex];
  const sessionsForMonth = getSessionsForMonth(sessions, currentMonthIndex);

  return (
    <Box
      key={monthName}
      sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1.5 }}
    >
      <h3 className="mb-0">{monthName}</h3>
      {sessionsForMonth && sessionsForMonth.length > 0 ?
        sessionsForMonth.map((session, index) => {
          return (
            <Box
              className="border border-info p-1"
              sx={{ backgroundColor: "#f3f9ff", borderRadius: "5px" }}
              key={index}
            >
              <div
                className="d-flex align-items-center"
                style={{ gap: "0.2rem" }}
              >
                <div
                  className="rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    width: "1.5rem",
                    minWidth: "1.5rem",
                    height: "1.5rem",
                    backgroundColor: subjectInfo[session["Primary Subject Text"]?.[0]]?.color,
                  }}
                >
                  <Tooltip title={session["Primary Subject Text"]?.[0] || ""} arrow placement="top">
                    <i
                      className={`fa ${subjectInfo[session["Primary Subject Text"]?.[0]]?.icon}`}
                      style={{ fontSize: '0.8rem', color: '#fff' }}
                    />
                  </Tooltip>
                </div>
                {userType === 'Provider' ? (
                  <span className="font-weight-700 lh-100" style={{color: "inherit"}}>
                    {session["Session Title Raw"]}
                  </span>
                ) : (
                  <a href={`https://sessions.connectednorth.org/cn/session/${session["Session Title"]}`} target="_blank" rel="noopener noreferrer" className="font-weight-700 lh-100" style={{color: "inherit"}}>
                    {session["Session Title Raw"]}
                  </a>
                )}
              </div>
              <div className="lh-100 mt-1" style={{ fontSize: "16px" }}>
                {
                session["# Schools"] > 1 ?
                  session["Primary Subject Text"]?.includes("Professional Development") ?
                    `Professional Learning with ${session["# Teachers"]} teachers from ${session["# Schools"]} schools` :
                    `Premium Session with ${session["# Teachers"]} classrooms from ${session["# Schools"]} schools` :
                  userType === 'Provider' ?
                    session["School Name Text"] :
                    session["Teacher Name"].join(", ")
                }
              </div>
              <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mt-1" style={{ gap: "0.2rem", fontSize: "14px" }}>
                <div>{session["Nice Date"].replace("Eastern", "").trim()}</div>
                <div>{session["Length (Minutes)"]} mins</div>
              </div>
            </Box>
          );
        })
      : "No session for this Month"}
      </Box>
  );
}

export default MontlySessionCards;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from "../../../airtables";

export const useCalendar = () => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [teacher] = useState(null);
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [sessions, setSessions] = useState([]);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);

  // Compute the academic year (if currentMonth <= 6, assume we're still in the previous academic year)
  useEffect(() => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    if (currentMonth <= 6) {
      setYear(currentYear - 1);
    }
  }, []);

  // Fetch user and school data.
  useEffect(() => {
    async function fetchData() {
      if (userInfo) {
        let schools = []
        if (userType === "Team") {
          if(userInfo["Administrator"]) {
            schools = await airtable.schools.listAll();
          }
          else {
            schools = await airtable.schools.getTeamSchools(userInfo);
          }
        }
        else if (userType === "Teacher") {
          if (userInfo["Cluster Leadership"]) {
            const cluster = await airtable.clusters.select(
              userInfo["Cluster Leadership"][0],
            );
            schools = await airtable.schools.listByIds(cluster["Schools"]);
          } else {
            schools = await airtable.schools.listByIds(userInfo["School Name"]);
          }
        }
        else if (userType === "Provider") {
          setIsLoadingSessions(true);
          const sessionsData = await airtable.sessions.getProviderSessionsInYear(userInfo.id, userInfo.Name);
          setSessions(sessionsData);
          setIsLoadingSessions(false);
        }

        setSchools(schools);

        // Find user's school in the schools list
        const userSchoolName = Array.isArray(userInfo["School Name Text"])
          ? userInfo["School Name Text"][0]
          : userInfo["School Name Text"];
        const defaultSchool = schools.find((s) => s.School === userSchoolName) || schools[0];
        setSchool(defaultSchool);
      }
    }
    fetchData();
  }, [userInfo, userType]);

  // Load all sessions for the current user's school within the defined year range.
  useEffect(() => {
    async function fetchSessions() {
      if (!school) return;
      setIsLoadingSessions(true);

      try {
        const sessionsData = await airtable.sessions.getSchoolSessionsInYear(school.id, school.School);

        setSessions(sessionsData);
      } catch (error) {
        console.error("Error fetching sessions data:", error);
      } finally {
        setIsLoadingSessions(false);
      }
    }
    fetchSessions();
  }, [school]);

  return {
    teacher,
    school,
    schools,
    year,
    sessions,

    isLoadingSessions,
    setSchool,
  };
};

import { IconButton } from "@mui/material";
import { CalendarIcon, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import SessionValidationChecker from "./SessionValidationChecker";

const TimePicker = ({
  label,
  date,
  sessionLen,
  shouldDisableDate,
  onChange,
  onValid,
  provider,
  providerSession,
  bookedSessionId,
  teachers,
  controlTime,
  isAddTime,
  onChangeIsAddDate,
  avoidSameDay,
  internalError
}) => {
  const [openTime, setOpenTime] = useState(false);

  return (
    <FormGroup>
      <label>
        {controlTime && (
          <Input
            type="checkbox"
            checked={isAddTime}
            onChange={() => onChangeIsAddDate(!isAddTime)}
          />
        )}
        {label}
      </label>
      {(!controlTime || isAddTime) && (
        <Fragment>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              open={openTime}
              shouldDisableDate={shouldDisableDate}
              minutesStep={5}
              ampm
              value={date ? dayjs(date) : null}
              onChange={(e) => onChange(e.toDate())}
              onClose={() => setOpenTime(false)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  readOnly: true,
                  InputProps: {
                    endAdornment: (
                      <Fragment>
                        {!!date && (
                          <span
                            className={classNames({
                              "end-adornment": true,
                              "end-adornment-normal": sessionLen < 90,
                              "end-adornment-bold": sessionLen >= 90
                            })}
                          >
                            until {dayjs(date).add(sessionLen, 'minute').format('h:mm A')}
                          </span>
                        )}
                        <IconButton onClick={() => setOpenTime(!openTime)}>
                          <CalendarIcon />
                        </IconButton>
                      </Fragment>
                    )
                  }
                },
              }}
            />
          </LocalizationProvider>
          <SessionValidationChecker
            time={date}
            avoidSameDay={avoidSameDay}
            session={providerSession}
            provider={provider}
            teachers={teachers}
            bookedSessionId={bookedSessionId}
            changeValidation={v => onValid(v)}
            sessionLength={sessionLen}
            internalError={internalError}
          />
        </Fragment>
      )}
    </FormGroup>
  )
}

export default TimePicker;
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal
} from 'reactstrap';
import Loading from '../../../components/Loading';
import airtable from '../../../airtables';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSessionBook from '../../../libs/hooks/use-session-book';
import SessionFullTimePicker from '../../../components/SessionFullTimePicker';

const SessionReject = ({ providerSession, session, sessionId, onToggle, onPost }) => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [startDate, setStartDate] = useState();
  const [alterDate, setAlterDate] = useState();
  const [commentValid, setCommentValid] = useState();
  const [pSession, setPSession] = useState(null);
  const { t } = useTranslation();
  const [teachers] = useState([userInfo]);
  const [timeValid, setTimeValid] = useState("");

  const {
    loading: bookSessionLoading,
    provider,
    shouldDisableDate
  } = useSessionBook({
    providerSession: pSession,
    bookType: "edit",
    session: session,
    teachers: teachers
  });

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    const sess = await airtable.sessions.select(sessionId);
    if (!providerSession) {
      const ps = await airtable.providerSessions.select(sess["Session Title"][0]);
      setPSession(ps);
    } else {
      setPSession(providerSession);
    }
    setLoading(false);
  }

  const canSave = () => {
    if (loading) return false;
    if (timeValid !== "valid") return false;
    return true;
  }

  const onSubmit = async () => {
    setSubmitting(true);

    const recentSessionInfo = await airtable.sessions.select(sessionId);
    const schedulingAttempts = (recentSessionInfo["Scheduling Attempts"] || 0) + 1

    airtable.sessions.update(
      sessionId,
      {
        "Teacher Booking Response": `${comment}`,
        "Session Start Date/Time": `${startDate.toString()}`,
        "Alternative Date/Time": `${alterDate.toString()}`,
        "Status": "Pending Provider Response",
        "Notify Teacher of Provider Response": false,
        "Scheduling Attempts": schedulingAttempts
      }
    ).then(async (res) => {
      await fetch(`https://hooks.zapier.com/hooks/catch/89715/37q7as9?sessionID=${sessionId}&action=reject`, {
        method: "GET",
        mode: "no-cors",
      });
      onToggle();
      if (onPost) onPost();
    }).catch(error => {
      alert(error.toString());
    });
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      backdrop={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Propose Alternates (Neither time is available)</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            if (submitting) return;
            onToggle()
          }}
        ><span aria-hidden={true}>×</span></button>
      </div>
      <div className="modal-body">
        <label>Please provide details on future availability/ideal booking times for the provider:</label>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              style={{ minHeight: 100 }}
              onChange={(e) => {
                setComment(e.target.value)
                setCommentValid(!e.target.value ? 'invalid' : 'valid')
              }}
              invalid={commentValid === 'invalid'}
              disabled={submitting}
            />
          </FormGroup>
          <label>
            Can you propose two ideal dates and times for the provider to review?
          </label>

          {!bookSessionLoading && (
            <SessionFullTimePicker
              preferTime={startDate}
              alterTime={alterDate}
              sessionLen={session["Length (Minutes)"]}
              shouldDisableDate={shouldDisableDate}
              provider={provider}
              providerSession={pSession}
              bookedSessionId={session.id}
              teachers={teachers}
              labels={[
                t("sessionrequest.prefer-time"),
                t("sessionrequest.alternative-time")
              ]}
              onChange={(date, which) => {
                if (which === "prefer") {
                  setStartDate(date);
                } else {
                  setAlterDate(date);
                }
              }}
              onValid={v => setTimeValid(v)}
            />
          )}
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >Close</Button>
        <Button
          color="success"
          type="button"
          onClick={() => onSubmit()}
          disabled={submitting || !canSave()}
        >{submitting && (<Loading size={14} />)}Submit Response</Button>
      </div>
    </Modal>
  )
}

export default SessionReject;

export const getGrade = (key) => {
	return key === "Kindergarten" ? key : `Grades ${key.replace("To", "-")}`;
};

export const setGrade = (grade) => {
	return grade.includes("Grades")
		? grade.split(" ")[1].replace("-", "To")
		: "Kindergarten";
};

export const minHours = {
	Kindergarten: 475,
	"1To6": 952,
	"7To12": 1000,
};

export const maxDailyMinutes = {
	Kindergarten: 360,
	"1To6": 360,
	"7To12": 390,
};

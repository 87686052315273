export const hasMultipleSchools = (userInfo, isSessionHost) => {
    if (isSessionHost) {
        return false;
    }
    
    return (
        (Array.isArray(userInfo["Schools Managed"]) && userInfo["Schools Managed"].length > 0) ||
        (Array.isArray(userInfo["School Name"]) && userInfo["School Name"].length > 1) ||
        userInfo["District Leader?"]
    );
};
import React from 'react';
import './styles.scss';

const CopyToClipboard = ({ value, copied }) => {
  const copyClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(value);
    } else {
      document.execCommand("copy", true, value);
    }
    copied && copied();
  }

  const downloadPDF = () => {
    window.open("https://research.tigweb.org/Connected North Student Survey.pdf", "_blank")
  }

  return (
    <div className='copy-to-clipboard-container'>
      <div className='copy-to-clipboard-text'>
        <span>{value}</span>
      </div>
      <div className='addon'>
        <span onClick={e => copyClipboard()}>Copy</span>&nbsp;&nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;&nbsp;
        <span onClick={e => downloadPDF()}><i className="fa fa-file-pdf-o" />&nbsp;Download PDF</span>
      </div>
    </div>
  )
}

export default CopyToClipboard;
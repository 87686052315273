import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewLightbulbCalenderIcon = () => (
  <SvgIcon width="28" height="29" viewBox="0 0 28 29" fill="none">
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6624 23.3674H2.33441C1.59747 23.3674 1 22.8352 1 22.1787V7.36743" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.18203 3.59082C5.12653 3.72289 5.0929 3.86874 5.0929 4.02249C5.0929 4.6434 5.60032 5.14805 6.2247 5.14805C6.84908 5.14805 7.35651 4.6434 7.35651 4.02249C7.35651 3.86874 7.32277 3.72289 7.26727 3.59082" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.22485 4.00287V1.36743" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.238 3.59082C21.2955 3.72289 21.3292 3.86874 21.3292 4.02249C21.3292 4.6434 20.8217 5.14805 20.1974 5.14805C19.571 5.14805 19.0656 4.6434 19.0656 4.02249C19.0656 3.86874 19.0973 3.72289 19.1548 3.59082" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.1971 4.00287V1.36743" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.9465 2.09668H7.47583" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M25.4223 7.38921V3.28528C25.4223 2.62889 24.8871 2.09668 24.227 2.09668H21.448" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.9742 2.09668H2.1952C1.53514 2.09668 1 2.62889 1 3.28528V7.38921H25.4222V10.6928" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.60657 11.7061V13.8408" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.1288 11.7061V13.8408" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.60657 16.9336V19.0703" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.1288 16.9336V19.0703" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.6532 11.7236V13.8584" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5858 16.9336V19.0703" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.81665 15.3882H16.443" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.87082 22.1985H4.36934V8.57202H2.18896V21.5204C2.18896 21.8949 2.49421 22.1985 2.87082 22.1985Z" fill="#9DE0F8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.2361 18.1244C27.2361 15.8598 25.3115 14.0423 23.0001 14.2011C21.0701 14.3339 19.498 15.8744 19.3392 17.7923C19.2395 18.9975 19.6872 20.1006 20.4619 20.883C20.8989 21.3236 21.1608 21.9067 21.1608 22.5255V22.5401H25.4008V22.5255C25.4008 21.9229 25.6348 21.3414 26.0652 20.917C26.7879 20.2043 27.2361 19.2178 27.2361 18.1244Z" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.1353 23.551H25.4255" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.6586 26.6999H22.9023C21.9264 26.6999 21.1353 25.9127 21.1353 24.9424V24.6589H25.4255V24.9424C25.4255 25.9127 24.6345 26.6999 23.6586 26.6999Z" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.3978 26.7371V27.0449C22.3978 27.5293 22.793 27.9229 23.2805 27.9229C23.7677 27.9229 24.1631 27.5293 24.1631 27.0449V26.7339" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.2808 13.2912V12.3674" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.1533 14.4928L19.5698 13.7847" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.408 14.4928L26.9914 13.7847" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.2802 15.1633C23.2096 15.1633 23.1384 15.1666 23.0669 15.1714C22.2689 15.2249 21.5529 15.6023 21.0492 16.1693C21.5543 16.0866 22.0712 16.0802 22.5748 16.1709C23.6038 16.3572 24.5541 16.9436 25.178 17.7778C25.5535 18.2816 25.8071 18.8696 25.9247 19.4851C26.1417 19.0704 26.2583 18.6072 26.2583 18.1244C26.2583 16.4916 24.9224 15.1633 23.2802 15.1633Z" fill="#9DE0F8" />
    </svg>
  </SvgIcon>
)

import { useEffect, useState } from "react";
import Airtable from "../../../airtables";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CREDIT_PER_PAGE = 10;
const SESSION_STORAGE_ENTRY = "credits";

/**
 * mapping the displayed to the action (airtable credit column)
 * the 2nd nested array is all the availble value for the display
 * NOTE: using array since the indexes to be in order for the filter to work
 *
 * @type {Array<Array<string>>}
 */
const CREDIT_TYPE = [
	["Requested Sessions", ["Requested", "In Planning", "Session Requested"]],
	["Booked Sessions", ["Session Booked"]],
	["Cancelled Sessions", ["Session Cancelled"]],
	["Completed Sessions", ["Session Completed"]],
	["Achievement Credits", ["Credits Earned from Achievement"]],
	["Purchased Credits", ["Credits Purchased", "Credits Issued by Program"]],
	[
		"Transferred Credits",
		[
			"Credits Transferred from a Teacher",
			"Credits Transferred to a Teacher",
		],
	],
];

/** @typedef {object} CreditRecord
 * @property {string} id
 * @property {number} ID
 * @property {string} Date
 * @property {string[]?} Session
 * @property {string[]} Teacher
 * @property {number} Credits
 * @property {string} Action
 * @property {string[]} Session Status
 * @property {number} amount
 * @property {number} onHold
 * @property {number} balance
 *
 * @typedef {object} UseCreditProps
 * @property {CreditRecord[]} creds
 * @property {boolean} loading
 * @property {boolean} purchaseOK
 * @property {number} totalCred
 * @property {number} page
 * @property {number} achievementCredit
 * @property {React.SetStateAction<React.Dispatch<number>>} setPage
 * @property {number} pageCount
 * @property {boolean} filterOpen
 * @property {() => void} toggleFilter
 * @property {Array<boolean>} filterValues
 * @property {Array<Array<string>>} filterMap
 * @property {(i: number) => React.ChangeEventHandler<HTMLInputElement>} handleFilterSelect
 *
 * @returns {UseCreditProps}
 */
export function useCreditRecord() {
	const { teacherId: reviewTeacherId } = useParams();
	const { userInfo, userType } = useSelector((state) => state.appInfo);

	const teacherId = userType === "Team" ? reviewTeacherId : userInfo.id;
	const [teacher, setTeacher] = useState();

	/** @type {string[]} */
	const [creditIDs, setCreditIDs] = useState();
	/** @type {[CreditRecord[], React.Dispatch<React.SetStateAction<CreditRecord[]>>]}*/
	const [creds, setCreds] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalCred, setTotalCred] = useState(0);
	const [page, setPage] = useState(0);
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [achievementCredit, setAchievementCredit] = useState(0);

	async function fetchRecords() {
		try {
			if (!creditIDs.length) {
				return;
			}

			const credits = await getCredits(creditIDs);
			credits.sort((i, j) => Date.parse(j.Date) - Date.parse(i.Date));
			let balance = 0;
			let totalCredit = 0;
			let achievement = 0;

			// NOTE: looping in reversed order, since credits is sorted by date
			// descending, and the math needs it to be in date ascending.
			for (let i = credits.length - 1; i >= 0; --i) {
				const c = credits[i];
				c.amount = 0;
				c.onHold = 0;

				if (c.Action !== "Session Booked") {
					c.amount += c.Credits;
				} else {
					const s = c["Session Status"][0] ?? "";
					if (s === "Completed" || s === "Booked") {
						c.amount = c.Credits;
					} else {
						c.onHold = c.Credits;
					}
					c.Action = "Session " + s;
				}

				if (c["Action"] === "Credits Earned from Achievement") {
					achievement += c["Credits"];
				}

				balance += c.Credits;
				totalCredit += c.Credits;
				c.balance = balance;
				credits[i] = c;
			}

			setAchievementCredit(() => achievement);

			// useEffect that handles pagination pulls data from sessionStorage
			window.sessionStorage.setItem(
				SESSION_STORAGE_ENTRY,
				JSON.stringify(credits),
			);

			setTotalCred(() => (totalCredit > 0 ? totalCredit : 0));
			setCreds(() => credits.slice(0, CREDIT_PER_PAGE)); // set first page
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(() => false);
		}
	}

	const [purchaseOK, setPurchaseOK] = useState(false);
	// unsetting the `purchaseOK` after 5 seconds
	useEffect(() => {
		if (!purchaseOK) return;
		const timeoutID = setTimeout(() => {
			setPurchaseOK(() => false);
		}, 5000);
		return () => clearTimeout(timeoutID);
	}, [purchaseOK, setPurchaseOK]);

	// FETCH AND PARSE
	useEffect(() => {
		if(!creditIDs) return;
		const param = new URL(window.location.href).searchParams;
		if (param.get("success")) {
			fetchRecords().then(() => setPurchaseOK(() => true));
		} else {
			fetchRecords();
		}
	}, [creditIDs]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if(userType !== "Team") {
			setCreditIDs(userInfo.Credits ?? []);
			return;
		}

		const fetchTeacher = async () => {
			const teacher = await Airtable.teachers.select(teacherId);
			setCreditIDs(teacher.Credits ?? []);
			setTeacher(teacher);
		}

		fetchTeacher();
	}, [teacherId]); // eslint-disable-line react-hooks/exhaustive-deps

	// HANDLES PAGINATION AND FILTERING

	/** @type {[boolean[], React.Dispatch<React.SetStateAction<boolean[]>>]} */
	const [filterValues, setFilterValues] = useState(
		new Array(CREDIT_TYPE.length).fill(false),
	);
	const [filterOpen, setFilterOpen] = useState(false);

	const v = Math.ceil((creditIDs?.length || 0) / CREDIT_PER_PAGE); // `page` is 0 based
	/** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
	const [pageCount, setPageCount] = useState(v);
	useEffect(() => {
		const c = window.sessionStorage.getItem(SESSION_STORAGE_ENTRY);
		if (!c) return;

		/** @type {CreditRecord[]} */
		let credits = JSON.parse(c);
		const low = page * CREDIT_PER_PAGE;
		let high = low + CREDIT_PER_PAGE;
		if (high > credits.length) high = credits.length;

		/** @type {Array<string>} */
		const filters = [];
		for (let i = 0; i < filterValues.length; ++i) {
			if (filterValues[i]) filters.push(...CREDIT_TYPE[i][1]);
		}

		if (filters.length) {
			credits = credits.filter((c) => filters.includes(c.Action));
		}

		setPageCount(() => Math.ceil(credits.length / CREDIT_PER_PAGE));
		setCreds(() => credits.slice(low, high));
	}, [page, filterValues]);

	// button handlers
	const toggleFilter = () => setFilterOpen((o) => !o);

	/**
	 * @param {number} i
	 * @returns {React.ChangeEventHandler<HTMLInputElement>}
	 * */
	function handleFilterSelect(i) {
		return (_) => {
			const s = [...filterValues];
			s[i] = !s[i];
			setFilterValues(() => s);
		};
	}

	return {
		filterValues,
		filterMap: CREDIT_TYPE,
		filterOpen,
		handleFilterSelect,
		toggleFilter,
		teacher,
		loading,
		creds,
		totalCred,
		page,
		setPage,
		pageCount,
		purchaseOK,
		achievementCredit,
	};
}

/**
 * getCredits:
 * query credits table to get session id's, then session table
 * and update the session id with the session description before
 * returning.
 *
 * @param {string[]} creditIDs
 * @returns [import("../../airtables/tables/Credit.js").Promise<CreditRecord[]>]
 */
async function getCredits(creditIDs) {
	// GET CREDITS
	const credits = await Airtable.credit.listByIds(creditIDs);

	/**
	 * @typedef {object} SessionRecord
	 * @property {string} id
	 * @property {string} Session
	 **/
	/** @type {Record<string, SessionRecord>} */
	const sessionMap = {};
	for (let i = 0; i < credits.length; ++i) {
		const session = credits[i].Session;
		if (session) sessionMap[session[0]] = {};
	}

	// get session
	/** @type {SessionRecord[]} */
	const sessions = await Airtable.sessions.listByIds(Object.keys(sessionMap));
	for (let i = 0; i < sessions.length; ++i) {
		const { id, Session: s } = sessions[i];
		sessionMap[id] = s;
	}

	// session description -> credit array
	for (let i = 0; i < credits.length; ++i) {
		if (!credits[i].Session) continue;
		const s = sessionMap[credits[i].Session[0]];
		if (s) credits[i].Session = [s];
	}

	return credits;
}

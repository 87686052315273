import AirtableTable from "../../AirtableTable";
import { dayType } from "../../../utils/schoolCalendar";
export class SchoolCalendarTable extends AirtableTable {
  constructor(instance) {
    super(instance, "School Calendar");

    this.fields = {
      "ID": "fldUW34JPI2IXlr5D",
      "School": "fldhrVKdYOtGrqETj",
      "Status": "fldWvt5eaUTGwSHop",
      "Approval Motion": "fldVQjfZTX2lCRxF9",
      "Year": "fldoOWfL8IAtUSVHs",
      "First Day (Principal)": "fldzll1IpyuKF3mHQ",
      "First Day (Teachers)": "fldUG1gHZa9Eg7mEQ",
      "First Day (Students)": "fld8jeGqA2IrpPMJ3",
      "Last Day (Students)": "fldc65Qvw7ul7kPXz",
      "Last Day (Teachers)": "fldmC4Sbsp7cFFVMl",
      "Last Day (Principal)": "fldS03iEuijxTC3v6",
      "Calendar Days": "fldoDZh4yWMuEnexM",
      "O Days": "fldwEqjVN85qYMrBD",
      "D Days": "fldhTphrfxVFxAY9h",
      "School Schedule": "fldmqz6sSoaiLq4iK",
      "Custom Calendar Motion": "fld2yrt6X8iO2pC2R",
      "Day Plan Feedback": "fldng9Ocqg1gizZxD",
    };
  }

  async getRecordsFromLinkedTable(linkedTable, recordIds, filterFormula = null) {
    const batches = [];
    for (let i = 0; i < recordIds.length; i += 100) {
      batches.push(recordIds.slice(i, i + 100));
    }

    const results = await Promise.all(
      batches.map(async (batch) => {
        const filter = batch.map((id) => `RECORD_ID() = '${id}'`).join(",");
        let formula = `OR(${filter})`;
        if (filterFormula) {
          formula = `AND(${formula}, ${filterFormula})`;
        }

        const records = await this.instance(linkedTable)
          .select({
            filterByFormula: formula,
          })
          .firstPage();

        return records.map((record) => ({
          id: record.id,
          ...record.fields,
        }));
      })
    );

    return results.flat();
  }

  async getSchoolCalendarByYear(school, year) {
    return new Promise((resolve, reject) => {
      this.selectOneByCondition({
        filterByFormula: `AND({School} = '${school}', {Year} = '${year}')`,
      })
        .then(async (calendarRecord) => {
          if (!calendarRecord) {
            resolve(null);
            return;
          }

          // Process the "School Schedule" field
          const scheduleIds = calendarRecord["School Schedule"];
          if (scheduleIds && scheduleIds.length > 0) {
            calendarRecord["School Schedule"] = await this.getRecordsFromLinkedTable(
              "School Schedule",
              scheduleIds
            );
          }

          // Process the "Calendar Days" field
          const dayIds = calendarRecord["Calendar Days"];
          if (dayIds && dayIds.length > 0) {
            calendarRecord["Calendar Days"] = await this.getRecordsFromLinkedTable(
              "Calendar Days",
              dayIds
            );
          }

          resolve(calendarRecord);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getSchoolCalendarWithDaysByYear(school, year) {
    return new Promise((resolve, reject) => {
      this.selectOneByCondition({
        filterByFormula: `AND({School} = '${school}', {Year} = '${year}')`,
      })
      .then(async (calendarRecord) => {
        if (!calendarRecord) {
          resolve(null);
          return;
        }

        // Process the "Calendar Days" field
        const dayIds = calendarRecord["Calendar Days"];
        const feedbackIds = calendarRecord["Day Plan Feedback"];
        // similar to dayIds && dayIds.length > 0 condition block add logic for feedbackIds
        if(feedbackIds && feedbackIds.length > 0) {
          const comments = await this.getRecordsFromLinkedTable(
            "Day Plan Feedback",
            feedbackIds
          );

          // Collect all Teachers IDs
          const allTeacherIds = [...new Set(
            comments
              .flatMap(comment => comment["Feedback From"] || [])
              .filter(id => id)
          )];

          const allTeachers = allTeacherIds.length > 0
            ? await this.getRecordsFromLinkedTable("tbljALpvSMnM64XNf", [...allTeacherIds])
            : [];

          // Create a map of Teachers by ID for quick lookup
          const teacherMap = Object.fromEntries(
            allTeachers.map(t => [t.id, t])
          );

          // Assign teachers to their respective Day Plan Feedback
          for (let comment of comments) {
            if (comment["Feedback From"] && comment["Feedback From"].length > 0) {
              comment["Feedback From"] = comment["Feedback From"]
                .map(teacherId => teacherMap[teacherId])
                .filter(teacher => teacher)?.[0]; // Remove any null/undefined entries
            }
          }
          // Sort calendar days by Date
          comments.sort((a, b) => new Date(a.Date) - new Date(b.Date));

          calendarRecord["Day Plan Feedback"] = comments;
        }

        if (dayIds && dayIds.length > 0) {
          // Get Calendar Days that are type "O" or "D" only
          const calendarDays = await this.getRecordsFromLinkedTable(
            "Calendar Days",
            dayIds,
            `OR({Type} = '${dayType.oDay}', {Type} = '${dayType.dDay}')`
          );

          // Collect all Day Plan IDs
          const allDayPlanIds = calendarDays
            .flatMap(day => day["Day Plans"] || [])
            .filter(id => id);

          // Fetch all Day Plans in a single request
          const allDayPlans = allDayPlanIds.length > 0
            ? await this.getRecordsFromLinkedTable("Day Plans", allDayPlanIds)
            : [];

          // Create a map of Day Plans by ID for quick lookup
          const dayPlansMap = Object.fromEntries(
            allDayPlans.map(plan => [plan.id, plan])
          );

          // Assign Day Plans to their respective Calendar Days
          for (let day of calendarDays) {
            if (day["Day Plans"] && day["Day Plans"].length > 0) {
              day["Day Plans"] = day["Day Plans"]
                .map(planId => dayPlansMap[planId])
                .filter(plan => plan); // Remove any null/undefined entries
            }
          }
          // Sort calendar days by Date
          calendarDays.sort((a, b) => new Date(a.Date) - new Date(b.Date));

          calendarRecord["Calendar Days"] = calendarDays;
        }

        resolve(calendarRecord);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  async getAllCalendarsForSchool(school) {
    return new Promise((resolve, reject) => {
      this.list({
        filterByFormula: `OR({School} = '${school}')`,
      })
        .then(async (calendarRecord) => {
          resolve(calendarRecord);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

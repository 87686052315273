import React, { useState, useId } from "react";
import { Menu as MuiMenu, MenuItem, Box } from "@mui/material";
import { Button } from "reactstrap";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const Menu = ({ title, menuItems, disabled, onItemClick }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const id = useId();

	return (
		<>
			<Button
				color="primary"
				outline
				id={id}
				aria-controls={`basic-menu-${id}`}
				aria-haspopup="true"
				aria-expanded={open}
				onClick={handleClick}
				disabled={disabled}
			>
				<Box component="span" display="flex" gap="0.5rem">
					{title}
					<KeyboardArrowDownRoundedIcon />
				</Box>
			</Button>
			<MuiMenu
				id={`basic-menu-${id}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": id,
				}}
			>
				{menuItems?.map((item) => (
					<MenuItem
						key={item.title}
						component="a"
						href={item.pdfUrl}
						download={item.fileName}
						onClick={() => {handleClose();onItemClick(item.id)}}
					>
						{item.title}
					</MenuItem>
				))}
			</MuiMenu>
		</>
	);
};

export default Menu;

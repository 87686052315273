import React, { useState } from "react";
import { CircularProgress, Box, TextField, Typography } from "@mui/material";
import { Button } from "reactstrap";
import { Card } from "../../../components/Card";
import { useDayPlanner } from "./hooks";
import { Planner } from "./components/Planner";
import Select from "react-select";
import { dayType } from "../../../utils/schoolCalendar";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import { useSelector } from "react-redux";

const DayPlanner = () => {
	const {
		isloading,
		isCalendarloading,
		oDays,
		dDays,
		comments,
		years,
		selectedYear,
		schools,
		selectedSchool,
		addComment,
		setSelectedYear,
		setSelectedSchool,
		updateDayPlan,
	} = useDayPlanner();
	const [expandedDay, setExpandedDay] = useState(false);
	const [comment, setComment] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { userInfo } = useSelector((state) => state.appInfo);
	const isLeader = !!userInfo["Cluster Leadership"];
	const loading = isloading || isCalendarloading;
	const onToggleDay = (panel) => (_event, isExpanded) => {
		setExpandedDay(isExpanded ? panel : false);
	};

	const handleCommentSubmit = async () => {
		if(!isLeader) return;
		// Handle comment submission logic here
		setIsSubmitting(true);
		const res = await addComment(comment);
		console.log("Comment submitted:", comment, res);
		setComment("");
		setIsSubmitting(false);
	};

	return (
		<div className="my-3 main-container">
			<Box sx={{ mb: 4 }}>
				<Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
					<h1 className="mb-0">D & O Day Planner</h1>
				</Box>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
				<h2 className="mb-0">Select Calendar Year</h2>
				<Select
					className="form-style"
					value={{ label: selectedYear, value: selectedYear }}
					placeholder="Select a Calendar Year"
					onChange={(selectedOption) => setSelectedYear(selectedOption.value)}
					options={years.map((y) => ({
						value: y,
						label: y,
					}))}
					styles={{
						control: (styles) => ({
							...styles,
							borderRadius: "16px",
							width: "200px",
							fontSize: "large",
						}),
					}}
				/>
				{!!schools && schools.length > 1 && (
					<div className="school-selector" style={{ marginLeft: "auto" }}>
						<Select
							className="form-style"
							value={
								selectedSchool
									? { value: selectedSchool.id, label: selectedSchool.School }
									: null
							}
							placeholder="Select a School"
							onChange={(selectedOption) => {
								const sc = schools.find((s) => s.id === selectedOption.value);
								if (sc) {
									setSelectedSchool(sc);
								}
							}}
							options={schools
								.filter(
									(sc) =>
										!sc.School.includes("Mobile Unit") &&
										!sc.School.includes("Floor"),
								)
								.map((sc) => ({
									value: sc.id,
									label: sc.School,
								}))}
							styles={{
								control: (styles) => ({ ...styles, minWidth: "200px" }),
							}}
						/>
					</div>
				)}
			</Box>

			{loading ? (
				<div className="d-flex align-items-center justify-content-center py-5">
					<CircularProgress />
				</div>
			) : !years || years.length === 0 ? (
				<div className="d-flex align-items-center justify-content-center py-5">
					No School Calendars have been created for this school yet.
				</div>
			) : (
				<>
					<section className="section">
						<Card className="bg-white">
							<Box sx={{ mb: 3 }}>
								<h3 className="mb-2 mr-2">
									<SmsRoundedIcon
										fontSize="1.5rem"
										style={{ marginRight: "8px" }}
									/>
									Superintendent Comments
								</h3>

								{comments && comments.length > 0 && (
									<Box sx={{ mt: 3 }}>
										{comments.map((comment) => (
											<Box
												key={comment.id}
												sx={{
													mb: 2,
													p: 3,
													bgcolor: '#ffffff',
													borderRadius: 2,
													boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
													border: '1px solid #e0e0e0',
												}}
											>
												<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
													<Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
														{comment["Feedback From"]["Teacher Name"]}
													</Typography>
													<Typography variant="body2" color="text.secondary">
														{new Date(comment["Reviewed On"]).toLocaleDateString('en-US', {
															year: 'numeric',
															month: 'long',
															day: 'numeric'
														})}
													</Typography>
												</Box>
												<Typography variant="body1" sx={{ lineHeight: 1.6 }}>
													{comment["Comments"]}
												</Typography>
											</Box>
										))}
									</Box>
								)}

								{isLeader && (
									<Box
										sx={{
											mt: 4,
											p: 3,
											bgcolor: '#ffffff',
											borderRadius: 2,
											boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
											border: '1px solid #e0e0e0',
										}}
									>
										<Typography variant="h6" sx={{ mb: 2 }}>
											Add New Comment
										</Typography>
										<TextField
											label="Your comment"
											variant="outlined"
											fullWidth
											multiline
											rows={3}
											value={comment}
											onChange={(e) => setComment(e.target.value)}
											sx={{
												mb: 2,
												'& .MuiOutlinedInput-root': {
													borderRadius: '8px',
												},
											}}
										/>
										<Button
											color="primary"
											type="submit"
											disabled={isSubmitting || !comment.trim()}
											onClick={handleCommentSubmit}
											style={{
												borderRadius: "8px",
												padding: "8px 24px",
												fontWeight: 600,
												textTransform: "none",
												display: "flex",
												alignItems: "center",
											}}
										>
											{isSubmitting ? (
												<>
													<CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
													Submitting...
												</>
											) : "Add Comment"}
										</Button>
									</Box>
								)}
							</Box>
						</Card>
					</section>

					<section className="section">
						<Card className="bg-white">
							<Box sx={{ mb: 3 }}>
								<h3 className="mb-0 mr-2">D Days</h3>
								{dDays && dDays.length > 0 ? (
									dDays.map((day) => (
										<Box key={day.id}>
											<Planner
												day={day}
												expanded={expandedDay === day.id}
												onToggleExpand={onToggleDay(day.id)}
												onUpdate={updateDayPlan}
											/>
										</Box>
									))
								) : (
									<div className="d-flex align-items-center justify-content-center">
										No {dayType.dDay} days exist in your calendar for{" "}
										{selectedYear}.
									</div>
								)}
							</Box>
						</Card>
					</section>

					<section className="section">
						<Card className="bg-white">
							<Box sx={{ mb: 3 }}>
								<h3 className="mb-0 mr-2">O Days</h3>
								{oDays && oDays.length > 0 ? (
									oDays.map((day) => (
										<Box key={day.id}>
											<Planner
												day={day}
												expanded={expandedDay === day.id}
												onToggleExpand={onToggleDay(day.id)}
												onUpdate={updateDayPlan}
											/>
										</Box>
									))
								) : (
									<div className="d-flex align-items-center justify-content-center">
										No {dayType.oDay} days exist in your calendar for{" "}
										{selectedYear}.
									</div>
								)}
							</Box>
						</Card>
					</section>
				</>
			)}
		</div>
	);
};

export default DayPlanner;

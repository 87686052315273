import { Box, Typography } from "@mui/material";

export const NumberCard = ({
	title,
	number,
	isLeftAlign,
	data,
	dataSecondary,
	primaryDataLabel,
	secondaryDataLabel,
}) => {
	return (
		<Box
			sx={{
				width: "100%",
				border: "1px solid #9DE0F8",
				borderRadius: "1.25rem",
				backgroundColor: "#fff",
				padding: "1rem 1.5rem",
				textAlign: isLeftAlign ? "left" : "center",
				marginBottom: "1.5rem",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#0D1120",
					fontSize: "1.25rem",
					fontWeight: "700",
				}}
			>
				{title}
			</Typography>

			<Typography
				sx={{
					fontFamily: "Effra, sans-serif",
					color: "#1094CE",
					fontSize: "2.5rem",
					fontWeight: "400",
				}}
			>
				{number}
			</Typography>

			{(data !== null || data !== undefined) && (
				<Box
					sx={{
						marginTop: "1rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box>
						<Typography
							sx={{
								fontFamily: "Effra, sans-serif",
								color: "#0D1120",
								fontSize: "1.125rem",
								fontWeight: "700",
							}}
						>
							{primaryDataLabel}
						</Typography>

						<Typography
							sx={{
								fontFamily: "Effra, sans-serif",
								color: "#1094CE",
								fontSize: "2.5rem",
								fontWeight: "400",
							}}
						>
							{data}
						</Typography>
					</Box>
					<Box>
						<Typography
							sx={{
								fontFamily: "Effra, sans-serif",
								color: "#0D1120",
								fontSize: "1.125rem",
								fontWeight: "700",
							}}
						>
							{secondaryDataLabel}
						</Typography>

						<Typography
							sx={{
								fontFamily: "Effra, sans-serif",
								color: "#1094CE",
								fontSize: "2.5rem",
								fontWeight: "400",
							}}
						>
							{dataSecondary}
						</Typography>
					</Box>
				</Box>
			)}
		</Box>
	);
};

import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewSessionsIcon = () => (
    <SvgIcon width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
            <path fillRule="evenodd" clipRule="evenodd" d="M25 12.5011C25 6.14915 19.6288 1 13.0001 1C6.37357 1 1 6.14915 1 12.5011C1 18.8509 6.37357 24 13.0001 24C19.6288 24 25 18.8509 25 12.5011Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.5827 22L27.6716 25.0888C28.1095 25.5252 28.1095 26.2352 27.6716 26.6716C27.2337 27.1095 26.5252 27.1095 26.0873 26.6716L23 23.5827" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.6299 25.8889C22.4832 24.4984 25.5379 21.4438 26.9262 17.5927" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0977 10.7571C17.0977 8.49249 15.1383 6.67502 12.785 6.83376C10.8202 6.96659 9.21968 8.50706 9.05798 10.425C8.95649 11.6302 9.41222 12.7333 10.2009 13.5157C10.6459 13.9563 10.9126 14.5394 10.9126 15.1582V15.1728H15.2292V15.1582C15.2292 14.5556 15.4675 13.9741 15.9056 13.5497C16.6414 12.837 17.0977 11.8505 17.0977 10.7571Z" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.8867 16.1836H15.2546" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.4557 19.3325H12.6857C11.6922 19.3325 10.8867 18.5453 10.8867 17.575V17.2915H15.2546V17.575C15.2546 18.5453 14.4493 19.3325 13.4557 19.3325Z" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.1719 19.3698V19.6776C12.1719 20.162 12.5742 20.5556 13.0706 20.5556C13.5666 20.5556 13.9691 20.162 13.9691 19.6776V19.3666" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.0708 5.92376V5" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.887 7.12563L9.29297 6.41748" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2549 7.12563L16.8489 6.41748" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.0701 7.7959C12.9983 7.7959 12.9259 7.79915 12.8531 7.80401C12.0406 7.85746 11.3116 8.23487 10.7988 8.80182C11.3131 8.71921 11.8393 8.71273 12.3521 8.80345C13.3997 8.98973 14.3672 9.57615 15.0023 10.4104C15.3846 10.9141 15.6428 11.5021 15.7625 12.1177C15.9834 11.703 16.1022 11.2397 16.1022 10.757C16.1022 9.12419 14.7421 7.7959 13.0701 7.7959Z" fill="#9DE0F8"/>
        </svg>
  </SvgIcon>
)

import AirtableTable from "../../AirtableTable";

export class CalendarDaysTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Calendar Days");

    this.fields = {
      "ID": "fldq5KpKy73OoO0NT",
      "Display": "fldKrq6eejcWznrgm",
      "Calendar": "fld28MHZCZPHlmkj3",
      "Name": "fldPsIwjPK5lvCsab",
      "Year": "fldeaAcsLELXksRRi",
      "Date": "fldqni2heNm8uuPsE",
      "Type": "fldFenlEkDHrm44bG",
      "Length": "fldaHgV4BUVZj9km3",
      "Minutes": "fldyj5CzWtmA0r7o7",
    };
  }

  getGenericCalendarDays = (startDate, endDate) => {
    // Validate input dates
    if (!startDate || !endDate) {
      console.warn('Invalid dates provided to getGenericCalendarDays:', { startDate, endDate });
      return [];
    }

    const condition = {
      filterByFormula: `AND(
        {Date} >= "${startDate}",
        {Date} <= "${endDate}",
        NOT({Calendar})
      )`,
      sort: [{ field: "Date", direction: "asc" }],
    };

    return this.list(condition);
  };

}

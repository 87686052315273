import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import airtable from "../../../../airtables/PDAirtable";
import cn_airtable from "../../../../airtables";
import { dayType } from "../../../../utils/schoolCalendar";

export const useDayPlanner = () => {
	const params = useParams();
	const schoolId = params.schoolId;

	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const [teacher, setTeacher] = useState();
	const [school, setSchool] = useState(null);
	const [schools, setSchools] = useState([]);
	const [selectedSchool, setSelectedSchool] = useState();
	const [years, setYears] = useState([]);
	const [selectedYear, setSelectedYear] = useState();
	const [isloading, setIsLoading] = useState(true);
	const [isCalendarloading, setIsCalendarLoading] = useState(true);
	const [canLoadDays, setCanLoadDays] = useState(true);
  const [schoolCalendar, setSchoolCalendar] = useState();
  const [oDays, setODays] = useState([]);
  const [dDays, setDDays] = useState([]);
  const [comments, setComments] = useState([]);
	const teacherId = userInfo?.id;

	// Fetch teacher and school data
	const fetchTeacherAndSchoolData = async () => {
		if (!teacherId) return;

		let sIds = null;
		setIsLoading(true);
		try {
			const teacherData = await airtable.nunavutTeachers.getTeacherByRecordId(teacherId);
			setTeacher(teacherData);
			if (userType === "Teacher") {
				if (userInfo["Cluster Leadership"]) {
					const cluster = await cn_airtable.clusters.select(
						userInfo["Cluster Leadership"][0],
					);
					sIds = cluster["Schools"];
				} else {
					sIds = userInfo["School Name"];
				}
				if (!sIds || !sIds.length) {
					setSchools([]);
				}
			}

			const schools = await cn_airtable.schools.getAllSchools(sIds);
			setSchools(schools);
			if (teacherData) {
				// Find user's school in the schools list
				const userSchoolName = Array.isArray(userInfo["School Name Text"])
					? userInfo["School Name Text"][0]
					: userInfo["School Name Text"];

				let defaultSchool;
				if(schoolId) {
					defaultSchool = schools.find(s => s.id === schoolId);
				}
				defaultSchool = defaultSchool ? defaultSchool :
					schools.find((s) => s.School === userSchoolName) || schools[0];

				await fetchSchoolData(defaultSchool.School);
				setSelectedSchool(defaultSchool);
			}
		} catch (err) {
			console.error("Error fetching teacher and school data:", err);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchSchoolData = async (schoolName) => {
		if (school && schoolName === school.School) return;
		setCanLoadDays(false);
		const schoolData = await airtable.schools.getSchoolByName(schoolName);

		setSchool(schoolData);
		const calendars = await airtable.schoolCalendar.getAllCalendarsForSchool(schoolName);
		const years = ( calendars && calendars.length > 0 ) ? calendars.map(calendar => calendar.Year) : [];
		setYears(years);
		setSelectedYear(years?.[0]);
		if(!years || !years[0]) {
			setComments([]);
			setODays([]);
			setDDays([]);
		}
		setCanLoadDays(true);
	};

	// Fetch the school calendar based on selected year
	const fetchSchoolCalendar = async () => {
		if (!school || !selectedYear) return;
		setIsCalendarLoading(true);
		try {
			setComments([]);
			setODays([]);
			setDDays([]);
			const calendarData = await airtable.schoolCalendar.getSchoolCalendarWithDaysByYear( school["School"], selectedYear);
			setSchoolCalendar(calendarData);
      if (calendarData && calendarData["Calendar Days"]) {
        setODays(calendarData["Calendar Days"].filter(day => day.Type === dayType.oDay))
        setDDays(calendarData["Calendar Days"].filter(day => day.Type === dayType.dDay))
      }
			if(calendarData && calendarData["Day Plan Feedback"]) {
				setComments(calendarData["Day Plan Feedback"]);
			}
		} catch (err) {
			console.error("Error fetching school:", err);
		} finally {
			setIsCalendarLoading(false);
		}
	};

	// Effect to fetch teacher and school data
	useEffect(() => {
		fetchTeacherAndSchoolData();
	}, [teacherId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch Nunavut School
  useEffect(() => {
    if (selectedSchool) fetchSchoolData(selectedSchool.School);
  }, [selectedSchool]); // eslint-disable-line react-hooks/exhaustive-deps

  // Effect to fetch school calendar after school data is set
	useEffect(() => {
		if(canLoadDays && selectedYear) fetchSchoolCalendar();
	}, [canLoadDays, selectedYear]); // eslint-disable-line react-hooks/exhaustive-deps

	const addComment = async (comment) => {
		const res = await airtable.dayPlanFeedback.create({
			"Comments": comment,
			"School": [school.id],
			"School Calendar": [schoolCalendar.id],
			"Feedback From": [teacher.id],
		});
		// update comments state as well
		if (res) {
			setComments([...comments, {...res, "Feedback From": teacher}]);
			return true;
		}
		else {
			return false;
		}
	};

  const updateDayPlan = async (dayId, fields) => {
    const params = {
      "Topic": fields["Topic"],
      "Alignment": fields["Alignment"],
      "Support": fields["Support"],
      "Support Needed?": fields["Support Needed?"],
    }

    let res;

    if(fields.id) {
      res = await airtable.dayPlans.update(fields.id, params);
    } else {
      res = await airtable.dayPlans.create({
        ...params,
        "Day Planned": [dayId],
        "Submitted By": [teacher.id],
      })
    }

    const calendar = {
      ...schoolCalendar,
      "Calendar Days": schoolCalendar["Calendar Days"].map(d => {
        if(d.id === dayId) {
          d["Day Plans"] = [res];
        }
        return d;
      })
    };


    setSchoolCalendar(calendar);
    if (calendar && calendar["Calendar Days"]) {
			const days = calendar["Calendar Days"].map(d => {
				if (d.id === dayId) {
					d["Day Plans"] = [res];
				}
				return d;
			})

      setODays(days.filter(day => day.Type === dayType.oDay))
      setDDays(days.filter(day => day.Type === dayType.dDay))
    }
  };

	return {
		isloading,
		isCalendarloading,
		years,
		selectedYear,
    oDays,
    dDays,
		comments,
    schools,
		selectedSchool,
		addComment,
    updateDayPlan,
		setSelectedYear,
		setSelectedSchool,
	};
};

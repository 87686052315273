import React from "react";
import { Card } from "../../../components/Card";
import SubjectsPieGraph from "../../../components/Graphs/SubjectsPie";
import s from "./style.module.scss";
import BarGraph from "../../../components/Graphs/Bar";
import { IndigenousGraph } from "../../Dashboard/components";
import { CircularProgress } from "@mui/material";

const SchoolCharts = ({ school, sessions, loading }) => {
	const renderSubjectsPieGraph = () => {
		if (loading) {
			return (
				<div>
					<div className={s.ContainerGraph}>
						<CircularProgress />
					</div>
					<SubjectsPieGraph title="Sessions by Subject Area" sessions={[]} />
				</div>
			);
		}

		if (!sessions || !sessions.length) {
			return (
				<div>
					<div className={s.ContainerGraph}>
						<div className={s.TextBox}>
							<p>No Sessions booked for this school yet.</p>
						</div>
					</div>
					<SubjectsPieGraph title="Sessions by Subject Area" sessions={[]} />
				</div>
			);
		}

		return (
			<SubjectsPieGraph title="Sessions by Subject Area" sessions={sessions} />
		);
	};

	const renderBarGraph = () => {
		if (loading) {
			return (
				<div>
					<div className={s.ContainerGraph}>
						<CircularProgress />
					</div>
					<BarGraph title="Sessions by Grade" sessions={[]} />
				</div>
			);
		}

		if (!sessions || !sessions.length) {
			return (
				<div>
					<div className={s.ContainerGraph}>
						<div className={s.TextBox}>
							<p>No Sessions booked for this school yet.</p>
						</div>
					</div>
					<BarGraph title="Sessions by Grade" sessions={[]} />
				</div>
			);
		}

		return <BarGraph title="Sessions by Grade" sessions={sessions} />;
	};

	return (
		<div className="school-charts">
			<section className="section">
				<Card className="bg-white">{renderSubjectsPieGraph()}</Card>
			</section>

			<div className="school-charts-grid" style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '2rem',
				width: '100%'
			}}>
				<div className="graph-box mb-5" style={{ flex: 1, minWidth: 0 }}>
					{renderBarGraph()}
				</div>
				<div className="graph-box mb-5" style={{ flex: 1, minWidth: 0 }}>
					<IndigenousGraph percentage={school["Indigenous % TY"]} />
				</div>
			</div>
		</div>
	);
};

export default SchoolCharts;

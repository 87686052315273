import React, { useEffect, useState } from "react";
import LoadingBar from "../../../components/LoadingBar";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setSession } from "../../../utils/session";
import { CONNECTEDNORTH_SIGNINFO } from "../../../config";
import { setAppUserInfo } from "../../../redux/actions";
import airtable from "../../../airtables";

const AddressPaymentTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.appInfo);
  const [updating, setUpdating] = useState(false);
  const [automaticInvoicing, setAutomaticInvoicing] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postal, setPostal] = useState("")
  const [country, setCountry] = useState("");
  const [invoiceName, setInvoiceName] = useState("");
  const [HSTTaxIDNumber, setHSTTaxIDNumber] = useState("");
  const [HSTTaxRate, setHSTTaxRate] = useState("");

  useEffect(() => {
    setAutomaticInvoicing((userInfo["PPPP"] && userInfo["Payment Type"] === "Automatic Invoices Per Session (PPPP)") ? true : false);
    setAddress(userInfo["Address"] || "");
    setCity(userInfo["City/Community"] || "");
    setProvince(userInfo["Province/Territory"] || "");
    setPostal(userInfo["Postal/Zip"] || "");
    setCountry(userInfo["Country"] || "");
    setInvoiceName(userInfo["PPPP Invoice Name"] || "");
    setHSTTaxIDNumber(userInfo["HST/Tax ID Number"] || "");
    setHSTTaxRate(userInfo["HST/Tax Rate"] || "");
  }, [userInfo]);

  const updateInfo = async () => {
    setUpdating(true);
    try {
      let params = {
        "PPPP": automaticInvoicing ? true : false,
        "Address": address,
        "City/Community": city,
        "Province/Territory": province,
        "Postal/Zip": postal,
        "Country": country,
        "PPPP Invoice Name": invoiceName,
        "HST/Tax ID Number": HSTTaxIDNumber,
        "HST/Tax Rate": HSTTaxRate === "" ? null : HSTTaxRate,
      }

      if (automaticInvoicing) {
        params["Payment Type"] = "Automatic Invoices Per Session (PPPP)";
      }

      const info = await airtable.providers.update(userInfo.id, params);

      await setSession(CONNECTEDNORTH_SIGNINFO, info);
      dispatch(setAppUserInfo(info));
      toast.success("Saved successfully!")
    } catch (error) { }
    setUpdating(false);
  }

  return (
    <div className="tab-container">
      {updating && <LoadingBar />}
      <div className="payment-form-section">
        <div className="section-header mt-4">
          <h4>Automatic Invoicing</h4>
          <p className="text-muted">
            By enabling automatic invoicing, we will automatically generate and send you a copy of an invoice for each Session you deliver, expediting the payment process.
          </p>
        </div>
        <FormGroup row>
          <Label for="automaticInvoicing" sm={4}>Enable Automatic Invoicing?</Label>
          <Col sm={8} className="v-center">
            <input
              id="automaticInvoicing"
              type="checkbox"
              checked={automaticInvoicing}
              onChange={(e) => setAutomaticInvoicing(e.target.checked)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="address" sm={4}>Address</Label>
          <Col sm={8} className="v-center">
            <Input
              type="text"
              name="address"
              id="address"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="city" sm={4}>City/Community</Label>
          <Col sm={8} className="v-center">
            <Input
              type="text"
              name="city"
              id="city"
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="province" sm={4}>Province/Territory</Label>
          <Col sm={8} className="v-center">
            <Input
              type="text"
              name="province"
              id="province"
              value={province}
              onChange={e => setProvince(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="postal" sm={4}>Postal/Zip</Label>
          <Col sm={8} className="v-center">
            <Input
              type="text"
              name="postal"
              id="postal"
              value={postal}
              onChange={e => setPostal(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="country" sm={4}>Country</Label>
          <Col sm={8} className="v-center">
            <Input
              type="text"
              name="country"
              id="country"
              value={country}
              onChange={e => setCountry(e.target.value)}
            />
          </Col>
        </FormGroup>
        {automaticInvoicing && (
          <>
            <div className="section-header mt-4">
              <h4>Business Payments and Tax</h4>
              <p className="text-muted">
                If your payments are going to a registered business, or if you need to charge taxes, please fill out the options below:
              </p>
            </div>
            <FormGroup row>
              <Label for="invoiceName" sm={4}>Invoice Name</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="text"
                  name="invoiceName"
                  id="invoiceName"
                  value={invoiceName}
                  placeholder="If different from your name"
                  onChange={e => setInvoiceName(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="HSTTaxIDNumber" sm={4}>HST/Tax ID Number</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="text"
                  name="HSTTaxIDNumber"
                  id="HSTTaxIDNumber"
                  value={HSTTaxIDNumber}
                  onChange={e => setHSTTaxIDNumber(e.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="HSTTaxRate" sm={4}>HST/Tax Rate</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="select"
                  name="HSTTaxRate"
                  id="HSTTaxRate"
                  className='pronouns form-control'
                  value={HSTTaxRate}
                  onChange={e => setHSTTaxRate(e.target.value === "" ? "" : Number(e.target.value))}
                >
                  <option value="">Select a tax rate (if applicable)</option>
                  <option value="0.05">5% (GST)</option>
                  <option value="0.13">13% (HST)</option>
                  <option value="0.14975">14.975% (QST)</option>
                </Input>
              </Col>
            </FormGroup>
          </>
        )}
      </div>
      <div className="ms-action" data-intercom-target="Save">
        <Button
          color="primary"
          size="sm"
          onClick={() => updateInfo()}
        >{t("save-change")}</Button>
      </div>
    </div>
  )
}

export default AddressPaymentTab;
import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "reactstrap";
import {
	Box,
	Typography,
	TextField,
	InputAdornment,
	FormHelperText,
} from "@mui/material";
import { RepeatableAccordion } from "../RepeatableAccordion";
import bgColor from "../../constants/dayColor";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const CalendarDays = ({
	type,
	description,
	maxDays = 0,
	dates,
	minDate,
	maxDate,
	onFinished,
	onDelete,
	disable = false,
	availableOHours,
	scheduledOHours,
	genericClosedDays = [],
	schoolClosedDays = [],
}) => {
	const title = type.split(" - ")[1].trim();
	const dayType = type.split("-")[0].trim();
	const displayQuarter = false;
	const displayCustom = dayType === "O";
	const formattedTotalHours = availableOHours
		? (Math.round(availableOHours * 10) / 10).toString().replace(/\.0$/, "")
		: "0";
	const formattedScheduledHours = scheduledOHours
		? (Math.round(scheduledOHours * 10) / 10).toString().replace(/\.0$/, "")
		: "0";
	const subtitle =
		maxDays > 0
			? `(Choose ${maxDays})`
			: dayType === "O"
				? availableOHours > 0
					? `(${formattedScheduledHours} of ${formattedTotalHours} hours used)`
					: "(No surplus hours)"
				: "(Number of Days Vary)";
	const [dateFields, setDateFields] = useState(
		dates && dates.length > 0
			? [...dates].sort((a, b) =>
					a.Date && b.Date ? a.Date.localeCompare(b.Date) : !a.Date ? 1 : -1,
				)
			: [
					{
						Date: null,
						Length: displayCustom ? null : "Full day",
						Type: type,
						Name: title,
						Minutes: null,
					},
				],
	);
	const [totalDays, setTotalDays] = useState(0);
	const [expanded, setExpanded] = useState(false);
	const [deleteIconVisible, setDeleteIconVisible] = useState(null);
	const [loading, setLoading] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});

	useEffect(() => {
		if (dates && !loading) {
			setDateFields(
				[...dates].sort((a, b) =>
					a.Date && b.Date ? a.Date.localeCompare(b.Date) : !a.Date ? 1 : -1,
				),
			);
		}
	}, [dates, loading]);

	useEffect(() => {
		if (Array.isArray(dateFields)) {
			setTotalDays(
				dateFields.reduce((sum, field) => {
					if (displayCustom) {
						return sum + (field.Minutes ? field.Minutes / 360 : 0);
					} else {
						return (
							sum +
							(field.Length === "Full day"
								? 1
								: field.Length === "Half day"
									? 0.5
									: field.Length === "Quarter day"
										? 0.25
										: 0)
						);
					}
				}, 0),
			);
		}
	}, [dateFields, displayCustom]);

	const handleAddDate = () => {
		if (maxDays === 0 || totalDays <= maxDays) {
			const newDateField = {
				Date: null,
				Length: displayCustom ? null : "Full day",
				Type: type,
				Name: title,
				Minutes: null,
			};

			setDateFields((prevFields) => [...prevFields, newDateField]);
		}
	};

	const handleDateChange = (index, newDate) => {
		if (!newDate || !newDate.isValid()) return;
		const updatedFields = [...dateFields];
		updatedFields[index].Date = newDate.toISOString().split("T")[0];
		setDateFields(updatedFields);
	};

	const handleDurationChange = (index, newDuration, minutes = false) => {
		const updatedFields = [...dateFields];
		if (minutes) {
			const totalMinutesUsed = dateFields.reduce((sum, field, i) => {
				return i !== index ? sum + (field.Minutes || 0) : sum;
			}, 0);

			const availableMinutes = (availableOHours || 0) * 60;
			const remainingMinutes = Math.round(availableMinutes - totalMinutesUsed);

			if (newDuration > remainingMinutes) {
				setValidationErrors({
					...validationErrors,
					[index]: `Cannot schedule more than ${Math.floor(remainingMinutes / 60)} hours and ${remainingMinutes % 60} minutes`,
				});
				return;
			} else {
				const newErrors = { ...validationErrors };
				delete newErrors[index];
				setValidationErrors(newErrors);
			}

			updatedFields[index].Minutes = newDuration;
			updatedFields[index].Length = null;
		} else {
			updatedFields[index].Length = newDuration;
		}
		setDateFields(updatedFields);
	};

	const handleFinished = async () => {
		setLoading(true);
		const sortedFields = [...dateFields].sort((a, b) =>
			a.Date && b.Date ? a.Date.localeCompare(b.Date) : !a.Date ? 1 : -1,
		);
		await onFinished(sortedFields);
		setLoading(false);
	};

	const handleDelete = async (index) => {
		if (!window.confirm("Are you sure you want to delete this date?")) {
			return;
		}
		setLoading(true);
		const date = dateFields.find((_, i) => i === index);
		setDateFields(dateFields.filter((_, i) => i !== index));
		if (date.id) await onDelete(date.id);
		setLoading(false);
	};

	return (
		<Box sx={{ mb: 2 }}>
			<RepeatableAccordion
				id="calendar-key"
				header={
					<div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
						<div
							className="rounded-circle d-flex justify-content-center align-items-center"
							style={{ width: "3rem", backgroundColor: bgColor[`${dayType}`] }}
						>
							{dayType}
						</div>
						<Typography
							variant="subtitle2"
							fontWeight="700"
							fontSize="1.25rem"
							lineHeight="1.25rem"
						>
							{title} {subtitle}
						</Typography>
					</div>
				}
				expanded={expanded}
				onChange={() => setExpanded(!expanded)}
			>
				<p>{description}</p>
				{!(dayType === "O" && availableOHours <= 0) && dateFields.map((field, index) => (
					<div
						key={index}
						className="mb-4 position-relative"
						onMouseEnter={() => setDeleteIconVisible(index)}
						onMouseLeave={() => setDeleteIconVisible(null)}
					>
						{/* Date Picker */}
						<div className="form-group">
							<label className="font-weight-bold mb-0">Choose Day</label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									className="w-100"
									value={field.Date ? dayjs(field.Date) : null}
									onChange={(newValue) => handleDateChange(index, newValue)}
									format="YYYY-MM-DD"
									minDate={minDate ? dayjs(minDate) : null}
									maxDate={maxDate ? dayjs(maxDate) : null}
									shouldDisableDate={(date) => {
										const dateStr = date.format("YYYY-MM-DD");

										// Check if it's a weekend (Saturday = 6, Sunday = 0)
										const day = date.day();
										if (day === 0 || day === 6) {
											return true;
										}

										// Check if the date is in genericClosedDays
										if (genericClosedDays?.some((day) => day.Date === dateStr)) {
											return true;
										}

										// Check if the date is an N day or Full day in schoolClosedDays
										if (schoolClosedDays?.some((day) =>
											day.Date === dateStr &&
											(day.Type?.startsWith("N") || day.Length === "Full day")
										)) {
											return true;
										}

										return false;
									}}
								/>
							</LocalizationProvider>
							<small className="form-text text-muted">YYYY-MM-DD</small>
						</div>

						{/* Duration Buttons */}
						<label className="font-weight-bold mb-0">Duration</label>
						{displayCustom ? (
							<Box>
								<TextField
									type="number"
									value={field.Minutes || ""}
									onChange={(e) =>
										handleDurationChange(
											index,
											parseInt(e.target.value) || 0,
											true,
										)
									}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">minutes</InputAdornment>
										),
									}}
									error={!!validationErrors[index]}
								/>
								{validationErrors[index] && (
									<FormHelperText error>
										{validationErrors[index]}
									</FormHelperText>
								)}
							</Box>
						) : (
							<ButtonGroup className="w-100">
								{displayQuarter && (
									<Button
										color={
											field.Length === "Quarter day"
												? "primary"
												: "outline-primary"
										}
										style={{
											borderRadius: "20px 0 0 20px",
										}}
										onClick={() => handleDurationChange(index, "Quarter day")}
									>
										Quarter Day
									</Button>
								)}
								<Button
									color={
										field.Length === "Half day" ? "primary" : "outline-primary"
									}
									style={{
										borderRadius: displayQuarter ? "0" : "20px 0 0 20px",
									}}
									onClick={() => handleDurationChange(index, "Half day")}
								>
									Half Day
								</Button>
								<Button
									color={
										field.Length === "Full day" ? "primary" : "outline-primary"
									}
									style={{
										borderRadius: "0 20px 20px 0",
									}}
									onClick={() => handleDurationChange(index, "Full day")}
								>
									Full Day
								</Button>
							</ButtonGroup>
						)}
						{index < dateFields.length - 1 && (
							<Box sx={{ borderTop: "1px solid #96ddf8", my: 4 }} />
						)}
						{deleteIconVisible === index && (
							<IconButton
								aria-label="delete"
								className="position-absolute right-0 text-danger"
								sx={{ top: "-0.5rem" }}
								onClick={() => handleDelete(index)}
							>
								<DeleteIcon />
							</IconButton>
						)}
					</div>
				))}

				{maxDays > 0 && (
					<div className="mb-4">
						<label
							className={`font-weight-bold mb-0 ${totalDays < maxDays && "text-danger"}`}
						>
							Total Days
						</label>
						<div className="mt-2 w-100 pr-3">
							<input
								className="font-weight-bold text-lg rounded-lg p-2 w-100 border-0"
								style={{
									backgroundColor:
										totalDays < maxDays ? "#e2ced2" : "transparent",
								}}
								value={totalDays}
								disabled
							/>
							{totalDays < maxDays && (
								<p className="mt-2 text-danger">
									Add {maxDays - totalDays} more{" "}
									{maxDays - totalDays === 1 ? "day" : "days"}
								</p>
							)}
						</div>
					</div>
				)}
				<Button
					color="primary"
					className="w-100 mb-3"
					onClick={handleAddDate}
					disabled={
						loading || disable || (maxDays !== 0 && totalDays >= maxDays)
					}
				>
					<AddIcon /> Add “{title}”
				</Button>
				<Button
					color="primary"
					outline
					className="w-100"
					onClick={handleFinished}
					disabled={loading || totalDays < maxDays}
				>
					{loading ? (
						"Saving..."
					) : (
						<>
							<DoneIcon /> Save
						</>
					)}
				</Button>
			</RepeatableAccordion>
		</Box>
	);
};

export default CalendarDays;

import React from "react";
import NotificationOptions from "./NotificationOptions";
import { useSelector } from "react-redux";

const Screen = () => {
  const { notificationPermission } = useSelector(state => state.appInfo);

  return (
    <div className="main-container">
      <h1>Notification <i className="fa fa-alarm" /></h1>
      <div style={{ marginTop: 20 }}>
        {notificationPermission === "granted" ? (<NotificationOptions />) : (
          <div>You are not allowed to get notifications now. Please check notification setting and turn it on.</div>
        )}
      </div>
    </div>
  )
}

export default Screen;
import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewPdPlanIcon = () => (
    <SvgIcon width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
        <path d="M17.6992 22.8169H2.84326C2.21145 22.8169 1.69922 22.2847 1.69922 21.6282V6.81689" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.88113 3.04053C5.82563 3.17259 5.79199 3.31845 5.79199 3.4722C5.79199 4.0931 6.29942 4.59776 6.9238 4.59776C7.54818 4.59776 8.05561 4.0931 8.05561 3.4722C8.05561 3.31845 8.02187 3.17259 7.96637 3.04053" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.92383 3.45233V0.816895" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.9371 3.04053C21.9946 3.17259 22.0283 3.31845 22.0283 3.4722C22.0283 4.0931 21.5208 4.59776 20.8965 4.59776C20.2701 4.59776 19.7646 4.0931 19.7646 3.4722C19.7646 3.31845 19.7964 3.17259 19.8539 3.04053" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.8965 3.45233V0.816895" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.646 1.5459H8.17529" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.1213 6.83843V2.7345C26.1213 2.07811 25.586 1.5459 24.926 1.5459H22.147" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.67342 1.54639H2.89442C2.23436 1.54639 1.69922 2.0786 1.69922 2.73499V6.83891H26.1214V10.1425" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.30566 11.1553V13.29" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.8281 11.1553V13.29" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.30566 16.3828V18.5195" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.8281 16.3828V18.5195" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.3525 11.1733V13.3081" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.2852 16.3828V18.5195" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.51611 14.8379H17.1425" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.57004 21.648H5.06856V8.02148H2.88818V20.9699C2.88818 21.3444 3.19342 21.648 3.57004 21.648Z" fill="#9DE0F8"/>
        <path d="M20.5583 13.786L23.9946 14.8423C24.3354 14.9467 24.7007 14.9467 25.0414 14.8423L29.3383 13.5223C29.4913 13.4743 29.4913 13.2628 29.3383 13.2162L25.0414 11.8962C24.7007 11.7905 24.3354 11.7905 23.9946 11.8962L19.6992 13.2162V15.4276" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.7122 14.0513V15.7506C27.7122 15.9396 27.5996 16.1117 27.4234 16.1893C25.5768 17.0072 23.4587 17.0072 21.612 16.1893C21.4359 16.1117 21.3232 15.9396 21.3232 15.7506V14.0513" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.9502 20.4863L29.2617 22.4368V24.792" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.0831 20.4297L19.7715 22.4365V24.7917" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.3876 17.106C27.3876 19.024 26.1026 20.5795 24.5173 20.5795C22.9305 20.5795 21.6455 19.024 21.6455 17.106" stroke="currentColor" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.157 21.4072L26.0262 24.3801L24.5766 26.0781L23.1255 24.3801L24.1391 21.4072C24.4452 21.5736 24.8162 21.5793 25.1281 21.4213L25.157 21.4072Z" fill="#9DE0F8"/>
        </svg>
    </SvgIcon>
)

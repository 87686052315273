import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./styles.scss";
import "./styles-override.scss";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormGroup, Tooltip, Row, Col } from "reactstrap";
import Select from 'react-select';
import { DateTime } from 'luxon';
import classNames from "classnames";
import { setAppUserInfo } from "../../redux/actions";
import airtable from '../../airtables';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];

const formatTime = (hour) => {
  const adjustedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
  return `${adjustedHour}`;
};

const Screen = ({ hostId }) => {
  const { userInfo, userType, totalTimezones } = useSelector(state => state.appInfo);

  const isTeamViewer = useMemo(() => userType === 'Team' && userInfo['Status'] !== 'Session Host' && !userInfo['Primary Session Host'], [userType, userInfo]);

  const [availability, setAvailability] = useState({});
  const [sessionHosts, setSessionHosts] = useState([]);
  const [selectedHost, setSelectedHost] = useState({ value: 'all', label: 'All Hosts' });
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [viewedHostName, setViewedHostName] = useState("");
  const [error, setError] = useState("");
  const [availableHosts, setAvailableHosts] = useState({});
  const [changed, setChanged] = useState(false);
  const dispatch = useDispatch();

  const [hostTimezone, setHostTimezone] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState({});
  
  // State for the date picker
  const [selectedDate, setSelectedDate] = useState(null);
  const [specificDateAvailability, setSpecificDateAvailability] = useState(null);
  const [isSpecificDateView, setIsSpecificDateView] = useState(false);
  const [hostBookings, setHostBookings] = useState({});
  const [hostProviderBookings, setHostProviderBookings] = useState({}); // New state for when hosts are providers
  const [isLoading, setIsLoading] = useState(false);
  const [hostAvailabilities, setHostAvailabilities] = useState([]);
  const [sessionsForDate, setSessionsForDate] = useState({});
  const [availableHostsForSessions, setAvailableHostsForSessions] = useState({});
  const [selectedSessionHosts, setSelectedSessionHosts] = useState({});
  const [blockedHostIds, setBlockedHostIds] = useState({});
  const [assignedSessionIds, setAssignedSessionIds] = useState([]);
  const [tentativeBookings, setTentativeBookings] = useState({}); // Host ID -> { hourIndex -> sessionInfo }
  const [resetKey, setResetKey] = useState(0); // Key to force re-render of Select components
  const [isAutoAssigning, setIsAutoAssigning] = useState(false); // State to track if auto-assign is in progress

  const convertToEastern = (hour, timezone) => {
    if (timezone === 'America/Toronto') return hour;
    const date = DateTime.now().setZone(timezone).set({ hour, minute: 0, second: 0, millisecond: 0 });
    return date.setZone('America/Toronto').hour;
  };

  const fetchIndividualHostAvailability = useCallback(async (hostId) => {
    try {
      const userData = await airtable.teams.select(hostId);
      let st = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
      let userTimezone = 'America/Toronto';

      if (userData["Time Zone"]) {
        const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
        if (tz) {
          userTimezone = tz["Connected North System"];
          setHostTimezone(userTimezone);
        }
      }

      const hostAvailability = {};
      weekDays.forEach((wd, i) => {
        hostAvailability[wd] = Array(11).fill(0);
        if (st && st[i] && st[i].length) {
          st[i].forEach(a => {
            const convertedHour = convertToEastern(a, userTimezone);
            if (convertedHour >= 8 && convertedHour <= 18) {
              hostAvailability[wd][convertedHour - 8] = 1;
            }
          });
        }
      });

      setAvailability(hostAvailability);
      setViewedHostName(userData["Name"] || "Host");
    } catch (error) {
      console.error("Error fetching host data:", error);
      setError("Unable to fetch host data. Please try again.");
    }
  }, [totalTimezones]);

  const fetchAvailability = useCallback(async () => {
    setIsLoading(true);
    if (isTeamViewer) {
      try {
        // Fetch both contract and primary session hosts
        const contractHosts = await airtable.teams.getContractSessionHosts();
        const primaryHosts = await airtable.teams.getPrimarySessionHost();
        
        // Combine and deduplicate hosts (in case someone is both primary and contract)
        const allHosts = [...contractHosts];
        primaryHosts.forEach(primaryHost => {
          if (!allHosts.some(host => host.id === primaryHost.id)) {
            allHosts.push(primaryHost);
          }
        });
        
        // Filter out hosts with 'Supervisor' in their name
        const filteredHosts = allHosts.filter(host => !host.Name.includes('Supervisor'));
        
        setSessionHosts(filteredHosts);

        if (selectedHost.value !== 'all') {
          await fetchIndividualHostAvailability(selectedHost.value);
        } else {
          setHostTimezone(null);
          // Fetch all hosts' availability
          const allAvailability = {};
          const allAvailableHosts = {};

          for (const host of allHosts) {
            const userData = await airtable.teams.select(host.id);
            let st = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
            let userTimezone = 'America/Toronto';

            if (userData["Time Zone"]) {
              const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
              if (tz) userTimezone = tz["Connected North System"];
            }

            weekDays.forEach((wd, i) => {
              if (!allAvailability[wd]) allAvailability[wd] = Array(11).fill(0);
              if (!allAvailableHosts[wd]) {
                allAvailableHosts[wd] = Array(11).fill().map(() => []);
              }

              if (st && st[i] && st[i].length) {
                st[i].forEach(a => {
                  const convertedHour = convertToEastern(a, userTimezone);
                  if (convertedHour >= 8 && convertedHour <= 18) {
                    allAvailability[wd][convertedHour - 8]++;
                    allAvailableHosts[wd][convertedHour - 8].push(host.Name);
                  }
                });
              }
            });
          }

          setAvailability(allAvailability);
          setAvailableHosts(allAvailableHosts);
          setViewedHostName("All Hosts");
        }
      } catch (error) {
        console.error("Error fetching availability data:", error);
        setError("Unable to fetch availability data. Please try again.");
      }
    } else {
      // Fetch individual Session Host availability
      let st = userInfo["Availability"] ? JSON.parse(userInfo["Availability"]) : null;
      const tmpArray = {};
      weekDays.forEach((wd, i) => {
        tmpArray[wd] = Array(11).fill(false);
        if (st && st[i] && st[i].length) {
          st[i].forEach(a => {
            if (a >= 8 && a <= 18) {
              tmpArray[wd][a - 8] = true;
            }
          });
        }
      });
      setAvailability(tmpArray);
    }

    if (userInfo["Time Zone"]) {
      const tz = totalTimezones.find(tt => tt.id === userInfo["Time Zone"][0]);
      if (tz) {
        setSelectedTimezone({ label: tz["Connected North System"], value: tz.id });
      }
    }
    setIsLoading(false);
  }, [isTeamViewer, selectedHost, totalTimezones, fetchIndividualHostAvailability, userInfo]);

  useEffect(() => {
    fetchAvailability();
  }, [fetchAvailability]);

  const handleBlockClick = (day, hour) => {
    if (isTeamViewer) {
      if (selectedHost.value !== 'all') {
        // For individual host view
        let isAvailable;
        if (day === 'specificDate') {
          isAvailable = specificDateAvailability[hour] === 1;
        } else {
          isAvailable = availability[day][hour] === 1;
        }
        
        // Just show the availability status
        alert(`${viewedHostName} is ${isAvailable ? 'available' : 'not available'} at this time.`);
      } else {
        // For all hosts view, show list of available hosts
        let hosts;
        if (day === 'specificDate') {
          hosts = availableHosts.specificDate[hour];
        } else {
          hosts = availableHosts[day][hour];
        }
        const uniqueHosts = [...new Set(hosts)]; // Remove duplicates
        alert(`Available hosts: ${uniqueHosts.join(", ")}`);
      }
    } else {
      // Session Hosts only have weekly view, not specific date view
      const newAvailability = { ...availability };
      newAvailability[day][hour] = !newAvailability[day][hour];
      setAvailability(newAvailability);
      setChanged(true);
    }
  };

  const saveChange = async () => {
    if (userInfo['Status'] !== "Session Host" && !userInfo["Primary Session Host"]) return;
    
    if (!selectedTimezone) {
      alert("Please select your time zone.");
      return;
    }

    const result = [];
    weekDays.forEach((wd) => {
      const wdTimes = availability[wd]
        .map((da, i) => ({ availability: da, time: i + 8 }))
        .filter(a => a.availability)
        .map((d) => d.time);
      result.push(wdTimes || []);
    })
    const updatedUserInfo = await airtable.teams.update(userInfo.id, {
      "Availability": JSON.stringify(result),
      "Time Zone": [selectedTimezone.value]
    })
    dispatch(setAppUserInfo(updatedUserInfo));
    setChanged(false);
    toast.success("Your availability has successfully been updated!");
  }

  const toggleTooltip = (hostId, hour) => {
    setTooltipOpen(prev => ({
      ...prev,
      [`${hostId}-${hour}`]: !prev[`${hostId}-${hour}`]
    }));
  };
  

  
  // Function to reset all unassigned sessions
  const resetUnassignedSessions = () => {
    // Create a completely new empty object for selected session hosts
    // We'll only keep hosts for sessions that have been officially assigned in Airtable
    const newSelectedSessionHosts = {};
    
    // Only keep selections for sessions that are officially assigned in Airtable
    if (assignedSessionIds && assignedSessionIds.length > 0) {
      assignedSessionIds.forEach(sessionId => {
        if (selectedSessionHosts[sessionId]) {
          newSelectedSessionHosts[sessionId] = selectedSessionHosts[sessionId];
        }
      });
    }
    
    // Update the state with the new selections (this will clear all unassigned selections)
    setSelectedSessionHosts(newSelectedSessionHosts);
    
    // Clear blocked hosts for unassigned sessions
    const newBlockedHostIds = {};
    
    // Keep only the blocked hosts for already assigned sessions
    if (assignedSessionIds && assignedSessionIds.length > 0) {
      Object.keys(blockedHostIds).forEach(sessionId => {
        if (assignedSessionIds.includes(sessionId)) {
          newBlockedHostIds[sessionId] = blockedHostIds[sessionId];
        }
      });
    }
    
    // Update the state with the filtered blocked hosts
    setBlockedHostIds(newBlockedHostIds);
    
    // Clear all tentative bookings
    setTentativeBookings({});
    
    // Increment the reset key to force re-render of all Select components
    setResetKey(prevKey => prevKey + 1);
    
    toast.info("Reset all unassigned session selections.");
  };
  
  // Function to automatically assign hosts to sessions based on availability
  const autoAssignHosts = async () => {
    // Set loading state to true when starting
    setIsAutoAssigning(true);
    
    try {
    // First, reset any manual selections of hosts for unassigned sessions
    // Create a completely new empty object for selected session hosts
    // We'll only keep hosts for sessions that have been officially assigned in Airtable
    const newSelectedSessionHosts = {};
    
    // Clear all tentative bookings
    setTentativeBookings({});
    
    // Only keep selections for sessions that are officially assigned in Airtable
    if (assignedSessionIds && assignedSessionIds.length > 0) {
      assignedSessionIds.forEach(sessionId => {
        if (selectedSessionHosts[sessionId]) {
          newSelectedSessionHosts[sessionId] = selectedSessionHosts[sessionId];
        }
      });
    }
    
    // Update the state with the new selections (this will clear all unassigned selections)
    setSelectedSessionHosts(newSelectedSessionHosts);
    
    // Clear blocked hosts for unassigned sessions
    const newBlockedHostIds = {};
    
    // Keep only the blocked hosts for already assigned sessions
    if (assignedSessionIds && assignedSessionIds.length > 0) {
      Object.keys(blockedHostIds).forEach(sessionId => {
        if (assignedSessionIds.includes(sessionId)) {
          newBlockedHostIds[sessionId] = blockedHostIds[sessionId];
        }
      });
    }
    
    // Update the state with the filtered blocked hosts
    setBlockedHostIds(newBlockedHostIds);
    
    // Check if we have host availability data
    if (hostAvailabilities.length === 0) {
      toast.warning("No host availability data found. Please ensure hosts have set their availability for this date.");
      return;
    }
    
    // Check if we have sessions data
    if (Object.keys(sessionsForDate).length === 0) {
      toast.warning("No sessions found for the selected date. Please select a different date.");
      return;
    }
  
    // Increment the reset key to force re-render of all Select components
    // Do this after clearing selections but before building new ones
    setResetKey(prevKey => prevKey + 1);
    
    // Check if availableHostsForSessions has data
    // Always calculate available hosts for each session to ensure fresh data
    let tempAvailableHosts = {};

    // Create a map to store previous session hosts for provider/teacher combinations
    const previousHostsMap = {};
      
    Object.keys(sessionsForDate).forEach(schoolLead => {
      sessionsForDate[schoolLead].forEach(session => {
        const sessionDate = new Date(session["Session Start Date/Time"]);
        const startHour = sessionDate.getHours();
        const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
        const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
        const endHour = endTime.getHours();
        
        // Generate array of all hours this session spans
        const sessionHours = [];
        for (let h = startHour; h <= endHour; h++) {
          if (h >= 8 && h <= 18) { // Only include hours within 8am-6pm
            sessionHours.push(h);
          }
        }
        
        // Special case: if session ends exactly at the top of the hour,
        // we need to handle the last hour differently
        const endsExactlyOnHour = endTime.getMinutes() === 0;
        
        // Determine available hosts for this session
        const availableHosts = [];
        
        // A host is available if they are available for all hours of the session
        hostAvailabilities.forEach(host => {
          let hostIsAvailable = true;
          
          // Check each hour that this session spans (excluding the ending hour if session ends exactly on the hour)
          const hoursToCheck = sessionHours.filter(h => !(h === endHour && endsExactlyOnHour));
          
          for (const hour of hoursToCheck) {
            const hourIndex = hour - 8; // Convert to 0-based index (8am = 0)
            
            // Check if host is available during this hour
            const isAvailableThisHour = hourIndex >= 0 && hourIndex < 11 && 
                                       Boolean(host.availability[hourIndex]);
            
            // Check if host is already booked during this hour
            const existingBooking = hostBookings[host.id] && hostBookings[host.id][hourIndex];
            
            if (!isAvailableThisHour || existingBooking) {
              hostIsAvailable = false;
              break;
            }
          }
          
          if (hostIsAvailable) {
            availableHosts.push({
              id: host.id,
              name: host.name
            });
          }
        });
        
        tempAvailableHosts[session.id] = availableHosts;
      });
    });
    
    // If still no available hosts, show a message
    if (Object.keys(tempAvailableHosts).length === 0) {
      toast.warning("Could not find available hosts for any sessions. Please check host availability.");
      return;
    }
    
    // Always use the temporary object we just created
    const availableHostsMap = tempAvailableHosts;
    // Step 1: Get all sessions that need hosting
    const allSessions = [];
    Object.keys(sessionsForDate).forEach(schoolLead => {
      sessionsForDate[schoolLead].forEach(session => {
        // Skip already assigned sessions
        if (!assignedSessionIds.includes(session.id)) {
          allSessions.push(session);
        }
      });
    });
    
    // If no sessions need hosting, show a message
    if (allSessions.length === 0) {
      toast.info("No sessions requiring hosts found");
      return;
    }
    
    // Step 2: Get host availability and prepare host workload data
    // Count how many sessions each host already has for the day
    const hostWorkload = {};
    
    // Initialize workload for all available hosts
    hostAvailabilities.forEach(host => {
      hostWorkload[host.id] = {
        id: host.id,
        name: host.name,
        assignedCount: 0
      };
    });
    
    // Calculate current workload based on existing bookings
    Object.keys(hostBookings).forEach(hostId => {
      if (hostWorkload[hostId]) {
        // Count non-null bookings for this host
        const bookingsCount = hostBookings[hostId].filter(booking => booking !== null).length;
        hostWorkload[hostId].assignedCount = bookingsCount;
      }
    });
    
    // Step 3: Sort sessions by start time to ensure we process them in chronological order
    allSessions.sort((a, b) => {
      const timeA = new Date(a["Session Start Date/Time"]).getTime();
      const timeB = new Date(b["Session Start Date/Time"]).getTime();
      return timeA - timeB;
    });
    
    // Step 3.5: For each session, check if this provider and teacher have worked together before
    // and if so, find which host supported them
    for (const session of allSessions) {
      const providerName = session["Provider Name"];
      const teacherName = session["Teacher Name"];
      
      if (providerName && teacherName) {
        const key = `${providerName}|${teacherName}`;
        
        // Only query if we haven't already looked up this combination
        if (!previousHostsMap[key]) {
          try {
            // Get the available hosts for this session
            const availableHosts = availableHostsMap[session.id] || [];
            
            // Only proceed if there are available hosts
            if (availableHosts.length > 0) {
              // Extract just the host names for the query
              const availableHostNames = availableHosts.map(host => host.name);
              
              // Query for previous sessions with these specific hosts
              const previousSessions = await airtable.sessions.getPreviousSessionsWithProviderAndTeacher(
                providerName, 
                teacherName,
                availableHostNames
              );
              
              if (previousSessions.length > 0) {
                // Get the most recent session's host (already sorted by date desc)
                const mostRecentSession = previousSessions[0];
                if (mostRecentSession["Session Host Text"]) {
                  previousHostsMap[key] = mostRecentSession["Session Host Text"];
                }
              }
            }
          } catch (error) {
            console.error("Error fetching previous sessions:", error);
          }
        }
      }
    }
    
    // Step 4: Process each session and assign a host if possible
    const assignments = {};
    
    allSessions.forEach(session => {
      // Get available hosts for this session
      const availableHosts = availableHostsMap[session.id] || [];
      
      if (availableHosts.length === 0) {
        // No hosts available for this session
        return;
      }
      
      // Get the overlapping session IDs
      const overlappingSessions = findOverlappingSessions(session.id);
      
      // Get hosts already assigned to overlapping sessions in our assignments object
      const blockedHosts = new Set();
      overlappingSessions.forEach(overlapId => {
        if (assignments[overlapId]) {
          blockedHosts.add(assignments[overlapId].value);
        }
      });
      
      // Filter out hosts that are already assigned to overlapping sessions
      const eligibleHosts = availableHosts.filter(host => !blockedHosts.has(host.id));
      
      if (eligibleHosts.length === 0) {
        // No eligible hosts available after filtering
        return;
      }
      
      // Check if this provider/teacher combination has a previous host
      const providerName = session["Provider Name"];
      const teacherName = session["Teacher Name"];
      const key = `${providerName}|${teacherName}`;
      const previousHostName = previousHostsMap[key];

      // If there's a previous host, check if they're available for this session
      if (previousHostName) {
        // Handle the case where previousHostName is an array
        const hostNameToCompare = Array.isArray(previousHostName) ? previousHostName[0] : previousHostName;
        const previousHost = eligibleHosts.find(host => host.name === hostNameToCompare);
        if (previousHost) {
          // If the previous host is available, assign them to this session
          // Add an isPreviousMatch flag to indicate this was a match from previous sessions
          assignments[session.id] = { 
            value: previousHost.id, 
            label: previousHost.name,
            isPreviousMatch: true  // Flag to indicate this was assigned based on previous host match
          };
          
          // Increment the host's workload for fairness in subsequent assignments
          if (hostWorkload[previousHost.id]) {
            hostWorkload[previousHost.id].assignedCount++;
          }
          
          return; // Skip the rest of this iteration
        }
      }
      
      // If no previous host or previous host not available, sort by workload
      eligibleHosts.sort((a, b) => {
        const countA = hostWorkload[a.id]?.assignedCount || 0;
        const countB = hostWorkload[b.id]?.assignedCount || 0;
        return countA - countB; // Sort by ascending workload
      });
      
      // Assign the host with the lowest workload
      const selectedHost = eligibleHosts[0];
      assignments[session.id] = { 
        value: selectedHost.id, 
        label: selectedHost.name,
        isPreviousMatch: false // Explicitly set to false for non-previous matches
      };
      
      // Increment the host's workload for fairness in subsequent assignments
      if (hostWorkload[selectedHost.id]) {
        hostWorkload[selectedHost.id].assignedCount++;
      }
    });
    
    // Step 5: Update the UI to show the selected hosts in the dropdowns
    setSelectedSessionHosts(assignments);
    
    // Step 6: Block hosts in overlapping sessions and create tentative bookings
    const assignmentBlockedHostIds = {};
    const tentativeBookingsMap = {};
    
    Object.keys(assignments).forEach(sessionId => {
      const hostId = assignments[sessionId].value;
      const allSessions = Object.values(sessionsForDate).flat();
      const session = allSessions.find(s => s.id === sessionId);
      
      if (session) {
        // Add this session to the tentative bookings for this host
        if (!tentativeBookingsMap[hostId]) {
          tentativeBookingsMap[hostId] = {};
        }
        
        // Create tentative booking data
        const sessionDate = new Date(session["Session Start Date/Time"]);
        const startHour = sessionDate.getHours();
        const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
        const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
        const endHour = endTime.getHours();
        


        const sessionInfo = {
          id: session.id,
          name: session["Session Title Text"],
          provider: session["Provider Name"],
          teacher: session["Teacher Name"],
          school: session["School Name Text"],
          startHour: startHour,
          endHour: endHour,
          timeRange: session.formattedTime,
          isPreviousMatch: assignments[sessionId].isPreviousMatch || false // Include whether this is a previous provider/teacher match
        };
        


        
        // Add session to each hour it spans
        for (let h = startHour; h <= endHour; h++) {
          const hourIndex = h - 8; // Convert to 0-based index (8am = 0)
          
          if (hourIndex >= 0 && hourIndex < 11) { // Only within our display range
            if (h === startHour && h === endHour) {
              // Session contained entirely within one hour
              const startMinutes = sessionDate.getMinutes();
              const endMinutes = endTime.getMinutes();
              
              tentativeBookingsMap[hostId][hourIndex] = {
                ...sessionInfo,
                isPartial: true,
                isPartialSingleHour: true,
                startMinute: startMinutes,
                endMinute: endMinutes,
                partialMinutes: endMinutes - startMinutes,
                partialPercentage: (endMinutes - startMinutes) / 60,
                startPosition: startMinutes / 60,
                isPreviousMatch: sessionInfo.isPreviousMatch // Ensure isPreviousMatch is copied
              };
            } else if (h === startHour) {
              // First hour - partial if not starting at XX:00
              const startMinutes = sessionDate.getMinutes();
              
              if (startMinutes > 0) {
                tentativeBookingsMap[hostId][hourIndex] = {
                  ...sessionInfo,
                  isPartial: true,
                  isPartialStart: true,
                  partialMinutes: startMinutes,
                  partialPercentage: (60 - startMinutes) / 60,
                  startPosition: startMinutes / 60,
                  isPreviousMatch: sessionInfo.isPreviousMatch // Ensure isPreviousMatch is copied
                };
              } else {
                tentativeBookingsMap[hostId][hourIndex] = sessionInfo;
              }
            } else if (h === endHour) {
              // Last hour - partial if not ending at XX:00
              const endMinutes = endTime.getMinutes();
              
              if (endMinutes > 0) {
                tentativeBookingsMap[hostId][hourIndex] = {
                  ...sessionInfo,
                  isPartial: true,
                  isPartialEnd: true,
                  partialMinutes: endMinutes,
                  partialPercentage: endMinutes / 60,
                  isPreviousMatch: sessionInfo.isPreviousMatch // Ensure isPreviousMatch is copied
                };
              }
            } else {
              // Middle hours - fully booked
              tentativeBookingsMap[hostId][hourIndex] = sessionInfo;
            }
          }
        }
      }
      
      // Handle overlapping sessions for blocking
      const overlappingSessions = findOverlappingSessions(sessionId);
      
      overlappingSessions.forEach(overlapId => {
        if (!assignmentBlockedHostIds[overlapId]) {
          assignmentBlockedHostIds[overlapId] = [];
        }
        assignmentBlockedHostIds[overlapId].push(hostId);
      });
    });
    
    // Update states
    setBlockedHostIds(assignmentBlockedHostIds);
    setTentativeBookings(tentativeBookingsMap);
    
    // Show a success message with the number of sessions assigned
    const assignmentCount = Object.keys(assignments).length;
    const sessionWord = assignmentCount === 1 ? "session" : "sessions";
    
    toast.success(`Successfully suggested hosts for ${assignmentCount} ${sessionWord}. Please review and confirm each assignment.`);
    
    // Set loading state back to false when finished
    setIsAutoAssigning(false);
    } catch (error) {
      console.error("Error during auto-assignment:", error);
      toast.error("An error occurred during automatic assignment. Please try again.");
      setIsAutoAssigning(false);
    }
  };
  
  // Check if two sessions overlap in time with a required buffer
  const doSessionsOverlap = (session1, session2) => {
    const start1 = new Date(session1["Session Start Date/Time"]).getTime();
    const duration1 = parseInt(session1["Length (Minutes)"]) || 60; // Default 60 mins
    const end1 = start1 + (duration1 * 60 * 1000);
    
    const start2 = new Date(session2["Session Start Date/Time"]).getTime();
    const duration2 = parseInt(session2["Length (Minutes)"]) || 60; // Default 60 mins
    const end2 = start2 + (duration2 * 60 * 1000);
    
    // Required buffer between sessions (10 minutes in milliseconds)
    const requiredBufferMs = 10 * 60 * 1000;
    
    // Case 1: Session 1 starts after Session 2
    if (start1 >= end2) {
      // Check if there's enough buffer
      return (start1 - end2) < requiredBufferMs;
    }
    
    // Case 2: Session 2 starts after Session 1
    if (start2 >= end1) {
      // Check if there's enough buffer
      return (start2 - end1) < requiredBufferMs;
    }
    
    // If we get here, the sessions directly overlap (one starts before the other ends)
    return true;
  };
  
  // Find all sessions that overlap with a given session
  const findOverlappingSessions = (sessionId) => {
    const overlappingSessionIds = [];
    const currentSession = Object.values(sessionsForDate)
      .flat()
      .find(s => s.id === sessionId);
      
    if (!currentSession) return [];
    
    // Check all sessions for overlaps
    Object.values(sessionsForDate).flat().forEach(session => {
      if (session.id !== sessionId && doSessionsOverlap(currentSession, session)) {
        overlappingSessionIds.push(session.id);
      }
    });
    
    return overlappingSessionIds;
  };

  // Function to handle date selection and fetch specific date availability
  // Helper function to check for sessions where hosts are also providers
  const checkForProviderSessions = async (providerMap, selectedDate) => {
    if (!isTeamViewer || !providerMap || Object.keys(providerMap).length === 0 || !selectedDate) {
      return;
    }
    
    // Get provider IDs from the map
    const providerIds = Object.values(providerMap);
    
    // Convert the date to a string format for the filter
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    
    // Create a filter to get sessions where the provider is one of our hosts
    const providerSessionsFilter = {
      filterByFormula: `AND(
        DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') = '${formattedDate}',
        OR(Status = 'Booked',Status = 'Completed'),
        OR(${providerIds.map(id => `FIND('${id}', {Provider Record ID})`).join(',')})
      )`
    };
    
    try {
      // Fetch sessions where our hosts are providers
      const providerSessions = await airtable.sessions.list(providerSessionsFilter);
      // Process these sessions to mark times when hosts are presenting
      const hostProviderBookingsData = {};
      
      // For each session where a host is the provider
      for (const session of providerSessions) {
        // Find which host is the provider for this session
        const providerId = session.Provider && session.Provider[0];
        if (!providerId) continue;
        
        // Find the host ID from our mapping
        const hostId = Object.keys(providerMap).find(
          hostId => providerMap[hostId] === providerId
        );
        
        if (hostId) {
          // Process this session to mark the host as unavailable during these hours
          const sessionStart = new Date(session["Session Start Date/Time"]);
          const sessionDuration = parseInt(session["Length (Minutes)"]) || 60;
          const sessionEnd = new Date(sessionStart.getTime() + sessionDuration * 60000);
          
          // Get the hours this session spans
          const startHour = sessionStart.getHours();
          const endHour = sessionEnd.getHours();
          
          // Initialize if needed
          if (!hostProviderBookingsData[hostId]) {
            hostProviderBookingsData[hostId] = Array(11).fill(null);
          }
          
          // Mark each hour that this session spans as booked for this host
          for (let h = startHour; h <= endHour; h++) {
            const hourIndex = h - 8; // Convert to 0-based index (8am = 0)
            if (hourIndex >= 0 && hourIndex < 11) { // Only within our display range
              // Format the session time for display
              const sessionStartTime = new Date(session["Session Start Date/Time"]);
              const sessionDurationMinutes = parseInt(session["Length (Minutes)"]) || 60;
              const sessionEndTime = new Date(sessionStartTime.getTime() + sessionDurationMinutes * 60000);
              
              // Format start time
              const startHourDisplay = sessionStartTime.getHours();
              const startMinuteDisplay = sessionStartTime.getMinutes();
              const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
              const formattedStartTime = `${startHourDisplay % 12 || 12}:${startMinuteDisplay.toString().padStart(2, '0')}`;
              
              // Format end time
              const endHourDisplay = sessionEndTime.getHours();
              const endMinuteDisplay = sessionEndTime.getMinutes();
              const endAmPm = endHourDisplay >= 12 ? 'PM' : 'AM';
              const formattedEndTime = `${endHourDisplay % 12 || 12}:${endMinuteDisplay.toString().padStart(2, '0')}`;
              
              // Create time range string
              const formattedTimeRange = startAmPm === endAmPm
                ? `${formattedStartTime}-${formattedEndTime} ${endAmPm}`
                : `${formattedStartTime} ${startAmPm}-${formattedEndTime} ${endAmPm}`;
              
              // Determine if this is a partial hour booking
              let isPartial = false;
              let isPartialStart = false;
              let isPartialEnd = false;
              let isPartialSingleHour = false;
              let partialPercentage = 1.0; // Default to full hour
              let startPosition = 0;
              
              // Check if session starts and ends within the same hour
              if (startHour === endHour) {
                // Session contained entirely within this hour
                isPartial = true;
                isPartialSingleHour = true;
                const startMinutes = sessionStartTime.getMinutes();
                const endMinutes = sessionEndTime.getMinutes() || 60; // If 0, treat as full hour
                const occupiedMinutes = endMinutes - startMinutes;
                partialPercentage = occupiedMinutes / 60;
                startPosition = startMinutes / 60;
              }
              // Check if this is the first hour of the session and it starts mid-hour
              else if (h === startHour && sessionStartTime.getMinutes() > 0) {
                isPartial = true;
                isPartialStart = true;
                const startMinutes = sessionStartTime.getMinutes();
                // For start hour, the percentage is how much of the hour is occupied (from start minute to end of hour)
                partialPercentage = (60 - startMinutes) / 60;
                // Start position is what percentage of the hour to leave empty before the colored part
                startPosition = startMinutes / 60;
              }
              // Check if this is the last hour of the session and it ends before the hour is complete
              else if (h === endHour && sessionEndTime.getMinutes() > 0) {
                isPartial = true;
                isPartialEnd = true;
                const endMinutes = sessionEndTime.getMinutes();
                // For end hour, the percentage is how much of the hour is occupied (from start of hour to end minute)
                partialPercentage = endMinutes / 60;
                // No start position needed for end hour - it starts at beginning of hour
                startPosition = 0;
              }
              
              // Create a booking entry specifically marking this as a provider session
              hostProviderBookingsData[hostId][hourIndex] = {
                id: session.id,
                name: session["Session Title Text"] || "Untitled Session",
                school: session["School Name Text"] || "",
                teacher: session["Teacher Name"] || "",
                provider: true, // Flag this as a provider session
                timeRange: formattedTimeRange,
                airtableUrl: `https://airtable.com/appP1kThwW9zVEpHr/tblRqP4N6gdA8WZEd/viw6N2TB9BG7Dk6xq/${session.id}?blocks=hide`,
                startHour,
                endHour,
                isPartial,
                isPartialStart,
                isPartialEnd,
                isPartialSingleHour,
                partialPercentage,
                startPosition,
                partialMinutes: isPartialSingleHour ? 
                  (sessionEndTime.getMinutes() - sessionStartTime.getMinutes()) :
                  (isPartialStart ? (60 - sessionStartTime.getMinutes()) : sessionEndTime.getMinutes())
              };
            }
          }
        }
      }
      
      // Update the state with provider bookings
      setHostProviderBookings(hostProviderBookingsData);
    } catch (error) {
      console.error("Error fetching provider sessions:", error);
    }
  };
  
  const handleDateChange = async (date) => {
    setIsLoading(true);
    
    // Clear previously selected hosts, blocked hosts, assigned sessions,
    // host provider bookings, and tentative bookings whenever date changes
    setSelectedSessionHosts({});
    setBlockedHostIds({});
    setAssignedSessionIds([]);
    setHostProviderBookings({});
    setTentativeBookings({});
    
    if (!date) {
      setSelectedDate(null);
      setIsSpecificDateView(false);
      setSpecificDateAvailability(null);
      setHostAvailabilities([]);
      setHostBookings({});
      setSessionsForDate({});
      setAvailableHostsForSessions({});
      setIsLoading(false);
      return;
    }

    // If a date is selected, automatically set to "All Hosts" view
    if (selectedHost.value !== 'all') {
      setSelectedHost({ value: 'all', label: 'All Hosts' });
    }
    
    setSelectedDate(date);
    setIsSpecificDateView(true);
    
    try {
      // Get the day of the week for the selected date
      const dayOfWeek = date.day() === 0 ? 6 : date.day() - 1;
      const weekdayName = weekDays[dayOfWeek < 5 ? dayOfWeek : 0]; // Default to Monday if weekend
      
      if (isTeamViewer) {
        // Convert selected date to a JavaScript Date object to use with Airtable API
        const jsDate = date.toDate();
        
        if (selectedHost.value !== 'all') {
          // For individual host view - we'll still use the old approach for this case
          const dateAvailability = Array(11).fill(0);
          
          // Use the weekday data from the host's regular availability
          if (availability[weekdayName]) {
            for (let i = 0; i < availability[weekdayName].length; i++) {
              dateAvailability[i] = availability[weekdayName][i];
            }
          }
          
          setSpecificDateAvailability(dateAvailability);
        } else {
          // For all hosts view - new approach with one row per host
          
          // Fetch all hosts' bookings for this date
          const hostBookingsData = {};
          try {
            // We need to get sessions for all hosts for this date
            // First, let's get all sessions for this date
            // Check the API used in the correct way
            
            // Use a different approach to get all sessions for this date
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const isDateTodayOrFuture = jsDate >= today;
            
            // For today or future dates, only show sessions with status "Booked" or "Completed"
            let filterFormula = `AND(
              YEAR({Session Start Date/Time}) = ${jsDate.getFullYear()},
              MONTH({Session Start Date/Time}) = ${jsDate.getMonth() + 1},
              DAY({Session Start Date/Time}) = ${jsDate.getDate()}
            )`;
            
            if (isDateTodayOrFuture) {
              filterFormula = `AND(
                YEAR({Session Start Date/Time}) = ${jsDate.getFullYear()},
                MONTH({Session Start Date/Time}) = ${jsDate.getMonth() + 1},
                DAY({Session Start Date/Time}) = ${jsDate.getDate()},
                OR(
                  {Status} = "Booked",
                  {Status} = "Completed"
                )
              )`;
            }
            
            const sessionsFilter = {
              filterByFormula: filterFormula
            };
            
            const sessionsOnDate = await airtable.sessions.list(sessionsFilter);
            
            
            // Map sessions to hosts by hour
            sessionsOnDate.forEach(session => {
              
              // Check the field name for start time in the actual data
              const startTime = new Date(session["Session Start Date/Time"]);
              const sessionStartHour = startTime.getHours();
              
              // Only process if the session is during working hours (8am-6pm)
              if (sessionStartHour >= 8 && sessionStartHour <= 18) {
                const hourIndex = sessionStartHour - 8;
                
                // Get the session details
                const sessionName = session["Session Title Text"] || "Untitled Session";
                const providerName = session["Provider Name"] || "Unknown Provider";
                const teacherName = session["Teacher Name"] || "Unknown Teacher";
                const schoolName = session["School Name Text"] || "Unknown School";
                
                // Calculate session times
                const startDateTime = new Date(session["Session Start Date/Time"]);
                const sessionLength = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 minutes if not specified
                const endDateTime = new Date(startDateTime.getTime() + sessionLength * 60000);
                
                // Get hour and minute components
                const startHour = startDateTime.getHours();
                const endHour = endDateTime.getHours();
                
                // Calculate how much of the starting hour is used
                const startHourPercentage = 0;
                
                // Format times
                const startAmPm = startHour >= 12 ? 'PM' : 'AM';
                const formattedStartTime = `${startHour % 12 || 12}:${startDateTime.getMinutes().toString().padStart(2, '0')}`;
                
                let formattedTime = '';
                
                // Calculate end time using Length (Minutes)
                if (session["Length (Minutes)"]) {
                  const durationMinutes = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 minutes if parsing fails
                  
                  // Create a new date object for end time by adding duration to start time
                  const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
                  
                  // Format end time
                  const endAmPm = endHour >= 12 ? 'PM' : 'AM';
                  const formattedEndTime = `${endHour % 12 || 12}:${endTime.getMinutes().toString().padStart(2, '0')}`;
                  
                  // Combined time range with AM/PM only at the end if they're the same
                  formattedTime = startAmPm === endAmPm
                    ? `${formattedStartTime}-${formattedEndTime} ${endAmPm}`
                    : `${formattedStartTime} ${startAmPm}-${formattedEndTime} ${endAmPm}`;
                } else {
                  // If no duration, just show start time
                  formattedTime = `${formattedStartTime} ${startAmPm}`;
                }
                
                const sessionInfo = {
                  name: sessionName,
                  id: session.id || "",
                  airtableUrl: `https://airtable.com/appP1kThwW9zVEpHr/tblRqP4N6gdA8WZEd/viw6N2TB9BG7Dk6xq/${session.id}?blocks=hide`,
                  time: formattedStartTime,
                  timeRange: formattedTime,
                  provider: providerName,
                  teacher: teacherName,
                  school: schoolName,
                  startHour: startHour,
                  endHour: endHour,
                  startHourPercentage: startHourPercentage
                };
                
                // If the session has a host assigned
                if (session["Session Host(s)"] && session["Session Host(s)"].length > 0) {
                  // Loop through all hosts for this session
                  session["Session Host(s)"].forEach(hostId => {
                    
                    if (!hostBookingsData[hostId]) {
                      hostBookingsData[hostId] = Array(11).fill(null);
                    }
                    
                    // First, calculate the end time for the session
                    const sessionDuration = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 minutes
                    const endTime = new Date(startDateTime.getTime() + sessionDuration * 60000);
                    
                    // Check if the session starts and ends within the same hour
                    const startsAndEndsInSameHour = startTime.getHours() === endTime.getHours();
                    
                    if (startsAndEndsInSameHour) {
                      // Session is contained entirely within one hour
                      const startMinutes = startDateTime.getMinutes();
                      const endMinutes = endTime.getMinutes();
                      const occupiedMinutes = endMinutes - startMinutes;
                      
                      const partialSessionInfo = {
                        ...sessionInfo,
                        isPartial: true,
                        isPartialSingleHour: true,
                        startMinute: startMinutes,
                        endMinute: endMinutes,
                        partialMinutes: occupiedMinutes,
                        partialPercentage: occupiedMinutes / 60,
                        // Position in the hour (percentage) where the colored portion should start
                        startPosition: startMinutes / 60
                      };
                      
                      hostBookingsData[hostId][hourIndex] = partialSessionInfo;
                    }
                    // Handle sessions that start mid-hour and continue to next hour
                    else if (startDateTime.getMinutes() > 0) {
                      // This is a session that starts in the middle of an hour
                      const partialStartSessionInfo = {
                        ...sessionInfo,
                        isPartial: true,
                        isPartialStart: true,
                        partialMinutes: startDateTime.getMinutes(),
                        partialPercentage: startDateTime.getMinutes() / 60
                      };
                      
                      hostBookingsData[hostId][hourIndex] = partialStartSessionInfo;
                    } else {
                      // Normal full hour start
                      hostBookingsData[hostId][hourIndex] = sessionInfo;
                    }
                    
                    // Check if the session extends into the next hour
                    // If session ends in a different hour and we're not at the last hour slot
                    // Only consider it extending into next hour if endHour > startHour
                    if (endHour > startHour && hourIndex < 10) {
                      
                      // Mark the next hour as partially booked
                      const nextHourIndex = hourIndex + 1;
                      
                      // Calculate how many minutes into the next hour the session extends
                      // We need to create the endTime based on session start time and duration
                      const sessionDuration = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 minutes
                      const endTime = new Date(startDateTime.getTime() + sessionDuration * 60000);
                      const endMinutes = endTime.getMinutes();
                      
                      // If the session ends exactly on the hour (e.g., 1:00pm),
                      // don't show it as extending into the next hour at all
                      if (endMinutes === 0) {
                        // No need to mark anything for the next hour
                        // The session ends precisely at the hour boundary
                      } else {
                        // Only for sessions that actually extend into the next hour
                        const minutesIntoNextHour = endMinutes;
                        
                        // Create a partial booking indicator
                        const partialEndSessionInfo = {
                          ...sessionInfo,
                          isPartial: true,
                          isPartialEnd: true,
                          partialMinutes: minutesIntoNextHour,
                          partialPercentage: minutesIntoNextHour / 60
                        };
                        
                        // We want to mark it even if the host isn't available for that hour
                        if (!hostBookingsData[hostId][nextHourIndex]) {
                          hostBookingsData[hostId][nextHourIndex] = partialEndSessionInfo;
                        }
                      }
                      
                      // Check if the session spans multiple hours
                      if (endHour > startHour + 1) {
                        // Mark all intermediate hours as fully booked
                        for (let i = startHour + 1; i < endHour; i++) {
                          const intermediateHourIndex = i - 8;
                          if (intermediateHourIndex >= 0 && intermediateHourIndex < 11) {
                            hostBookingsData[hostId][intermediateHourIndex] = sessionInfo;
                          }
                        }
                      }
                    }
                  });
                } else {
                  // No host assigned to this session
                }
              }
            });
            
            setHostBookings(hostBookingsData);
          } catch (error) {
            console.error("Error fetching host bookings:", error);
          }
          
          // Initialize host availability structure
          const hostAvailabilityData = [];
          
          // For each host, check their availability on this weekday
          for (const host of sessionHosts) {
            // Skip hosts with 'Supervisor' in their name
            if (host.Name.includes('Supervisor')) continue;
            
            const userData = await airtable.teams.select(host.id);
            let st = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
            let userTimezone = 'America/Toronto';

            if (userData["Time Zone"]) {
              const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
              if (tz) userTimezone = tz["Connected North System"];
            }
            
            // Initialize availability array for this host
            const hostHourlyAvailability = Array(11).fill(false);
            
            if (st && st[dayOfWeek] && st[dayOfWeek].length) {
              st[dayOfWeek].forEach(a => {
                const convertedHour = convertToEastern(a, userTimezone);
                if (convertedHour >= 8 && convertedHour <= 18) {
                  hostHourlyAvailability[convertedHour - 8] = true;
                }
              });
            }
            
            
            // Add this host to the availability data
            hostAvailabilityData.push({
              id: host.id,
              name: host.Name,
              availability: hostHourlyAvailability.map(Boolean), // Ensure all values are boolean (true/false)
              timezone: userTimezone
            });
          }
          
          // Build a map of Session Host IDs to Provider IDs
          const hostToProviderMap = {};
          
          // Get provider information for hosts who are also providers
          for (const host of sessionHosts) {
            const userData = await airtable.teams.select(host.id);
            if (userData["Also a Provider?"] && userData["Also a Provider?"].length > 0) {
              // This host is also a provider - store their provider ID
              hostToProviderMap[host.id] = userData["Also a Provider?"][0];
            }
          }
          
          // Immediately check for provider sessions with our newly built map
          // This ensures provider sessions load on the first date selection
          await checkForProviderSessions(hostToProviderMap, date);
          
          // Sort hosts alphabetically by name
          hostAvailabilityData.sort((a, b) => a.name.localeCompare(b.name));
          setHostAvailabilities(hostAvailabilityData);
          
          // Also maintain the old structure for backwards compatibility
          const dateAvailability = Array(11).fill(0);
          const dateAvailableHosts = Array(11).fill().map(() => []);
          
          hostAvailabilityData.forEach(host => {
            host.availability.forEach((isAvailable, hourIndex) => {
              if (isAvailable) {
                dateAvailability[hourIndex]++;
                dateAvailableHosts[hourIndex].push(host.name);
              }
            });
          });
          
          setSpecificDateAvailability(dateAvailability);
          setAvailableHosts(prev => ({
            ...prev,
            specificDate: dateAvailableHosts
          }));
        }
      } else {
        // For session host view (their own availability)
        const dateAvailability = Array(11).fill(false);
        
        // Use the weekday data from the regular availability
        if (availability[weekdayName]) {
          for (let i = 0; i < availability[weekdayName].length; i++) {
            dateAvailability[i] = availability[weekdayName][i];
          }
        }
        
        setSpecificDateAvailability(dateAvailability);
      }
      
      // Fetch sessions for this date
      await fetchSessionsForDate(date);
      
      // Now that we have both host availabilities and sessions, calculate available hosts for each session
      if (isTeamViewer && Object.keys(sessionsForDate).length > 0 && hostAvailabilities.length > 0) {
        const hostsForSessions = {};
        
        // Provider sessions are now checked immediately after building the map in the code above
        // This section is intentionally left empty to avoid duplicate code
        
        Object.keys(sessionsForDate).forEach(schoolLead => {
          sessionsForDate[schoolLead].forEach(session => {
            const sessionDate = new Date(session["Session Start Date/Time"]);
            const startHour = sessionDate.getHours();
            
            // Calculate session end time
            const rawDuration = session["Length (Minutes)"];
            
            let durationMinutes = 60; // Default to 60 min
            
            // Safely parse the duration - handle if it's already a number, string, null, or undefined
            if (rawDuration !== null && rawDuration !== undefined) {
              if (typeof rawDuration === 'number') {
                durationMinutes = rawDuration;
              } else if (typeof rawDuration === 'string') {
                durationMinutes = parseInt(rawDuration, 10) || 60;
              }
            }
            
            const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
            const endHour = endTime.getHours();
            
            // Calculate exact end time in minutes
            const sessionEndMinutes = endTime.getMinutes();
            
            // Generate array of all hours this session spans
            const sessionHours = [];
            for (let h = startHour; h <= endHour; h++) {
              if (h >= 8 && h <= 18) { // Only include hours within 8am-6pm
                sessionHours.push(h);
              }
            }
            
            // Special case: if session ends exactly at the top of the hour,
            // we need to handle the last hour differently
            const endsExactlyOnHour = sessionEndMinutes === 0;
            
            // Determine available hosts for this session
            const availableHosts = [];
            
            // A host is available if they are available for all hours of the session
            // If the session ends exactly at the top of an hour, they don't need to be
            // available during that ending hour
            hostAvailabilities.forEach(host => {
              let hostIsAvailable = true;
              
              // Check each hour that this session spans (excluding the ending hour if session ends exactly on the hour)
              const hoursToCheck = sessionHours.filter(h => !(h === endHour && endsExactlyOnHour));
              
              // Check each hour that this session spans
              for (const hour of hoursToCheck) {
                const hourIndex = hour - 8; // Convert to 0-based index (8am = 0)
                
                // Check if host is available during this hour (using Boolean to handle both true and 1 values)
                const isAvailableThisHour = hourIndex >= 0 && hourIndex < 11 && 
                                           Boolean(host.availability[hourIndex]);
                                           
                // Check if host is already booked during this hour
                const existingBooking = hostBookings[host.id] && hostBookings[host.id][hourIndex];
                
                // If host is not available this hour, they can't host
                if (!isAvailableThisHour) {
                  hostIsAvailable = false;
                  break;
                }
                
                // If the host has an existing booking during this hour, check for a conflict
                if (existingBooking) {
                  // Extract time information from the timeRange string
                  if (existingBooking.timeRange) {
                    const timeString = existingBooking.timeRange;
                    
                    // Parse times from the timeRange (e.g. "1:00-2:30 PM")
                    const timeRegex = /(\d+):(\d+)(?:\s*-\s*(\d+):(\d+))?\s*(AM|PM)?/i;
                    const match = timeString.match(timeRegex);
                    
                    if (match) {
                      // Extract start time
                      let startHour = parseInt(match[1]);
                      const startMin = parseInt(match[2]);
                      
                      // Handle AM/PM conversion
                      const ampm = match[5] ? match[5].toUpperCase() : '';
                      if (ampm === 'PM' && startHour < 12) startHour += 12;
                      if (ampm === 'AM' && startHour === 12) startHour = 0;
                      
                      // Calculate end time if available
                      let endHour = startHour;
                      let endMin = startMin + 60; // Default to 1 hour if no end time
                      
                      if (match[3] && match[4]) {
                        endHour = parseInt(match[3]);
                        endMin = parseInt(match[4]);
                        
                        // Handle AM/PM for end time
                        if (ampm === 'PM' && endHour < 12) endHour += 12;
                        if (ampm === 'AM' && endHour === 12) endHour = 0;
                      }
                      
                      // Convert to Date objects for comparison
                      const bookingDate = new Date(
                        sessionDate.getFullYear(),
                        sessionDate.getMonth(),
                        sessionDate.getDate(),
                        startHour,
                        startMin,
                        0
                      );
                      
                      const bookingEndDate = new Date(
                        sessionDate.getFullYear(),
                        sessionDate.getMonth(),
                        sessionDate.getDate(),
                        endHour,
                        endMin,
                        0
                      );
                      
                      // Get this session's start and end times
                      const sessionDuration = parseInt(session["Length (Minutes)"]) || 60;
                      const sessionEndDate = new Date(sessionDate.getTime() + sessionDuration * 60000);
                      
                      // Required buffer between sessions (10 minutes in milliseconds)
                      const requiredBufferMs = 10 * 60 * 1000;
                      
                      // Check if there's enough buffer between sessions
                      if (
                        // Case 1: This session starts after the booking ends
                        (sessionDate >= new Date(bookingEndDate.getTime() + requiredBufferMs)) ||
                        // Case 2: This session ends before the booking starts
                        (sessionEndDate <= new Date(bookingDate.getTime() - requiredBufferMs))
                      ) {
                        // Enough buffer, no conflict
                      } else {
                        // Not enough buffer, there's a conflict
                        hostIsAvailable = false;
                        break;
                      }
                    } else {
                      // Couldn't parse the time string, be conservative
                      hostIsAvailable = false;
                      break;
                    }
                  } else {
                    // No timeRange information, be conservative
                    hostIsAvailable = false;
                    break;
                  }
                }
              }
              
              // If host is available for all hours, add them to available hosts list
              if (hostIsAvailable) {
                availableHosts.push({
                  id: host.id,
                  name: host.name
                });
              }
            });
            
            hostsForSessions[session.id] = availableHosts;
          });
        });
        
        setAvailableHostsForSessions(hostsForSessions);
      }
    } catch (error) {
      console.error("Error fetching specific date availability:", error);
      setError("Unable to fetch specific date availability. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch sessions for a specific date
  const fetchSessionsForDate = async (date) => {
    try {
      if (!date) return;
      
      const formattedDate = date.format('YYYY-MM-DD');
      
      // Create filter for sessions on this specific date that are assigned to Lily or have Jayson/Serei as School Lead
      const filterFormula = `AND(
        DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') = '${formattedDate}',
        Status = 'Booked',
        OR(
          {Session Host Text} = 'Lily Aniwaya',
          {School Lead Text} = 'Jayson Moore',
          {School Lead Text} = 'Michael Furdyk',
          {School Lead Email} = 'serei@takingitglobal.org'
        )
      )`;
      
      const sessionsFilter = {
        filterByFormula: filterFormula
      };
      
      const sessions = await airtable.sessions.list(sessionsFilter);
      // Process sessions to include formatted time
      const processedSessions = sessions.map(session => {
        const startTime = new Date(session["Session Start Date/Time"]);
        
        // Format start time
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
        const startAmPm = startHour >= 12 ? 'PM' : 'AM';
        const formattedStartTime = `${startHour % 12 || 12}:${startMinute.toString().padStart(2, '0')}`;
        
        let formattedTime = '';
        
        // Calculate end time using Length (Minutes)
        if (session["Length (Minutes)"]) {
          const durationMinutes = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 minutes if parsing fails
          
          // Create a new date object for end time by adding duration to start time
          const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
          
          // Format end time
          const endHour = endTime.getHours();
          const endMinute = endTime.getMinutes();
          const endAmPm = endHour >= 12 ? 'PM' : 'AM';
          const formattedEndTime = `${endHour % 12 || 12}:${endMinute.toString().padStart(2, '0')}`;
          
          // Combined time range with AM/PM only at the end if they're the same
          formattedTime = startAmPm === endAmPm
            ? `${formattedStartTime}-${formattedEndTime} ${endAmPm}`
            : `${formattedStartTime} ${startAmPm}-${formattedEndTime} ${endAmPm}`;
        } else {
          // If no duration, just show start time
          formattedTime = `${formattedStartTime} ${startAmPm}`;
        }
        
        return {
          ...session,
          formattedTime,
          hour: startHour
        };
      });
      
      // Filter out sessions that already have hosts other than Lily
      const filteredSessions = processedSessions.filter(session => {
        // Get the session hosts text and ensure it's a string
        const sessionHosts = String(session["Session Host Text"] || "");
        
        // If the session has no hosts or only has Lily, include it
        return !sessionHosts || sessionHosts === "Lily Aniwaya" || 
               (sessionHosts.includes("Lily Aniwaya") && sessionHosts.split(",").length === 1);
      });
      
      // Group sessions by School Lead Text
      const groupedSessions = {};
      filteredSessions.forEach(session => {
        const schoolLead = session["School Lead Text"] || "No School Lead";
        if (!groupedSessions[schoolLead]) {
          groupedSessions[schoolLead] = [];
        }
        groupedSessions[schoolLead].push(session);
      });
      
      // Sort sessions by time within each group
      Object.keys(groupedSessions).forEach(schoolLead => {
        groupedSessions[schoolLead].sort((a, b) => {
          const timeA = new Date(a["Session Start Date/Time"]).getTime();
          const timeB = new Date(b["Session Start Date/Time"]).getTime();
          return timeA - timeB;
        });
      });
      
      setSessionsForDate(groupedSessions);
      
      // The calculation of available hosts for each session has been moved to handleDateChange
      // to ensure it happens after both host availabilities and sessions are loaded
    } catch (error) {
      console.error("Error fetching sessions for date:", error);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="main-container">
      <div className="d-flex justify-content-end mb-3">
        <Button 
          color="info" 
          size="sm" 
          onClick={() => window.location.href = '/cn/session-host/availability-schedule'}
          className="mr-2"
        >
          Try new Weekly Scheduler
        </Button>
        {isTeamViewer && (
          <Button 
            color="primary" 
            size="sm" 
            onClick={() => window.location.href = '/cn/session-host/team-viewer'}
          >
            Host Team Viewer
          </Button>
        )}
      </div>
      <div className="page-head">
        <div className="page-title">
          {isTeamViewer ? (
            <div className="title-with-refresh">
              <h1>Session Host Availability</h1>
              <Button 
                className="refresh-btn" 
                color="secondary" 
                size="sm" 
                onClick={() => {
                  // Refresh data based on current view
                  if (isSpecificDateView && selectedDate) {
                    // If we're in specific date view, re-fetch with the current date
                    handleDateChange(selectedDate);
                  } else {
                    // Otherwise just refresh the regular availability data
                    fetchAvailability();
                  }
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                )}
                &nbsp;Refresh
              </Button>
            </div>
          ) : (
            <h1>My Availability</h1>
          )}
          <p>{isTeamViewer
            ? "View availability for all Session Hosts. Select a specific Host below, or click on a block to see available hosts for that time slot." 
            : "Let us know which days of the week and hours in each day (in your time zone) you're available for hosting by clicking the cells below!"}
          </p>
        </div>
        {!isTeamViewer && (
          <div className="head-actions">
            <Button
              color="primary"
              type="button"
              disabled={!changed}
              onClick={() => saveChange()}
            ><i className="fa fa-save" />&nbsp;Save</Button>
          </div>
        )}
      </div>
      <Row>
        {isTeamViewer && (
          <>
            <Col md={6}>
              <div className="host-select">
                <FormGroup>
                  <label>Select Session Host</label>
                  <Select
                    className="form-style"
                    value={selectedHost}
                    placeholder="All Hosts"
                    isDisabled={isSpecificDateView} // Disable when a date is selected
                    onChange={(selectedOption) => {
                      setSelectedHost(selectedOption);
                      // Clear specific date view when changing hosts
                      setIsSpecificDateView(false);
                      setSelectedDate(null);
                      if (selectedOption.value === 'all') {
                        fetchAvailability();
                      } else {
                        fetchIndividualHostAvailability(selectedOption.value);
                      }
                    }}
                    options={[
                      { value: 'all', label: 'All Hosts' },
                      ...sessionHosts.map(host => ({ value: host.id, label: host.Name }))
                    ]}
                  />
                </FormGroup>
              </div>
            </Col>
            
            <Col md={6}>
              <div className="date-select">
                <FormGroup>
                  <label>Select date (optional)</label>
                  <div className="date-picker-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="form-style"
                        inputFormat="MM/DD/YYYY"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <input {...params} className="form-control" />}
                        shouldDisableDate={(date) => {
                          // Optionally disable weekends
                          const day = date.day();
                          return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
                        }}
                      />
                    </LocalizationProvider>
                    {selectedDate && (
                      <Button
                        className="clear-date-btn"
                        color="secondary"
                        size="sm"
                        onClick={() => {
                          setSelectedDate(null);
                          setIsSpecificDateView(false);
                        }}
                      >
                        Clear date
                      </Button>
                    )}
                  </div>
                </FormGroup>
              </div>
            </Col>
          </>
        )}
      </Row>
      {!isTeamViewer && (
        <div className="timezone-select">
          <FormGroup>
            <label>What time zone do you live in?</label>
            <Select
              className="form-style"
              value={selectedTimezone}
              placeholder="Time Zone"
              onChange={(selectedOption) => {
                setSelectedTimezone(selectedOption)
                setChanged(true);
              }}
              options={totalTimezones
                .filter(tt => tt["Connected North System"].includes("Canada"))
                .map(tt => ({ value: tt.id, label: tt["Connected North System"] }))
                .sort((a, b) => a.label.localeCompare(b.label))
              }
            />
          </FormGroup>
        </div>
      )}
      <table className="availability">
        <thead>
          {isTeamViewer && (
            <tr>
              <th className="head-weekday"></th>
              <th className="head-time" colSpan="11">
                {selectedHost.value !== 'all' && hostTimezone
                  ? `Converted from ${hostTimezone} to Eastern Time (ET)`
                  : 'Eastern Time (ET)'}
              </th>
            </tr>
          )}
          <tr>
            <th className="head-weekday"></th>
            {Array.from({ length: 11 }, (_, i) => {
              const startHour = i + 8;
              const endHour = i + 9;
              const period = endHour >= 12 ? 'PM' : 'AM';
              return (
                <th key={i} className="head-time">
                  {`${formatTime(startHour)}-${formatTime(endHour)} ${period}`}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {isTeamViewer && isSpecificDateView && selectedDate ? (
            // Display specific date availability with one row per host (only for Team Members)
            <>
              <tr>
                <td colSpan="12" className="specific-date-heading">
                  Availability for {selectedDate.format('MMMM D, YYYY')} ({weekDays[selectedDate.day() === 0 ? 6 : selectedDate.day() - 1]})
                </td>
              </tr>
              {hostAvailabilities
                .filter(host => {
                  // Only show hosts who have at least one available hour for this day
                  // and don't have 'Supervisor' in their name
                  return host.availability.some(isAvailable => isAvailable) && !host.name.includes('Supervisor');
                })
                .map((host, hostIndex) => (
                  <tr key={host.id}>
                    <th className="host-name-cell">
                      {host.name}
                    </th>
                    {host.availability.map((isAvailable, hourIndex) => {
                      // Check if this host is booked for this hour
                      const sessionInfo = hostBookings[host.id] && hostBookings[host.id][hourIndex];
                      const isBooked = !!sessionInfo;
                      
                      // Check if host is booked as a provider during this hour
                      const providerInfo = hostProviderBookings[host.id] && hostProviderBookings[host.id][hourIndex];
                      const isProviderBooked = !!providerInfo;
                      
                      // Check if this is a partial booking
                      const isPartialBooked = isBooked && sessionInfo && sessionInfo.isPartial;
                      const isPartialStart = isPartialBooked && sessionInfo.isPartialStart;
                      const isPartialEnd = isPartialBooked && sessionInfo.isPartialEnd;
                      const isPartialSingleHour = isPartialBooked && sessionInfo.isPartialSingleHour;
                      const partialPercentage = isPartialBooked ? sessionInfo.partialPercentage : 0;
                      const startPosition = isPartialSingleHour ? sessionInfo.startPosition : 0;
                      
                      // Check if this host has a tentative booking for this hour
                      const tentativeInfo = tentativeBookings[host.id] && tentativeBookings[host.id][hourIndex];
                      const isTentativeBooked = !!tentativeInfo;
                      const isTentativePartial = isTentativeBooked && tentativeInfo.isPartial;
                      

                      
                      // Determine the cell class based on availability and booking status
                      let cellClass;
                      if (isProviderBooked) {
                        // Provider bookings take precedence - marked as red
                        if (providerInfo.isPartial) {
                          if (providerInfo.isPartialSingleHour) {
                            cellClass = "host-partial-single-hour host-provider-partial";
                          } else if (providerInfo.isPartialStart) {
                            cellClass = "host-partial-booked host-provider-partial partial-start";
                          } else if (providerInfo.isPartialEnd) {
                            cellClass = "host-partial-booked host-provider-partial";
                          } else {
                            cellClass = "host-provider-booked";
                          }
                        } else {
                          cellClass = "host-provider-booked";
                        }
                      } else if (isBooked) {
                        if (isPartialBooked) {
                          if (!isAvailable && isPartialEnd) {
                            // Special case: session extends into an unavailable hour
                            cellClass = classNames("host-partial-booked", "host-not-available-extended");
                          } else if (isPartialSingleHour) {
                            // Special case: session starts and ends within the same hour
                            cellClass = "host-partial-single-hour";
                          } else {
                            cellClass = classNames("host-partial-booked", {
                              "partial-start": isPartialStart
                            });
                          }
                        } else {
                          cellClass = "host-booked";
                        }
                      } else if (isTentativeBooked) {
                        // Tentative bookings - marked as yellow
                        if (isTentativePartial) {
                          if (tentativeInfo.isPartialSingleHour) {
                            cellClass = "host-partial-single-hour host-tentative-partial";
                          } else if (tentativeInfo.isPartialStart) {
                            cellClass = "host-partial-booked host-tentative-partial partial-start";
                          } else if (tentativeInfo.isPartialEnd) {
                            cellClass = "host-partial-booked host-tentative-partial";
                          } else {
                            cellClass = "host-tentative-booked";
                          }
                        } else {
                          cellClass = "host-tentative-booked";
                        }
                      } else if (!isAvailable) {
                        cellClass = "host-not-available";
                      } else {
                        cellClass = "host-available";
                      }
                      
                      // Check if this cell has both a confirmed session ending and tentative session starting in the same hour 
                      const hasEndingSession = isBooked && isPartialBooked && isPartialEnd;
                      const hasStartingTentative = isTentativeBooked && isTentativePartial && tentativeInfo.isPartialStart;
                      
                      // Check if this cell has both a tentative session ending and confirmed session starting in the same hour
                      const hasEndingTentative = isTentativeBooked && isTentativePartial && tentativeInfo.isPartialEnd;
                      const hasStartingSession = isBooked && isPartialBooked && isPartialStart;
                      
                      // Create the style with CSS variables for partial booking width and position
                      let cellStyle = {};
                      
                      // Handle the special cases where a session is ending and another is starting in the same hour
                      if (hasEndingSession && hasStartingTentative) {
                        // A confirmed session is ending and a tentative one is starting
                        cellClass = "host-partial-single-hour host-tentative-partial"; // Add tentative class for styling
                        
                        cellStyle = {
                          '--partial-width': `${sessionInfo.partialMinutes / 60 * 100}%`, // Width of ending session
                          '--partial-start': '0%', // Ending session starts at beginning of hour
                          '--partial-width2': `${(60 - tentativeInfo.partialMinutes) / 60 * 100}%`, // Width of starting session
                          '--partial-start2': `${tentativeInfo.startPosition * 100}%`, // Position of starting session
                          '--display-second': 'block' // Make the second partial visible
                        };
                        
                      } else if (hasEndingTentative && hasStartingSession) {
                        // A tentative session is ending and a confirmed one is starting
                        cellClass = "host-partial-single-hour host-tentative-partial"; // Add tentative class for styling
                        
                        cellStyle = {
                          '--partial-width': `${tentativeInfo.partialMinutes / 60 * 100}%`, // Width of ending tentative
                          '--partial-start': '0%', // Ending session starts at beginning of hour
                          '--partial-width2': `${(60 - sessionInfo.partialMinutes) / 60 * 100}%`, // Width of starting session
                          '--partial-start2': `${sessionInfo.startPosition * 100}%`, // Position of starting session
                          '--display-second': 'block' // Make the second partial visible
                        };
                        
                      } else if (isProviderBooked && providerInfo.isPartial) {
                        if (providerInfo.isPartialSingleHour) {
                          // For single hour provider sessions, we need both position and width
                          cellStyle = { 
                            '--partial-width': `${providerInfo.partialPercentage * 100}%`,
                            '--partial-start': `${providerInfo.startPosition * 100}%`
                          };
                        } else if (providerInfo.isPartialStart) {
                          // For partial start provider bookings, we need both start position and width
                          cellStyle = { 
                            '--partial-width': `${providerInfo.partialPercentage * 100}%`,
                            '--partial-start': `${providerInfo.startPosition * 100}%`
                          };
                        } else {
                          // For partial end provider bookings, just need width
                          cellStyle = { '--partial-width': `${providerInfo.partialPercentage * 100}%` };
                        }
                      } else if (isPartialBooked) {
                        if (isPartialSingleHour) {
                          // For single hour sessions, we need both position and width
                          cellStyle = { 
                            '--partial-width': `${partialPercentage * 100}%`,
                            '--partial-start': `${startPosition * 100}%`
                          };
                        } else {
                          // For other partial bookings, just need width
                          cellStyle = { '--partial-width': `${partialPercentage * 100}%` };
                        }
                      } else if (isTentativeBooked && isTentativePartial) {
                        if (tentativeInfo.isPartialSingleHour) {
                          // For single hour tentative sessions
                          cellStyle = { 
                            '--partial-width': `${tentativeInfo.partialPercentage * 100}%`,
                            '--partial-start': `${tentativeInfo.startPosition * 100}%`
                          };
                        } else if (tentativeInfo.isPartialStart) {
                          cellStyle = { 
                            '--partial-width': `${tentativeInfo.partialPercentage * 100}%`,
                            '--partial-start': `${tentativeInfo.startPosition * 100}%`
                          };
                        } else {
                          cellStyle = { '--partial-width': `${tentativeInfo.partialPercentage * 100}%` };
                        }
                      }
                      
                      // Create tooltip content without quotes
                      let tooltipContent = '';
                      
                      // Special case: handle both a session ending and another starting in the same hour
                      if (hasEndingSession && hasStartingTentative) {
                        // Show both sessions in the tooltip
                        const confirmEndMins = sessionInfo.partialMinutes || 0;
                        const tentativeStartMins = tentativeInfo.startPosition * 60 || 0;
                        
                        tooltipContent = 
                          `${sessionInfo.name} with ${sessionInfo.provider} extends ${confirmEndMins} minutes into this hour, ${sessionInfo.timeRange}\n` +
                          `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} starts ${tentativeStartMins} minutes into this hour, ${tentativeInfo.timeRange}`;
                      } else if (hasEndingTentative && hasStartingSession) {
                        // Show both sessions in the tooltip
                        const tentativeEndMins = tentativeInfo.partialMinutes || 0;
                        const confirmStartMins = sessionInfo.startPosition * 60 || 0;
                        
                        tooltipContent = 
                          `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} extends ${tentativeEndMins} minutes into this hour, ${tentativeInfo.timeRange}\n` +
                          `${sessionInfo.name} with ${sessionInfo.provider} starts ${confirmStartMins} minutes into this hour, ${sessionInfo.timeRange}`;
                      } else if (isBooked && sessionInfo) {
                        if (isPartialBooked) {
                          const partialMinutes = sessionInfo.partialMinutes;
                          if (isPartialSingleHour) {
                            const startMin = sessionInfo.startMinute;
                            const endMin = sessionInfo.endMinute;
                            tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} occupies from minute ${startMin} to ${endMin} of this hour, ${sessionInfo.timeRange}`;
                          } else if (isPartialStart) {
                            tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} starts ${partialMinutes} minutes into this hour, ${sessionInfo.timeRange}`;
                          } else if (isPartialEnd) {
                            if (partialMinutes === 0) {
                              tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} ends at the top of this hour, ${sessionInfo.timeRange}`;
                            } else {
                              tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} extends ${partialMinutes} minutes into this hour, ${sessionInfo.timeRange}`;
                            }
                          } else {
                            tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} partially occupies this hour, ${sessionInfo.timeRange}`;
                          }
                        } else {
                          tooltipContent = `${sessionInfo.name} with ${sessionInfo.provider} for ${sessionInfo.teacher} at ${sessionInfo.school}, ${sessionInfo.timeRange}`;
                        }
                      } else if (isTentativeBooked && tentativeInfo) {
                        if (isTentativePartial) {
                          const partialMinutes = tentativeInfo.partialMinutes;
                          if (tentativeInfo.isPartialSingleHour) {
                            const startMin = tentativeInfo.startMinute;
                            const endMin = tentativeInfo.endMinute;
                            tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} occupies from minute ${startMin} to ${endMin} of this hour, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                          } else if (tentativeInfo.isPartialStart) {
                            tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} starts ${partialMinutes} minutes into this hour, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                          } else if (tentativeInfo.isPartialEnd) {
                            if (partialMinutes === 0) {
                              tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} ends at the top of this hour, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                            } else {
                              tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} extends ${partialMinutes} minutes into this hour, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                            }
                          } else {
                            tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} partially occupies this hour, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                          }
                        } else {
                          tooltipContent = `TENTATIVE: ${tentativeInfo.name} with ${tentativeInfo.provider} for ${tentativeInfo.teacher} at ${tentativeInfo.school}, ${tentativeInfo.timeRange}${tentativeInfo.isPreviousMatch ? ' (Previously worked with this teacher/provider)' : ''}`;
                        }
                      } else if (isAvailable) {
                        tooltipContent = `${host.name} is available`;
                      } else {
                        tooltipContent = `${host.name} is not available`;
                      }
                      
                      // Create unique ID for this cell's tooltip
                      const tooltipId = `tooltip-${host.id}-${hourIndex}`;
                      
                      return (
                        <td
                          key={hourIndex}
                          id={tooltipId}
                          className={classNames("available-cell", cellClass)}
                          style={cellStyle}
                        >
                          {/* Show calendar icon for booked and provider-booked sessions */}
                          {(isBooked && sessionInfo && !(isPartialEnd && sessionInfo.partialMinutes === 0)) && (
                            <i 
                              className="fa fa-calendar" 
                              aria-hidden="true"
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(sessionInfo.airtableUrl, '_blank');
                              }}
                            ></i>
                          )}
                          {/* Show calendar icon for provider bookings */}
                          {isProviderBooked && providerInfo && (
                            <i 
                              className="fa fa-calendar" 
                              aria-hidden="true"
                              onClick={(e) => {
                                e.stopPropagation();
                                window.open(providerInfo.airtableUrl, '_blank');
                              }}
                            ></i>
                          )}
                          {/* Show history icon for tentative bookings with repeat provider/teacher */}
                          {isTentativeBooked && tentativeInfo && tentativeInfo.isPreviousMatch && (
                            <i 
                              className="fa fa-history" 
                              aria-hidden="true"
                              title="Previously worked with this teacher/provider"
                              style={{ color: '#444444', marginLeft: '3px', fontSize: '14px', zIndex: 5, position: 'relative' }}
                            ></i>
                          )}
                          {/* Show tooltip with appropriate content based on booking type */}
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[`${host.id}-${hourIndex}`]}
                            target={tooltipId}
                            toggle={() => toggleTooltip(host.id, hourIndex)}
                          >
                            <div className="session-tooltip">
                              {isProviderBooked ? 
                                `${host.name} is delivering a session: ${providerInfo.name} for ${providerInfo.teacher} at ${providerInfo.school}, ${providerInfo.timeRange}` :
                                (hasEndingSession && hasStartingTentative) || (hasEndingTentative && hasStartingSession) ?
                                  <div>
                                    {tooltipContent.split('\n').map((line, i) => (
                                      <div key={i}>{line}</div>
                                    ))}
                                  </div> :
                                  (!(isPartialEnd && sessionInfo?.partialMinutes === 0) ? tooltipContent : `${host.name} is available`)
                              }
                            </div>
                          </Tooltip>
                        </td>
                      );
                    })}
                  </tr>
                ))}
            </>
          ) : (
            // Display weekly schedule
            weekDays.map(wd => (
              <tr key={wd}>
                <th className="head-weekday">{wd}</th>
                {availability[wd] && availability[wd].map((value, i) => (
                  <td
                    key={i}
                    id={`tooltip-${wd}-${i}`}
                    className={classNames("available-cell", {
                      "selected": !isTeamViewer && value,
                      "low-availability": isTeamViewer && selectedHost?.value === 'all' && value > 0 && value <= 3,
                      "medium-availability": isTeamViewer && selectedHost?.value === 'all' && value > 3 && value <= 6,
                      "high-availability": isTeamViewer && selectedHost?.value === 'all' && value > 6,
                      "individual-host-available": isTeamViewer && selectedHost?.value !== 'all' && value === 1
                    })}
                    onClick={() => isTeamViewer ? null : handleBlockClick(wd, i)}
                  >
                    {isTeamViewer && selectedHost?.value === 'all' && value > 0 && (
                      <>
                        {value}
                        {availableHosts[wd] && availableHosts[wd][i] && availableHosts[wd][i].length > 0 && (
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[`${wd}-${i}`]}
                            target={`tooltip-${wd}-${i}`}
                            toggle={() => toggleTooltip(wd, i)}
                          >
                            <div className="session-tooltip">
                              <strong>Available Hosts:</strong>
                              <ul>
                                {[...new Set(availableHosts[wd][i])]
                                  .sort((a, b) => a.localeCompare(b))
                                  .map((host, idx) => (
                                    <li key={idx}>{host}</li>
                                  ))
                                }
                              </ul>
                            </div>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="12">
              <div className="foot-row">
                {isTeamViewer && isSpecificDateView ? (
                  // Legend for specific date view - one row per host
                  <>
                    <div className="host-not-available" />
                    <p>Not available</p>
                    <div className="host-available" style={{ marginLeft: '20px' }} />
                    <p>Available</p>
                    <div className="host-booked" style={{ marginLeft: '20px' }} />
                    <p>Booked</p>
                    <div className="host-tentative-booked" style={{ marginLeft: '20px' }} />
                    <p>Tentative assignment</p>
                    <div className="host-provider-booked" style={{ marginLeft: '20px' }} />
                    <p>Delivering a session</p>
                    <div className="legend-calendar" style={{ marginLeft: '20px' }}>
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </div>
                    <p>View session</p>
                    <div className="legend-history" style={{ marginLeft: '20px' }}>
                      <i className="fa fa-history" aria-hidden="true"></i>
                    </div>
                    <p>Repeat teacher/provider</p>
                  </>
                ) : isTeamViewer ? (
                  // Legend for team viewer weekly schedule
                  selectedHost?.value === 'all' ? (
                    <>
                      <div className="low-availability-ex" />
                      <p>1-3 hosts available</p>
                      <div className="medium-availability-ex" />
                      <p>4-6 hosts available</p>
                      <div className="high-availability-ex" />
                      <p>7+ hosts available</p>
                    </>
                  ) : (
                    <>
                      <div className="individual-host-available-ex" />
                      <p>Host is available</p>
                      <div className="individual-host-unavailable-ex" />
                      <p>Host is not available</p>
                    </>
                  )
                ) : (
                  // Legend for session host view (their own availability)
                  <>
                    <div className="selected-ex" />
                    <p>time blocks you're available</p>
                    <div className="gap" />
                    <div className="unselected-ex" />
                    <p>time blocks you're not available</p>
                  </>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      {isSpecificDateView && selectedDate && Object.keys(sessionsForDate).length > 0 && (
        <>
          <div className="sessions-header-container">
            <h2 className="sessions-table-title">Host Assignment Queue for {selectedDate.format('MMMM D, YYYY')}</h2>
            <div className="button-group">
              <Button 
                className="reset-all-btn" 
                color="secondary" 
                onClick={resetUnassignedSessions}
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i> Reset All
              </Button>
              <Button 
                className="auto-assign-btn" 
                color="primary" 
                onClick={() => {
                  // Implement automagic host assignment
                  autoAssignHosts();
                }}
                disabled={isAutoAssigning}
              >
                {isAutoAssigning ? (
                  <>
                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Assigning Hosts...
                  </>
                ) : (
                  <>
                    <i className="fa fa-magic" aria-hidden="true"></i> Automagically Assign
                  </>
                )}
              </Button>
            </div>
          </div>
          {isLoading ? (
            <div className="loading-container">
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              <p>Loading host availability data...</p>
            </div>
          ) : (
            Object.keys(sessionsForDate).map(schoolLead => (
              <div key={schoolLead}>
                <h3 className="school-lead-title">{schoolLead}</h3>
                <table className="sessions">
                  <thead>
                    <tr>
                      <th>Session Title & Provider</th>
                      <th>School & Teacher</th>
                      <th>Time</th>
                      <th>Available Hosts</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sessionsForDate[schoolLead].map(session => {
                      // Check if this session is in the assigned list
                      const isAssigned = assignedSessionIds.includes(session.id);
                      
                      return (
                      <tr 
                        key={session.id} 
                        className={isAssigned ? 'assigned-session-row' : ''}
                      >
                        <td>
                          <div className="session-title"><a href={`http://sessions.connectednorth.org/cn/session/${session["Session Title"]}`} target="_blank" rel="noreferrer">{session["Session Title Text"]}</a></div>
                          <div className="session-provider">with <a href={`http://sessions.connectednorth.org/cn/provider/${session["Provider"]}`} target="_blank" rel="noreferrer">{session["Provider Name"]}</a></div>
                        </td>
                        <td>
                          {session["# Schools"] > 1 ? (
                            <div className="school-name">
                              {session["Primary Subject Text"] === "Professional Development" ? 
                                `PD with ${session["# Schools"]} Schools` : 
                                `Premium Session with ${session["# Schools"]} Schools`}
                            </div>
                          ) : (
                            <div className="school-name"><a href={`${session["School Profile Link"]}`} target="_blank" rel="noreferrer">{session["School Name Text"]}</a></div>
                          )}
                          <div className="session-provider">for {session["Teacher Name"]}</div>
                        </td>
                        <td>{session.formattedTime}</td>
                        <td>
                          {(() => {
                            // Calculate session hours
                            const sessionDate = new Date(session["Session Start Date/Time"]);
                            const startHour = sessionDate.getHours();
                            
                            // Calculate session end time
                            const durationMinutes = parseInt(session["Length (Minutes)"]) || 60; // Default to 60 min
                            const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
                            const endHour = endTime.getHours();
                            
                            // Generate array of all hours this session spans
                            const sessionHours = [];
                            for (let h = startHour; h <= endHour; h++) {
                              if (h >= 8 && h <= 18) { // Only include hours within 8am-6pm
                                sessionHours.push(h);
                              }
                            }
                            
                            // Calculate if session ends exactly at the top of an hour
                            const sessionEndMinutes = endTime.getMinutes();
                            const endsExactlyOnHour = sessionEndMinutes === 0;
                            
                            // Force use hosts from the main list if needed - check for both availability AND booking conflicts
                            const availHosts = hostAvailabilities.filter(host => {
                              // For sessions that end exactly at the top of the hour,
                              // we need to exclude the end hour from our checks
                              const hoursToCheck = endsExactlyOnHour
                                ? sessionHours.filter(h => h !== endHour)
                                : sessionHours;
                              
                              // Check each hour of the session
                              for (const hour of hoursToCheck) {
                                const hourIndex = hour - 8;
                                
                                // Check if host is available
                                const isAvailable = hourIndex >= 0 && hourIndex < 11 && Boolean(host.availability[hourIndex]);
                                
                                // Check if host is already booked for this hour
                                const existingBooking = hostBookings[host.id] && hostBookings[host.id][hourIndex];
                                
                                // Also check if the host is booked as a provider during this hour
                                const providerBooking = hostProviderBookings[host.id] && hostProviderBookings[host.id][hourIndex];
                                
                                let hasBookingConflict = false;
                                
                                // If host is booked as a provider, they can't host this session
                                if (providerBooking) {
                                  // This host is delivering a session during this hour
                                  return false;
                                }
                                
                                // If there's an existing booking in this hour, we need to check if there's
                                // actual time overlap or if there's at least a 10-minute buffer
                                if (existingBooking) {
                                  // For this session
                                  const newSessionStartTime = sessionDate.getTime();
                                  const newSessionEndTime = endTime.getTime();
                                  
                                  if (existingBooking.timeRange) {
                                    // Parse the existing booking's time range to get start and end times
                                    const timeString = existingBooking.timeRange;
                                    
                                    // Extract times using regex
                                    const timeRegex = /(\d+):(\d+)(?:\s*-\s*(\d+):(\d+))?\s*(AM|PM)?/i;
                                    const match = timeString.match(timeRegex);
                                    
                                    if (match) {
                                      // Extract start time
                                      let startHour = parseInt(match[1]);
                                      const startMin = parseInt(match[2]);
                                      
                                      // Handle AM/PM conversion
                                      const ampm = match[5] ? match[5].toUpperCase() : '';
                                      if (ampm === 'PM' && startHour < 12) startHour += 12;
                                      if (ampm === 'AM' && startHour === 12) startHour = 0;
                                      
                                      // Calculate end time if available
                                      let endHour = startHour;
                                      let endMin = startMin + 60; // Default to 1 hour
                                      
                                      if (match[3] && match[4]) {
                                        endHour = parseInt(match[3]);
                                        endMin = parseInt(match[4]);
                                        
                                        // Handle AM/PM for end time
                                        if (ampm === 'PM' && endHour < 12) endHour += 12;
                                        if (ampm === 'AM' && endHour === 12) endHour = 0;
                                      }
                                      
                                      // Create Date objects for the booking times
                                      const bookingDate = new Date(
                                        sessionDate.getFullYear(),
                                        sessionDate.getMonth(),
                                        sessionDate.getDate(),
                                        startHour,
                                        startMin
                                      );
                                      
                                      const bookingEndDate = new Date(
                                        sessionDate.getFullYear(),
                                        sessionDate.getMonth(),
                                        sessionDate.getDate(),
                                        endHour,
                                        endMin
                                      );
                                      
                                      // Convert to timestamps for comparison
                                      const bookingStartTime = bookingDate.getTime();
                                      const bookingEndTime = bookingEndDate.getTime();
                                      
                                      // Required buffer between sessions (10 minutes)
                                      const requiredBufferMs = 10 * 60 * 1000;
                                      
                                      // Check if there's a conflict (less than 10 min buffer between sessions)
                                      if (
                                        // Case 1: Booking ends before session starts
                                        bookingEndTime <= newSessionStartTime &&
                                        (newSessionStartTime - bookingEndTime) >= requiredBufferMs
                                      ) {
                                        hasBookingConflict = false;
                                      } 
                                      // Case 2: Session ends before booking starts
                                      else if (
                                        newSessionEndTime <= bookingStartTime &&
                                        (bookingStartTime - newSessionEndTime) >= requiredBufferMs
                                      ) {
                                        hasBookingConflict = false;
                                      }
                                      // If neither of the above, there's a conflict
                                      else {
                                        hasBookingConflict = true;
                                      }
                                    } else {
                                      // If we can't parse the time, be conservative and assume conflict
                                      hasBookingConflict = true;
                                    }
                                  } else {
                                    // If there's no timeRange info, be conservative and assume conflict
                                    hasBookingConflict = true;
                                  }
                                }
                                
                                // If not available or has a booking conflict, host can't take this session
                                if (!isAvailable || hasBookingConflict) {
                                  return false;
                                }
                              }
                              
                              // If we get here, host is available for all hours of this session
                              return true;
                            }).map(host => ({
                              id: host.id,
                              name: host.name
                            }));
                            
                            // Use availableHostsForSessions if it exists, otherwise use our calculated availHosts
                            const hostsToUse = (availableHostsForSessions[session.id] && 
                                              availableHostsForSessions[session.id].length > 0) 
                                              ? availableHostsForSessions[session.id] 
                                              : availHosts;
                            
                            if (hostsToUse && hostsToUse.length > 0) {
                              return (
                                <div className="host-assign-container">
                                  <Select
                                    key={`select-${session.id}-${resetKey}`}
                                    className="host-select"
                                    value={selectedSessionHosts[session.id]}
                                    options={hostsToUse
                                      .filter(host => !blockedHostIds[session.id]?.includes(host.id))
                                      .map(host => ({
                                        value: host.id,
                                        label: host.name
                                      }))}
                                    placeholder="Select a host..."
                                    isSearchable
                                    formatOptionLabel={(option) => (
                                      <div className="select-option-with-icon">
                                        {option.label}
                                        {selectedSessionHosts[session.id]?.isPreviousMatch && 
                                         option.value === selectedSessionHosts[session.id]?.value && (
                                          <i className="fa fa-history ml-1" title="Host previously assigned to this teacher/provider combination"></i>
                                        )}
                                      </div>
                                    )}
                                    onChange={(selectedOption) => {
                                      const previousHostId = selectedSessionHosts[session.id]?.value;
                                      
                                      // First, if we had a previous selection, remove that host ID from blocked lists
                                      // and clear any tentative bookings for that host for this session
                                      if (previousHostId) {
                                        // Clear tentative bookings for previous host
                                        setTentativeBookings(prev => {
                                          const newState = {...prev};
                                          
                                          // If we have tentative bookings for this host
                                          if (newState[previousHostId]) {
                                            // Create a clean new hourly bookings state that removes this session
                                            const newHourlyBookings = {...newState[previousHostId]};
                                            
                                            // Remove all hour slots related to this session
                                            Object.keys(newHourlyBookings).forEach(hourIdx => {
                                              if (newHourlyBookings[hourIdx]?.id === session.id) {
                                                delete newHourlyBookings[hourIdx];
                                              }
                                            });
                                            
                                            // Update the host's bookings
                                            newState[previousHostId] = newHourlyBookings;
                                            
                                            // If there are no more bookings for this host, remove the host entry
                                            if (Object.keys(newHourlyBookings).length === 0) {
                                              delete newState[previousHostId];
                                            }
                                          }
                                          
                                          return newState;
                                        });
                                        
                                        // Find all sessions that overlap with this one
                                        const overlappingSessions = findOverlappingSessions(session.id);
                                        
                                        // For each overlapping session, remove the previous host from their blocked list
                                        overlappingSessions.forEach(overlapSessionId => {
                                          setBlockedHostIds(prev => ({
                                            ...prev,
                                            [overlapSessionId]: (prev[overlapSessionId] || [])
                                              .filter(id => id !== previousHostId)
                                          }));
                                        });
                                      }
                                      
                                      // Then handle the new selection
                                      if (selectedOption) {
                                        // Update the selected host for this session
                                        setSelectedSessionHosts(prev => ({
                                          ...prev,
                                          [session.id]: selectedOption
                                        }));
                                        
                                        // Add tentative bookings for this host and session
                                        const sessionDate = new Date(session["Session Start Date/Time"]);
                                        const startHour = sessionDate.getHours();
                                        const durationMinutes = parseInt(session["Length (Minutes)"]) || 60;
                                        const endTime = new Date(sessionDate.getTime() + durationMinutes * 60000);
                                        const endHour = endTime.getHours();
                                        
                                        const sessionInfo = {
                                          id: session.id,
                                          name: session["Session Title Text"],
                                          provider: session["Provider Name"],
                                          teacher: session["Teacher Name"],
                                          school: session["School Name Text"],
                                          startHour: startHour,
                                          endHour: endHour,
                                          timeRange: session.formattedTime,
                                        };
                                        
                                        setTentativeBookings(prev => {
                                          const newState = {...prev};
                                          
                                          // Initialize host bookings if not already
                                          if (!newState[selectedOption.value]) {
                                            newState[selectedOption.value] = {};
                                          }
                                          
                                          // Handle hours this session spans
                                          for (let h = startHour; h <= endHour; h++) {
                                            const hourIndex = h - 8; // Convert to 0-based index (8am = 0)
                                            
                                            if (hourIndex >= 0 && hourIndex < 11) { // Only within our display range
                                              if (h === startHour && h === endHour) {
                                                // Single hour session
                                                const startMinutes = sessionDate.getMinutes();
                                                const endMinutes = endTime.getMinutes();
                                                
                                                newState[selectedOption.value][hourIndex] = {
                                                  ...sessionInfo,
                                                  isPartial: true,
                                                  isPartialSingleHour: true,
                                                  startMinute: startMinutes,
                                                  endMinute: endMinutes,
                                                  partialMinutes: endMinutes - startMinutes,
                                                  partialPercentage: (endMinutes - startMinutes) / 60,
                                                  startPosition: startMinutes / 60
                                                };
                                              } else if (h === startHour) {
                                                // First hour - partial if not starting at XX:00
                                                const startMinutes = sessionDate.getMinutes();
                                                
                                                if (startMinutes > 0) {
                                                  newState[selectedOption.value][hourIndex] = {
                                                    ...sessionInfo,
                                                    isPartial: true,
                                                    isPartialStart: true,
                                                    partialMinutes: startMinutes,
                                                    partialPercentage: (60 - startMinutes) / 60,
                                                    startPosition: startMinutes / 60
                                                  };
                                                } else {
                                                  newState[selectedOption.value][hourIndex] = sessionInfo;
                                                }
                                              } else if (h === endHour) {
                                                // Last hour - partial if not ending at XX:00
                                                const endMinutes = endTime.getMinutes();
                                                
                                                if (endMinutes > 0) {
                                                  newState[selectedOption.value][hourIndex] = {
                                                    ...sessionInfo,
                                                    isPartial: true,
                                                    isPartialEnd: true,
                                                    partialMinutes: endMinutes,
                                                    partialPercentage: endMinutes / 60
                                                  };
                                                }
                                              } else {
                                                // Middle hours - fully booked
                                                newState[selectedOption.value][hourIndex] = sessionInfo;
                                              }
                                            }
                                          }
                                          
                                          return newState;
                                        });
                                        
                                        // Find all sessions that overlap with this one
                                        const overlappingSessions = findOverlappingSessions(session.id);
                                        
                                        // For each overlapping session, add this host ID to their blocked list
                                        overlappingSessions.forEach(overlapSessionId => {
                                          setBlockedHostIds(prev => ({
                                            ...prev,
                                            [overlapSessionId]: [...(prev[overlapSessionId] || []), selectedOption.value]
                                          }));
                                        });
                                      } else {
                                        // If selection was cleared, just update selected hosts
                                        setSelectedSessionHosts(prev => {
                                          const newState = {...prev};
                                          delete newState[session.id];
                                          return newState;
                                        });
                                      }
                                    }}
                                  />
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="assign-host-btn"
                                    disabled={!selectedSessionHosts[session.id]}
                                    onClick={async () => {
                                      if (selectedSessionHosts[session.id]) {
                                        const hostId = selectedSessionHosts[session.id].value;
                                        const hostName = selectedSessionHosts[session.id].label;
                                        
                                        try {
                                          // Update the session in Airtable with the selected host
                                          await airtable.sessions.update(session.id, {
                                            "Session Host(s)": [hostId]
                                          });
                                          
                                          // Show success message with session details
                                          toast.success(`Session "${session["Session Title Text"]}" successfully assigned to ${hostName}!`);
                                          
                                          // Add this session to the assigned list (will be used to fade out the row)
                                          setAssignedSessionIds(prev => [...prev, session.id]);
                                          
                                          // Clear any tentative bookings for this host for this session
                                          setTentativeBookings(prev => {
                                            const newState = {...prev};
                                            
                                            if (newState[hostId]) {
                                              // Create a clean new hourly bookings state that removes this session
                                              const newHourlyBookings = {...newState[hostId]};
                                              
                                              // Remove all hour slots related to this session
                                              Object.keys(newHourlyBookings).forEach(hourIdx => {
                                                if (newHourlyBookings[hourIdx]?.id === session.id) {
                                                  delete newHourlyBookings[hourIdx];
                                                }
                                              });
                                              
                                              // Update the host's bookings
                                              newState[hostId] = newHourlyBookings;
                                              
                                              // If there are no more bookings for this host, remove the host entry
                                              if (Object.keys(newHourlyBookings).length === 0) {
                                                delete newState[hostId];
                                              }
                                            }
                                            
                                            return newState;
                                          });
                                          
                                          // Remove this host from being selected and clear blocking
                                          const overlappingSessions = findOverlappingSessions(session.id);
                                          
                                          // For each overlapping session, remove this host from their blocked list
                                          // since it's now assigned in Airtable and will show up as booked
                                          overlappingSessions.forEach(overlapSessionId => {
                                            setBlockedHostIds(prev => {
                                              const newState = {...prev};
                                              if (newState[overlapSessionId]) {
                                                newState[overlapSessionId] = newState[overlapSessionId].filter(id => id !== hostId);
                                              }
                                              return newState;
                                            });
                                          });
                                          
                                          // Instead of refreshing the entire page, update the hostBookings
                                          // data structure to mark this host as booked during this session's hours
                                          setTimeout(() => {
                                            // Create a synthetic booking entry for this host/session
                                            const sessionStart = new Date(session["Session Start Date/Time"]);
                                            const sessionLength = parseInt(session["Length (Minutes)"]) || 60;
                                            const sessionEnd = new Date(sessionStart.getTime() + sessionLength * 60000);
                                            
                                            // Get the start and end hours
                                            const startHour = sessionStart.getHours();
                                            const endHour = sessionEnd.getHours();
                                            
                                            // Add a booking for this host for each hour that the session spans
                                            for (let h = startHour; h <= endHour; h++) {
                                              const hourIdx = h - 8; // Convert to 0-based index
                                              if (hourIdx >= 0 && hourIdx < 11) { // Only handle hours in our display range
                                                // Create a booking entry that will block this host
                                                const bookingEntry = {
                                                  id: session.id,
                                                  name: session["Session Title Text"],
                                                  provider: session["Provider Name"],
                                                  teacher: session["Teacher Name"],
                                                  school: session["School Name Text"],
                                                  time: session.formattedTime,
                                                  timeRange: session.formattedTime,
                                                  isPartial: false
                                                };
                                                
                                                // Update the hostBookings data structure
                                                setHostBookings(prev => {
                                                  const updated = {...prev};
                                                  
                                                  // Initialize if needed
                                                  if (!updated[hostId]) {
                                                    updated[hostId] = Array(11).fill(null);
                                                  }
                                                  
                                                  // Add this booking to this host's schedule
                                                  updated[hostId][hourIdx] = bookingEntry;
                                                  
                                                  return updated;
                                                });
                                              }
                                            }
                                          }, 3000); // Still wait 3 seconds to allow the fade animation to complete
                                        } catch (error) {
                                          console.error("Error assigning host:", error);
                                          toast.error("Failed to assign host. Please try again.");
                                        }
                                      }
                                    }}
                                  >
                                    <i className="fa fa-check"></i>
                                  </Button>
                                </div>
                              );
                            } else {
                              return 'No available hosts';
                            }
                          })()}
                        </td>
                      </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};

export default Screen;
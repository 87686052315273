import React, { useId, useState, useEffect } from "react";
import {
	Box,
	Typography,
	AccordionDetails,
	AccordionSummary,
	Accordion as MuiAccordion,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { withRouter } from "react-router-dom";

const AccordionComponent = ({
	title,
	icon,
	children,
	isNavOpen,
	tooltipId = "",
	component = "span",
	intercomLabel,
	history
}) => {
	const id = useId();
	const [isExpanded, setIsExpanded] = useState(false);

	const handleExpansion = (event) => {
		// Check if click was on the expand icon or its container
		const isExpandIconClick = event.target.closest('.MuiAccordionSummary-expandIconWrapper');
		
		if (isExpandIconClick) {
			// Just toggle the expanded state
			setIsExpanded((prevExpanded) => !prevExpanded);
			return;
		}

		// If it's not expanded, expand it first
		if (!isExpanded) {
			setIsExpanded(true);
		}
		
		// Navigate to the first valid submenu item's URL
		const list = React.Children.toArray(children)[0];
		if (list && list.props && list.props.children) {
			const menuItems = React.Children.toArray(list.props.children);
			const firstValidItem = menuItems.find(item => item && item.props && item.props.to && item.props.showLink);
			
			if (firstValidItem && firstValidItem.props.to) {
				setTimeout(() => {
					history.push(firstValidItem.props.to);
				}, 0);
			}
		}
	};

	useEffect(() => {
		if (!isNavOpen) {
			setIsExpanded(false);
		}
	}, [isNavOpen]);

	return (
		<MuiAccordion
			data-intercom-target={intercomLabel}
			disableGutters
			elevation={0}
			expanded={isExpanded}
			onChange={handleExpansion}
			sx={{
				"&:before": {
					display: "none",
				},
				borderBottom: isNavOpen ? "1px solid #0E6BA8" : "1px solid transparent",
				"&.MuiPaper-root": {
					borderRadius: 0,
				},
				".MuiButtonBase-root.MuiAccordionSummary-root": {
					borderLeft: '2px solid transparent',
				},
				".MuiButtonBase-root.MuiAccordionSummary-root.Mui-focusVisible": { 
					backgroundColor: '#9DE0F8',
					borderLeft: '2px solid #0E6BA8',
					color: '#000',
					borderRadius: '0.25rem',
				}
			}}
		>
			<AccordionSummary
				expandIcon={
					<KeyboardArrowDownRoundedIcon
						sx={{
							color: "#0E6BA8",
							stroke: "#0E6BA8",
						}}
					/>
				}
				aria-controls={`panel-${id}-content`}
				id={`panel-${id}-header`}
				sx={{
					justifyContent: "start",
					"&.MuiAccordionSummary-root": {
						padding: "0",
						fontWeight: "400",
					},
					".MuiAccordionSummary-content": {
						margin: '0',
					}
				}}
			>
				<Box
					className={`${isNavOpen ? 'submenu-title' : 'submenu-title collapsed-nav'}`}>
					<Box
						id={tooltipId}
						className='menu-item-container'
					>
						{icon && (
							<Box className="icon-container" sx={{ marginRight: '14px', padding: "7px" }}>
								{icon}
							</Box>
						)}
						<Typography
							component={component}
							sx={{
								fontFamily: "Effra, sans-serif",
								fontSize: "1.13rem",
								fontWeight: "400",
								display: "flex",
								whiteSpace: 'nowrap',
								alignItems: 'center',
							}}
						>
							{title}
						</Typography>
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: '0', backgroundColor: '#F3F9FF' }}>
				<Box className="sub-menu-items">
					{children}
				</Box>
			</AccordionDetails>
		</MuiAccordion>
	);
};

export const Accordion = withRouter(AccordionComponent);
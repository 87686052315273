import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewSchoolZoomedIcon = () => (
    <SvgIcon width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
            <g id="Group 5432">
            <path id="Vector" d="M15.3195 5.33462C15.2438 5.31368 15.1664 5.29615 15.0882 5.28031C14.2155 5.10266 13.3313 5.30851 12.6169 5.78755C13.1843 5.84592 13.7409 5.99218 14.2536 6.24228C15.3007 6.75428 16.1406 7.68655 16.5543 8.79666C16.8027 9.46665 16.8945 10.1938 16.8319 10.9112C17.1919 10.5158 17.4592 10.0369 17.6072 9.50167C18.108 7.6914 17.0819 5.82217 15.3195 5.33462Z" fill="#9DE0F8"/>
            <path id="Vector_2" d="M2.79598 22.0723H1.65707V11.3009H3.31421V21.5363C3.31421 21.8323 3.08222 22.0723 2.79598 22.0723Z" fill="#9DE0F8"/>
            <path id="Vector_3" d="M26.8245 22.0723H25.6856V11.3009H27.3428V21.5363C27.3428 21.8323 27.1108 22.0723 26.8245 22.0723Z" fill="#9DE0F8"/>
            <path id="Vector_4" d="M22.45 22.9794H18.15V17.2044C18.15 16.3896 17.4898 15.7294 16.675 15.7294H12.325C11.5102 15.7294 10.85 16.3896 10.85 17.2044V22.9794H6.55V6.81261C6.55 6.81256 6.55 6.81251 6.55 6.81245C6.55005 6.69728 6.57852 6.5839 6.63287 6.48236L5.97165 6.1284L6.63287 6.48236C6.68725 6.38078 6.76587 6.29418 6.86174 6.23027L14.1115 1.39697C14.1115 1.39694 14.1115 1.39691 14.1116 1.39688C14.1116 1.39685 14.1117 1.39682 14.1117 1.39678C14.2268 1.32025 14.3618 1.27942 14.5 1.27942C14.6382 1.27942 14.7732 1.32025 14.8883 1.39678C14.8883 1.39682 14.8884 1.39685 14.8884 1.39688C14.8885 1.39691 14.8885 1.39694 14.8885 1.39697L22.1383 6.23027L22.1384 6.23038C22.3328 6.35987 22.45 6.57866 22.45 6.81291V22.9794ZM3.6 22.9794H0.75V10.6794C0.75 10.293 1.06354 9.97942 1.45 9.97942H3.6V22.9794ZM10.125 8.50442C10.125 10.9205 12.0839 12.8794 14.5 12.8794C16.9161 12.8794 18.875 10.9205 18.875 8.50442C18.875 6.0883 16.9161 4.12942 14.5 4.12942C12.0839 4.12942 10.125 6.0883 10.125 8.50442ZM25.4 22.9794V9.97942H27.55C27.9365 9.97942 28.25 10.293 28.25 10.6794V22.9794H25.4Z" stroke="currentColor" strokeWidth="1.5"/>
            </g>
        </svg>
  </SvgIcon>
)

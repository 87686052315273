import { Card } from "../../../components/Card";
import { Token } from "./components/Token";
import { CreditTable } from "./components/CreditTable";
import { Pagination } from "../../../components/Pagination.jsx";
import { Filter } from "./components/Filter";
import { CreditStepper } from "./components/CreditStepper";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading";
import { useCreditRecord } from "./useCreditRecord";
import { useHelp } from "./useHelp";
import { HelpModal } from "./components/HelpModal";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Col, Row } from "reactstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

export function CreditDash() {
	const {
		loading,
		creds,
		totalCred,
		pageCount,
		page,
		setPage,
		teacher,
		filterOpen,
		filterValues,
		handleFilterSelect,
		toggleFilter,
		filterMap,
		purchaseOK,
		achievementCredit,
	} = useCreditRecord();

	const { userType } = useSelector((state) => state.appInfo);
	const helper = useHelp();

	useEffect(() => {
		window.document.title = "Credit - Connected North";
		window.document
			.querySelector("main")
			?.style.setProperty("background-color", "#f3f9ff");
	}, []);

	const { t } = useTranslation();

	return loading ? (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				height: "100vh",
				width: "100%",
			}}
		>
			<Loading />
		</div>
	) : (
		<>
			<div
				className="container my-4"
				style={{ minHeight: "calc(100vh- 70px)" }}
			>
				<Row className="justify-content-between align-items-center mb-4">
					<Col xs="12" sm="6" className="pl-0">
						<h1>{userType === "Team" ? `${teacher["Teacher Name"]}'s Credit History` : t("credit-page.dash.heading")}</h1>
					</Col>
					{	userType !== "Team" &&
						<Col
							xs="12"
							sm="6"
							className="d-flex justify-content-md-end pl-0"
						>
							<Link
								to="/cn/credits/earn-more"
								className="btn btn-primary"
								style={{
									width: "190px",
									height: "44px",
								}}
							>
								{t("credit-page.dash.earn-more-btn")}
							</Link>
						</Col>
						}
				</Row>

				<Row className="mb-4">
					{/* Available Booking Credits */}
					<Col
						xs="12"
						md="6"
						className={isMobile ? "px-0 mb-3" : "pr-2 pl-0"}
					>
						<Card
							className="col-md d-flex flex-column pb-3"
							style={{ backgroundColor: "#0E6BA8" }}
						>
							<h2 className="h5 text-white font-weight-bold">
								{t("credit-page.dash.available")}
							</h2>
							<div className="d-flex align-items-center">
								<p className="text-white m-0 mr-2 h1">
									{totalCred}
								</p>
								<Token />
							</div>
						</Card>
					</Col>
					{/* Earn Credits with Achievements */}
					<Col
						xs="12"
						md="6"
						className={isMobile ? "px-0" : "pr-0 pl-2"}
					>
						{/* the test credits from airtable can be
			negative, but it breaks the lib if a negative number is passed in*/}
						<CreditStepper credit={achievementCredit} isTeacher={userType !== "Team"} />
					</Col>
				</Row>

				<section className="row">
					{/* My Activity */}
					<Card className="col bg-white">
						<div className="d-flex justify-content-between align-items-center my-2 mb-4">
							<h2 className="h4 font-weight-bold">
								{t("credit-page.dash.my-activity")}
							</h2>
							<div
								className="d-flex flex-row justify-content-center align-items-center"
								style={{ gap: "35px" }}
							>
								<Filter
									open={filterOpen}
									toggleOpen={toggleFilter}
									filterValues={filterValues}
									handleSelect={handleFilterSelect}
									filterOpts={filterMap}
								/>
								{
									userType !== "Team" &&
									<button
										className="btn btn-outline-primary"
										onClick={helper.toggleModal}
									>
										Need Help?
									</button>
								}
							</div>
						</div>

						{/* Credit table */}
						<CreditTable credits={creds} />
						{pageCount > 1 ? (
							<Pagination
								pageCount={pageCount}
								setPage={setPage}
								page={page}
							/>
						) : (
							<></>
						)}
					</Card>
				</section>
			</div>

			<HelpModal {...helper} />
			{/* get in touch soon */}
			{helper.ok ? (
				<div
					className="bg-success py-2 px-3"
					role="alert"
					style={{
						gap: 20,
						position: "fixed",
						bottom: 50,
						left: 50,
						zIndex: 1000,
						borderRadius: 10,
					}}
				>
					<p className="p-0 m-0">{t("credit-page.dash.email-ok")}</p>
				</div>
			) : (
				<></>
			)}

			<Alert
				style={{
					position: "fixed",
					top: 20,
					left: "50%",
					transform: "translateX(-50%)",
					zIndex: 9999,
					maxWidth: "65ch",
				}}
				isOpen={purchaseOK}
				fade={true}
			>
				<p className="p-0 m-0">
					{t("credit-page.dash.credit-purchase-ok")}
				</p>
			</Alert>
		</>
	);
}

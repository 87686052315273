export const getIsPrincipal = (userInfo) => {
    if (!userInfo) return false;
    if ((userInfo["School Leader?"] || userInfo["District Leader?"]) && (userInfo["School P/T Text"] === undefined || userInfo["School P/T Text"] !== "NU" || (userInfo["2FA"] && userInfo["2FA"] !== "None"))) {
      return true
    }
    return false;
};

export const getIsSessionPrincipal = (userInfo, isSessionHost) => {
  if (!userInfo) return false;

  if (!isSessionHost && userInfo["Schools Managed"] && userInfo["Schools Managed"].length > 0) {
    return true
  }

  return false;
};

import { PRINCIPAL_FIELDS } from "../constants/graph-choices";

const getFieldIdsFromField = (field) => {
	if (!field || !field.options) return [];
	return field.options.group_by_id
		? [field.field_id, field.options.group_by_id]
		: [field.field_id];
};

const getFieldIdsFromGraphFields = (graphFields) => {
	const fieldIds = [];

	if (Array.isArray(graphFields.graph_cards)) {
		graphFields.graph_cards.forEach((card) => {
			Object.values(card.options).forEach((field) => {
				if (field) {
					fieldIds.push(...getFieldIdsFromField(field));
				}
			});
		});
	}

	if (Array.isArray(graphFields.comparative_graph)) {
		graphFields.comparative_graph.forEach((field) => {
			if (field) {
				fieldIds.push(...getFieldIdsFromField(field));
			}
		});
	}

	return fieldIds;
};

export const getFieldIds = (graphFields) => {
	return [...new Set(getFieldIdsFromGraphFields(graphFields))];
};

const parseValue = (value) => {
	try {
		const parsed = JSON.parse(value);
		return Array.isArray(parsed) ? parsed : [value];
	} catch {
		return [value];
	}
};

const getUniqueOptions = (data) => {
	if (!Array.isArray(data)) return {}; // Prevent reduce on non-array
	return data.reduce((acc, item) => {
		parseValue(item.value).forEach((val) => {
			acc[val] = val;
		});
		return acc;
	}, {});
};

const singleValue = (data) => {
	return parseValue(data[0]?.value || "")[0];
};

const multiValueCount = (data) => {
	return data.reduce((count, item) => count + parseValue(item.value).length, 0);
};

const multiValueSum = (data) => {
	return data.reduce((acc, curr) => {
		return (
			acc +
			parseValue(curr.value).reduce(
				(sum, val) => sum + (parseFloat(val) || 0),
				0,
			)
		);
	}, 0);
};

const multiValueAvg = (data) => {
	if (!Array.isArray(data) || data.length === 0) return 0;
	const sum = multiValueSum(data);
	const count = multiValueCount(data);
	return count > 0 ? Math.round(sum / count) : 0;
};

const distinctValueCount = (data, options) => {
	options = options || getUniqueOptions(data);
	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;
		const groupByDataItem = data.find((item) =>
			parseValue(item.value).includes(key),
		);
		if (!groupByDataItem) return acc;
		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);
		acc[key] = new Set(
			groupData.flatMap((item) => parseValue(item.value)),
		).size;
		return acc;
	}, {});
};

const filteredValueCount = (data, filter_value, filter_operator) => {
	return data.filter((item) =>
		parseValue(item.value).some((val) =>
			applyFilter(val, filter_value, filter_operator),
		),
	).length;
};

const filteredValueSum = (data, filter_value, filter_operator) => {
	return data.reduce((acc, curr) => {
		return (
			acc +
			parseValue(curr.value)
				.filter((val) => applyFilter(val, filter_value, filter_operator))
				.reduce((sum, val) => sum + (parseFloat(val) || 0), 0)
		);
	}, 0);
};

const filteredValueAvg = (data, filter_value, filter_operator) => {
	if (!Array.isArray(data) || data.length === 0) return 0;

	const sum = filteredValueSum(data, filter_value, filter_operator);
	const count = filteredValueCount(data, filter_value, filter_operator);

	return count > 0 ? Math.round(sum / count) : 0;
};

const groupedValueCount = (data, options, group_by_data = []) => {
	options = options || getUniqueOptions(group_by_data);
	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;
		const groupByDataItem = group_by_data.find((item) =>
			parseValue(item.value).includes(key),
		);
		if (!groupByDataItem) return acc;
		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);
		acc[key] = groupData.length;
		return acc;
	}, {});
};

const groupedValueSum = (data, options, group_by_data = []) => {
  console.log('data', data);
  console.log('options', options);
  console.log('group_by_data', group_by_data);
  options = options || getUniqueOptions(group_by_data);
  return Object.keys(options).reduce((acc, key) => {
    acc[key] = 0;
    const groupByDataItems = group_by_data.filter((item) =>
      parseValue(item.value).includes(key),
    );
    if (groupByDataItems.length === 0) return acc;
    groupByDataItems.forEach((groupByDataItem) => {
      const groupData = data.filter(
        (item) => item.instance_number === groupByDataItem.instance_number,
      );
      acc[key] += groupData.reduce(
        (sum, item) =>
          sum +
          parseValue(item.value).reduce(
            (innerSum, val) => innerSum + (parseFloat(val) || 0),
            0,
          ),
        0,
      );
    });
    return acc;
  }, {});
};

const groupedValueAvg = (data, options, group_by_data = []) => {
	options = options || getUniqueOptions(group_by_data);
	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;

		const groupByDataItem = group_by_data.find((item) =>
			parseValue(item.value).includes(key),
		);

		if (!groupByDataItem) return acc;

		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);

		const sum = groupData.reduce((sum, item) => {
			return (
				sum +
				parseValue(item.value).reduce(
					(innerSum, val) => innerSum + (parseFloat(val) || 0),
					0,
				)
			);
		}, 0);

		const count = groupData.length;
		acc[key] = count > 0 ? Math.round(sum / count) : 0;
		return acc;
	}, {});
};

const filteredGroupValueCount = (
	data,
	options,
	group_by_data = [],
	filter_value,
	filter_operator,
) => {
	options = options || getUniqueOptions(group_by_data);
	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;
		const groupByDataItem = group_by_data.find((item) =>
			parseValue(item.value).includes(key),
		);
		if (!groupByDataItem) return acc;
		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);
		acc[key] = groupData.filter((item) =>
			parseValue(item.value).some((val) =>
				applyFilter(val, filter_value, filter_operator),
			),
		).length;
		return acc;
	}, {});
};

const filteredGroupValueSum = (
	data,
	options,
	group_by_data = [],
	filter_value,
	filter_operator,
) => {
	options = options || getUniqueOptions(group_by_data);
	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;
		const groupByDataItem = group_by_data.find((item) =>
			parseValue(item.value).includes(key),
		);
		if (!groupByDataItem) return acc;
		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);
		acc[key] = groupData.reduce((sum, item) => {
			return (
				sum +
				parseValue(item.value)
					.filter((val) => applyFilter(val, filter_value, filter_operator))
					.reduce((innerSum, val) => innerSum + (parseFloat(val) || 0), 0)
			);
		}, 0);
		return acc;
	}, {});
};

const filteredGroupValueAvg = (
	data,
	options,
	group_by_data = [],
	filter_value,
	filter_operator,
) => {
	options = options || getUniqueOptions(group_by_data);

	return Object.keys(options).reduce((acc, key) => {
		acc[key] = 0;

		const groupByDataItem = group_by_data.find((item) =>
			parseValue(item.value).includes(key),
		);

		if (!groupByDataItem) return acc;

		const groupData = data.filter(
			(item) => item.instance_number === groupByDataItem.instance_number,
		);

		const sum = groupData.reduce((sum, item) => {
			return (
				sum +
				parseValue(item.value)
					.filter((val) => applyFilter(val, filter_value, filter_operator))
					.reduce((innerSum, val) => innerSum + (parseFloat(val) || 0), 0)
			);
		}, 0);

		const count = groupData.filter((item) =>
			parseValue(item.value).some((val) =>
				applyFilter(val, filter_value, filter_operator),
			),
		).length;

		acc[key] = count > 0 ? Math.round(sum / count) : 0;
		return acc;
	}, {});
};

const applyFilter = (value, filter_value, operator) => {
	const numValue = parseFloat(value);
	const numFilterValue = parseFloat(filter_value);

	switch (operator) {
		case "===":
			return value === filter_value;
		case "!==":
			return value !== filter_value;
		case ">":
			return (
				!isNaN(numValue) && !isNaN(numFilterValue) && numValue > numFilterValue
			);
		case "<":
			return (
				!isNaN(numValue) && !isNaN(numFilterValue) && numValue < numFilterValue
			);
		case "in":
			return Array.isArray(filter_value) && filter_value.includes(value);
		case "not-in":
			return Array.isArray(filter_value) && !filter_value.includes(value);
		default:
			return false;
	}
};

const transformationMap = {
	SINGLE_VALUE: singleValue,
	MULTI_VALUE_COUNT: multiValueCount,
	MULTI_VALUE_SUM: multiValueSum,
	MULTI_VALUE_AVG: multiValueAvg,
	DISTINCT_VALUE_COUNT: distinctValueCount,
	FILTERED_VALUE_COUNT: filteredValueCount,
	FILTERED_VALUE_SUM: filteredValueSum,
	FILTERED_VALUE_AVG: filteredValueAvg,
	GROUPED_VALUE_COUNT: groupedValueCount,
	GROUPED_VALUE_SUM: groupedValueSum,
	GROUPED_VALUE_AVG: groupedValueAvg,
	FILTERED_GROUP_VALUE_COUNT: filteredGroupValueCount,
	FILTERED_GROUP_VALUE_SUM: filteredGroupValueSum,
	FILTERED_GROUP_VALUE_AVG: filteredGroupValueAvg,
};

const applyTransformation = (field, sortedData, t) => {
	if (!field) return { transformedData: 0 };

	const transformFn = transformationMap[field.options?.type];
	if (!transformFn) return { ...field, transformedData: 0 };

	//DATA
	const args = [
		Array.isArray(sortedData[field.field_id]) ? sortedData[field.field_id] : [],
	];

	//OPTIONS
	if (
		[
			"DISTINCT_VALUE_COUNT",
			"GROUPED_VALUE_COUNT",
			"GROUPED_VALUE_SUM",
			"FILTERED_GROUP_VALUE_COUNT",
			"FILTERED_GROUP_VALUE_SUM",
			"GROUPED_VALUE_AVG",
			"FILTERED_GROUP_VALUE_AVG",
		].includes(field.options?.type)
	) {
		args.push(t(`${field.options?.options_id}`, { returnObjects: true }) || {});
	}
	//GROUP_BY_DATA
	if (
		[
			"GROUPED_VALUE_COUNT",
			"GROUPED_VALUE_SUM",
			"FILTERED_GROUP_VALUE_COUNT",
			"FILTERED_GROUP_VALUE_SUM",
			"GROUPED_VALUE_AVG",
			"FILTERED_GROUP_VALUE_AVG",
		].includes(field.options?.type)
	) {
		args.push(
			Array.isArray(sortedData[field.options?.group_by_id])
				? sortedData[field.options?.group_by_id]
				: [],
		);
	}
	//FILTER_VALUE, FILTER_OPERATOR
	if (
		[
			"FILTERED_VALUE_COUNT",
			"FILTERED_VALUE_SUM",
			"FILTERED_GROUP_VALUE_COUNT",
			"FILTERED_GROUP_VALUE_SUM",
			"FILTERED_VALUE_AVG",
			"FILTERED_GROUP_VALUE_AVG",
		].includes(field.options?.type)
	) {
		args.push(field.options?.filter_value, field.options?.filter_operator);
	}

	let transformedData = transformFn(...args) ?? 0;
	return { ...field, data: transformedData };
};

export const transformGraphFields = (data, principalFields, t) => {
	try {
		const { fieldIds, graphFields = {} } = principalFields;
		const sortedData = Object.fromEntries(fieldIds.map((id) => [id, []]));

		data.forEach(({ field_id, value, instance_number }) => {
			if (!sortedData[field_id]) {
				sortedData[field_id] = [];
			}
			if (value !== null && value !== undefined) {
				sortedData[field_id].push({ field_id, value, instance_number });
			}
		});

		const transformFields = (options) => {
			return options
				? Object.fromEntries(
						Object.entries(options).map(([key, field]) => [
							key,
							applyTransformation(field, sortedData, t),
						]),
					)
				: {};
		};

		return {
			graph_cards: (graphFields.graph_cards || []).map((card) => ({
				...card,
				options: transformFields(card.options),
			})),

			comparative_graph: (graphFields.comparative_graph || [])
				.map((field) => applyTransformation(field, sortedData, t))
				.filter(Boolean),
		};
	} catch (error) {
		console.error("Error in transformGraphFields:", error);
		return null;
	}
};

export const getYearToDateReports = (reports) => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1;
	const schoolYearStart = new Date(
		currentMonth >= 9 ? currentYear : currentYear - 1,
		8,
	);

	return reports
		.filter((report) => {
			const reportDate = new Date(`${report.year}-${report.monthValue}-01`);
			return reportDate >= schoolYearStart && reportDate <= currentDate;
		})
		.sort((a, b) => {
			if (a.year !== b.year) return b.year - a.year;
			return b.monthValue - a.monthValue;
		});
};

const parseNumeric = (value) => {
	if (typeof value === "number") return value;
	if (typeof value === "string") {
		const parsed = parseFloat(value);
		return isNaN(parsed) ? 0 : parsed;
	}
	return 0;
};

export const combineFieldsForYearToDate = (reports) => {
	if (!Array.isArray(reports) || reports.length === 0) {
		return JSON.parse(JSON.stringify(PRINCIPAL_FIELDS.graphFields.graph_cards));
	}

	const aggregatedCards = JSON.parse(
		JSON.stringify(PRINCIPAL_FIELDS.graphFields.graph_cards),
	);
	const averageTypes = [
		"MULTI_VALUE_AVG",
		"GROUPED_VALUE_AVG",
		"FILTERED_VALUE_AVG",
		"FILTERED_GROUP_VALUE_AVG",
	];
	const fieldCounts = {}; 
	const isAverageType = (type) => averageTypes.includes(type);
	const isValidNumber = (value) =>
		value !== null && value !== undefined;

	reports
		.sort((a, b) => {
			const yearA = a.year || 0;
			const yearB = b.year || 0;
			const monthA = a.monthValue || 0;
			const monthB = b.monthValue || 0;

			if (yearA !== yearB) return yearB - yearA;
			return monthB - monthA;
		})
		.forEach((report) => {
			const cards = report.cardData || [];

			cards.forEach(({ options, type, id }) => {
				if (!id) return;

				let aggregatedCard = aggregatedCards.find((card) => card.id === id);
				if (!aggregatedCard) return;

				// Initialize fieldCounts for this card if not set
				if (!fieldCounts[id])
					fieldCounts[id] = { field_a: 0, field_b: 0, count: 0 };

				switch (type) {
					case "VALUE_CARD_LATEST":
						if (options?.field_a) {
							let field = aggregatedCard.options?.field_a;
							if (field) field.data = options.field_a.data;
						}
						if (options?.field_b) {
							let field = aggregatedCard.options?.field_b;
							if (field) field.data = options.field_b.data;
						}
						break;

					case "VALUE_CARD_COMBINED":
						["field_a", "field_b"].forEach((key) => {
							if (options?.[key]) {
								let field = aggregatedCard.options?.[key];
								let value = parseNumeric(options[key].data);

								if (field && isValidNumber(value)) {
									fieldCounts[id][key]++; // Increment only if value is non-zero
									field.data = isAverageType(field.options.type)
										? Math.round(
												(parseNumeric(field.data) * (fieldCounts[id][key] - 1) +
													value) /
													fieldCounts[id][key],
											)
										: Math.round(parseNumeric(field.data) + value);
								}
							}
						});
						break;

					case "DONUT_GRAPH":
					case "BAR_GRAPH":
						if (options?.count) {
							let field = aggregatedCard.options?.count;
							let value = parseNumeric(options.count.data);

							if (field && isValidNumber(value)) {
								fieldCounts[id].count++;
								field.data = isAverageType(field.options.type)
									? Math.round(
											(parseNumeric(field.data) * (fieldCounts[id].count - 1) +
												value) /
												fieldCounts[id].count,
										)
									: Math.round(parseNumeric(field.data) + value);
							}
						}

						["field_a", "field_b"].forEach((key) => {
							if (options?.[key]) {
								let field = aggregatedCard.options?.[key];
								if (field) {
									if (!field.data) field.data = {};
									Object.entries(options[key].data).forEach(
										([subKey, value]) => {
											
											let numericValue = parseNumeric(value);
											if (!fieldCounts[id][key]) fieldCounts[id][key] = {};
											if (!fieldCounts[id][key][subKey])
												fieldCounts[id][key][subKey] = 0;

											if (isValidNumber(numericValue)) {
												fieldCounts[id][key][subKey]++;
												field.data[subKey] = isAverageType(field.options.type)
													? Math.round(
															(parseNumeric(field.data[subKey]) *
																(fieldCounts[id][key][subKey] - 1) +
																numericValue) /
																fieldCounts[id][key][subKey],
														)
													: Math.round(
															parseNumeric(field.data[subKey]) + numericValue,
														);
											}
										},
									);
								}
							}
						});
						break;

					default:
						break;
				}
			});
		});

	return aggregatedCards;
};

const withinAYearByDate = (date) => {
	const today = new Date();
	const lastYear = new Date();
	lastYear.setFullYear(today.getFullYear() - 1);
	return date > lastYear;
};

export const transformComparativeData = (reports, sessions) => {
	if (!Array.isArray(reports) || reports.length === 0) return [];
	const emptyNumbers = new Array(12).fill(0);
	const comparativeGraphData =
		PRINCIPAL_FIELDS.graphFields.comparative_graph.map((field) => ({
			...field,
			data: [...emptyNumbers],
		}));

	comparativeGraphData.push({
		id: "SESSIONS",
		translation_id: "principal.metrics-grid.sessions-card",
		data: [...emptyNumbers],
	});

	reports.forEach((report) => {
		const monthIndex = report.monthValue - 1;
		comparativeGraphData.forEach((field) => {
			const value = report.comparativeData?.find(
				(data) => data.id === field.id,
			);
			if (value) {
				field.data[monthIndex] = parseNumeric(value?.data);
			}
		});
	});

	const filteredSessions = sessions.filter((session) =>
		withinAYearByDate(new Date(session["Session Start Date/Time"])),
	);
	filteredSessions.forEach((session) => {
		const monthIndex = new Date(session["Session Start Date/Time"]).getMonth();
		const sessionField = comparativeGraphData.find(
			(field) => field.id === "SESSIONS",
		);
		if (sessionField) {
			sessionField.data[monthIndex] += 1;
		}
	});

	const currentMonth = new Date().getMonth();
	comparativeGraphData.forEach((field) => {
		field.data = [
			...field.data.slice(currentMonth + 1),
			...field.data.slice(0, currentMonth + 1),
		];
	});

	return comparativeGraphData.sort((a, b) => a.id.localeCompare(b.id));
};

import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import "./styles.scss";
import ResourceItem from "./components/ResourceItem";
import useExploreResources from "./hooks/use-explore-resources";
import SectionLoader from "../../components/SectionLoader";
import BrowserLayout from "./components/BrowserLayout";
import Slider from "react-slick";
import { Pagination } from "../../components/Pagination";
import { ResourceSliderSettings } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const Screen = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesize = 10;
  const { t } = useTranslation();

  const {
    loadingFeatured,
    featuredResources,
    loadingFiltered,
    filteredResources,
    filterCondition,
    visibleFilterModal,
    setVisibleFilterModal,
    applyFilter,
    search,
    searchWithKeyword,
    updateFilterCondition,
    pageChanged,
    userType,
    userInfo
  } = useExploreResources();

  useEffect(() => {
    window.document.title = `Learning Resources - Connected North`;

    return () => window.document.title = "Connected North";
  }, [])

  useEffect(() => {
    if (currentPage) pageChanged(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const pageCount = Math.ceil(filteredResources.length / pagesize);
  const sessionsToShow = filteredResources.slice(currentPage, (currentPage + 1) * pagesize);

  return (
    <div className="main-container school-resources">
      <Row>
        <Col md="10" lg="8">
          <h1>{t("learning-resources")}</h1>
          <p>Welcome! There are hundreds of resources available for you to explore to support your students' learning journey, with a particular focus on language of instruction. Resources are available in a variety of formats, with many available to download, and others shipped to your school upon request. Your Principal will be notified to approve orders of shipped materials.</p>
        </Col>
      </Row>

      <div className="featured-resources resources-slider mt-5">
        <h2>Featured Resources</h2>
        {loadingFeatured ? (
          <SectionLoader />
        ) : (
          <Fragment>
            <Slider {...ResourceSliderSettings}>
              {featuredResources.map((item, i) => (
                <div className="resource-item-wrapper" key={i}>
                  <ResourceItem data={item} />
                </div>
              ))}
            </Slider>
          </Fragment>
        )}
      </div>

      <div className="browser-resources mt-5">
        <h2>Search all Resources</h2>
        {userType === 'Teacher' && userInfo?.["Grade(s) Taught"]?.length > 0 && (
          <p className="text-muted mb-3">
            We've pre-selected the grade(s) you teach based on your profile to highlight resources most relevant to your needs. You can unselect or add additional grade levels in the left filter box below!
          </p>
        )}
        <BrowserLayout
          condition={filterCondition}
          visibleModal={visibleFilterModal}
          toggleModal={(v) => setVisibleFilterModal(v)}
          applyFilter={applyFilter}
          searchWithKeyword={searchWithKeyword}
          update={updateFilterCondition}
          query={search}
        >
          {loadingFiltered ? (
            <SectionLoader />
          ) : (
            <Fragment>
              {sessionsToShow.length > 0 ? (
                <div className="filtered-resources">
                  {sessionsToShow.map((resource, i) => (
                    <ResourceItem key={i} data={resource} />
                  ))}
                </div>
              ) : (
                <div className="no-results-message" style={{ 
                  textAlign: 'center', 
                  padding: '50px 20px', 
                  color: '#666', 
                  fontSize: '18px',
                  width: '100%',
                  gridColumn: '1 / -1',
                  borderTop: '1px solid #eee',
                  marginTop: '20px'
                }}>
                  No Resources were found matching your search or filter criteria. Try making it less specific to find results!
                </div>
              )}
              <div className="paginate pb-4">
                {filteredResources.length > pagesize && (
                  <Pagination
                    page={currentPage}
                    setPage={(p) => {
                      pageChanged(p);
                      setCurrentPage(p);
                    }}
                    pageCount={pageCount}
                  />
                )}
              </div>
            </Fragment>
          )}
        </BrowserLayout>
      </div>
    </div>
  )
}

export default Screen;

import { getFieldIds } from "../utils/graphCalculations";
import { FIELDS } from "./graph-fields";

const findField = (id) => FIELDS.find((field) => field.id === id);

const CARD_TYPES = {
	VALUE_CARD_LATEST: "VALUE_CARD_LATEST", //options: field_a, (optional) field_b
	VALUE_CARD_COMBINED: "VALUE_CARD_COMBINED", //options: field_a, (optional) field_b
	DONUT_GRAPH: "DONUT_GRAPH", //options: count, field_a
	BAR_GRAPH: "BAR_GRAPH", //options: count, field_a, (optional) field_b
};

//All fiends are in graph-fields. If a value you want is not there, it'll need to be added.
const graphFields = {
	//Only add fields that return a single value per month (not grouped or distinct)
	comparative_graph: [
		findField("SUSPENSION_COUNT"),
		findField("SCHOOL_CLOSURE_COUNT"),
		findField("STUDENT_POPULATION"),
		findField("COMMUNITY_POPULATION"),
		findField("GRADUATING_STUDENTS"),
	],

	graph_cards: [
		{
			id: "value_card_1",
			type: CARD_TYPES.VALUE_CARD_LATEST,
			translation_id: "principal.metrics-grid.number-card.school-population",
			options: {
				field_a: findField("STUDENT_POPULATION"),
			},
		},
		{
			id: "value_card_2",
			type: CARD_TYPES.VALUE_CARD_LATEST,
			translation_id: "principal.metrics-grid.number-card.graduates",
			options: {
				field_a: findField("GRADUATING_STUDENTS"),
			},
		},
		{
			id: "value_card_3",
			type: CARD_TYPES.VALUE_CARD_LATEST,
			translation_id: "principal.metrics-grid.number-card.community-population",
			options: {
				field_a: findField("COMMUNITY_POPULATION"),
			},
		},
		{
			id: "donut_graph",
			type: CARD_TYPES.DONUT_GRAPH,
			translation_id: "principal.metrics-grid.closures-card",
			options: {
				count: findField("SCHOOL_CLOSURE_COUNT"),
				field_a: findField("SCHOOL_CLOSURE_REASON"),
			},
		},
		{
			id: "bar_graph_1",
			type: CARD_TYPES.BAR_GRAPH,
			translation_id: "principal.metrics-grid.suspensions-card",
			options: {
				count: findField("SUSPENSION_COUNT"),
				field_a: findField("SUSPENSION_HOURS_IN_SCHOOL"),
				field_b: findField("SUSPENSION_HOURS_OUT_OF_SCHOOL"),
			},
		},
		// {
		// 	id: "bar_graph_2",
		// 	type: CARD_TYPES.BAR_GRAPH,
		// 	translation_id: "principal.metrics-grid.expulsions-card",
		// 	options: {
		// 		count: findField("EXPULSION_COUNT"),
		// 		field_a: findField("EXPULSION_GRADE"),
		// 	},
		// },
		{
			id: "bar_graph_2",
			type: CARD_TYPES.BAR_GRAPH,
			translation_id: "principal.metrics-grid.attendance-card",
			options: {
				count: findField("ATTENDANCE_RATE_AVG"),
				field_a: findField("ATTENDANCE_RATE"),
			},
		},
		// {
		// 	id: "value_card_4",
		// 	type: CARD_TYPES.VALUE_CARD_COMBINED,
		// 	translation_id: "principal.metrics-grid.value-card",
		// 	options: {
		// 		field_a: findField("SUSPENSION_VIOLENCE_STUDENTS"),
		// 		field_b: findField("SUSPENSION_VIOLENCE_STAFF"),
		// 	},
		// },
	],
};

export const PRINCIPAL_FIELDS = {
	fieldIds: getFieldIds(graphFields),
	graphFields,
};

import React, { useEffect, useState } from "react";
import placeholderImg from "../../../../assets/img/placeholder.png";
import { Rating } from "@mui/material";
import { useSelector } from "react-redux";
import RequestResourceModal from "../../../modals/RequestResource";
import useResource from "../../hooks/use-resource";
import Loading from "../../../../components/Loading";
import "./styles.scss";

const ResourceItem = ({ data }) => {
  const { favouritedResources } = useSelector(state => state.appInfo);
  const [favourited, setFavourited] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [favouriting, setFavouriting] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { toggleFavourite, trackDownload } = useResource(data.id);

  useEffect(() => {
    const ids = favouritedResources.map(f => f.id);
    setFavourited(ids.indexOf(data.id) >= 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouritedResources])

  const setPlaceholderImage = (event) => {
    event.target.src = placeholderImg;
  }

  const goToResourceDetail = () => {
    window.location.href = `/cn/school-resources/${data.id}`
  }

  const imageData = data.Image ? data.Image[0].url : placeholderImg;
  const rating = data["Average Rating"] || 0;

  const formatLanguages = (languages) => {
    if (!Array.isArray(languages)) return languages;
    if (languages.length === 1) return languages[0];
    if (languages.length === 2) return `${languages[0]} and ${languages[1]}`;
    const lastLang = languages[languages.length - 1];
    const otherLangs = languages.slice(0, -1).join(', ');
    return `${otherLangs}, and ${lastLang}`;
  };

  return (
    <div
      className="school-resource-item"
      onClick={() => goToResourceDetail()}
    >
      <div className="resource-image">
        <img
          src={imageData}
          onError={setPlaceholderImage}
          alt={"name of resource"}
        />
        <div className="resource-type">
          <span>{data["Type"]}</span>
        </div>
      </div>
      <div className="resource-content">
        <div className="resource-grade-lang">
          <span>
            {data["Grade Level"] && `Grade Level ${data["Grade Level"]}`}
            {data["Grade Level"] && data.Language && " | "}
            {data.Language && formatLanguages(data.Language)}
          </span>
        </div>
        <div className="resource-title">
          {data.Title}
        </div>
        <div className="resource-author">
          {data.Author ? `By ${data.Author}` : `No author`}
        </div>
        <div className="resource-other-info">
          {!!rating ? (
            <div className="resource-reviews">
              <span>{rating}/5 Stars</span>
              <Rating
                value={rating}
                max={5}
                readOnly
              />
            </div>
          ) : <div />}
        </div>
        <div className="resource-actions">
          <button
            className="btn btn-primary btn-sm"
            onClick={async (e) => {
              e.stopPropagation();
              if (data["Order Type"] === 'Digital' && data["Resource File"] && data["Resource File"].length > 0) {
                // Handle download
                const attachment = data["Resource File"][0];
                if (attachment && attachment.url) {
                  try {
                    // First track the download
                    setDownloading(true);
                    await trackDownload();
                    // Then open the file
                    window.open(attachment.url, '_blank');
                  } catch (error) {
                    console.error('Error downloading resource:', error);
                  } finally {
                    setDownloading(false);
                  }
                }
              } else {
                // Show request modal for physical resources
                setShowRequestModal(true);
              }
            }}
          >
            {data["Order Type"] === 'Digital' && data["Resource File"] && data["Resource File"].length > 0 
              ? (
                downloading ? (
                  <Loading size={22} />
                ) : (
                  <><i className="fa fa-download" /> Download Resource</>
                )
              ) : `+ Request Resource`
            }
          </button>

          <div
            className="resource-favorite"
            onClick={async (e) => {
              e.stopPropagation();
              setFavouriting(true);
              await toggleFavourite();
              setFavouriting(false);
            }}
          >
            {favouriting ? (
              <Loading size={22} />
            ) : (
              <i className={favourited ? "fa fa-heart" : "fa fa-heart-o"} />
            )}
          </div>
        </div>
      </div>

      {!!data && showRequestModal && (
        <RequestResourceModal
          resource={data}
          toggle={() => setShowRequestModal(!showRequestModal)}
        />
      )}
    </div>
  )
}

export default ResourceItem;
export const subjectInfo = {
  "ADST (Applied Design, Skills, and Technologies)": { color: "#e89d69", icon: "fa-cogs", unicode: "\uf085" },
  "Arts": { color: "#ff87b2", icon: "fa-paint-brush", unicode: "\uf1fc" },
  "ASL": { color: "#47b3e3", icon: "fa-comments", unicode: "\uf27a" },
  "Business & Entrepreneurship": { color: "#e6b800", icon: "fa-briefcase", unicode: "\uf0b1" },
  "Careers": { color: "#ff9966", icon: "fa-user", unicode: "\uf007" },
  "Civics & Citizenship": { color: "#ff7777", icon: "fa-university", unicode: "\uf19c" },
  "Dance": { color: "#ff66cc", icon: "fa-music", unicode: "\uf001" },
  "Drama": { color: "#ff6699", icon: "fa-users", unicode: "\uf0c0" },
  "Film Studies": { color: "#a64dff", icon: "fa-film", unicode: "\uf03d" },
  "Finance": { color: "#66cc00", icon: "fa-money", unicode: "\uf0d6" },
  "First Nations, Metis and Inuit Studies": { color: "#bf8040", icon: "fa-leaf", unicode: "\uf06c" },
  "Food & Nutrition": { color: "#e6b800", icon: "fa-cutlery", unicode: "\uf2e5" },
  "French": { color: "#4d94ff", icon: "fa-language", unicode: "\uf1ab" },
  "Future Pathways": { color: "#e6b800", icon: "fa-road", unicode: "\uf018" },
  "Geography": { color: "#47b347", icon: "fa-globe", unicode: "\uf10f" },
  "Health, Wellness & Physical Education": { color: "#ff6666", icon: "fa-heartbeat", unicode: "\uf21e" },
  "History": { color: "#d68533", icon: "fa-book", unicode: "\uf02d" },
  "Holiday Themed": { color: "#ff6699", icon: "fa-gift", unicode: "\uf06b" },
  "Indigenous Studies": { color: "#bf8040", icon: "fa-leaf", unicode: "\uf06c" },
  "Literacy / English / Language Arts": { color: "#4d94ff", icon: "fa-book", unicode: "\uf02d" },
  "Math": { color: "#e6b800", icon: "fa-calculator", unicode: "\uf1ec" },
  "Media Literacy/Media Studies": { color: "#a64dff", icon: "fa-film", unicode: "\uf03d" },
  "Music": { color: "#e64dba", icon: "fa-music", unicode: "\uf001" },
  "Native Language Studies": { color: "#e6b800", icon: "fa-comments", unicode: "\uf27a" },
  "Premium Session": { color: "#e6b800", icon: "fa-star", unicode: "\uf005" },
  "Professional Development": { color: "#47b3e3", icon: "fa-graduation-cap", unicode: "\uf19d" },
  "Science & Environmental Studies": { color: "#33b3aa", icon: "fa-flask", unicode: "\uf0c3" },
  "Social Studies": { color: "#d68533", icon: "fa-users", unicode: "\uf0c0" },
  "Technology": { color: "#47b3e3", icon: "fa-laptop", unicode: "\uf109" },
  "Therapy (SLP/OT)": { color: "#47cc47", icon: "fa-heart", unicode: "\uf004" },
  "Visual Arts": { color: "#ff66cc", icon: "fa-paint-brush", unicode: "\uf1fc" }
};

// For backward compatibility
export const subjectColors = Object.fromEntries(
  Object.entries(subjectInfo).map(([key, value]) => [key, value.color])
);

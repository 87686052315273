export const getIsNunavutAdmin = (userInfo) => {
    if (!userInfo) return false;
    
    const schoolName = userInfo["School Name"];
    
    // Check if school name is an array
    if (Array.isArray(schoolName)) {
        // Check if the array includes the Nunavut admin school ID
        return schoolName.includes('recDN0Ig0S0Tjasay');
    } else if (schoolName === 'recDN0Ig0S0Tjasay') {
        // For backward compatibility if it's a string
        return true;
    }
    
    return false;
};
import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import s from "./styles.module.scss";
import { Pagination } from "../../components/Pagination";
import SessionInfo from "../../components/SessionInfo";
import { useSelector } from "react-redux";
import {
  getActionMenuForSearch,
  getDisplayInfosForSearch
} from "../SessionInfoDisplay";
import BookSessionModal from "../modals/BookSession";
import SendToTeacher from "../modals/SendToTeacher";
import AssignTagForSession from "../modals/AssignTagForSession";

const SessionList = ({
  loading,
  sessions,
  pageChanged,
  currentPage
}) => {
  const { userType, userInfo } = useSelector(state => state.appInfo);

  const [showBookSessionModal, setShowBookSessionModal] = useState(false);
  const [activeSession, setActiveSession] = useState(null);

  const [showSendToTeacherModal, setShowSendToTeacherModal] = useState(false);
  const [activeSessionForSTT, setActiveSessionForSTT] = useState(null);

  const [showAssignTagModal, setShowAssignTagModal] = useState(false);
  const [activeSessionForAT, setActiveSessionForAT] = useState(null);

  const pagesize = 15;

  if (loading) {
    return (
      <div className={s.loadingCenterAlign}>
        <CircularProgress />
      </div>
    )
  }

  if (!sessions || sessions.length === 0) {
    return (
      <div className={s.sessionsContainer}>
        <span>Sorry, no results were found. Please try a less specific query, using a more general keyword or fewer filters!</span>
      </div>
    )
  }

  const pageCount = Math.ceil(sessions.length / pagesize);
  const sessionsToShow = sessions.slice(currentPage * pagesize, (currentPage + 1) * pagesize);

  return (
    <div className={s.sessionsContainer}>
      <div className="session-search-card-container">
        {sessionsToShow.map((session, i) => (
          <SessionInfo
            key={i}
            displayInfos={getDisplayInfosForSearch(session, userType)}
            actionMenu={getActionMenuForSearch(userType, userInfo)}
            session={session}
            sessionDetailLink={session.id}
            bookSession={() => {
              setActiveSession(session);
              setShowBookSessionModal(true);
            }}
            sendToTeacher={() => {
              setShowSendToTeacherModal(true);
              setActiveSessionForSTT(session)
            }}
            addToCollection={() => {
              setShowAssignTagModal(true)
              setActiveSessionForAT(session)
            }}
          />
        ))}
      </div>
      <div className="paginate pb-4">
        {sessions.length > pagesize && (
          <Pagination
            page={currentPage}
            setPage={(p) => pageChanged(p)}
            pageCount={pageCount}
          />
        )}
      </div>

      {!!activeSession && showBookSessionModal && (
        <BookSessionModal
          providerSessionId={activeSession.id}
          onToggle={() => {
            setShowBookSessionModal(false);
            setActiveSession(null);
          }}
        />
      )}

      {!!activeSessionForSTT && showSendToTeacherModal && (
        <SendToTeacher
          data={activeSessionForSTT}
          onToggle={() => {
            setShowSendToTeacherModal(!showSendToTeacherModal)
            setActiveSessionForSTT(null);
          }}
          type="Session"
        />
      )}

      {showAssignTagModal && !!activeSessionForAT && (
        <AssignTagForSession
          session={activeSessionForAT}
          onToggle={() => {
            setShowAssignTagModal(!showAssignTagModal)
            setActiveSessionForAT(null)
          }}
        />
      )}
    </div>
  )
}

export default SessionList;
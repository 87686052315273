import { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import airtable from "../../../airtables";
import { DAY_LIMITATIONS_MAP } from "../../../utils/constants";
import {
  getSimpleDateString,
  isValidDateOnPicker,
  selectEarliestSessionDates
} from "../../../utils/time";
import { calcDayRestriction } from "./utils";

const useSessionBook = ({
  providerSession,
  bookType = "create", // create | reschedule | edit | reject
  session,
  teachers,
  suppliesShipment = false
}) => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [prerequisiteSession, setPrerequisiteSession] = useState(null);
  const [supplies, setSupplies] = useState([]);
  const [suppliesOption, setSuppliesOption] = useState("");
  const [dayRestriction, setDayRestriction] = useState(0);
  const [dayLimitations, setDayLimitations] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [notAvailableDates, setNotAvailableDates] = useState([]);
  const [initialDates, setInitialDates] = useState([]);

  useEffect(() => {
    if (!providerSession) return;

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerSession])

  const initialize = async () => {
    const providerInfo = await airtable.providers.select(providerSession["Provider"][0]);
    setProvider(providerInfo);

    let dl;
    if (providerInfo["Day Limitations"]) {
      dl = providerInfo["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l])
      setDayLimitations(dl);
    }

    if (providerSession["Prerequisite Session"]) {
      const ps = await airtable.providerSessions.select(providerSession["Prerequisite Session"][0]);
      setPrerequisiteSession(ps);
    }

    let sps = null;
    if (providerSession.Supplies) {
      sps = await airtable.supplies.listByIds(providerSession["Supplies"]);
      setSupplies(sps);
    }

    let aDates = [], naDates = [];
    if (providerInfo["Valid NAO"] === "Valid" && providerInfo["Not Available On"]) {
      naDates = providerInfo["Not Available On"].split(',').map(d => new Date(d));
      setNotAvailableDates(naDates);
    }

    if (providerSession["Valid OAO"] === "Valid" && providerSession["Only Available On"]) {
      // Fix timezone issue by ensuring dates are created properly
      const ds = providerSession["Only Available On"].split(',').map(dateStr => {
        // Parse the date string and create a date that preserves the intended day
        const [year, month, day] = dateStr.split(/[-T]/);
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12, 0, 0);
      });

      const minDate = new Date();
      aDates = ds.filter(d => d >= minDate).sort((a, b) => a - b);

      // If there are not available dates, filter out those dates from the available dates
      if (naDates && naDates.length > 0) {
        const naos = naDates.map(d => getSimpleDateString(d));
        aDates = aDates.filter(availableDate => {
          return !(naos.indexOf(getSimpleDateString(availableDate)) >= 0);
        });
      }
      setAvailableDates(aDates);
    }

    const so = await checkSupplyOption();
    setSuppliesOption(so);
    const dr = calcDayRestriction(userType, providerInfo, sps, so, userInfo);
    setDayRestriction(dr);
    setLoading(false);
  }

  useEffect(() => {
    if (loading) return;
    const iDates = selectEarliestSessionDates({
      availableDates: availableDates,
      notAvailableDates: notAvailableDates,
      dayRestriction: dayRestriction, // Use the value calculated by calcDayRestriction
      dayLimitations: dayLimitations,
      tillDate: providerSession["Availability End Date"],
      teacher: teachers,
      userType: userType
    });
    if (bookType === "edit") {
      setInitialDates([
        new Date(session["Session Start Date/Time"]),
        session["Alternative Date/Time"] ? new Date(session["Alternative Date/Time"]) : iDates[1]
      ])
    } else {
      setInitialDates([iDates[0], iDates[1]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, teachers])

  useEffect(() => {
    if (loading) return;
    if (!suppliesShipment) {
      const dr = calcDayRestriction(userType, provider, supplies, suppliesOption, userInfo);
      setDayRestriction(dr);
    } else {
      setDayRestriction(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, suppliesShipment])

  const checkSupplyOption = () => {
    if (userType !== "Teacher") return "";
    if (bookType !== "create" || !Array.isArray(providerSession["School-provided Supplies Option?"]) || !providerSession["School-provided Supplies Option?"].includes(true)) return "";
    
    return new Promise((resolve) => {
      const providerGroups = providerSession["Indigenous Group(s)"];
      const schoolGroups = userInfo["School Indigenous Group(s)"];
      let x, y;
      if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
        x = Math.floor(providerSession["Discounted Credits"] - (providerSession["Credits for Supplies"] * 0.75));
        y = providerSession["Discounted Credits"];
      } else {
        x = providerSession["Credits"] - providerSession["Credits for Supplies"];
        y = providerSession["Credits"];
      }

      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Do you need a shipment of supplies for this Session?</h1>
              <p>This Session requires the following supplies: {providerSession["Supplies Name"]}</p>
              <p>We know some schools may have these supplies available, so we can offer a discounted Session cost if shipment is not required. Please let us know how you'd like to proceed:</p>
              <div className="confirm-alert-actions">
                <Button
                  color="outline-primary"
                  type="button"
                  onClick={() => {
                    resolve(`not-required-${x}`);
                    onClose();
                  }}
                >
                  Book without Supplies for {x} Credits
                </Button>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    resolve("agreed")
                    onClose();
                  }}
                >
                  Book with Supplies for {y} Credits
                </Button>
              </div>
            </div>
          );
        }
      })
    });
  }

  const isSchoolManager = useMemo(() => {
    if (userType !== "Teacher") return false;

    return (userInfo["In-school Coordinator"] || userInfo["School Leader?"]) ? true : false;
  }, [userType, userInfo]);

  const sessionLengthOptions = useMemo(() => {
    if (!providerSession) return [];

    const defaultLength = providerSession["Length (Minutes)"];
    const minimumLength = providerSession["Minimum Length"];

    // If minimum length equals default length, return just the default option
    if (minimumLength && minimumLength === defaultLength) {
      return [{ value: defaultLength, label: `${defaultLength} minutes` }];
    }

    // If minimum length is set, create options in 5-minute decrements down to minimum
    if (minimumLength) {
      const options = [];
      for (let length = defaultLength; length >= minimumLength; length -= 5) {
        options.push({ value: length, label: `${length} minutes` });
      }
      return options;
    }

    // If no minimum length set, use original -5/-10 minute options
    return [
      { value: defaultLength, label: `${defaultLength} minutes` },
      { value: defaultLength - 5, label: `${defaultLength - 5} minutes` },
      { value: defaultLength - 10, label: `${defaultLength - 10} minutes` }
    ];
  }, [providerSession]);

  const isHandsOnSession = useMemo(() => {
    if (!providerSession) return false;

    const hasSupplies = providerSession["Supplies"] && providerSession["Supplies"].length > 0;
    const subjects = providerSession["Primary Subject Text"] || [];
    const isArtOrScience = subjects.some(subject =>
      subject.includes("Art") || subject.includes("Science")
    );
    const isHandsOn = hasSupplies && isArtOrScience;

    if (!isHandsOn) return false;

    const standardLength = providerSession["Length (Minutes)"];
    const minimumLength = providerSession["Minimum Length"];

    if (minimumLength &&
      typeof minimumLength === 'number' &&
      typeof standardLength === 'number' &&
      (standardLength - minimumLength) >= 5) {
      return false;
    }

    return true;
  }, [providerSession]);

  const shouldDisableDate = (selectedDate) => {
    return !isValidDateOnPicker(
      dayLimitations,
      selectedDate,
      dayRestriction,
      teachers,
      providerSession["Availability End Date"],
      availableDates,
      notAvailableDates,
      userType
    );
  }

  return {
    loading,
    provider,
    supplies,
    suppliesOption,
    dayRestriction,
    dayLimitations,
    availableDates,
    notAvailableDates,
    prerequisiteSession,
    sessionLengthOptions,
    isHandsOnSession,
    initialDates,
    isSchoolManager,

    shouldDisableDate
  }
}

export default useSessionBook;

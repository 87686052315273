import React, { useState, useEffect } from "react";
import { Button, Row, Col, Table } from "reactstrap";
import { Stepper, Step, StepLabel, Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Card } from "../../../components/Card";
import StartDates from "./StartDates";
import GradesHour from "./GradesHour";
import ClosedDays from "./ClosedDays";
import { useSchoolCalendar } from "../hooks";
import airtable from "../../../airtables/PDAirtable";
import { years } from "../constants/calendar";
import uploadFile from "../../../libs/aws/uploadfile";
import { getGrade, minHours } from "../utils/transformGradeKeys";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastMessage } from "../../PDPlan/components/ToastMessage";
const steps = [
	"Start and end dates",
	"Hours for each grade group",
	"Non-instructional days",
];

const EditCalendar = () => {
	const yearParam = new URLSearchParams(window.location.search).get("year");
	const stepParam = new URLSearchParams(window.location.search).get("step");
	const year = years.includes(yearParam) ? yearParam : years[0];
	const [activeStep, setActiveStep] = useState(
		stepParam ? parseInt(stepParam) - 1 : 0,
	);
	const [selectedCalendar, setSelectedCalendar] = useState();
	const [schoolHours, setSchoolHours] = useState();
	const [savingDraft, setSavingDraft] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [motionUploaded, setMotionUploaded] = useState(false);
	const [isCalendarError, setIsCalendarError] = useState(false);
	const [canAddODays, setCanAddODays] = useState(true);
	const [availableOHours, setAvailableOHours] = useState(0);
	const {
		isloading,
		isCalendarloading,
		calendarOptions,
		schoolCalendar,
		allowCustomCalendar,
		schoolSchedule,
		groupedDays,
		schoolClosedDays,
		closureDay,
		scheduledHours,
		totalScheduledHours,
		scheduledOHours,
		genericClosedDays,
		school: nunavutSchool,
		saveSchoolCalendar,
		saveSchoolSchedule,
		saveCalendarDays,
		deleteCalendarDay,
	} = useSchoolCalendar(year);
	const history = useHistory();

	const updateStep = (step) => {
		setActiveStep(step);
		const params = new URLSearchParams(window.location.search);
		params.set("step", step + 1);
		window.history.replaceState(
			{},
			"",
			`${window.location.pathname}?${params}`,
		);
	};

	const saveDates = () => {
		if (isCalendarError) return;
		saveSchoolCalendar(selectedCalendar);
		updateStep(1);
	};

	const saveHours = async () => {
		try {
			if (schoolHours) {
				await saveSchoolSchedule(schoolHours);
			}
			updateStep(2);
		} catch (err) {
			console.error("Error saving hours:", err);
			updateStep(2);
		}
	};

	const saveDraft = async () => {
		if (isCalendarError) return;
		setSavingDraft(true);
		try {
			await saveSchoolCalendar(selectedCalendar);
			if (activeStep === 1 && schoolHours) {
				await saveSchoolSchedule(schoolHours);
			}
			history.push("/cn/schoolcalendar");
			toast.success(
				<ToastMessage>
					Your Calendar has been saved to continue editing later. You can download a draft PDF to review below!
				</ToastMessage>,
			);
		} catch (err) {
			console.error("Error saving draft:", err);
		}
		setSavingDraft(false);
		updateStep(activeStep);
	};

	useEffect(() => {
		if (schoolSchedule) setSchoolHours(schoolSchedule);
	}, [schoolSchedule]);

	useEffect(() => {
		document.title =
			"Non-instructional days - School Calendar - Connected North";
	}, []);

	useEffect(() => {
		if (schoolCalendar) {
			setMotionUploaded(!!schoolCalendar["Custom Calendar Motion"]);
			setSelectedCalendar({
				"First Day (Principal)": schoolCalendar["First Day (Principal)"],
				"First Day (Students)": schoolCalendar["First Day (Students)"],
				"First Day (Teachers)": schoolCalendar["First Day (Teachers)"],
				"Last Day (Students)": schoolCalendar["Last Day (Students)"],
				"Last Day (Teachers)": schoolCalendar["Last Day (Teachers)"],
				"Last Day (Principal)": schoolCalendar["Last Day (Principal)"],
			});
		}
	}, [schoolCalendar]);

	useEffect(() => {
		if (totalScheduledHours) {
			// First check if any grade is below minimum
			const hasDeficit = Object.entries(totalScheduledHours).some(
				([key, value]) => value < minHours[key]
			);

			if (hasDeficit) {
				// If any grade is below minimum, no O hours allowed
				setCanAddODays(false);
				setAvailableOHours(0);
				return;
			}

			// If all grades meet minimum, calculate surplus
			let minSurplus = Infinity;
			Object.entries(totalScheduledHours).forEach(([key, value]) => {
				const surplus = value - minHours[key];
				minSurplus = Math.min(minSurplus, surplus);
			});

			setCanAddODays(minSurplus > 0);
			setAvailableOHours(minSurplus === Infinity ? 0 : minSurplus);
		}
	}, [totalScheduledHours]);

	const submitCalendar = async () => {
		try {
			setIsSubmitting(true);
			await airtable.schoolCalendar.update(schoolCalendar.id, {
				Status: "Submitted",
			});
			history.push("/cn/schoolcalendar");
			toast.success(
				<ToastMessage>
					Your Calendar has been submitted for validation. You can download a PDF to review below!
				</ToastMessage>,
			);
		} catch (e) {
			console.error(e);
			toast.error(
				<ToastMessage>
					There was an error submitting your calendar. Please try again.
				</ToastMessage>,
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const uploadCustomMotion = async (e) => {
		const file = e.target.files[0];
		setMotionUploaded(false);
		if (file) {
			try {
				const uRes = await uploadFile(file);
				const params = {};
				params["Custom Calendar Motion"] = [{ url: uRes }];
				const upload = await airtable.schoolCalendar.update(
					schoolCalendar.id,
					params,
				);
				if (upload) {
				}
			} catch (e) {
			} finally {
				setMotionUploaded(true);
			}
		}
	};

	return (
		<>
			<Row className="justify-content-between mt-4">
				<Col md={6}>
					<h1>School Calendar {year}</h1>
				</Col>
				<Col md={6} className="text-right">
					<Button
						color="primary"
						className="mr-2"
						onClick={saveDraft}
						disabled={savingDraft || isCalendarError}
					>
						{savingDraft ? "Saving..." : "Save Draft"}
					</Button>
					<Tooltip title={
						(() => {
							if (isSubmitting) return "";

							const messages = [];

							// Check for closure days
							if (!(
								closureDay.principal === 0 &&
								closureDay.teacher === 0 &&
								closureDay.student === 0
							)) {
								messages.push("All non-instructional days must be allocated");
							}

							// Check for minimum hours
							if (totalScheduledHours) {
								const belowMinimum = Object.entries(totalScheduledHours)
									.filter(([key, value]) => value < minHours[key])
									.map(([key]) => getGrade(key));

								if (belowMinimum.length > 0) {
									messages.push(
										`The following grades are below minimum hours: ${belowMinimum.join(", ")}`
									);
								}
							}

							return messages.join("\n");
						})()
					}
						placement="top"
						arrow
					>
						<span> {/* Wrapper needed because Tooltip can't be applied directly to disabled buttons */}
							<Button
								color="primary"
								onClick={submitCalendar}
								disabled={
									isSubmitting ||
									// Check for closure days
									!(
										closureDay.principal === 0 &&
										closureDay.teacher === 0 &&
										closureDay.student === 0
									) ||
									// Check if any grade is below minimum hours
									(totalScheduledHours && Object.entries(totalScheduledHours).some(
										([key, value]) => value < minHours[key]
									))
								}
							>
								{isSubmitting ? "Submitting..." : "Finish & Submit"}
							</Button>
						</span>
					</Tooltip>
				</Col>
			</Row>

			<section className="section">
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						return (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			</section>

			<section className="section">
				<Card className="bg-white">
					<Row className="">
						<Col md={8}>
							{(isloading || isCalendarloading) && <CircularProgress />}
							{!isloading && !isCalendarloading && activeStep === 0 && (
								<StartDates
									disable={savingDraft || isCalendarError}
									year={year}
									options={calendarOptions}
									custom={allowCustomCalendar}
									selectedCalendar={selectedCalendar}
									setSelectedCalendar={setSelectedCalendar}
									handleNext={saveDates}
									uploadCustomMotion={uploadCustomMotion}
									motionUploaded={motionUploaded}
									setError={setIsCalendarError}
								/>
							)}
							{!isloading && activeStep === 1 && (
								<GradesHour
									disable={savingDraft}
									year={year}
									school={nunavutSchool}
									schoolHours={schoolHours}
									onTimesChange={setSchoolHours}
									handleNext={saveHours}
									handlePrev={() => updateStep(0)}
								/>
							)}
							{!isloading && activeStep === 2 && (
								<>
									<h2>Choose your Non-Instructional Days</h2>
									<p className="mt-4">
										As you select the non-instructional days for your school
										year, the table below will update to reflect the total
										instructional hours scheduled for each grade group. The
										amount of O days/hours available will calculate based on the
										hours you have scheduled above the minimum.
									</p>
									{!schoolSchedule ||
									Object.keys(scheduledHours).length === 0 ? (
										<Card paddingSmall>
											<p
												className="text-center mb-0 p-4"
												style={{ fontWeight: "bold" }}
											>
												Please complete the hours for each grade group (Step 2)
												to view the table of instructional hours!
											</p>
										</Card>
									) : (
										<Card paddingSmall>
											<Table borderless>
												<thead
													className="border-bottom"
													style={{ border: "lightblue" }}
												>
													<tr>
														<th></th>
														<th className="">Minimum Hours</th>
														<th className="">Currently Scheduled Hours</th>
													</tr>
												</thead>
												<tbody>
													{Object.keys(minHours).map((key, index) =>
														schoolSchedule[key] ? (
															<tr key={index}>
																<td>{getGrade(key)}</td>
																<td>{minHours[key]}</td>
																<td
																style={{
																	color: scheduledHours[key] < minHours[key] ? "#d32f2f" : "inherit",
																	backgroundColor: scheduledHours[key] < minHours[key] ? "#fff5f5" : "inherit",
																	fontWeight: scheduledHours[key] < minHours[key] ? "bold" : "inherit",
																}}
															>
																{scheduledHours[key]}
															</td>
															</tr>
														) : null,
													)}
												</tbody>
											</Table>
										</Card>
									)}
								</>
							)}
						</Col>

						<Col md={4}>
							<Card paddingSmall>
								<Table borderless>
									<thead
										className="border-bottom"
										style={{ border: "lightblue" }}
									>
										<tr>
											<th></th>
											<th className="">Maximum School Days</th>
											<th className="">Named Days to Add</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Students</td>
											<td>182</td>
											<td>{closureDay.student}</td>
										</tr>
										<tr>
											<td>Teachers</td>
											<td>195</td>
											<td>{closureDay.teacher}</td>
										</tr>
										<tr>
											<td>Principals</td>
											<td>{schoolCalendar && schoolCalendar["Last Day (Principal)"] === "2028-06-30" ? 201 : 202}</td>
											<td>{closureDay.principal}</td>
										</tr>
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>
					{activeStep === 2 && (
						<ClosedDays
							disable={savingDraft}
							calendarStartDay={
								schoolCalendar && schoolCalendar["First Day (Principal)"]
							}
							calendarEndDay={
								schoolCalendar && schoolCalendar["Last Day (Principal)"]
							}
							year={year}
							events={groupedDays}
							schoolClosedDays={schoolClosedDays}
							updateClosedDays={saveCalendarDays}
							deleteClosedDay={deleteCalendarDay}
							handlePrev={() => updateStep(1)}
							disableODays={!canAddODays}
							availableOHours={availableOHours}
							scheduledOHours={scheduledOHours}
							genericClosedDays={genericClosedDays}
						/>
					)}
				</Card>
			</section>
		</>
	);
};

export default EditCalendar;

import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useSchools } from "./hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../components/LoadingBar";
import Select from "react-select";
import School from "./School";
import TransferCreditModal from "../modals/TransferCredit";
import SchoolCharts from "./SchoolCharts";
import { Button } from "reactstrap";
import AddTeacherModal from "../modals/AddTeacher";
import { hideLoading, showLoading } from "../../utils/loading";
import { toast } from "react-toastify";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { NUNAVUT_CLUSTER } from "../PDPlan/constants/pd-fields";
import { ReportingLayout } from "./components/ReportingLayout";
import { UserControlledGraph } from "./components/UserControlledGraph";
import { MetricsGrid } from "./components/MetricsGrid";
import useSchool from "./SchoolCharts/useSchool";
import {
	combineFieldsForYearToDate,
	getYearToDateReports,
} from "./utils/graphCalculations";

const Screen = () => {
	const { t } = useTranslation();
	const { userType, userInfo } = useSelector((state) => state.appInfo);

	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const {
		loading: schoolLoading,
		schools,
		school,
		setSchool,
		addNewTeacher,
		refresh,
		getTitle,
	} = useSchools();

	const { loading: schoolSessionsLoading, sessions } = useSchool(school);

	const yearToDateReports = useMemo(() => {
		if (!school?.reports) return [];
		return getYearToDateReports(school.reports);
	}, [school?.reports]);

	const yearToDateFields = useMemo(() => {
		return combineFieldsForYearToDate(yearToDateReports);
	}, [yearToDateReports]);

	const [currentData, setCurrentData] = useState(null);
	const [schoolData, setSchoolData] = useState(null);

	const handleDateSelect = (dateStr) => {
		const [year, month] = dateStr.split("-");
		setSelectedYear(year);
		setSelectedMonth(month);
	};

	const [showTransferCreditModal, setShowTransferCreditModal] = useState(false);
	const [newTeacherModal, showNewTeacherModal] = useState(false);
	const [refreshSchool, setRefreshScool] = useState(1);
	const history = useHistory();
	const [activeTab, setActiveTab] = useState(null);
	const [isHashChange, setIsHashChange] = useState(false);

	const getTabs = useCallback(() => {
		const isNunavut =
			userInfo?.["School P/T Text"] === "NU" ||
			(userInfo?.Cluster && userInfo.Cluster.includes(NUNAVUT_CLUSTER));

		const isDistrictLeader = userInfo["District Leader?"];

		let availableTabs = [];

		if (isDistrictLeader || userType === "Team") {
			availableTabs.push("Program Engagement");
		}

		if (isNunavut && userType !== "Team") {
			availableTabs.push("Monthly Reports");
		}

		if (userInfo["No Teacher List"] !== true) {
			availableTabs.push("Teachers & Staff");
		}
		return availableTabs;
	}, [userInfo, userType]);

	const handleTabChange = useCallback((newTab) => {
		setActiveTab(newTab);
		if (!isHashChange) {
			if (newTab === "Monthly Reports") {
				history.replace({ hash: "reports" });
			} else if (newTab === "Teachers & Staff") {
				history.replace({ hash: "teachers" });
			} else if (window.location.hash === "#reports") {
				history.replace({ hash: "" });
			}
		}
		setIsHashChange(false);
	}, [history, isHashChange]);

	// Make handleTabChange available globally for navigation
	useEffect(() => {
		window.principalHandleTabChange = handleTabChange;
		return () => {
			delete window.principalHandleTabChange;
		};
	}, [handleTabChange]);

	const handleHashChange = useCallback(() => {
		setIsHashChange(true);
		const availableTabs = getTabs();
		if (
			window.location.hash === "#reports" &&
			userInfo?.["School P/T Text"] === "NU" &&
			availableTabs.includes("Monthly Reports")
		) {
			handleTabChange("Monthly Reports");
		} else if (
			window.location.hash === "#teachers" &&
			availableTabs.includes("Teachers & Staff")
		) {
			handleTabChange("Teachers & Staff");
		} else if (!window.location.hash) {
			// When hash is removed, go back to default tab
			const savedTab = sessionStorage.getItem("principalActiveTab");
			if (savedTab && availableTabs.includes(savedTab)) {
				handleTabChange(savedTab);
			} else {
				handleTabChange((userType === "Team" || userInfo["District Leader?"]) ? "Program Engagement" : "Teachers & Staff");
			}
		}
	}, [userInfo, userType, getTabs, handleTabChange]);

	// Set initial tab after mount
	useEffect(() => {
		const availableTabs = getTabs();
		if (
			window.location.hash === "#reports" &&
			userInfo?.["School P/T Text"] === "NU" &&
			availableTabs.includes("Monthly Reports")
		) {
			handleTabChange("Monthly Reports");
		} else {
			const savedTab = sessionStorage.getItem("principalActiveTab");
			if (savedTab && availableTabs.includes(savedTab)) {
				handleTabChange(savedTab);
			} else {
				handleTabChange((userType === "Team" || userInfo["District Leader?"]) ? "Program Engagement" : "Teachers & Staff");
			}
		}
	}, [getTabs, handleTabChange, userInfo, userType]);

	// Listen for hash changes
	useEffect(() => {
		window.addEventListener('hashchange', handleHashChange);
		return () => window.removeEventListener('hashchange', handleHashChange);
	}, [handleHashChange]);

	// Save active tab to session storage whenever it changes
	useEffect(() => {
		if (activeTab) {
			sessionStorage.setItem("principalActiveTab", activeTab);
		}
	}, [activeTab]);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get("reportSubmitted")) {
			toast.success("Your report has successfully been submitted!");
			// Remove the parameter from URL without triggering a page reload
			const newUrl = window.location.pathname + window.location.hash;
			window.history.replaceState({}, "", newUrl);
		}
	}, []);

	useEffect(() => {
		sessionStorage.setItem("principalActiveTab", activeTab);
	}, [activeTab]);

	useEffect(() => {
		if (
			userType === "Teacher" &&
			!userInfo["School Leader?"] &&
			!userInfo["District Leader?"]
		) {
			window.location.replace("/cn/dashboard");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (school) {
			if (selectedYear === "all" && selectedMonth === "time") {
				setCurrentData(yearToDateFields);
			} else {
				const reports = school.reports;
				const currentReport = reports.find(
					(report) =>
						report.monthValue === Number(selectedMonth) &&
						report.year === Number(selectedYear) &&
						report.status !== "not-started",
				);

				if (currentReport?.cardData) {
					setCurrentData(currentReport?.cardData);
				} else {
					setCurrentData(null);
				}
			}
		}
	}, [selectedMonth, selectedYear, school]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (school && school.reports) {
			setSchoolData(school.reports);
		}
	}, [school, sessions]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let isMulti = false;
		if (userType === "Team" || (schools && schools.length > 1)) isMulti = true;

		const schoolTitle = isMulti
			? t("principal.heading-multi")
			: t("principal.heading");

		window.document.title = `${schoolTitle} - Connected North`;
	}, [schools]); // eslint-disable-line react-hooks/exhaustive-deps

	const onAddNewTeacher = async (name, email) => {
		try {
			showNewTeacherModal(false);
			showLoading("Adding...");
			const newTeacher = await addNewTeacher(name, email);
			setRefreshScool(refreshSchool + 1);
			hideLoading();

			if (newTeacher) {
				toast.success("A new teacher was created successfully!");
			}
		} catch (error) {
			const errorMsg = error?.message || "Unknown Error";
			toast.error(errorMsg);
			hideLoading();
		}
	};

	const handleKeyDown = (e, tab) => {
		if (e.key === "Enter") {
			handleTabChange(tab);
		}
	};

	const tabs = getTabs();


	useEffect(() => {
		if (activeTab && !tabs.includes(activeTab)) {
			setActiveTab(tabs[0]);
		}
	}, [tabs, activeTab]);

	const [creatingReport, setCreatingReport] = useState({});

	const handleReportClick = async (report) => {
		if (report.report_label === "Create Report") {
			if (creatingReport[report.form_id]) return; // Prevent duplicate clicks
			setCreatingReport((prev) => ({ ...prev, [report.form_id]: true }));
			await report.onClick();
			setCreatingReport((prev) => ({ ...prev, [report.form_id]: false }));
		} else {
			report.onClick();
		}
	};

	return (
		<div className="main-container">
			<div className="schools-header">
				<h1>{getTitle()}</h1>
				{!!schools && schools.length > 1 && (
					<div className="school-selector">
						<Select
							className="form-style"
							value={school ? { value: school.id, label: school.School } : null}
							placeholder="Select a School"
							onChange={(selectedOption) => {
								const sc = schools.find((s) => s.id === selectedOption.value);
								if (sc) {
									setSchool(sc);
									setRefreshScool((prev) => prev + 1);
								}
							}}
							options={schools
								.filter(
									(sc) =>
										!sc.School.includes("Mobile Unit") &&
										!sc.School.includes("Floor"),
								)
								.map((sc) => ({
									value: sc.id,
									label: sc.School,
								}))}
						/>
					</div>
				)}
			</div>
			{tabs.length > 1 && (
				<Nav tabs className="my-school-tabs">
					{tabs.map((tabData, i) => (
						<NavItem key={i} className="tabs-name">
							<NavLink
								className={classNames({
									active: activeTab === tabData,
								})}
								onClick={() => handleTabChange(tabData)}
								role="tab"
								aria-selected={activeTab === tabData}
								tabIndex="0"
								onKeyDown={(e) => handleKeyDown(e, tabData)}
							>
								{tabData}
							</NavLink>
						</NavItem>
					))}
				</Nav>
			)}
			<TabContent activeTab={activeTab} className="tab-content">
				{tabs.map((tabData, i) => (
					<TabPane tabId={tabData} key={i}>
						{tabData === "Program Engagement" && (
							<div>
								{!!school &&
									(userType === "Team" || userInfo["District Leader?"]) && (
										<SchoolCharts
											school={school}
											sessions={sessions}
											loading={schoolSessionsLoading}
										/>
									)}
							</div>
						)}

						{tabData === "Monthly Reports" && (
							<div>
								{!schoolLoading &&
									school?.reports?.some(
										(report) => report.status !== "not-started",
									) && (
										<ReportingLayout
											selectedMonth={selectedMonth}
											selectedYear={selectedYear}
											onDateSelect={handleDateSelect}
											schoolId={school?.id}
										>
											{!!currentData ? (
												<MetricsGrid data={currentData} />
											) : (
												<p>No data available for this month and year.</p>
											)}
											{!!schoolData && !!sessions ? (
												//currentData.comparative_graph
												<UserControlledGraph
													data={schoolData}
													sessions={sessions}
												/>
											) : (
												<p>No data available for this school.</p>
											)}
										</ReportingLayout>
									)}
								<div className="reports-table-container">
									<h2>
										Monthly School Reports
										<span
											style={{
												color: "red",
												marginLeft: "8px",
												fontSize: "0.8em",
											}}
										>
											NEW
										</span>
									</h2>

									{school && school.reports.length > 0 ? (
										<>
											<div className="no-reports-container">
												<p>
													We are collecting feedback and bug reports on this new
													feature. If you notice any issues, please{" "}
													<a
														href="mailto:connectednorth@takingitglobal.org"
														style={{ textDecoration: "underline" }}
													>
														e-mail us
													</a>{" "}
													so they can be addressed. Thank you!
												</p>
											</div>
											<table className="reports-table">
												<thead className="table-head">
													<tr className="table-row">
														<th>Report</th>
														<th>Status</th>
														<th>Created By</th>
														<th>Reviewed By</th>
														<th>Submitted On</th>
														<th>Last Updated On</th>
														<th>Report Link</th>
													</tr>
												</thead>
												<tbody>
													{school.reports.map((report, index) => (
														<tr key={index} className="table-row">
															<td className="table-data">
																<span>{report.month}</span>
															</td>
															<td className="table-data">
																<span
																	className={`table-data-status status-${report.status}`}
																>
																	{t(
																		`form_status.${report.status || "not-started"}`,
																	)}
																</span>
															</td>
															<td className="table-data">
																<span>{report.user_name}</span>
															</td>
															<td className="table-data">
																<span>{report.dea_name}</span>
															</td>
															<td className="table-data">
																<span>
																	{report.submitted_at &&
																	new Date(report.submitted_at).getTime() > 0
																		? new Date(
																				report.submitted_at + "T12:00:00Z",
																			).toLocaleDateString("en-US", {
																				year: "numeric",
																				month: "short",
																				day: "numeric",
																			})
																		: "-"}
																</span>
															</td>
															<td className="table-data">
																<span>
																	{report.last_saved_at
																		? new Date(
																				report.last_saved_at + "T12:00:00Z",
																			).toLocaleDateString("en-US", {
																				year: "numeric",
																				month: "short",
																				day: "numeric",
																			})
																		: ""}
																</span>
															</td>
															<td className="table-data" role="button">
																<span
																	className={`text-underline ${creatingReport[report.form_id] ? "disabled" : ""}`}
																	onClick={() => handleReportClick(report)}
																	style={{
																		cursor: creatingReport[report.form_id]
																			? "not-allowed"
																			: "pointer",
																		opacity: creatingReport[report.form_id]
																			? 0.7
																			: 1,
																		display: "inline-flex",
																		alignItems: "center",
																		gap: "8px",
																	}}
																>
																	{report.report_label}
																	{creatingReport[report.form_id] && (
																		<span
																			className="loading-spinner"
																			style={{
																				width: "16px",
																				height: "16px",
																				border: "2px solid #f3f3f3",
																				borderTop: "2px solid #3498db",
																				borderRadius: "50%",
																				animation: "spin 1s linear infinite",
																			}}
																		/>
																	)}
																</span>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</>
									) : (
										<div className="no-reports-container">
											<p>
												Through this platform, school leaders can complete
												monthly reports to collect and visualize data on their
												school's progress and key activities. Click the button
												below to create your first report.
											</p>
											<p>
												We are collecting feedback and bug reports on this new
												feature. If you notice any issues, please{" "}
												<a
													href="mailto:connectednorth@takingitglobal.org"
													style={{ textDecoration: "underline" }}
												>
													e-mail us
												</a>{" "}
												so they can be addressed. Thank you!
											</p>
											<Button
												className="btn btn-primary"
												onClick={() => history.push(`/cn/principal-reporting/`)}
											>
												Create Report
											</Button>
										</div>
									)}
								</div>
							</div>
						)}
						{(tabData === "My Teachers" || tabData === "Teachers & Staff") && (
							<div>
								<div>
									{userType === "Teacher" &&
										userInfo["School Leader?"] &&
										!userInfo["District Leader?"] && (
											<Button
												className="btn btn-secondary"
												onClick={() => showNewTeacherModal(true)}
											>
												{t("principal.add-teacher")}
											</Button>
										)}
									{userType === "Teacher" && !userInfo["District Leader?"] && (
										<a className="btn btn-primary" href="/cn/credits/earn-more">
											{t("principal.buy-credits")}
										</a>
									)}
									{userType === "Team" && (
										<button
											className="btn btn-primary"
											onClick={() => setShowTransferCreditModal(true)}
										>
											{t("principal.transfer-credits")}
										</button>
									)}
								</div>
								{!!school && (
									<School
										school={{
											id: school.id,
											name: school.School,
										}}
										isSchoolCredit={
											(userType === "Teacher" || userType === "Team") &&
											schools.length > 1
										}
										refresh={refreshSchool}
										refresher={() => setRefreshScool(refreshSchool + 1)}
									/>
								)}

								{showTransferCreditModal && (
									<TransferCreditModal
										onToggle={() => setShowTransferCreditModal(false)}
										onPost={() => refresh()}
									/>
								)}
								{newTeacherModal && (
									<AddTeacherModal
										confirmBtnLabel={t("principal.save-teacher")}
										onToggle={() => showNewTeacherModal(!newTeacherModal)}
										addTeacher={onAddNewTeacher}
									/>
								)}
							</div>
						)}
					</TabPane>
				))}
			</TabContent>

			{schoolLoading && <LoadingBar />}
			<style>{`
				@keyframes spin {
					0% { transform: rotate(0deg); }
					100% { transform: rotate(360deg); }
				}
			`}</style>
		</div>
	);
};

export default Screen;

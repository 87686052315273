import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import './i18n';
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import fr from 'javascript-time-ago/locale/fr.json'
import { IntercomProvider } from 'react-use-intercom';
import { PostHogProvider } from 'posthog-js/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(fr);

const options = {
  api_host: 'https://us.i.posthog.com',
  maskAllInputs: false,
}

// Check for local environment
const isLocalhost = window.location.hostname === 'localhost' || 
                   window.location.hostname === '127.0.0.1';

const store = configureStore();
const queryClient = new QueryClient();

const ConnectedNorthRedux = () => (
  <Provider store={store}>
    <IntercomProvider
      appId='an4qqxki'
      apiBase='https://api-iam.intercom.io'
    >
      <App isLocalEnvironment={isLocalhost} />
    </IntercomProvider>
  </Provider>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    {!isLocalhost ? (
      <PostHogProvider 
        apiKey='phc_COUkOSJ6KDOXyBq7lQsgoTKho3EgCplFFI89EXHwRTc'
        options={options}
      >
        <ConnectedNorthRedux />
      </PostHogProvider>
    ) : (
      <ConnectedNorthRedux />
    )}
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const FORM_RESPONSE_VALUES_FIELD_IDS = {
	GRADUATING_STUDENTS: "27b3607e-f7e5-438f-b13f-83b275d1d4fb",
	STUDENT_POPULATION: "b5e16737-92a6-4783-9b81-e6b8e273b382",
	COMMUNITY_POPULATION: "eff08de2-daa1-4a78-b44a-18fd9ae1b449",
	SCHOOL_CLOSURE_DATE: "03df80bf-269f-442c-a567-d1284506241e",
	SCHOOL_CLOSURE_REASON: "1c30070a-02c9-4be8-8af3-c617e48e9f21",
	SUSPENSION_DATE: "1f3febd5-9eac-4c28-a45f-a39be64d6cc1",
	SUSPENSION_GRADE: "937930b8-df3f-4822-bc5e-6e4811fc903c",
	SUSPENSION_HOURS_IN_SCHOOL: "99b9a830-f28c-4e20-870c-f55103a062fd",
	SUSPENSION_HOURS_OUT_OF_SCHOOL: "e068a3e2-f8aa-40ca-983d-a7daf222c146",
	ATTENDANCE_RATE_AVG: "82635573-0bf3-47d2-a33a-9cb99baa3d8d",
	ATTENDANCE_RATE: "43f582cf-57c0-4474-9b61-754530fb0951",
	EXPULSION: "e46a4d48-9588-474a-bc0c-476ddeffdddd",
	EXPULSION_GRADE: "937930b8-df3f-4822-bc5e-6e4811fc903c",
	SUSPENSION_VIOLENCE_STUDENTS: "437e3be0-10a7-4c7b-910b-d6a9f88d3d9b",
	SUSPENSION_VIOLENCE_STAFF: "fe95da80-acd5-4bf8-8890-e31edb8bb2ec",
};

import {
    SET_REQUEST_SESSION,
    SET_TRIVIA_MODAL
} from './modalTypes';

export const setRequestSessionModal = (payload) => ({
    type: SET_REQUEST_SESSION,
    payload
});

export const setTriviaModal = (payload) => ({
    type: SET_TRIVIA_MODAL,
    payload
});
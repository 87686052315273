import React, { useEffect } from 'react';
import useSignOut from '../libs/hooks/use-sign-out';

const Screen = () => {
  const { preSignOut } = useSignOut();

  useEffect(() => {
    preSignOut();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div></div>
  )
}

export default Screen;
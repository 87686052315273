import { Box, styled } from "@mui/material";
import { ChartCard } from "./cards/ChartCard";
import { NumberCard } from "./cards/NumberCard";
import { useTranslation } from "react-i18next";

const MetricsRow = styled(Box)(() => ({
	display: "flex",
	gap: "1rem",
}));

export const MetricsGrid = ({ data }) => {
	const { t } = useTranslation();
	// const {
	// 	studentPopulationData,
	// 	graduatingStudentsData,
	// 	communityPopulationData,
	// 	schoolClosureData,
	// 	schoolClosureReasonData,
	// 	suspensionGradeData,
	// 	expulsionGradeData,
	// 	suspensionViolenceStudentsData,
	// 	suspensionViolenceStaffData,
	// 	suspensionHoursInSchoolData,
	// 	suspensionHoursOutOfSchoolData,
	// } = data;

	// const { t } = useTranslation();

	// const gradeLabels = [
	// 	"a",
	// 	"b",
	// 	"c",
	// 	"d",
	// 	"e",
	// 	"f",
	// 	"g",
	// 	"h",
	// 	"i",
	// 	"j",
	// 	"k",
	// 	"l",
	// 	"m",
	// ].map((key) => t(`principal.metrics-grid.grade-list.${key}`));

	// const closureLabels = ["a", "b", "c", "d", "e", "f", "g", "h"].map((key) =>
	// 	t(`db_fields.closureReason.options.${key}`),
	// );

	// const stats = [
	// 	{
	// 		label: t("principal.metrics-grid.stats-card.violence"),
	// 		value: suspensionViolenceStudentsData || 0,
	// 	},
	// 	{
	// 		label: t("principal.metrics-grid.stats-card.incidents"),
	// 		value: suspensionViolenceStaffData || 0,
	// 	},
	// ];

	const getLabels = (card, key) => {
		const optionObject = t(`${card.options[key].options.options_id}`, {
			returnObjects: true,
		});
		return Object.values(optionObject);
	};
	const getData = (data) => {
		if (!data) return [];
		return Object.values(data);
	};

	const valueCards = data.filter((card) => card.type === "VALUE_CARD_LATEST");
	const donutGraph = data.find((card) => card.type === "DONUT_GRAPH");
	const barGraphs = data.filter((card) => card.type === "BAR_GRAPH");
	const doubleValueCard = data.find(
		(card) => card.type === "VALUE_CARD_COMBINED",
	);

	return (
		<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
					gap: "1rem",
				}}
			>
				{valueCards.map((card) => (
					<NumberCard
						key={card.id}
						title={t(`${card.translation_id}`)}
						number={card.options.field_a.data}
					/>
				))}
			</MetricsRow>

			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				{/* Attendance is TBD */}
				{/* <ChartCard
					title={t("principal.metrics-grid.attendance-card.title")}
					subtitle={t("principal.metrics-grid.attendance-card.description")}
					chartType="bar"
					value="85%"
				/>
				<ChartCard
					title={t("principal.metrics-grid.absences-card.title")}
					subtitle={t("principal.metrics-grid.absences-card.description")}
					chartType="donut"
					value="3"
					labels={["Group 1", "Group 2"]}
				/> */}
				{donutGraph && (
					<ChartCard
						title={t("principal.metrics-grid.closures-card.title")}
						subtitle={t("principal.metrics-grid.closures-card.description")}
						chartType="donut"
						value={donutGraph.options.count.data}
						data={getData(donutGraph.options.field_a?.data)}
						labels={getLabels(donutGraph, "field_a")}
					/>
				)}
				{/* {schoolClosureData?.length !== 0 && (
					<ChartCard
						title={t("principal.metrics-grid.closures-card.title")}
						subtitle={t("principal.metrics-grid.closures-card.description")}
						chartType="donut"
						value={sumCount(schoolClosureReasonData?.counts)}
						data={schoolClosureReasonData?.counts || []}
						labels={closureLabels}
					/>
				)} */}
			</MetricsRow>
			<MetricsRow
				sx={{
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				{barGraphs.map((card) => (
					<ChartCard
						key={card.id}
						title={t(`${card.translation_id}.title`)}
						subtitle={t(`${card.translation_id}.description`)}
						chartType="bar"
						value={card.options.count.data}
						data={getData(card.options.field_a.data)}
						dataSecondary={getData(card.options.field_b?.data)}
						labels={getLabels(card, "field_a")}
						showSecondaryData={card.options.field_b ? true : false}
						primaryDataLabel={t(`${card.translation_id}.data_a`)}
						secondaryDataLabel={t(`${card.translation_id}.data_b`)}
					/>
				))}
				{/* <ChartCard
					title={t("principal.metrics-grid.suspensions-card.title")}
					subtitle={t("principal.metrics-grid.suspensions-card.description")}
					chartType="bar"
					value={sumCount(suspensionGradeData)}
					data={suspensionHoursInSchoolData}
					dataSecondary={suspensionHoursOutOfSchoolData}
					labels={gradeLabels}
					showSecondaryData={true}
					primaryDataLabel={"In School Days"}
					secondaryDataLabel={"Out of School Days"}
				/>
				<ChartCard
					title={t("principal.metrics-grid.expulsions-card.title")}
					subtitle={t("principal.metrics-grid.expulsions-card.description")}
					chartType="bar"
					value={sumCount(expulsionGradeData)}
					data={expulsionGradeData}
					labels={gradeLabels}
					primaryDataLabel={"Expulsions"}
				/> */}
				{doubleValueCard && (
					<NumberCard
						isLeftAlign={true}
						data={doubleValueCard.options.field_a.data}
						dataSecondary={doubleValueCard.options.field_b.data}
						primaryDataLabel={t(`${doubleValueCard.translation_id}.data_a`)}
						secondaryDataLabel={t(`${doubleValueCard.translation_id}.data_b`)}
					/>
				)}
			</MetricsRow>
		</Box>
	);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getSessionsForMonth = (sessions, targetMonth) => {
  return sessions.filter(session => {
    const sessionDate = new Date(session["Session Start Date/Time"]);
    return sessionDate.getMonth() === targetMonth;
  });
};

import React, { useEffect, useRef, useState } from 'react';
import logoImg from "../../assets/img/logo1.png"
import CNNavItem from './CNNavItem';
import { useMainMenu } from './use-mainMenu';
import { useSelector } from 'react-redux';
import SVGIcon from '../Icons/SVGIcons';
import { Link } from 'react-router-dom';
import { getDefaultUrl } from '../../utils/url';
import EllipsisIcon from "../../assets/img/icons/menu/ellipsis.svg";
import { Accordion } from "./components/Accordion";
import "./styles.scss";
import { List } from "@mui/material";

const SCROLL_STEPS = 3;

export const MainNav = ({
  isOpen,
  path,
  shown,
  toggleNavbar
}) => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const { navs } = useMainMenu();

  const [deviceType, setDeviceType] = useState("desktop");
  const [showScrollUpButton, setShowScrollUpButton] = useState(false);
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);
  const [mobileHeight, setMobileHeight] = useState(0);
  const [sMobileHeight, setSMobileHeight] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    // Load saved navbar state
    const savedNavState = localStorage.getItem('navbarOpen');
    if (savedNavState !== null) {
      shown(savedNavState === 'true');
    }

    const handleScroll = () => {
      const scrollContainer = scrollRef.current;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;
      const scrollTop = scrollContainer.scrollTop;

      setShowScrollUpButton(scrollTop > 16);
      setShowScrollDownButton(scrollTop + clientHeight < scrollHeight - 16);
    };

    const handleWindowResizing = () => {
      const width = window.innerWidth;
      const savedState = localStorage.getItem('navbarOpen');
      
      if (width >= 1280) {
        setDeviceType("desktop");
        // Only set to true if there's no saved state
        if (savedState === null) {
          shown(true);
        }
      } else if (width >= 480) {
        setDeviceType("tablet");
        shown(false);
      } else {
        setDeviceType("phone");
        shown(false);
      }
    }

    handleWindowResizing();
    window.addEventListener('resize', handleWindowResizing);

    setTimeout(() => handleScroll(), 1)
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener("resize", handleWindowResizing)
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const totalNavs = navs.length + 1;
    setMobileHeight(Math.ceil(totalNavs / 5) * 80 - 8);
    setSMobileHeight(Math.ceil(totalNavs / 4) * 80 - 8);
  }, [navs])

  const HoverOverNav = () => {
    shown(true);
  }

  const controlNavShown = () => {
    if (deviceType === "desktop" && isOpen) return;
    shown(false);
  }

  return (
    <>
      <div
        className="sidebar-wrapper"
        style={{
          zIndex: 1049,
          "--mh": `${mobileHeight}px`,
          "--smh": `${sMobileHeight}px`
        }}
      >
        <div className="sidebar">
          <div className="sidebar-header">
            <div className="sidebar-toggler" onClick={() => {
            toggleNavbar();
            localStorage.setItem('navbarOpen', !isOpen);
          }}>
              <i className="fa fa-bars" />
            </div>
            <div className="logo-img">
              <Link to={`${path}${getDefaultUrl(userType, userInfo)}`}>
                <img alt="Connected North Logo"
                  src={logoImg}
                />
              </Link>
            </div>
          </div>

          <div className="nav-content" onMouseEnter={HoverOverNav} onFocus={HoverOverNav}>
            <div className="main-navs">
              {showScrollUpButton && (
                <div
                  className="nav-scroll up-indicator"
                  onClick={() => scrollRef.current.scrollTo({
                    top: scrollRef.current.scrollTop - 64 * SCROLL_STEPS,
                    behavior: 'smooth',
                  })}
                >
                  <SVGIcon name="nav-up" color="#0f6aa8" />
                </div>
              )}

              {/* default open when active link */}
              <List ref={scrollRef} className="nav-list menu-list">
                {navs.map((nav, i) => {
                  if (nav.subMenuItems && nav.subMenuItems.length > 0 & nav.showMenu) {
                    return (
                      <li key={nav.title}>
                        <Accordion
                          title={nav.title}
                          icon={nav.iconImg}
                          isNavOpen={isOpen}
                          intercomLabel={nav.intercomLabel}
                        >
                          <List style={{ listStyle: 'none', padding: '0' }}>
                            {nav.subMenuItems.map((linkItem, i) => {
                              // eslint-disable-next-line array-callback-return
                              if (!linkItem.showLink) return
                              return (
                                  <CNNavItem
                                    key={i}
                                    showTooltip={deviceType !== "phone"}
                                    {...linkItem}
                                    hideNav={controlNavShown}
                                    isNavOpen={isOpen}
                                />)
                            })}
                          </List>
                        </Accordion>
                      </li>
                    )
                  } else { 
                    // eslint-disable-next-line array-callback-return
                    if (!nav.showLink) return;

                    return (
                      <CNNavItem
                        key={i}
                        path={path}
                        showTooltip={deviceType !== "phone"}
                        {...nav}
                        hideNav={controlNavShown}
                        isNavOpen={isOpen}
                      />
                    )
                  }
                })}
              </List>
              {showScrollDownButton && (
                <div
                  className="nav-scroll down-indicator"
                  onClick={() => scrollRef.current.scrollTo({
                    top: scrollRef.current.scrollTop + 64 * SCROLL_STEPS,
                    behavior: 'smooth',
                  })}
                >
                  <SVGIcon name="nav-down" color="#0f6aa8" />
                </div>
              )}
              <div className="open-bottom-nav" onClick={toggleNavbar}>
                <img src={EllipsisIcon} alt="Popup Bottom Nav" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-dismiss"
        onClick={() => shown(false)}
      />
    </>
  )
};
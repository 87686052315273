import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import s from "./styles.module.scss";
import SearchInput from "./Search";
import Filter from "./Filter";
import { SEARCH_SORT_SELECTBOX_STYLE, sortlist } from "../../utils/constants";
import Select from "react-select";
import SessionList from "./SessionList";
import useSearch from "./use-search";
import { Button } from "reactstrap";
import DynamicCollectionModal from "../modals/DynamicCollection";

const Screen = () => {
	const { t } = useTranslation();
	const {
		totalSubjects,
		communities,
		appLocale,
		userInfo,
		userType,
	} = useSelector((state) => state.appInfo);
	const [loading, setLoading] = useState(true);
	const [tagModal, showTagModal] = useState(false);
	const {
		searching,
		sessions,
		filterCondition,
		updateFilterCondition,
		sortInfo,
		setSortInfo,
		search,
		setSearch,
		hasFilter,
		tagQuery,
		visibleFilterModal,
		setVisibleFilterModal,
		applyFilter,
		pageChanged,
		currentPage,
		resetPage
	} = useSearch(loading);

	useEffect(() => {
		if (
			totalSubjects &&
			totalSubjects.length &&
			communities &&
			communities.length
		) {
			setLoading(false);
		}
	}, [totalSubjects, communities])

	const renderPageContent = () => {
		if (loading) {
			return (
				<div className={s.loadingCenterAlign}>
					<CircularProgress />
				</div>
			)
		}

		return (
			<div className={s.pageLayout}>
				<Filter
					condition={filterCondition}
					update={updateFilterCondition}
					visibleModal={visibleFilterModal}
					toggleModal={(v) => setVisibleFilterModal(v)}
					applyFilter={applyFilter}
				/>
				<div className={s.filterResult}>
					<div className="search-input-org">
						<div className={s.searchInput}>
							<SearchInput value={search} onSearch={s => {
								resetPage();
								setSearch(s)
							}} />
							<div className={s.btnFilter} onClick={() => setVisibleFilterModal(true)}>
								<i className="fa fa-filter" />
							</div>
						</div>
					</div>
					{!hasFilter && (
						<div className={s.defaultViewHeader}>
							<h2>Newest Sessions offered by First Nations, Inuit and/or Métis Providers</h2>
							<p className={s.defaultViewNote}>
								To search our directory of 2,600+ Sessions, select filters on the left or enter a keyword above.
								Below, you can explore the newest Sessions offered by Indigenous content providers, offering a 50% discount on the first 10 bookings!
							</p>
						</div>
					)}
					{hasFilter && (
						<div className={s.sortWrapper}>
							<Select
								className="form-style"
								styles={SEARCH_SORT_SELECTBOX_STYLE}
								value={{
									value: sortInfo.id,
									label: (
										<>
											<i className={`fa fa-${sortInfo.icon}`} style={{ marginRight: '8px' }} />
											{appLocale === 'fr' ? sortInfo.label_fr : sortInfo.label}
										</>
									)
								}}
								onChange={(selectedOption) => {
										resetPage();
										const newSortOption = sortlist[selectedOption.value];
										setSortInfo(newSortOption);
									}}
								options={sortlist.map((sort) => ({
									value: sort.id,
									label: (
										<>
											<i className={`fa fa-${sort.icon}`} style={{ marginRight: '8px' }} />
											{appLocale === 'fr' ? sort.label_fr : sort.label}
										</>
									)
								}))}
							/>
						</div>
					)}
					<div className={s.sessionList}>
						<SessionList
							loading={searching}
							sessions={sessions}
							pageChanged={pageChanged}
							currentPage={currentPage}
						/>

						{!searching && hasFilter && userType === "Team" && !!tagQuery && sessions.length > 0 && (
							<div className="for-collection" align="center">
								<Button
									color="primary"
									onClick={() => showTagModal(true)}
								>
									Create Dynamic Collection
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div
			className="main-container search-container"
			style={{ minHeight: "100vh" }}
		>
			<div className={s.pageHeader}>
				<h1>{t("explore-sessions")}</h1>
				{userType === "Teacher" && (
					<span className="search-actions-credits">{`${t("my-booking-credits")}: ${userInfo["Credit Balance"]}`}</span>
				)}
			</div>
			<div className={s.pageContent}>
				{renderPageContent()}
			</div>

			{userType === "Team" && tagModal && !!tagQuery && (
				<DynamicCollectionModal
					query={tagQuery}
					onToggle={() => showTagModal(false)}
				/>
			)}
		</div>
	);
}

export default Screen;
import React, { Fragment, useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import {
  Button,
  Modal,
  FormGroup,
  Input,
  Form
} from 'reactstrap'
import { toast } from 'react-toastify';
import airtable from '../../airtables';
import { getDateTimeString } from '../../utils/time';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ConnectionTypes,
  SendMeetingRequestTypes,
  SessionTypes,
  ZoomAccounts
} from '../../utils/constants';
import useSessionBook from '../../libs/hooks/use-session-book';
import SessionFullTimePicker from '../../components/SessionFullTimePicker';

const Booking = ({ session, showModal, onDone, onToggle }) => {
  const { totalSubjects } = useSelector(state => state.appInfo);
  const sessionId = session.id;
  const [loading, setLoading] = useState(true);
  const [providerSession, setProviderSession] = useState(null);
  const [booking, setBooking] = useState(false);
  const [startDate, setStartDate] = useState(new Date(session['Session Start Date/Time']));
  const [sessionType, setSessionType] = useState(session["Type of Session"]);
  const [sendMeetingInvite, setSendMeetingInvite] = useState('All');
  const [subjectCurriculum, setSubjectCurriculum] = useState([]);
  const [connectionType, setConnectionType] = useState("");
  const [sessionHost, setSessionHost] = useState();
  const [hosts, setHosts] = useState([]);
  const [zoomAccount, setZoomAccount] = useState(session["Zoom Account"]);
  const [bridgeAddr, setBridgeAddr] = useState(session['Bridge Address / SIP URI']);
  const [webex, setWebex] = useState(session["WebEx/Zoom Link"])
  const [timeValid, setTimeValid] = useState("");
  const [teachers, setTeachers] = useState([]);
  const { t } = useTranslation();
  const alternativeDate = session['Alternative Date/Time'] ? new Date(session['Alternative Date/Time']) : 'none'

  const {
    loading: bookSessionLoading,
    provider,
    shouldDisableDate
  } = useSessionBook({
    providerSession: providerSession,
    bookType: "edit",
    session: session,
    teachers: teachers
  });

  useEffect(() => {
    (async () => {
      if (!totalSubjects) return;

      const ts = await airtable.teachers.listByIds(session["Teacher"]);
      setTeachers(ts);

      setConnectionType(session["Connection Type"] || "Our Zoom Only");
      if (session["Indigenous?"]) {
        setSessionType("Indigenous Role Model/Content Provider");
      }

      airtable.teams.getAllTeams().then(ts => setHosts(ts));

      var subs = [];
      const providerSessionId = session['Session Title'][0];

      if (providerSessionId) {
        const pSession = await airtable.providerSessions.select(providerSessionId);
        pSession['Subject'].forEach(subjectId => {
          for (var i = 0; i < totalSubjects.length; i++) {
            if (subjectId === totalSubjects[i].id) {
              subs.push({ value: subjectId, label: totalSubjects[i].Subject })
            }
          }
        });

        setProviderSession(pSession);
        setSubjectCurriculum(subs);
        setLoading(false)
      }
    })();
  }, [session, totalSubjects]);

  const canSave = () => {
    if (loading) return false;
    if (timeValid !== "valid") return false;
    return true;
  }

  const onBook = () => {
    setBooking(true);
    var param = {
      'Session Start Date/Time': startDate,
      'Connection Type': connectionType,
      'Type of Session': sessionType,
      'Send Meeting Invite to:': sendMeetingInvite,
      'Zoom Account': zoomAccount,
      'Subject/Curriculum': subjectCurriculum.map(item => (item.value)),
      'Status': 'Booked',
    }

    if (sessionHost && sessionHost.value) {
      param["Session Host(s)"] = [sessionHost.value];
    }

    if (connectionType === 'Our Zoom Only' || connectionType === 'Our Zoom & TMS/CMM') {
      param["Zoom Account"] = zoomAccount;
    } else {
      param["Bridge Address / SIP URI"] = bridgeAddr || "";
      param["WebEx/Zoom Link"] = webex || "";
    }

    airtable.sessions.update(sessionId, param).then(async res => {
      // await Axios.post(
      //     "https://hooks.zapier.com/hooks/catch/89715/omzbz3f/",
      //     { record_id: sessionId },
      //     { headers: { 'Content-Type': 'text/plain' } }
      // )
      setBooking(false);
      toast.success("Booked successfully!")
      onDone();
    }).catch(error => {
      setBooking(false);
      toast.error(error.toString())
    })
  }

  const renderZoomInfo = () => {
    if (!connectionType || connectionType !== 'Provider Zoom Only') return null; // Temporarily disable the Zoom Account dropdown since all meetings will default to CN Zoom this year

    if (connectionType === 'Our Zoom Only' || connectionType === 'Our Zoom & TMS/CMM') {
      return (
        <FormGroup>
          <label>Zoom Account</label>

          <Select
            className="form-style"
            value={{ value: zoomAccount, label: zoomAccount }}
            placeholder="Zoom Account"
            onChange={(selectedOption) => setZoomAccount(selectedOption.value)}
            options={ZoomAccounts.map(type => ({ value: type, label: type }))}
          />
        </FormGroup>
      )
    }

    return (
      <Fragment>
        <FormGroup>
          <label>Bridge Address / SIP URI</label>
          <Input
            className="form-style"
            defaultValue={bridgeAddr}
            placeholder="Bridge Address / SIP URI"
            onChange={e => setBridgeAddr(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <label>WebEx/Zoom Link</label>
          <Input
            className="form-style"
            defaultValue={webex}
            placeholder="WebEx/Zoom Link"
            onChange={e => setWebex(e.target.value)}
          />
        </FormGroup>
      </Fragment>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={showModal}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">{t("book-confirm")}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {
          loading ? (
            <div style={{ padding: '20px 0' }} align="center">
              <Loading size={20} />
            </div>
          ) : (
            <Form>
              <p>Please ensure you have confirmed the date and time below with the provider before booking!</p>
              {!bookSessionLoading && (
                <SessionFullTimePicker
                  preferTime={startDate}
                  alterTime={null}
                  sessionLen={session["Length (Minutes)"]}
                  shouldDisableDate={shouldDisableDate}
                  provider={provider}
                  providerSession={providerSession}
                  bookedSessionId={session.id}
                  teachers={teachers}
                  withAlterTime={false}
                  labels={[
                    t("sessionrequest.prefer-time"),
                    t("sessionrequest.alternative-time")
                  ]}
                  onChange={(date, which) => {
                    if (which === "prefer") {
                      setStartDate(date);
                    }
                  }}
                  onValid={v => setTimeValid(v)}
                />
              )}
              {alternativeDate !== 'none' && (
                <label>Alternate Time Requested: {getDateTimeString(alternativeDate)}</label>
              )}

              <FormGroup>
                <label>Connection Type</label>

                <Select
                  className="form-style"
                  value={{ value: connectionType, label: connectionType }}
                  placeholder="Type of Session"
                  onChange={(selectedOption) => setConnectionType(selectedOption.value)}
                  options={ConnectionTypes.map(type => ({ value: type, label: type }))}
                />
              </FormGroup>

              {renderZoomInfo()}

              <FormGroup>
                <label>Type of Session</label>
                <Select
                  className="form-style"
                  value={{ value: sessionType, label: sessionType }}
                  placeholder="Type of Session"
                  onChange={(selectedOption) => setSessionType(selectedOption.value)}
                  options={SessionTypes.map(type => ({ value: type, label: type }))}
                />
              </FormGroup>

              <FormGroup>
                <label>Session Host</label>
                <Select
                  className="form-style"
                  value={{ value: sessionHost?.value, label: sessionHost?.label }}
                  placeholder="Type of Session"
                  onChange={(selectedOption) => setSessionHost(selectedOption)}
                  options={hosts.map(h => ({ value: h.id, label: h.Name }))}
                />
              </FormGroup>

              <FormGroup>
                <label>Subject/Curriculum</label>
                <Select
                  className="form-style"
                  isMulti={true}
                  value={subjectCurriculum}
                  placeholder="Subject/Curriculum"
                  onChange={(selectedOptions) => setSubjectCurriculum(selectedOptions)}
                  options={totalSubjects.map(subject => ({ value: subject.id, label: subject.Subject }))}
                />
              </FormGroup>

              <FormGroup>
                <label>Send Meeting Invite to:</label>
                <Select
                  className="form-style"
                  value={{ value: sendMeetingInvite, label: sendMeetingInvite }}
                  onChange={selectedOption => setSendMeetingInvite(selectedOption.value)}
                  options={SendMeetingRequestTypes.map(type => ({ value: type, label: type }))}
                />
              </FormGroup>
            </Form>
          )
        }
      </div >
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          disabled={!canSave()}
          onClick={() => onBook()}
        >
          {booking ? (<Loading size={14} />) : (null)}{t("book-confirm")}
        </Button>
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >{t("close")}</Button>
      </div>
    </Modal >
  )
}

export default Booking;

import { jsPDF } from "jspdf";
import { subjectInfo } from "./subjectColors";
import { monthNames, getSessionsForMonth } from "./monthNames";
import { fontAwesomeBase64 } from "./fontAwesomeBase64";

export const generateCalendarPDF = (
	title,
	sessions,
	year,
	userType = null // Add userType parameter with null default
) => {
	const doc = new jsPDF({
		orientation: "portrait",
		unit: "mm",
		format: "letter", // Standard Letter format
	});
	doc.addFileToVFS("FontAwesome.ttf", fontAwesomeBase64);
	doc.addFont("FontAwesome.ttf", "FontAwesome", "normal");

	// Title
	doc.setFontSize(24);
	doc.setTextColor(44, 37, 96);
	doc.text(title, 105, 20, { align: "center" });
	doc.setFontSize(18);
	doc.text(`${year}-${(parseInt(year) + 1).toString().substring(2)} Connected North Sessions`, 105, 30, { align: "center" });

	generateCalendarEvent(
		doc,
		sessions,
		year,
		userType
	);

	// Calendar setup
	let currentPage = 1;
	doc.setPage(currentPage);

	const start = new Date(year, 7, 1); // Start from August of the given year
	const monthsPerRow = 4;
	const monthWidth = 50;
	const monthHeight = 70;
	const marginLeft = 9;

	// Generate 12 months starting from the start date
	for (let i = 0; i < 12; i++) {
		const monthDate = new Date(start);
		monthDate.setDate(1);
		monthDate.setMonth(start.getMonth() + i);
		const monthName = monthNames[monthDate.getMonth()];
		const row = Math.floor(i / monthsPerRow);
		const col = i % monthsPerRow;

		// Calculate position for each month
		const x = marginLeft + col * monthWidth + col * 0.5;
		const y = 50 + row * monthHeight;

		// Add month name
		doc.setFontSize(15);
		doc.setTextColor(44, 37, 96);
		const monthNameWidth =
			(doc.getStringUnitWidth(monthName) * doc.internal.getFontSize()) /
			doc.internal.scaleFactor;
		doc.text(monthName, x + (monthWidth - monthNameWidth - marginLeft) / 2, y);

		const monthSessions = getSessionsForMonth(sessions, monthDate.getMonth()) || [];
		drawCustomCalendar(
			doc,
			x,
			y + 8,
			generateMonthCalendarData(monthDate),
			monthSessions,
			monthDate.getFullYear(),
			monthDate.getMonth(),
		);
	}

	doc.save(`${title} ${year}-${(parseInt(year) + 1).toString().substring(2)} Sessions.pdf`);
};

const generateMonthCalendarData = (monthDate) => {
	const daysInMonth = new Date(
		monthDate.getFullYear(),
		monthDate.getMonth() + 1,
		0,
	).getDate();
	const firstDay = new Date(
		monthDate.getFullYear(),
		monthDate.getMonth(),
		1,
	).getDay();
	const calendar = Array.from({ length: 6 }, () => Array(7).fill("")); // 6 weeks max

	let dayCount = 1;

	// Fill first week
	for (let i = firstDay; i < 7 && dayCount <= daysInMonth; i++) {
		calendar[0][i] = dayCount++;
	}

	// Fill remaining weeks
	for (let weekIndex = 1; dayCount <= daysInMonth; weekIndex++) {
		for (
			let dayIndex = 0;
			dayIndex < 7 && dayCount <= daysInMonth;
			dayIndex++
		) {
			calendar[weekIndex][dayIndex] = dayCount++;
		}
	}

	return calendar;
};

const processEvent = (doc, event, boxWidth, totalBoxHeight, circleRadius, titleHeight, headerHeight, lineHeight, titleLines, descLines, yPos, xPos, userType = null) => {
	const icon = subjectInfo[event["Primary Subject Text"]?.[0]]?.unicode || "";
	const color = subjectInfo[event["Primary Subject Text"]?.[0]]?.color || "#ffffff";
	const url = `https://sessions.connectednorth.org/cn/session/${event["Session Title"]}`;
	const niceDate = event["Nice Date"].replace("Eastern", "").trim();
	const lengthText = `${event["Length (Minutes)"]}mins`;

	// Draw event box using calculated total height
	doc.setFillColor(243, 249, 255);
	doc.setDrawColor(17, 205, 239);
	doc.roundedRect(xPos, yPos, boxWidth, totalBoxHeight, 2, 2, "FD");

	// Draw event type circle in header (centered vertically in header or title if title height is greater)
	const circleX = xPos + circleRadius + 1;
	const circleY = titleHeight > headerHeight ? yPos + titleHeight / 2 : yPos + headerHeight / 2;
	doc.setFillColor(...hexToRGB(color));
	doc.circle(circleX, circleY + 1, circleRadius, "F");
	doc.setFontSize(8);
	doc.setFont("FontAwesome", "normal");
	doc.setTextColor(255, 255, 255);
	doc.text(icon, circleX, circleY + 1, {
		align: "center",
		baseline: "middle",
	});

	yPos += 2;

	doc.setTextColor(44, 37, 96);
	doc.setFont("helvetica", "bold");
	doc.setFontSize(8);
	const titleStartX = xPos + 7.5;

	// Draw session title text (wrapped into multiple lines)
	if (titleHeight < headerHeight) {
		let centerOffset = yPos + (headerHeight - titleHeight) / 2;
		titleLines.forEach((line) => {
			if (userType === 'Provider') {
				// Plain text for Provider users (no link)
				doc.text(line, titleStartX, centerOffset, {
					baseline: "middle"
				});
			} else {
				// Clickable link for other users
				doc.textWithLink(line, titleStartX, centerOffset, {
					baseline: "middle",
					url,
				});
			}
			centerOffset += lineHeight;
		});
		yPos += (headerHeight);
	} else {
		titleLines.forEach((line) => {
			if (userType === 'Provider') {
				// Plain text for Provider users (no link)
				doc.text(line, titleStartX, yPos, {
					baseline: "middle"
				});
			} else {
				// Clickable link for other users
				doc.textWithLink(line, titleStartX, yPos, {
					baseline: "middle",
					url,
				});
			}
			yPos += lineHeight;
		});
	}
	yPos += 1;

	// Display session description
	doc.setFont("helvetica", "normal")
	doc.setFontSize(8);
	descLines.forEach((line) => {
		doc.text(line, xPos + 2, yPos, {
			baseline: "middle",
		});
		yPos += lineHeight;
	});
	yPos += 1;

	// Draw footer: session date and session length
	doc.setFontSize(7);
	const footerY = yPos;
	doc.text(niceDate, xPos + 2, footerY, {
		baseline: "middle",
	});
	doc.text(lengthText, xPos + boxWidth - 2, footerY, {
		align: "right",
		baseline: "middle",
	});

	// Return new yPos value adding a small margin below the event box
	return yPos + 6;
};

const generateCalendarEvent = (
	doc,
	sessions,
	year,
	userType = null
) => {
	doc.addPage();

	let yPos = 20;
	let columnNo = 0;
	const leftMargin = 10;
	const start = new Date(year, 7, 1);
	start.setHours(12, 0, 0, 0);

	for (let i = 0; i < 12; i++) {
		const currentMonthIndex = (start.getMonth() + i) % 12;
		const monthName = monthNames[currentMonthIndex];
		const events = getSessionsForMonth(sessions, currentMonthIndex) || [];

		if (events?.length > 0) {
			let isDisplayed = false;

			for (const event of events) {

				// Set font style for the event text
				doc.setFont("helvetica", "normal");
				doc.setFontSize(8);

				// Dimensions for the event box
				const boxWidth = 45;
				// Available width for the session title (leaving space for left padding and a bit of right margin)
				const availableTitleWidth = boxWidth - 7.5 - 3.5;

				// Compute wrapped text lines for Session Title Raw
				const sessionTitle = event["Session Title Raw"] || "";
				const sessionDesc = event["# Schools"] > 1 ?
					event["Primary Subject Text"]?.includes("Professional Development") ?
						`Group Professional Learning with ${event["# Teachers"]} teachers from ${event["# Schools"]} schools participating` :
						`Premium event with ${event["# Teachers"]} classrooms from ${event["# Schools"]} schools participating` :
					userType === 'Provider' ?
						event["School Name Text"] :
						event["Teacher Name"].join(", ");

				const titleLines = doc.splitTextToSize(sessionTitle, availableTitleWidth);
				const descLines = doc.splitTextToSize(sessionDesc, boxWidth - 2);
				const lineHeight = 3; // approximate line height (in mm) for an 8pt font
				const titleHeight = titleLines.length * lineHeight;
				const descHeight = descLines.length * lineHeight;

				// Compute header height based on event type icon
				const circleRadius = 3;
				const headerHeight = circleRadius * 2 + 0.5; // extra padding for the header
				const footerHeight = 4; // space for date and duration

				const totalBoxHeight = Math.max(titleHeight, headerHeight) + 1 + descHeight + 1 + footerHeight;

				// Check if we need a new page
				if (yPos + totalBoxHeight > 268) {
					yPos = 20;
					if (columnNo < 3) {
						columnNo += 1;
					} else {
						doc.addPage();
						columnNo = 0;
					}
				}

				if (!isDisplayed) {
						// Add month header
					doc.setFontSize(14);
					doc.text(monthName, leftMargin + 50 * columnNo, yPos);
					yPos += 2;
					isDisplayed = true;
				}

				yPos = processEvent(
					doc,
					event,
					boxWidth,
					totalBoxHeight,
					circleRadius,
					titleHeight,
					headerHeight,
					lineHeight,
					titleLines,
					descLines,
					yPos,
					leftMargin + 50 * columnNo,
					userType
				);

			}
			yPos += 5;
		}
	}
};

const drawCustomCalendar = (
	doc,
	x,
	y,
	calendarData,
	monthSessions,
	year,
	month,
) => {
	const cellSize = 6.5;
	const cellPadding = 0;

	// Draw headers
	const days = ["S", "M", "T", "W", "T", "F", "S"];
	doc.setFontSize(7);
	doc.setTextColor(150, 150, 150);
	days.forEach((day, i) => {
		doc.text(day, x + i * (cellSize + cellPadding) + cellSize / 2, y, {
			align: "center",
		});
	});

	// Draw calendar days
	calendarData.forEach((week, weekIndex) => {
		week.forEach((day, dayIndex) => {
			if (day) {
				const cellX = x + dayIndex * (cellSize + cellPadding);
				const cellY = y + 4 + weekIndex * (cellSize + cellPadding + 1.5);

				doc.setTextColor(dayIndex === 0 || dayIndex === 6 ? 150 : 0);

				// Find if there's an event for this day
				const event = monthSessions.find((e) => e["Session Start Date/Time"].split("T")[0] === `${year}-${(month + 1).toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`);

				if (event) {
					// Draw event pill
					const icon = subjectInfo[event["Primary Subject Text"]?.[0]]?.unicode || "";
					const pillWidth = cellSize - 1.5;
					const pillHeight = cellSize + 1;

					doc.setFillColor(...hexToRGB(subjectInfo[event["Primary Subject Text"]?.[0]]?.color || "#e0e0e0"));
					doc.roundedRect(
						cellX + 0.725,
						cellY - 0.25,
						pillWidth,
						pillHeight,
						2,
						2,
						"F",
					);
					doc.setFont("FontAwesome", "normal");
					doc.setTextColor(255, 255, 255);
					doc.text(
						icon,
						cellX + cellSize / 2,
						cellY + cellSize / 2 - 1.5,
						{ align: "center", baseline: "middle" },
					);
					doc.setTextColor(44, 37, 96);
					doc.setFont("helvetica", "normal");
					doc.text(
						day.toString(),
						cellX + cellSize / 2,
						cellY + cellSize / 2 + 1.5,
						{ align: "center", baseline: "middle" },
					);
				} else {
					doc.text(day.toString(), cellX + cellSize / 2, cellY + cellSize / 2, {
						align: "center",
						baseline: "middle",
					});
				}
			}
		});
	});
};

// Helper function to convert hex to RGB
const hexToRGB = (hex) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
};


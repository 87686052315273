import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import { CircularProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Card } from '../../components/Card';
import YearlyCalendar from './components/YearlyCalendar';
import { useCalendar } from "./hooks/useCalendar";
import MontlySessionCards from "./components/MontlySessionCards";
import { getSessionsForMonth } from "./utils/monthNames";
import Select from "react-select";
import { generateCalendarPDF } from "./utils/pdf"
import { useSelector } from "react-redux";

const StyledCard = styled(Card)({
  boxShadow: 'none',
  padding: '0 !important',
  backgroundColor: 'white',
})

const Screen = () => {
  const { year, sessions, school, schools, isLoadingSessions, setSchool } = useCalendar();
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const { userType } = useSelector(state => state.appInfo);

  const handleGeneratePDF = () => {
		generateCalendarPDF(
			school ? school.School : "Session Calendar",
      sessions,
      year,
      userType
		);
	};

	return (
		<div className="main-container">
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        <h1>{userType === 'Provider' ? 'Session Calendar' : 'School Calendar'}</h1>
        {!!schools && schools.length > 1 && (
          <div className="school-selector" style={{ marginLeft: "auto" }}>
            <Select
              className="form-style"
              value={
                school
                ? { value: school.id, label: school.School }
                : null
              }
              placeholder="Select a School"
              onChange={(selectedOption) => {
                const sc = schools.find((s) => s.id === selectedOption.value);
                if (sc) {
                  setSchool(sc);
                }
              }}
              options={schools
                .filter(
                  (sc) =>
                    !sc.School.includes("Mobile Unit") &&
                    !sc.School.includes("Floor") &&
                    !sc.School.includes("Test") &&
                    !sc.School.includes("Connected North") &&
                    !sc.School.includes("Department") &&
                    (["Active & Engaged", "Planning in Progress"].includes(sc["Participation Status"]))
                )
                .sort((a, b) => a.School.localeCompare(b.School))
                .map((sc) => ({
                  value: sc.id,
                  label: sc.School,
                }))}
                styles={{
                  control: (styles) => ({ ...styles, minWidth: "200px" }),
                }}
                />
						</div>
					)}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 3  }}>
        <h2 className="mb-0 mr-2">
          Connected North Sessions
        </h2>
        <Button color="primary" outline className="rounded-pill" onClick={handleGeneratePDF}>
          <i className="fa fa-file-pdf-o" />&nbsp;&nbsp;Download PDF
        </Button>
      </Box>

      <Typography sx={{ mb: 2, fontSize: '18px', lineHeight: 1.6 }}>
      </Typography>

      <StyledCard>
        <Row className="m-0" >
          <Col className="border-right py-2" md={3}>
            {isLoadingSessions ? <CircularProgress /> : <MontlySessionCards sessions={getSessionsForMonth(sessions, selectedMonth)} selectedMonth={selectedMonth} userType={userType} />}
          </Col>
          <Col md={9}>
            <YearlyCalendar year={year} sessions={sessions} setSelectedMonth={setSelectedMonth} selectedMonth={selectedMonth} />
          </Col>
        </Row>
      </StyledCard>

    </div>
	);
};

export default Screen;


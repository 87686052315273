import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, Progress } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import airtable from '../../airtables';
import { format, isWeekend } from 'date-fns';
import confetti from 'canvas-confetti';

// Simple version of date formatter to avoid issues
const formatAirtableDate = (dateString) => {
  if (!dateString) return "";
  if (typeof dateString !== 'string') {
    if (Array.isArray(dateString) && dateString.length > 0) {
      dateString = dateString[0];
    } else {
      return "";
    }
  }
  
  try {
    const datePart = dateString.split('T')[0];
    const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
    const date = new Date(year, month - 1, day);
    return format(date, "MMMM d, yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

const TriviaModal = ({ isOpen, toggle, onComplete }) => {
  useTranslation();
  const { userInfo } = useSelector((state) => state.appInfo);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [teacherData, setTeacherData] = useState(null);
  const [timeLeft, setTimeLeft] = useState(30);
  const timerRef = useRef(null);
  const [showingFeedback, setShowingFeedback] = useState(false);
  const [lastAnswerCorrect, setLastAnswerCorrect] = useState(false);
  const [hintUsed, setHintUsed] = useState(false);
  const [eliminatedAnswers, setEliminatedAnswers] = useState([]);
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [insufficientSessions, setInsufficientSessions] = useState(false);

  // Effect to detect mobile devices
  useEffect(() => {
    if (!isOpen) return;
    
    const checkIfMobile = () => {
      const ua = navigator.userAgent;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
    };
    
    setIsMobile(checkIfMobile());
  }, [isOpen]);
  
  // Helper function to generate unique provider options
  const generateUniqueProviderOptions = useCallback((correctValue) => {
    // Convert to number or default to 0
    const correctNumber = correctValue ? parseInt(correctValue) : 0;
    const options = [correctNumber.toString()];
    
    // Generate 3 other random values that are within 30% of the correct answer
    while (options.length < 4) {
      let minVariation = 2, maxVariation = 5;
      
      if (correctNumber > 1) {
        // For larger numbers, make options within 30% of the correct value
        minVariation = Math.max(Math.floor(correctNumber * 0.7), correctNumber - 10);
        maxVariation = Math.ceil(correctNumber * 1.3);
        
        // Ensure there's at least some variation
        if (minVariation === correctNumber) minVariation = correctNumber - 1;
        if (maxVariation === correctNumber) maxVariation = correctNumber + 1;
      }
      
      // Generate a random number within the variation range
      const randomNumber = Math.floor(Math.random() * (maxVariation - minVariation + 1)) + minVariation;
      
      // Avoid duplicates and the correct answer
      if (!options.includes(randomNumber.toString()) && randomNumber !== correctNumber) {
        options.push(randomNumber.toString());
      }
    }
    
    // Shuffle the options
    return options.sort(() => Math.random() - 0.5);
  }, []);
  
  // Helper function to generate distance options
  const generateDistanceOptions = useCallback((correctValue) => {
    // Convert to number, round to nearest whole number, or default to 0
    const correctNumber = correctValue ? Math.round(Number(correctValue)) : 0;
    const options = [`${correctNumber.toLocaleString()} km`];
    
    // Generate 3 other random values that are within 30% of the correct answer
    while (options.length < 4) {
      let minVariation = 2, maxVariation = 5;
      
      if (correctNumber > 1) {
        // For larger numbers, make options within 30% of the correct value
        minVariation = Math.max(Math.floor(correctNumber * 0.7), correctNumber - 10);
        maxVariation = Math.ceil(correctNumber * 1.3);
        
        // Ensure there's at least some variation
        if (minVariation === correctNumber) minVariation = correctNumber - 1;
        if (maxVariation === correctNumber) maxVariation = correctNumber + 1;
      }
      
      // Generate a random number within the variation range
      const randomNumber = Math.floor(Math.random() * (maxVariation - minVariation + 1)) + minVariation;
      
      // Format with commas and add km
      const formattedNumber = `${randomNumber.toLocaleString()} km`;
      
      // Avoid duplicates and the correct answer
      if (!options.includes(formattedNumber)) {
        options.push(formattedNumber);
      }
    }
    
    // Shuffle the options
    return options.sort(() => Math.random() - 0.5);
  }, []);

  // Helper function to generate date options
  const generateDateOptions = useCallback((correctDate, dateAdded) => {
    if (!correctDate) {
      return ["You haven't participated yet", "January 15, 2023", "February 22, 2023", "March 10, 2023"];
    }
    
    // Handle array format
    if (Array.isArray(correctDate) && correctDate.length > 0) {
      correctDate = correctDate[0];
    }
    
    if (typeof correctDate !== 'string') {
      return ["You haven't participated yet", "January 15, 2023", "February 22, 2023", "March 10, 2023"];
    }
    
    try {
      // Parse the date part only from the ISO string
      const datePart = correctDate.split('T')[0];
      const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
      const correctDateObj = new Date(year, month - 1, day); // month is 0-indexed
      const correctFormatted = formatAirtableDate(correctDate);
      
      // Parse the "Date Added" to use as the minimum date
      let minDateObj = new Date(correctDateObj);
      minDateObj.setFullYear(minDateObj.getFullYear() - 1);
      
      if (dateAdded && typeof dateAdded === 'string') {
        try {
          const dateAddedPart = dateAdded.split('T')[0];
          const [addedYear, addedMonth, addedDay] = dateAddedPart.split('-').map(num => parseInt(num, 10));
          minDateObj = new Date(addedYear, addedMonth - 1, addedDay);
        } catch (error) {
          // Keep default date
        }
      }

      // Create consistently formatted alternative dates
      const options = [correctFormatted]; // The correct answer in "Month D, YYYY" format
      
      // Option 1: Same day, different month (within school year, avoiding July/August)
      const alternateDateObj1 = new Date(correctDateObj);
      // Get a month that's different but in same school year
      let alternateMonth1;
      if (month >= 9) { // Sept-Dec, use Jan-June of next year
        alternateMonth1 = Math.floor(Math.random() * 6); // 0-5 (Jan-June)
        alternateDateObj1.setFullYear(year + 1);
      } else if (month <= 5) { // Jan-June, use Sept-Dec of previous year
        alternateMonth1 = Math.floor(Math.random() * 4) + 8; // 8-11 (Sept-Dec)
        alternateDateObj1.setFullYear(year - 1);
      } else { // Don't use July/August (6-7)
        // Use a random month from the same school year
        const possibleMonths = [0, 1, 2, 3, 4, 5, 8, 9, 10, 11]; // Jan-June, Sept-Dec
        // Filter out the current month
        const filteredMonths = possibleMonths.filter(m => m !== month);
        alternateMonth1 = filteredMonths[Math.floor(Math.random() * filteredMonths.length)];
      }
      alternateDateObj1.setMonth(alternateMonth1);
      
      // Option 2: Same month, different day (avoid weekends)
      const alternateDateObj2 = new Date(correctDateObj);
      let alternateDay2 = day;
      // Keep trying until we get a different day that's not a weekend
      let attempts = 0;
      while ((alternateDay2 === day || isWeekend(alternateDateObj2)) && attempts < 15) {
        attempts++;
        // Get a random day between 1 and last day of month
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        alternateDay2 = Math.floor(Math.random() * lastDayOfMonth) + 1;
        alternateDateObj2.setDate(alternateDay2);
      }
      
      // Option 3: Different year (previous year)
      const alternateDateObj3 = new Date(correctDateObj);
      alternateDateObj3.setFullYear(year - 1);
      
      // Format all options consistently
      const option1 = format(alternateDateObj1, "MMMM d, yyyy");
      const option2 = format(alternateDateObj2, "MMMM d, yyyy");
      const option3 = format(alternateDateObj3, "MMMM d, yyyy");
      
      // Only add if they're different from the correct answer
      if (option1 !== correctFormatted) options.push(option1);
      if (option2 !== correctFormatted && option2 !== option1) options.push(option2);
      if (option3 !== correctFormatted && option3 !== option1 && option3 !== option2) options.push(option3);
      
      // If we still need more options, generate random ones
      while (options.length < 4) {
        // Generate a random date within 1 year before/after
        const randomYearOffset = Math.floor(Math.random() * 2) - 1; // -1 or 0
        const randomMonthOffset = Math.floor(Math.random() * 12);
        const randomDayOffset = Math.floor(Math.random() * 28);
        
        const randomDate = new Date(year + randomYearOffset, 
                                   (month + randomMonthOffset) % 12, 
                                   Math.min(day + randomDayOffset, 28));
        
        // Skip weekends and summer months
        if (isWeekend(randomDate) || randomDate.getMonth() === 6 || randomDate.getMonth() === 7) continue;
        
        const formatted = format(randomDate, "MMMM d, yyyy");
        
        // Avoid duplicates
        if (!options.includes(formatted)) {
          options.push(formatted);
        }
      }
      
      // Shuffle the options
      return options.sort(() => Math.random() - 0.5);
    } catch (error) {
      console.error("Error generating date options:", error);
      // Return some default options with consistent formatting
      return [
        "August 20, 2021",
        "June 15, 2021", 
        "September 5, 2021", 
        "March 12, 2022"
      ].sort(() => Math.random() - 0.5);
    }
  }, []);
  
  // Generate questions function with safety checks
  const generateQuestions = useCallback((teacher) => {
    if (!teacher) return;

    // Extract first session date - handle if it's an array
    let firstSessionDate = teacher["First Session Date"];
    if (Array.isArray(firstSessionDate) && firstSessionDate.length > 0) {
      firstSessionDate = firstSessionDate[0];
    }

    // Ensure all fields have proper defaults
    const questions = [
      {
        question: "What was the date of your first Connected North Session?",
        correctAnswer: firstSessionDate 
          ? formatAirtableDate(firstSessionDate) 
          : "You haven't participated yet",
        generateAnswers: () => generateDateOptions(firstSessionDate, teacher["Date Added"])
      },
      {
        question: "How many unique Connected North Content Providers have you booked or completed Sessions with?",
        correctAnswer: teacher["Unique Providers"] ? teacher["Unique Providers"].toString() : "0",
        generateAnswers: () => generateUniqueProviderOptions(teacher["Unique Providers"])
      },
      {
        question: "How many Connected North Sessions have you completed in total?",
        correctAnswer: teacher["# Past Completed Sessions"] ? teacher["# Past Completed Sessions"].toString() : "0",
        generateAnswers: () => generateUniqueProviderOptions(teacher["# Past Completed Sessions"])
      },
      {
        question: "How many kilometers of distance have been virtually covered by your completed Sessions?",
        correctAnswer: teacher["All-time Distance"] 
          ? `${Math.round(Number(teacher["All-time Distance"])).toLocaleString()} km` 
          : "0 km",
        generateAnswers: () => generateDistanceOptions(teacher["All-time Distance"])
      },
      {
        question: "How many other Teachers at your school have booked Connected North Sessions this year?",
        correctAnswer: teacher["School Booking Teachers"] ? teacher["School Booking Teachers"].toString() : "0",
        generateAnswers: () => generateUniqueProviderOptions(teacher["School Booking Teachers"])
      }
    ];

    setQuestionData(questions);
    
    // Generate answers for the first question with safety check
    if (questions.length > 0) {
      try {
        const generatedAnswers = questions[0].generateAnswers();
        setAnswers(generatedAnswers);
      } catch (error) {
        console.error("Error generating answers:", error);
        setAnswers(["Option 1", "Option 2", "Option 3", "Option 4"]);
      }
    }
  }, [generateDateOptions, generateDistanceOptions, generateUniqueProviderOptions]);
  
  // Handle time expired
  const handleTimeExpired = useCallback(() => {
    // Time's up - show feedback that they ran out of time
    setShowingFeedback(true);
    setLastAnswerCorrect(false);
  }, []);
  
  // Fetch teacher data and initialize questions
  useEffect(() => {
    if (!isOpen) return;
    
    const fetchTeacherData = async () => {
      try {
        if (!userInfo || !userInfo.id) {
          setLoading(false);
          return;
        }
        
        const teacher = await airtable.teachers.getTeacherByRecordId(userInfo.id);
        if (!teacher) {
          setLoading(false);
          return;
        }
        
        setTeacherData(teacher);
        
        // Check if teacher has already played
        const engagement = teacher["Engagement"] || [];
        if (engagement.includes("Played 2025 Trivia Game")) {
          setAlreadyPlayed(true);
        }
        
        // Check if teacher has at least 3 completed sessions
        const completedSessions = parseInt(teacher["# Past Completed Sessions"] || 0);
        if (completedSessions < 3) {
          setInsufficientSessions(true);
        }
        
        // Generate questions once teacher data is loaded
        generateQuestions(teacher);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
        setLoading(false);
      }
    };

    fetchTeacherData();
    
    // Clear timer when modal closes
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isOpen, userInfo, generateQuestions]);
  
  // Timer effect with safety checks
  useEffect(() => {
    // Only run when modal is open
    if (!isOpen) return;
    
    // Don't run timer in these cases
    if (submitted || loading || showingFeedback || showIntro || alreadyPlayed) {
      return;
    }
    
    // Clean up any existing timer first
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    // Reset timer
    setTimeLeft(30);
    
    // Start a new timer
    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          handleTimeExpired();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    
    // Clean up on unmount or when dependencies change
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isOpen, currentQuestion, submitted, loading, showingFeedback, showIntro, alreadyPlayed, handleTimeExpired]);

  // Handle selecting an answer
  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
  };
  
  // Handle using a hint
  const useHint = () => {
    // Can only use hint once per quiz
    if (hintUsed) return;
    
    // Mark hint as used
    setHintUsed(true);
    
    try {
      // Get the correct answer for current question
      const correctAnswer = questionData[currentQuestion]?.correctAnswer;
      if (!correctAnswer) return;
      
      // Find wrong answers to eliminate
      let wrongAnswers = answers.filter(answer => answer !== correctAnswer);
      
      // If we have enough wrong answers, eliminate some
      if (wrongAnswers.length > 0) {
        // If we have three wrong answers, randomly remove one from consideration
        if (wrongAnswers.length > 2) {
          const randomIndex = Math.floor(Math.random() * wrongAnswers.length);
          wrongAnswers.splice(randomIndex, 1);
        }
        
        // Set the eliminated answers
        setEliminatedAnswers(wrongAnswers.slice(0, Math.min(2, wrongAnswers.length)));
      }
    } catch (error) {
      console.error("Error using hint:", error);
    }
  };

  // Award credit for correct answer
  const awardCredit = async (question) => {
    try {
      if (!userInfo || !userInfo.id) return;
      
      // Create a credit record in the Credits table
      await airtable.credit.create({
        "Teacher": [userInfo.id],
        "Credits": 1,
        "Action": "Credits Earned from Achievement",
        "Display Note": `Trivia Question Correct: ${question}`
      });
    } catch (error) {
      console.error("Error awarding credit:", error);
    }
  };

  // Trigger confetti celebration
  const triggerConfetti = () => {
    try {
      const duration = 5 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 9999 };

      // Create an interval to launch confetti repeatedly
      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        
        // Launch confetti from both sides
        confetti({
          ...defaults,
          particleCount,
          origin: { x: Math.random(), y: Math.random() - 0.2 }
        });
        
        confetti({
          ...defaults,
          particleCount,
          origin: { x: Math.random(), y: Math.random() - 0.2 }
        });
      }, 250);
    } catch (error) {
      console.error("Error triggering confetti:", error);
    }
  };

  // Award participation credit
  const awardParticipationCredit = async () => {
    // Award a participation credit if they didn't get any answers correct
    if (correctAnswers === 0) {
      try {
        if (!userInfo || !userInfo.id) return;
        
        // Create a credit record in the Credits table
        await airtable.credit.create({
          "Teacher": [userInfo.id],
          "Credits": 1,
          "Action": "Credits Earned from Achievement",
          "Display Note": "Trivia participation"
        });
      } catch (error) {
        console.error("Error awarding participation credit:", error);
      }
    }
  };

  // Move to next question
  const moveToNextQuestion = async () => {
    // Hide feedback
    setShowingFeedback(false);
    
    // Clear eliminated answers from hint
    setEliminatedAnswers([]);
    
    // Move to next question or complete quiz
    if (currentQuestion < questionData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null);
      
      // Generate answers for the next question with safety check
      try {
        const nextQuestionIndex = currentQuestion + 1;
        if (questionData[nextQuestionIndex]) {
          const generatedAnswers = questionData[nextQuestionIndex].generateAnswers();
          setAnswers(generatedAnswers);
        }
      } catch (error) {
        console.error("Error generating answers for next question:", error);
        setAnswers(["Option 1", "Option 2", "Option 3", "Option 4"]);
      }
      
      // Update the teacher's Engagement field
      if (currentQuestion === 0 && teacherData) {
        try {
          // Get current engagement values
          const currentEngagement = teacherData["Engagement"] || [];
          
          // Only add if not already present
          if (!currentEngagement.includes("Played 2025 Trivia Game")) {
            await airtable.teachers.update(userInfo.id, {
              "Engagement": [...currentEngagement, "Played 2025 Trivia Game"]
            });
          }
        } catch (error) {
          console.error("Error updating teacher engagement:", error);
        }
      }
    } else {
      // Quiz complete - award participation credit if needed
      await awardParticipationCredit();
      
      // Mark as submitted to show completion screen
      setSubmitted(true);
      
      // Trigger confetti celebration if they got at least 3 out of 5 correct
      if (correctAnswers >= 3 && questionData.length >= 4) {
        // Use setTimeout to ensure the DOM is updated before triggering confetti
        setTimeout(() => {
          triggerConfetti();
        }, 300);
      }
      
      if (onComplete) {
        onComplete();
      }
    }
  };
  
  // Handle submitting an answer
  const handleNext = async () => {
    // Clear the timer when user submits an answer
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    // If no answer selected (time expired), just show feedback
    if (selectedAnswer === null) {
      handleTimeExpired();
      return;
    }
    
    // Check if answer is correct with safety check
    let isCorrect = false;
    let currentQuestionText = "";
    
    if (questionData[currentQuestion]) {
      currentQuestionText = questionData[currentQuestion].question || "";
      const correctAnswer = questionData[currentQuestion].correctAnswer;
      isCorrect = selectedAnswer === correctAnswer;
    }
    
    // Set feedback state
    setLastAnswerCorrect(isCorrect);
    setShowingFeedback(true);
    
    if (isCorrect) {
      setCorrectAnswers(correctAnswers + 1);
      
      // Award credit immediately for this correct answer
      await awardCredit(currentQuestionText);
    }
  };
  
  // Get custom feedback message for specific questions
  const getFeedbackMessage = () => {
    if (!questionData[currentQuestion]) {
      return lastAnswerCorrect ? 'Great job!' : selectedAnswer ? `You answered: ${selectedAnswer}` : 'You ran out of time!';
    }
    
    // Special message for the school teachers question
    if (questionData[currentQuestion].question === "How many other Teachers at your school have booked Connected North Sessions this year?") {
      return "Please spread the word and encourage others to book a Session!";
    }
    
    // Default messages
    return lastAnswerCorrect ? 'Great job!' : selectedAnswer ? `You answered: ${selectedAnswer}` : 'You ran out of time!';
  };

  // Start the game
  const startGame = () => {
    setShowIntro(false);
  };

  // Display loading state
  if (loading) {
    return (
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader toggle={toggle}>Connected North Trivia Challenge</ModalHeader>
        <ModalBody className="text-center p-5">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-3">Loading your trivia challenge...</p>
        </ModalBody>
      </Modal>
    );
  }

  // Main render
  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalHeader toggle={toggle}>2025 Connected North Trivia Challenge</ModalHeader>
      <ModalBody>
        {alreadyPlayed ? (
          // Already played message
          <div className="text-center py-5">
            <div className="display-4 mb-4">🎮</div>
            <h3>Thanks for playing our 2025 Trivia Game!</h3>
            <p className="lead mt-4">
              Keep your eyes out for fun ways to earn additional Credits in the future.
            </p>
          </div>
        ) : insufficientSessions ? (
          // Not enough sessions message
          <div className="text-center py-5">
            <div className="display-4 mb-4">📚</div>
            <h3>Almost there!</h3>
            <div className="alert alert-info my-4">
              <p className="mb-0">
                Please book and complete at least 3 Connected North Sessions and come back and try Trivia then! 
                Otherwise the questions will be a bit too easy.
              </p>
            </div>
            <p className="lead mt-4">
              We look forward to seeing you participate in more Connected North sessions soon!
            </p>
          </div>
        ) : showIntro ? (
          // Intro screen
          <div className="text-center py-4">
            <h3>2025 Connected North Trivia Challenge</h3>
            <div className="my-4">
              <p>Thanks for joining us for this fun Spring activity!</p>
              <p className="mt-3">
                In the following screens, you'll be presented with questions about 
                your engagement in the Connected North program, with 30 seconds to answer each.
              </p>
              <p className="mt-3">
                For each question correct, you'll earn one bonus booking Credit!
              </p>
              <p className="mt-3 font-weight-bold">
                You can only play once. Ready to start?
              </p>
              
              {isMobile && (
                <div className="alert alert-warning mt-4">
                  <div className="d-flex align-items-center mb-2">
                    <i className="fa fa-exclamation-triangle mr-2" style={{ fontSize: '1.2rem', marginRight: '8px' }}></i>
                    <strong>Mobile Device Detected</strong>
                  </div>
                  <p className="mb-0">
                    For the best trivia experience, we recommend playing on a desktop computer or laptop.
                    The game will still work, but some elements may not display optimally on smaller screens.
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : !submitted ? (
          showingFeedback ? (
            // Feedback screen after answering
            <div className="text-center py-3">
              <h3>{lastAnswerCorrect ? '✅ Correct!' : '❌ Incorrect'}</h3>
              
              {!lastAnswerCorrect && (
                <div className="my-4">
                  <p>The correct answer was:</p>
                  <div className="alert alert-info py-4">
                    <div className="correct-answer" style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>
                      {questionData[currentQuestion]?.correctAnswer || "No answer available"}
                    </div>
                  </div>
                </div>
              )}
              
              {lastAnswerCorrect && (
                <div className="alert alert-success my-4">
                  <p>You earned 1 bonus credit!</p>
                </div>
              )}
              
              <p className="lead">
                {getFeedbackMessage()}
              </p>
            </div>
          ) : (
            // Question screen
            <>
              <div className="mb-4">
                <Progress value={(currentQuestion / Math.max(1, questionData.length)) * 100} />
                <div className="d-flex justify-content-between mt-2">
                  <small>Question {currentQuestion + 1} of {questionData.length}</small>
                  <small>{correctAnswers} Correct</small>
                </div>
                
                {/* Timer */}
                <div className="mt-2 text-center">
                  <div className={`badge ${timeLeft > 10 ? 'bg-success' : timeLeft > 5 ? 'bg-warning' : 'bg-danger'}`} style={{ fontSize: '1rem', padding: '8px 12px' }}>
                    Time remaining: {timeLeft} seconds
                  </div>
                  <Progress value={(timeLeft / 30) * 100} color={timeLeft > 10 ? 'success' : timeLeft > 5 ? 'warning' : 'danger'} className="mt-2" />
                </div>
              </div>
              
              <Card className="mb-4">
                <CardBody>
                  <h4>{questionData[currentQuestion]?.question || "Loading question..."}</h4>
                  
                  <div className="answer-grid mt-4">
                    <div className="row">
                      {answers.map((answer, index) => {
                        const isEliminated = eliminatedAnswers.includes(answer);
                        // Define colors for each answer box (Kahoot-style)
                        const colors = ['primary', 'success', 'danger', 'warning'];
                        const color = colors[index % colors.length];
                        
                        return (
                          <div className={`${isMobile ? 'col-12' : 'col-md-6'} mb-3`} key={index}>
                            <div 
                              className={`card answer-card ${selectedAnswer === answer ? 'selected' : ''} ${isEliminated ? 'eliminated' : ''}`}
                              onClick={() => !isEliminated && handleAnswerSelect(answer)}
                              style={{
                                backgroundColor: selectedAnswer === answer ? 
                                  'rgb(17, 205, 239)' : // Connected North blue when selected
                                  (isEliminated ? '#f8f9fa' : `var(--bs-${color})`),
                                color: '#fff',
                                cursor: isEliminated ? 'not-allowed' : 'pointer',
                                padding: isMobile ? '25px 15px' : '20px 15px',
                                borderRadius: '8px',
                                height: '100%',
                                textAlign: 'center',
                                transition: 'all 0.2s ease-in-out',
                                transform: selectedAnswer === answer ? 'scale(0.98)' : 'scale(1)',
                                opacity: isEliminated ? 0.5 : 1,
                                boxShadow: selectedAnswer === answer ? 
                                  '0 0 0 6px rgba(255,255,255,0.6) inset, 0 0 20px rgba(0,0,0,0.3)' : 
                                  '0 4px 8px rgba(0,0,0,0.1)',
                                border: selectedAnswer === answer ? '3px solid #fff' : 'none',
                                position: 'relative'
                              }}
                            >
                              <div className="card-body d-flex justify-content-center align-items-center">
                                <h5 className="card-title mb-0">
                                  {answer} {isEliminated && '(eliminated)'}
                                </h5>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  
                  <style>
                    {`
                      .answer-card:not(.eliminated):hover {
                        transform: scale(0.98);
                        box-shadow: 0 0 0 4px rgba(255,255,255,0.4) inset, 0 4px 10px rgba(0,0,0,0.2);
                        border: 2px solid #fff;
                        background-color: rgba(17, 205, 239, 0.85) !important; /* Connected North blue with slight transparency on hover */
                      }
                      .card-title {
                        text-shadow: 1px 1px 2px rgba(0,0,0,0.3);
                        font-weight: 600;
                        word-break: break-word;
                        font-size: 1.4rem;
                      }
                      .answer-card.selected:after {
                        content: '✓';
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        font-size: 1.5rem;
                        font-weight: bold;
                      }
                      .answer-card.selected {
                        background-color: rgb(17, 205, 239) !important; /* Connected North blue */
                      }
                      .answer-card.selected .card-title {
                        color: white;
                        font-weight: 700;
                      }
                    `}
                  </style>
                  
                  {/* Hint button */}
                  <div className="mt-4 text-center">
                    <Button 
                      color="info" 
                      size="sm"
                      onClick={useHint}
                      disabled={hintUsed}
                      className="mb-2"
                    >
                      {hintUsed ? 'Hint Used' : 'Use Hint (Eliminates 2 Wrong Answers)'}
                    </Button>
                    {hintUsed && eliminatedAnswers.length === 0 && (
                      <div className="small text-muted">You already used your hint on a previous question.</div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </>
          )
        ) : (
          // Quiz completion screen
          <div className="text-center py-4">
            <h3>That's a wrap!</h3>
            
            <div className="results-summary my-4">
              <div className="score-display" style={{ 
                fontSize: '2.5rem', 
                fontWeight: 'bold',
                margin: '20px 0',
                color: correctAnswers > 0 ? '#28a745' : '#6c757d'
              }}>
                {correctAnswers} / {questionData.length}
              </div>
              
              <div className="progress mb-4" style={{ height: '25px', borderRadius: '15px' }}>
                <div 
                  className="progress-bar bg-success" 
                  role="progressbar" 
                  style={{ 
                    width: `${(correctAnswers / Math.max(1, questionData.length)) * 100}%`,
                    fontSize: '1rem',
                    fontWeight: 'bold'
                  }} 
                  aria-valuenow={correctAnswers} 
                  aria-valuemin="0" 
                  aria-valuemax={questionData.length}
                >
                  {Math.round((correctAnswers / Math.max(1, questionData.length)) * 100)}%
                </div>
              </div>
            </div>
            
            {correctAnswers > 0 ? (
              <div className="alert alert-success">
                <h4>Congratulations!</h4>
                <p>You've earned {correctAnswers} bonus credit{correctAnswers > 1 ? 's' : ''}!</p>
                <small className="d-block mt-2">Credits have been added to your account for each correct answer.</small>
              </div>
            ) : (
              <div className="alert alert-info">
                <h4>Thanks for participating!</h4>
                <p>You've earned 1 bonus credit for participating in our 2025 Trivia Game!</p>
              </div>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {alreadyPlayed || insufficientSessions ? (
          // Close button for already played or insufficient sessions message
          <Button color="primary" onClick={toggle}>Close</Button>
        ) : showIntro ? (
          // Start button for intro screen
          <Button color="primary" onClick={startGame}>Play Trivia</Button>
        ) : !submitted ? (
          showingFeedback ? (
            // Continue button after showing feedback
            <Button 
              color="primary" 
              onClick={moveToNextQuestion}
            >
              Continue
            </Button>
          ) : (
            // Submit answer button
            <Button 
              color="primary" 
              onClick={handleNext} 
              disabled={selectedAnswer === null}
            >
              {selectedAnswer === null ? `Waiting for your answer (${timeLeft}s)` : 'Submit Answer'}
            </Button>
          )
        ) : (
          // Just a close button - no try again option
          <Button color="primary" onClick={toggle}>Close</Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TriviaModal;
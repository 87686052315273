import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewUserIcon = () => (
    <SvgIcon  width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
            <path d="M16.2169 18.1957C16.0602 18.1732 15.9012 18.157 15.741 18.1438C13.9534 17.9951 12.2561 18.5114 10.9771 19.4691C12.1224 19.4671 13.2723 19.6189 14.3654 19.9581C16.5984 20.6531 18.5434 22.1122 19.6933 23.956C20.3849 25.0691 20.7821 26.3094 20.8693 27.5608C21.4684 26.812 21.8585 25.9356 21.9949 24.9836C22.4565 21.7635 19.8648 18.7186 16.2169 18.1957Z" fill="#9DE0F8"/>
            <path d="M15.0364 4.1687C17.8236 4.1687 20.1709 6.44253 20.1709 9.3032C20.1709 12.0905 17.8237 14.4377 15.0364 14.4377C12.1756 14.4377 9.82861 12.0905 9.82861 9.3032C9.82861 6.44245 12.1758 4.1687 15.0364 4.1687ZM15.0364 5.56235C12.9092 5.56235 11.2221 7.17603 11.2221 9.3031C11.2221 11.3569 12.9091 13.0439 15.0364 13.0439C17.0902 13.0439 18.7771 11.3569 18.7771 9.3031C18.7771 7.17595 17.0901 5.56235 15.0364 5.56235Z" fill="currentColor"/>
            <path d="M15.0367 0.208008C23.252 0.208008 30 6.95626 30 15.2448C30 23.46 23.2517 30.208 15.0367 30.208C6.74825 30.208 0 23.4598 0 15.2448C0 6.95626 6.74825 0.208008 15.0367 0.208008ZM15.0367 16.0515C20.0245 16.0515 24.132 19.939 24.5723 24.8535C27.0661 22.3597 28.6065 18.9855 28.6065 15.2448C28.6065 7.68976 22.5185 1.60176 15.0367 1.60176C7.48175 1.60176 1.39375 7.68976 1.39375 15.2448C1.39375 18.9855 2.93407 22.3598 5.428 24.8535C5.86812 19.939 9.97575 16.0515 15.0367 16.0515ZM23.1785 26.027C23.3985 21.3328 19.731 17.445 15.0367 17.445C10.269 17.445 6.6015 21.3325 6.8215 26.027C9.09532 27.7874 11.956 28.8143 15.0367 28.8143C18.044 28.8143 20.9048 27.7874 23.1785 26.027Z" fill="currentColor"/>
        </svg>
  </SvgIcon>
)

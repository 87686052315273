const getDayRestrictionConfig = (supply) => {
  if (!supply) return 0;

  // Default Quantity Remaining to 0 if undefined or empty
  const quantityRemaining = (supply["Quantity Remaining"] || 0) - (supply["Wishlist Student Quantity"] || 0);

  if (supply["Essential?"] === "Yes") {
    if (
      (supply.Status === "Sioux Lookout Storage" ||
        supply.Status === "Ottawa Storage" ||
        supply.Status === "Toronto Office Storage")
    ) {
      // Apply 30-day restriction if conditions are met
      if (
        (supply["Supplies Type"] === "Kit per Class" && quantityRemaining !== 0) ||
        (supply["Supplies Type"] === "Kit per Group" && quantityRemaining > 6) ||
        ((!supply["Supplies Type"] || supply["Supplies Type"] === "Kit per Student") && quantityRemaining >= 20)
      ) {
        return 30;
      }
      // Otherwise, apply a 90-day restriction
      return 60;
    }
    // If it doesn't match any storage location, apply a default 30-day restriction
    return 30;
  }

  return 0;
};

export const calcDayRestriction = (
  userType,
  provider,
  supplies,
  suppliesOption,
  userInfo
) => {
  if (userType === "Team" && (!supplies || supplies.length === 0)) {
    return (provider["Advance Booking Requirement (Days)"] || 0)
  }

  if (suppliesOption.startsWith('not-required')) {
    // If userInfo is provided and School P/T Text is not NU, return 7 days instead of 14
    if (userInfo && userInfo["School P/T Text"] !== "NU" && provider["Autobook"]) {
      return 7;
    }
    return 14;
  }
  if (!supplies || supplies.length === 0) {
    // If userInfo is provided and School P/T Text is not NU, return 7 days instead of 14
    if (userInfo && userInfo["School P/T Text"] !== "NU" && provider["Autobook"]) {
      return 7;
    } else {
      return getDayRestrictionConfig(null);
    }
  }

  return getDayRestrictionConfig(supplies[0])
}

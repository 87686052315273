import { SignJWT } from "jose";

const JWT_SECRET = "1f4be7c42abf58d25dc78f939a2c4f35b7b5270f60bcbaa3edd4455bc5f9da77"; 

export const generateAuthToken = async (userId, userType) => {
  try {
    const secret = new TextEncoder().encode(JWT_SECRET); // Convert secret to Uint8Array

    return await new SignJWT({ user_id: userId, table: userType }) // Payload
      .setProtectedHeader({ alg: "HS256" }) // Algorithm
      .setExpirationTime("5m") // Token expires in 5 minutes
      .sign(secret); // Sign with secret
  } catch (error) {
    console.error("JWT Generation Error:", error);
    return null;
  }
};
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { getNotificationSetting, updateNotificationSetting } from "../../libs/apis";
import { hideLoading, showLoading } from "../../utils/loading";

const defaultItems = [
  { label: "1 Week", hours: 168 },
  { label: "2 days", hours: 48 },
  { label: "a day", hours: 24 },
  { label: "an hour", hours: 1 },
]

const NotificationOptions = () => {
  const [items, setItems] = useState([]);
  const { userInfo } = useSelector((state) => state.appInfo);

  const applySetting = (setting) => {
    if (setting === "none") {
      setItems(defaultItems.map(item => ({
        ...item,
        checked: false
      })))
    } else {
      const is = defaultItems.map(item => {
        if (setting.indexOf(item.hours) >= 0) {
          return ({
            ...item,
            checked: true
          })
        } else {
          return ({
            ...item,
            checked: false
          })
        }
      });
      setItems(is);
    }
  }

  useEffect(() => {
    getNotificationSetting(userInfo.id).then(applySetting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleItem = (no) => {
    showLoading("Applying...");
    const is = [...items];
    is[no].checked = !is[no].checked;
    const iss = is.filter(item => item.checked).map(item => item.hours);
    updateNotificationSetting(userInfo.id, iss.length ? iss : "none")
      .then((setting) => {
        applySetting(setting);
        hideLoading();
      });
  }

  return (
    <div className="notification-time-options">
      <FormGroup>
        {items.map((item, i) => (
          <FormControlLabel
            key={i}
            control={<Checkbox checked={item.checked} onChange={() => toggleItem(i)} />}
            label={item.label}
          />
        ))}
      </FormGroup>
    </div>
  )
}

export default NotificationOptions;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormGroup, Tooltip } from "reactstrap";
import Select from 'react-select';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import { toast } from 'react-toastify';
import "./styles.scss";
import "./styles-override.scss";
import { cnSupabase } from '../../database';
import airtable from '../../airtables';

// Configure dayjs to use Monday as the first day of the week
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1 // Monday as first day of week (0 is Sunday)
});

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const weekDaysMobile = ["M", "T", "W", "T", "F"];

const TeamViewerScheduler = () => {
  const { userInfo, totalTimezones } = useSelector(state => state.appInfo);
  
  // State for week selection - always start at current week (Monday)
  const [currentWeekStart, setCurrentWeekStart] = useState(dayjs().weekday(0)); // 0 = Monday with weekday plugin
  
  // Track if we're at the current week (to disable previous button)
  const [isCurrentWeek, setIsCurrentWeek] = useState(true);
  const [weekDates, setWeekDates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Session host states
  const [sessionHosts, setSessionHosts] = useState([]);
  const [selectedHost, setSelectedHost] = useState({ value: 'all', label: 'All Hosts' });
  const [hostAvailabilities, setHostAvailabilities] = useState({});
  const [aggregateAvailability, setAggregateAvailability] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [hostBookings, setHostBookings] = useState({});
  const [hostProviderBookings, setHostProviderBookings] = useState({});

  // Determine if the logged-in user is a team viewer
  const isTeamViewer = useMemo(() => 
    userInfo && userInfo['Status'] !== 'Session Host' && !userInfo['Primary Session Host'], 
    [userInfo]
  );

  // Generate week dates from start date and check if it's the current week
  useEffect(() => {
    const dates = [];
    // Ensure we're starting from Monday regardless of what day currentWeekStart is
    const start = currentWeekStart.weekday(0); // 0 = Monday with weekday plugin
    
    for (let i = 0; i < 5; i++) {
      dates.push(start.add(i, 'day'));
    }
    
    setWeekDates(dates);
    
    // Check if we're at the current week
    const currentWeek = dayjs().weekday(0); // 0 = Monday with weekday plugin
    setIsCurrentWeek(currentWeekStart.isSame(currentWeek, 'day'));
  }, [currentWeekStart]);

  // Move to previous week, but not before current week
  const goToPreviousWeek = async () => {
    const currentWeek = dayjs().weekday(0); // 0 = Monday with weekday plugin
    setCurrentWeekStart(prev => {
      // If the previous week would be before current week, don't navigate back
      const previousWeek = prev.subtract(1, 'week');
      if (previousWeek.isBefore(currentWeek)) {
        return currentWeek;
      }
      return previousWeek;
    });
  };

  // Move to next week
  const goToNextWeek = async () => {
    setCurrentWeekStart(prev => prev.add(1, 'week'));
  };

  // Format date for database storage
  const formatDateForDB = (date) => {
    return date.format('YYYY-MM-DD');
  };

  // Helper function to convert time zones
  const convertToEastern = (hour, timezone) => {
    if (timezone === 'America/Toronto') return hour;
    const date = DateTime.now().setZone(timezone).set({ hour, minute: 0, second: 0, millisecond: 0 });
    return date.setZone('America/Toronto').hour;
  };

  // Toggle tooltip
  const toggleTooltip = (id) => {
    setTooltipOpen(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Fetch all session hosts
  const fetchSessionHosts = useCallback(async () => {
    if (!isTeamViewer) return;
    
    try {
      // Fetch both contract and primary session hosts
      const contractHosts = await airtable.teams.getContractSessionHosts();
      const primaryHosts = await airtable.teams.getPrimarySessionHost();
      
      // Combine and deduplicate hosts
      const allHosts = [...contractHosts];
      primaryHosts.forEach(primaryHost => {
        if (!allHosts.some(host => host.id === primaryHost.id)) {
          allHosts.push(primaryHost);
        }
      });
      
      // Filter out hosts with 'Supervisor' in their name
      const filteredHosts = allHosts.filter(host => !host.Name.includes('Supervisor'));
      
      setSessionHosts(filteredHosts);
    } catch (error) {
      console.error("Error fetching session hosts:", error);
      toast.error("Unable to fetch session hosts. Please try again.");
    }
  }, [isTeamViewer]);

  // Fetch host bookings (when they are assigned to sessions or presenting as providers)
  const fetchHostBookings = useCallback(async () => {
    const hostBookings = {};
    const hostProviderBookings = {};
    
    // Only proceed if we have session hosts and dates
    if (!sessionHosts.length || !weekDates.length) {
      return { hostBookings, hostProviderBookings };
    }
    
    try {
      // Build map of host IDs to provider IDs
      const hostToProviderMap = {};
      
      // Get provider information for hosts who are also providers
      for (const host of sessionHosts) {
        const userData = await airtable.teams.select(host.id);
        if (userData["Also a Provider?"] && userData["Also a Provider?"].length > 0) {
          // This host is also a provider - store their provider ID
          hostToProviderMap[host.id] = userData["Also a Provider?"][0];
        }
      }
      
      // Fetch bookings for each day in the week
      for (const date of weekDates) {
        const formattedDate = date.format('YYYY-MM-DD');
        
        // Fetch sessions for this date
        const filterFormula = `AND(
          DATETIME_FORMAT({Session Start Date/Time}, 'YYYY-MM-DD') = '${formattedDate}',
          OR(Status = 'Booked', Status = 'Completed')
        )`;
        
        const sessions = await airtable.sessions.list({ filterByFormula: filterFormula });
        
        // Process sessions
        sessions.forEach(session => {
          // Get session details
          const startTime = new Date(session["Session Start Date/Time"]);
          const startHour = startTime.getHours();
          
          // Only process if the session is during working hours (8am-6pm)
          if (startHour < 8 || startHour > 18) return;
          
          // Calculate session duration and end time
          const sessionDuration = parseInt(session["Length (Minutes)"]) || 60;
          const endTime = new Date(startTime.getTime() + sessionDuration * 60000);
          const endHour = endTime.getHours();
          
          // Format times for display
          const startHourDisplay = startTime.getHours();
          const startMinuteDisplay = startTime.getMinutes();
          const startAmPm = startHourDisplay >= 12 ? 'PM' : 'AM';
          const formattedStartTime = `${startHourDisplay % 12 || 12}:${startMinuteDisplay.toString().padStart(2, '0')}`;
          
          const endHourDisplay = endTime.getHours();
          const endMinuteDisplay = endTime.getMinutes();
          const endAmPm = endHourDisplay >= 12 ? 'PM' : 'AM';
          const formattedEndTime = `${endHourDisplay % 12 || 12}:${endMinuteDisplay.toString().padStart(2, '0')}`;
          
          const formattedTimeRange = startAmPm === endAmPm
            ? `${formattedStartTime}-${formattedEndTime} ${endAmPm}`
            : `${formattedStartTime} ${startAmPm}-${formattedEndTime} ${endAmPm}`;
          
          // Create session info object
          const sessionInfo = {
            id: session.id,
            name: session["Session Title Text"] || "Untitled Session",
            provider: session["Provider Name"] || "Unknown Provider",
            teacher: session["Teacher Name"] || "Unknown Teacher",
            school: session["School Name Text"] || "Unknown School",
            startHour,
            endHour,
            timeRange: formattedTimeRange
          };
          
          // First, process host bookings (when hosts are assigned to sessions)
          if (session["Session Host(s)"] && session["Session Host(s)"].length > 0) {
            session["Session Host(s)"].forEach(hostId => {
              if (!hostBookings[hostId]) {
                hostBookings[hostId] = {};
              }
              
              if (!hostBookings[hostId][formattedDate]) {
                hostBookings[hostId][formattedDate] = Array(11).fill(null);
              }
              
              // Add booking to each hour this session spans
              for (let hour = startHour; hour <= endHour; hour++) {
                const hourIndex = hour - 8;
                if (hourIndex < 0 || hourIndex > 10) continue;
                
                // Determine if this is a partial hour
                let isPartial = false;
                let isPartialStart = false;
                let isPartialEnd = false;
                let isPartialSingleHour = false;
                let partialPercentage = 1.0;
                let startPosition = 0;
                
                // Check for single hour session
                if (startHour === endHour) {
                  isPartial = true;
                  isPartialSingleHour = true;
                  const startMinutes = startTime.getMinutes();
                  const endMinutes = endTime.getMinutes() || 60;
                  const occupiedMinutes = endMinutes - startMinutes;
                  partialPercentage = occupiedMinutes / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial start hour
                else if (hour === startHour && startTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialStart = true;
                  const startMinutes = startTime.getMinutes();
                  partialPercentage = (60 - startMinutes) / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial end hour
                else if (hour === endHour && endTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialEnd = true;
                  const endMinutes = endTime.getMinutes();
                  partialPercentage = endMinutes / 60;
                }
                
                // Create booking entry
                hostBookings[hostId][formattedDate][hourIndex] = {
                  ...sessionInfo,
                  isPartial,
                  isPartialStart,
                  isPartialEnd,
                  isPartialSingleHour,
                  partialPercentage,
                  startPosition
                };
              }
            });
          }
          
          // Then, process provider bookings (when hosts are presenting)
          const providerId = session.Provider && session.Provider[0];
          if (providerId) {
            // Find host ID from provider ID
            const hostId = Object.keys(hostToProviderMap).find(
              hid => hostToProviderMap[hid] === providerId
            );
            
            if (hostId) {
              if (!hostProviderBookings[hostId]) {
                hostProviderBookings[hostId] = {};
              }
              
              if (!hostProviderBookings[hostId][formattedDate]) {
                hostProviderBookings[hostId][formattedDate] = Array(11).fill(null);
              }
              
              // Add provider booking to each hour this session spans
              for (let hour = startHour; hour <= endHour; hour++) {
                const hourIndex = hour - 8;
                if (hourIndex < 0 || hourIndex > 10) continue;
                
                // Determine if this is a partial hour
                let isPartial = false;
                let isPartialStart = false;
                let isPartialEnd = false;
                let isPartialSingleHour = false;
                let partialPercentage = 1.0;
                let startPosition = 0;
                
                // Check for single hour session
                if (startHour === endHour) {
                  isPartial = true;
                  isPartialSingleHour = true;
                  const startMinutes = startTime.getMinutes();
                  const endMinutes = endTime.getMinutes() || 60;
                  const occupiedMinutes = endMinutes - startMinutes;
                  partialPercentage = occupiedMinutes / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial start hour
                else if (hour === startHour && startTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialStart = true;
                  const startMinutes = startTime.getMinutes();
                  partialPercentage = (60 - startMinutes) / 60;
                  startPosition = startMinutes / 60;
                }
                // Check for partial end hour
                else if (hour === endHour && endTime.getMinutes() > 0) {
                  isPartial = true;
                  isPartialEnd = true;
                  const endMinutes = endTime.getMinutes();
                  partialPercentage = endMinutes / 60;
                }
                
                // Create provider booking entry (flag as provider session)
                hostProviderBookings[hostId][formattedDate][hourIndex] = {
                  ...sessionInfo,
                  provider: true,
                  isPartial,
                  isPartialStart,
                  isPartialEnd,
                  isPartialSingleHour,
                  partialPercentage,
                  startPosition
                };
              }
            }
          }
        });
      }
      
      return { hostBookings, hostProviderBookings };
    } catch (error) {
      console.error("Error fetching host bookings:", error);
      return { hostBookings, hostProviderBookings };
    }
  }, [sessionHosts, weekDates]);

  // Fetch host availability for the current week
  const fetchHostAvailabilities = useCallback(async () => {
    if (!sessionHosts.length) return;
    
    setIsLoading(true);
    
    try {
      const hostData = {};
      const aggregateData = {};
      
      // Initialize aggregate data structure
      weekDates.forEach((date, dateIndex) => {
        const dateString = formatDateForDB(date);
        aggregateData[dateString] = Array(11).fill(0);
      });
      
      // For each host, fetch their availability
      for (const host of sessionHosts) {
        const userData = await airtable.teams.select(host.id);
        let availabilityRaw = userData["Availability"] ? JSON.parse(userData["Availability"]) : null;
        let timezone = 'America/Toronto';
        
        if (userData["Time Zone"]) {
          const tz = totalTimezones.find(tt => tt.id === userData["Time Zone"][0]);
          if (tz) timezone = tz["Connected North System"];
        }
        
        // Fetch availability from Supabase for all dates in the selected week
        const startDate = formatDateForDB(weekDates[0]);
        const endDate = formatDateForDB(weekDates[weekDates.length - 1]);
        
        const supabaseAvailability = await cnSupabase.hostAvailability.getByHostAndDateRange(
          host.id,
          startDate,
          endDate
        );
        
        // Initialize host data with traditional weekly pattern
        const hostAvailability = {};
        
        weekDates.forEach((date, dateIndex) => {
          const dateString = formatDateForDB(date);
          // Get day of the week (0-6, Sunday-Saturday)
          const dayOfWeek = date.day() === 0 ? 6 : date.day() - 1;
          
          // Default to empty array for this date
          hostAvailability[dateString] = Array(11).fill(0);
          
          // Check if we have specific date availability from Supabase
          const specificDateData = supabaseAvailability.find(record => record.date === dateString);
          
          if (specificDateData) {
            // Use specific date availability from Supabase
            try {
              const availArray = JSON.parse(specificDateData.availability);
              hostAvailability[dateString] = availArray;
              
              // Update aggregate data
              availArray.forEach((value, hourIndex) => {
                // For values 1, 2, 3 (representing half-hour availability)
                if (value === 1 || value === 3) {
                  // First half hour is available
                  aggregateData[dateString][hourIndex]++;
                }
                if (value === 2 || value === 3) {
                  // Second half hour is available
                  aggregateData[dateString][hourIndex]++;
                }
              });
            } catch (e) {
              console.error("Error parsing Supabase JSON:", e);
            }
          } else if (availabilityRaw && dayOfWeek < 5) {
            // Fallback to traditional weekly pattern from Airtable if no specific date data
            const dayAvailability = availabilityRaw[dayOfWeek] || [];
            
            dayAvailability.forEach(hour => {
              const convertedHour = convertToEastern(hour, timezone);
              if (convertedHour >= 8 && convertedHour <= 18) {
                const hourIndex = convertedHour - 8;
                hostAvailability[dateString][hourIndex] = 3; // Mark as fully available
                
                // Update aggregate count for both half hours
                aggregateData[dateString][hourIndex] += 2;
              }
            });
          }
        });
        
        // Add host availability to the collected data
        hostData[host.id] = {
          id: host.id,
          name: host.Name,
          timezone,
          availability: hostAvailability
        };
      }
      
      // Get host bookings for the week
      const bookingsData = await fetchHostBookings();
      
      // Set state with all the collected data
      setHostAvailabilities(hostData);
      setAggregateAvailability(aggregateData);
      setHostBookings(bookingsData.hostBookings);
      setHostProviderBookings(bookingsData.hostProviderBookings);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching host availabilities:", error);
      toast.error("Unable to fetch availability data. Please try again.");
      setIsLoading(false);
    }
  }, [sessionHosts, weekDates, totalTimezones, fetchHostBookings]);

  // Load initial data
  useEffect(() => {
    if (isTeamViewer) {
      fetchSessionHosts();
    }
  }, [isTeamViewer, fetchSessionHosts]);

  // Update availabilities when hosts or week changes
  useEffect(() => {
    if (sessionHosts.length) {
      fetchHostAvailabilities();
    }
  }, [sessionHosts, currentWeekStart, fetchHostAvailabilities]);

  // Format time for display
  const formatTime = (hour) => {
    const adjustedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
    return `${adjustedHour}`;
  };

  // Get color class based on count of available hosts
  const getHeatmapColorClass = (count) => {
    if (count === 0) return 'heat-none';
    if (count <= 2) return 'heat-low';
    if (count <= 4) return 'heat-medium';
    if (count <= 6) return 'heat-high';
    return 'heat-very-high';
  };

  // Handle click on a time block
  const handleHeatmapClick = (date, hour, count) => {
    if (count === 0) {
      toast.info("No hosts available at this time.");
      return;
    }
    
    // Get the hosts available at this time
    const dateString = formatDateForDB(date);
    const availableHosts = [];
    
    Object.keys(hostAvailabilities).forEach(hostId => {
      const host = hostAvailabilities[hostId];
      
      // Check if host is available for this block
      if (host.availability[dateString] && 
          (host.availability[dateString][hour] === 1 || 
           host.availability[dateString][hour] === 2 || 
           host.availability[dateString][hour] === 3)) {
        
        // Check if host is booked for this time
        const isBooked = hostBookings[hostId] && 
                       hostBookings[hostId][dateString] && 
                       hostBookings[hostId][dateString][hour];
                       
        // Check if host is presenting (as provider) at this time
        const isProvider = hostProviderBookings[hostId] && 
                          hostProviderBookings[hostId][dateString] && 
                          hostProviderBookings[hostId][dateString][hour];
        
        // Only add if host is not booked or presenting
        if (!isBooked && !isProvider) {
          availableHosts.push(host.name);
        }
      }
    });
    
    // Show available hosts
    if (availableHosts.length === 0) {
      toast.info("All hosts at this time are booked or presenting their own sessions.");
    } else {
      toast.info(
        <div>
          <strong>Available hosts:</strong>
          <ul className="mb-0 pl-3">
            {availableHosts.map((host, i) => (
              <li key={i}>{host}</li>
            ))}
          </ul>
        </div>,
        { autoClose: 5000 }
      );
    }
  };

  // Get bookings and availability for a specific host
  const getHostData = (hostId, date, hour) => {
    const dateString = formatDateForDB(date);
    
    // Check if host is booked for this time
    const booking = hostBookings[hostId] && 
                   hostBookings[hostId][dateString] && 
                   hostBookings[hostId][dateString][hour];
    
    // Check if host is presenting (as provider) at this time
    const providerBooking = hostProviderBookings[hostId] && 
                            hostProviderBookings[hostId][dateString] && 
                            hostProviderBookings[hostId][dateString][hour];
    
    // Check availability
    const availability = hostAvailabilities[hostId] && 
                        hostAvailabilities[hostId].availability[dateString] && 
                        hostAvailabilities[hostId].availability[dateString][hour];
    
    return { booking, providerBooking, availability };
  };

  // Determine cell class and tooltip for host table cell
  const getHostCellInfo = (hostId, date, hour) => {
    const { booking, providerBooking, availability } = getHostData(hostId, date, hour);
    
    let cellClass = "";
    let tooltipContent = "";
    
    // Priority: Provider Booking > Session Booking > Availability > Not Available
    if (providerBooking) {
      cellClass = "host-provider-booked";
      tooltipContent = `${providerBooking.name} (${providerBooking.provider} is presenting)`;
    } else if (booking) {
      cellClass = "host-booked";
      tooltipContent = `${booking.name} with ${booking.provider} for ${booking.teacher}`;
    } else if (availability === 3) {
      cellClass = "host-available availability-full";
      tooltipContent = "Available (full hour)";
    } else if (availability === 1) {
      cellClass = "host-available availability-first-half";
      tooltipContent = "Available (first half-hour)";
    } else if (availability === 2) {
      cellClass = "host-available availability-second-half";
      tooltipContent = "Available (second half-hour)";
    } else {
      cellClass = "host-not-available";
      tooltipContent = "Not available";
    }
    
    return { cellClass, tooltipContent };
  };

  // Render the aggregate heatmap view
  const renderHeatmap = () => {
    return (
      <div className="availability-grid team-viewer-grid">
        <div className="days-header heatmap-header">
          <div className="time-label"></div>
          {weekDates.map((date, i) => (
            <div key={i} className="day-label">
              <div className="day-name">
                <span className="desktop-day">{weekDays[i]}</span>
                <span className="mobile-day">{weekDaysMobile[i]}</span>
              </div>
              <div className="date">
                <span className="desktop-date">{date.format('MMMM D')}</span>
                <span className="mobile-date">{date.format('MMM D')}</span>
              </div>
            </div>
          ))}
        </div>
        
        <div className="time-slots heatmap-slots">
          {Array.from({ length: 11 }).map((_, hour) => (
            <div key={hour} className="time-row">
              <div className="time-label">
                {formatTime(hour + 8)}
                <span className="am-pm">{hour + 8 >= 12 ? 'PM' : 'AM'}</span>
              </div>
              
              {weekDates.map((date, dayIndex) => {
                const dateString = formatDateForDB(date);
                const count = aggregateAvailability[dateString] ? aggregateAvailability[dateString][hour] : 0;
                const colorClass = getHeatmapColorClass(count);
                const tooltipId = `heatmap-${dayIndex}-${hour}`;
                
                return (
                  <div key={dayIndex} className="heatmap-cell-wrapper">
                    <div
                      id={tooltipId}
                      className={`heatmap-cell ${colorClass}`}
                      onClick={() => handleHeatmapClick(date, hour, count)}
                    >
                      {count > 0 && <span className="host-count">{count}</span>}
                    </div>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[tooltipId]}
                      target={tooltipId}
                      toggle={() => toggleTooltip(tooltipId)}
                    >
                      {count === 1 ? '1 host available' : `${count} hosts available`}
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render the individual host view
  const renderHostView = () => {
    if (!selectedHost || selectedHost.value === 'all') {
      return null;
    }
    
    return (
      <div className="availability-grid individual-host-grid">
        <div className="days-header">
          <div className="time-label"></div>
          {weekDates.map((date, i) => (
            <div key={i} className="day-label">
              <div className="day-name">
                <span className="desktop-day">{weekDays[i]}</span>
                <span className="mobile-day">{weekDaysMobile[i]}</span>
              </div>
              <div className="date">
                <span className="desktop-date">{date.format('MMMM D')}</span>
                <span className="mobile-date">{date.format('MMM D')}</span>
              </div>
            </div>
          ))}
        </div>
        
        <div className="time-slots">
          {Array.from({ length: 11 }).map((_, hour) => (
            <div key={hour} className="time-row">
              <div className="time-label">
                {formatTime(hour + 8)}
                <span className="am-pm">{hour + 8 >= 12 ? 'PM' : 'AM'}</span>
              </div>
              
              {weekDates.map((date, dayIndex) => {
                const { cellClass, tooltipContent } = getHostCellInfo(selectedHost.value, date, hour);
                const tooltipId = `host-${selectedHost.value}-${dayIndex}-${hour}`;
                
                return (
                  <div key={dayIndex} className="host-cell-wrapper">
                    <div
                      id={tooltipId}
                      className={`host-cell ${cellClass}`}
                    >
                    </div>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[tooltipId]}
                      target={tooltipId}
                      toggle={() => toggleTooltip(tooltipId)}
                    >
                      {tooltipContent}
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render the host table view (all hosts)
  const renderHostTable = () => {
    // Only show hosts with availability data
    const hostsWithData = Object.keys(hostAvailabilities)
      .map(hostId => ({
        id: hostId,
        name: hostAvailabilities[hostId].name
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    
    return (
      <div className="host-table-container">
        <h3>Host Availability Details</h3>
        <div className="host-table-scroll">
          <table className="host-table">
            <thead>
              <tr>
                <th>Host</th>
                {weekDates.map((date, dateIndex) => (
                  <th key={dateIndex} colSpan="11">
                    <div className="host-table-date">
                      <span>{weekDays[dateIndex]}</span>
                      <span>{date.format('MMM D')}</span>
                    </div>
                  </th>
                ))}
              </tr>
              <tr className="hour-header">
                <th></th>
                {weekDates.map((date, dateIndex) => (
                  Array.from({ length: 11 }).map((_, hour) => (
                    <th key={`${dateIndex}-${hour}`} className="hour-cell">
                      {hour + 8}
                    </th>
                  ))
                ))}
              </tr>
            </thead>
            <tbody>
              {hostsWithData.map(host => (
                <tr key={host.id}>
                  <td className="host-name">{host.name}</td>
                  {weekDates.map((date, dateIndex) => (
                    Array.from({ length: 11 }).map((_, hour) => {
                      const { cellClass, tooltipContent } = getHostCellInfo(host.id, date, hour);
                      const tooltipId = `table-${host.id}-${dateIndex}-${hour}`;
                      
                      return (
                        <td key={`${dateIndex}-${hour}`} className="host-hour-cell">
                          <div
                            id={tooltipId}
                            className={`host-hour ${cellClass}`}
                          ></div>
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[tooltipId]}
                            target={tooltipId}
                            toggle={() => toggleTooltip(tooltipId)}
                          >
                            {tooltipContent}
                          </Tooltip>
                        </td>
                      );
                    })
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="team-viewer-scheduler">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Session Host Availability Manager</h2>
        <a href="../session-host/availability" className="btn btn-outline-secondary btn-sm">
          Go back to current scheduler
        </a>
      </div>
      
      <p className="text-muted">
        View and manage availability for all session hosts across multiple weeks. Select specific hosts, or view the heat map to see overall availability.
      </p>
      
      <div className="week-selector d-flex justify-content-between align-items-center mb-4">
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={goToPreviousWeek}
          disabled={isCurrentWeek || isLoading}
          title={isCurrentWeek ? "Cannot go before current week" : "Go to previous week"}
        >
          <i className="fa fa-chevron-left" />
        </Button>
        
        <div className="week-display">
          <span>Week of {weekDates[0] ? weekDates[0].format('MMMM D, YYYY') : ''}</span>
          {isCurrentWeek && <span className="current-week-badge ml-2">Current Week</span>}
        </div>
        
        <Button 
          color="primary" 
          outline 
          className="nav-button" 
          onClick={goToNextWeek}
          disabled={isLoading}
        >
          <i className="fa fa-chevron-right" />
        </Button>
      </div>
      
      <div className="host-selector mb-4">
        <FormGroup>
          <label>Select Session Host</label>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={selectedHost}
            onChange={setSelectedHost}
            options={[
              { value: 'all', label: 'All Hosts (Heat Map)' },
              ...sessionHosts.map(host => ({ value: host.id, label: host.Name }))
            ]}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </FormGroup>
      </div>
      
      {isLoading ? (
        <div className="text-center my-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="mt-2">Loading availability data...</p>
        </div>
      ) : (
        <>
          {selectedHost && selectedHost.value === 'all' ? (
            renderHeatmap()
          ) : (
            renderHostView()
          )}
          
          {selectedHost && selectedHost.value === 'all' && renderHostTable()}
        </>
      )}
    </div>
  );
};

export default TeamViewerScheduler;
import React, { useState } from 'react';
import './styles-override.scss';

// A simple test component for the half-hour block functionality
const TestBlock = () => {
  const [value, setValue] = useState(0);
  
  return (
    <div className="container mt-5">
      <h2>Test Half-Hour Block</h2>
      <p>Current value: {value}</p>
      <p>
        0 = none, 1 = top half, 2 = bottom half, 3 = full hour
      </p>
      
      <div 
        className={`time-block ${value === 1 ? 'first-half' : ''} ${value === 2 ? 'second-half' : ''} ${value === 3 ? 'full-hour' : ''}`}
        style={{ width: "200px", height: "100px", margin: "20px auto", border: "1px solid #ddd" }}
      >
        <div 
          className="half-block top-half"
          onClick={() => {
            // Toggle top half
            if (value === 0 || value === 2) {
              // Add top half
              setValue(value + 1);
            } else {
              // Remove top half
              setValue(value - 1);
            }
          }}
          style={{ height: "50%" }}
        />
        <div 
          className="half-block bottom-half"
          onClick={() => {
            // Toggle bottom half
            if (value === 0 || value === 1) {
              // Add bottom half
              setValue(value + 2);
            } else {
              // Remove bottom half
              setValue(value - 2);
            }
          }}
          style={{ height: "50%" }}
        />
      </div>
      
      <button 
        className="btn btn-secondary"
        onClick={() => setValue(0)}
      >
        Reset
      </button>
    </div>
  );
};

export default TestBlock;
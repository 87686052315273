export const dayType = {
  dDay: "D - Department in-service",
  oDay: "O - Staff development",
  aDay: "A - School administration",
  cDay: "C - School/community orientation",
  nDay: "N - Principal's extra days",
  hDay: "H - Extended holidays",
  pDay: "P - Professional development",
  sDay: "S - Statutory holidays"
}

import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Table } from "reactstrap";
import { CircularProgress, Box, Typography, Chip } from "@mui/material";
import { Card } from "../../../components/Card";
import { useSchoolCalendar } from "../hooks";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Select from "react-select";
import bgColor from "../constants/dayColor";
import YearlyCalendar from "../components/calendar/YearlyCalendar";
import uploadFile from "../../../libs/aws/uploadfile";
import airtable from "../../../airtables/PDAirtable";
import { years } from "../constants/calendar";
import { generateCalendarPDF, downloadAllSchoolsSummary } from "../utils/pdfUtils";
import dayjs from "dayjs";
import Menu from "../components/calendar/Menu";

const StyledChip = styled(Chip)(({ label }) => ({
	backgroundColor:
		label === "Completed"
			? "#2E7D32"
			: label === "Validated"
				? "#98FFB0"
				: label === "In Progress"
					? "#FFE598"
					: "#FFB098", // Not Started
	color: label === "Completed" ? "#FFFFFF" : "#000000",
	borderRadius: "20px",
	padding: "4px 8px",
	"& .MuiChip-label": {
		padding: "8px 12px",
	},
}));

const StyledCard = styled(Card)({
	boxShadow: "none",
	padding: "0 !important",
	backgroundColor: "white",
});

const MontlyEventCards = ({ groupedDays, monthNames, startingMonth }) => {
	const checkFridayMondayConnection = (group, currentEvent, prevEvent) => {
		const dayDiff = dayjs(currentEvent.Date).diff(
			dayjs(prevEvent.Date),
			"days",
		);
		const prevDay = dayjs(prevEvent.Date).day();
		const currentDay = dayjs(currentEvent.Date).day();

		// Check if events are consecutive (including Friday to Monday)
		return (
			dayDiff === 1 ||
			(dayDiff === 3 && // Friday to Monday
				prevDay === 5 && // Previous event is on Friday
				currentDay === 1 && // Current event is on Monday
				!group.some(
					(
						e, // No events between these dates
					) =>
						dayjs(e.Date).isAfter(dayjs(prevEvent.Date)) &&
						dayjs(e.Date).isBefore(dayjs(currentEvent.Date)),
				))
		);
	};

	const checkContinuesSequence = (group, currentEvent, prevEvent) => {
		const dayDiff = dayjs(currentEvent.Date).diff(
			dayjs(prevEvent.Date),
			"days",
		);

		return (
			group.length > 1 && // We have at least 2 events in the group
			dayDiff === 1 && // Current event is consecutive with previous
			group.some((event, index) => {
				// Check if group contains a Friday-Monday connection
				if (index === 0) return false;
				const eventDay = dayjs(event.Date).day();
				const prevEventDay = dayjs(group[index - 1].Date).day();
				const eventDiff = dayjs(event.Date).diff(
					dayjs(group[index - 1].Date),
					"days",
				);
				return eventDiff === 3 && prevEventDay === 5 && eventDay === 1;
			})
		);
	};

	const renderMonths = () => {
		const monthsToRender = [];

		for (let i = 0; i < 12; i++) {
			const currentMonthIndex = (startingMonth + i) % 12;
			const monthName = monthNames[currentMonthIndex];
			const eventsForMonth = groupedDays[monthName];

			// Group consecutive events of same type and length
			const groupedEvents = [];

			if (eventsForMonth && eventsForMonth.length > 0) {
				let currentGroup = [eventsForMonth[0]];

				for (let j = 1; j < eventsForMonth.length; j++) {
					const currentEvent = eventsForMonth[j];
					const prevEvent = eventsForMonth[j - 1];

					const isConsecutive = checkFridayMondayConnection(
						eventsForMonth,
						currentEvent,
						prevEvent,
					);
					const continuesSequence = checkContinuesSequence(
						currentGroup,
						currentEvent,
						prevEvent,
					);

					if (
						currentEvent.Type === prevEvent.Type &&
						currentEvent.Length === prevEvent.Length &&
						(isConsecutive || continuesSequence)
					) {
						currentGroup.push(currentEvent);
					} else {
						groupedEvents.push([...currentGroup]);
						currentGroup = [currentEvent];
					}
				}
				groupedEvents.push([...currentGroup]);
			}

			monthsToRender.push(
				eventsForMonth && eventsForMonth.length > 0 ? (
					<Box
						key={monthName}
						sx={{ mb: 2, display: "flex", flexDirection: "column", gap: 1.5 }}
					>
						<h3 className="mb-0">{monthName}</h3>
						{groupedEvents.map((eventGroup, index) => {
							const firstEvent = eventGroup[0];
							const lastEvent = eventGroup[eventGroup.length - 1];
							const dateDisplay =
								eventGroup.length > 1
									? `${dayjs(firstEvent.Date).format("MMMM D")}-${dayjs(lastEvent.Date).format("D, YYYY")}`
									: dayjs(firstEvent.Date).format("MMMM D, YYYY");

							return (
								<Box
									className="border border-info p-1"
									sx={{ backgroundColor: "#f3f9ff", borderRadius: "5px" }}
									key={index}
								>
									<div
										className="d-flex align-items-center"
										style={{ gap: "0.2rem" }}
									>
										<div
											className="rounded-circle d-flex justify-content-center align-items-center"
											style={{
												width: "1.5rem",
												height: "1.5rem",
												backgroundColor:
													bgColor[firstEvent.Type.split(" - ")[0]],
											}}
										>
											{firstEvent.Type.split(" - ")[0]}
										</div>
										<span className="font-weight-500 lh-100">
											{firstEvent.Type.split(" - ")[1].replace(
												"Professional development",
												"Professional dev.",
											)}
										</span>
									</div>
									<div className="d-flex flex-wrap align-items-center justify-content-between w-100 mt-1">
										<div>{dateDisplay}</div>
										{firstEvent.Length && <div>{firstEvent.Length}</div>}
									</div>
								</Box>
							);
						})}
					</Box>
				) : null,
			);
		}

		return monthsToRender;
	};

	return <div>{groupedDays && renderMonths()}</div>;
};

const SchoolCalendar = () => {
	const [year, setYear] = useState(years[years.length - 1]);
	const {
		teacher,
		schools,
		selectedSchool,
		school,
		isloading,
		isCalendarloading,
		closureDay,
		schoolCalendar,
		groupedDays,
		monthNames,
		schoolSchedule,
		scheduledHours,
		setSelectedSchool,
		fetchSchoolCalendar,
		setGenericClosedDays,
	} = useSchoolCalendar(year);
	const [isUploading, setIsUploading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isApproving, setIsApproving] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const fileInputRef = useRef();

	const isLeader = !!schools && schools.length > 1;
	useEffect(() => {
		document.title = "School Calendar - Connected North";
	}, []);

	useEffect(() => {
		setGenericClosedDays(undefined);
		fetchSchoolCalendar();
	}, [year]); // eslint-disable-line react-hooks/exhaustive-deps

	const submitCalendar = async () => {
		try {
			setIsSubmitting(true);
			const update = await airtable.schoolCalendar.update(schoolCalendar.id, {
				Status: "Submitted",
			});
			if (update) {
				fetchSchoolCalendar();
			}
		} catch (e) {
			console.error(e);
		} finally {
			setIsSubmitting(false);
		}
	};

	const uploadMotion = async (e) => {
		const file = e.target.files[0];
		if (file) {
			setIsUploading(true);
			try {
				const uRes = await uploadFile(file);
				const params = {};
				params["Approval Motion"] = [{ url: uRes }];
				const upload = await airtable.schoolCalendar.update(
					schoolCalendar.id,
					params,
				);
				if (upload) {
					fetchSchoolCalendar();
				}
			} catch (e) {
			} finally {
				setIsUploading(false);
			}
		}
	};

	const approveCalendar = async () => {
		setIsApproving(true);
		try {
			const params = {};
			params["Status"] = "Approved";
			params["Approved By"] = [teacher?.id];
			const approve = await airtable.schoolCalendar.update(
				schoolCalendar.id,
				params,
			);
			if (approve) {
				fetchSchoolCalendar();
			}
		} catch (e) {
		} finally {
			setIsApproving(false);
		}
	};

	const handleGeneratePDF = () => {
		generateCalendarPDF(
			school,
			year,
			schoolCalendar,
			groupedDays,
			monthNames,
			schoolSchedule,
			scheduledHours,
		);
	};

	const downloadSummaryPage = async (locale) => {
		setIsDownloading(true);
		await downloadAllSchoolsSummary(year, locale);
		setIsDownloading(false);
	}
	return (
		<div className="my-5">
			<Box sx={{ mb: 4 }}>
				{isLeader &&
					<Box sx={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}>
						<Menu
							menuItems={[
								{title: "Download English Version", id: "en"},
								{title: "Download French Version", id: "fr"},
								{title: "Download Inuktitut Version", id: "iu"},
								{title: "Download Inuinnaqtun Version", id: "ikt"},
							]}
							title={isDownloading ? "Downloading ..." : "Download Summary Page" }
							disabled={isDownloading}
							onItemClick={downloadSummaryPage}
						/>
					</Box>
				}
				<Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
					<h2 className="mb-0">Select Calendar Year</h2>
					<Select
						className="form-style"
						value={{ label: year, value: year }}
						placeholder="Select a Calendar Year"
						onChange={(selectedOption) => setYear(selectedOption.value)}
						options={years.map((y) => ({
							value: y,
							label: y,
						}))}
						styles={{
							control: (styles) => ({
								...styles,
								borderRadius: "16px",
								width: "200px",
								fontSize: "large",
							}),
						}}
					/>
					{!!schools && schools.length > 1 && (
						<div className="school-selector" style={{marginInlineStart: "auto"}}>
							<Select
								className="form-style"
								value={
									selectedSchool
										? { value: selectedSchool.id, label: selectedSchool.School }
										: null
								}
								placeholder="Select a School"
								onChange={(selectedOption) => {
									const sc = schools.find((s) => s.id === selectedOption.value);
									if (sc) {
										setSelectedSchool(sc);
									}
								}}
								options={schools
									.filter(
										(sc) =>
											!sc.School.includes("Mobile Unit") &&
											!sc.School.includes("Floor"),
									)
									.map((sc) => ({
										value: sc.id,
										label: sc.School,
									}))}
								styles={{
									control: (styles) => ({ ...styles, borderRadius: "16px", minWidth: "200px", fontSize: "large" }),
								}}
							/>
						</div>
					)}
				</Box>
			</Box>
			<section className="section">
				<Card className="bg-white">
					<Row className="border-bottom border-info pb-5">
						<Col md={8}>
							{(isloading || isCalendarloading) && <CircularProgress />}
							{!isloading && (
								<Box>
									<Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
										<h2 className="mb-0 mr-2">School Calendar {year}</h2>
										<StyledChip
											label={
												schoolCalendar
													? schoolCalendar.Status === "Approved"
														? schoolCalendar["Approval Motion"]
															? "Completed"
															: "Validated"
														: schoolCalendar.Status === "Submitted"
															? "Pending Approval"
															: "In Progress"
													: "Not Started"
											}
										/>
									</Box>

									<Typography sx={{ mb: 4, fontSize: "18px", lineHeight: 1.6 }}>
										Welcome to the School Calendar tool! Using this platform,
										you can create your school calendar for each school year,
										defining your days of instruction, class timing, and all of
										the various non-instructional days where students will not
										be in school. Once completed, you can download your Calendar
										as a PDF and upload the motion for approval from your DEA.
									</Typography>

									<Box sx={{ display: "flex", gap: 2 }}>
										<Link to={`/cn/schoolcalendar/edit?year=${year}&school=${selectedSchool.id}`}>
											<Button color="primary">
												{schoolCalendar ? "Edit Calendar" : "Create Calendar"}
											</Button>
										</Link>
										{schoolCalendar?.["Status"] === "Draft" && (
											<Button
												color="primary"
												onClick={submitCalendar}
												disabled={
													isSubmitting ||
													!(
														closureDay.principal === 0 &&
														closureDay.teacher === 0 &&
														closureDay.student === 0
													)
												}
											>
												{isSubmitting ? "Submitting..." : "Submit Calendar"}
											</Button>
										)}
										{!!schools &&
											schools.length > 1 &&
											schoolCalendar?.["Status"] === "Submitted" && (
												<Button
													color="primary"
													onClick={approveCalendar}
													disabled={isApproving}
												>
													{isApproving ? "Validating..." : "Validate Calendar"}
												</Button>
											)}
										{schoolCalendar &&
											schoolCalendar["Status"] === "Approved" &&
											!schoolCalendar["Approval Motion"] && (
												<>
													<input
														type="file"
														accept="application/pdf"
														ref={fileInputRef}
														style={{ display: "none" }}
														onChange={uploadMotion}
													/>

													<div className="image-upload-button">
														<Button
															onClick={() => fileInputRef.current.click()}
															type="button"
															color="primary"
															disabled={isUploading}
														>
															{isUploading ? "Uploading..." : "Upload Motion"}
														</Button>
													</div>
												</>
											)}
									</Box>
								</Box>
							)}
						</Col>

						<Col md={4}>
							<Card paddingSmall>
								<Table borderless>
									<thead
										className="border-bottom"
										style={{ border: "lightblue" }}
									>
										<tr>
											<th></th>
											<th className="">Maximum School Days</th>
											<th className="">Named Days to Add</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Students</td>
											<td>182</td>
											<td>{closureDay.student}</td>
										</tr>
										<tr>
											<td>Teachers</td>
											<td>195</td>
											<td>{closureDay.teacher}</td>
										</tr>
										<tr>
											<td>Principals</td>
											<td>{schoolCalendar && schoolCalendar["Last Day (Principal)"] === "2028-06-30" ? 201 : 202}</td>
											<td>{closureDay.principal}</td>
										</tr>
									</tbody>
								</Table>
							</Card>
						</Col>
					</Row>

					{!isloading && !isCalendarloading && !!schoolCalendar && (
						<>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									mt: 3,
								}}
							>
								<h2 className="mb-0 mr-2">Your Year</h2>
								<Button
									color="primary"
									outline
									className="rounded-pill"
									onClick={handleGeneratePDF}
								>
									<i className="fa fa-file-pdf-o" />
									&nbsp;&nbsp;Download PDF
								</Button>
							</Box>

							<Typography sx={{ mb: 2, fontSize: "18px", lineHeight: 1.6 }}>
								Below is your school calendar for the year. You can edit the
								calendar by clicking the "Edit Calendar" button above.
							</Typography>

							<StyledCard>
								<Row className="m-0">
									<Col className="border-right py-2" md={3}>
										<MontlyEventCards
											groupedDays={groupedDays}
											monthNames={monthNames}
											startingMonth={new Date(
												schoolCalendar &&
													schoolCalendar["First Day (Principal)"],
											).getMonth()}
										/>
									</Col>
									<Col md={9}>
										<YearlyCalendar
											year={year}
											startDate={
												schoolCalendar &&
												schoolCalendar["First Day (Principal)"]
											}
											events={groupedDays}
										/>
									</Col>
								</Row>
							</StyledCard>
						</>
					)}
				</Card>
			</section>
		</div>
	);
};

export default SchoolCalendar;

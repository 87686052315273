import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Pagination } from "../../components/Pagination";
import { showLoading, hideLoading } from "../../utils/loading";
import airtable from "../../airtables";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import differenceInDays from "date-fns/differenceInDays";
import { downloadAttachment } from "../../utils/file";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Modal, Box, Select, MenuItem, FormControl, InputLabel, Button, Tooltip } from "@mui/material";
import "./MyPayments.css";

// Email details for payment inquiry
const PAYMENT_INQUIRY_EMAIL = "payments@takingitglobal.org";

const MyPayments = () => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.appInfo);
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalEarnings, setTotalEarnings] = useState(userInfo["Total Earnings"]);
  const [earningsTitle, setEarningsTitle] = useState(""); // For dynamic earnings title 
  
  // Date filtering
  const [dateFilter, setDateFilter] = useState("all");
  const [availableMonths, setAvailableMonths] = useState([]);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const today = new Date(); // For restricting date selection to past dates
  
  // Pagination
  const PAYMENTS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [paymentsToShow, setPaymentsToShow] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const fetchPayments = async () => {
      showLoading(t("loading"));
      try {
        // Fetch payments linked to this provider's ID
        const providerPayments = await airtable.payments.list({
          filterByFormula: `{Provider Record ID} = '${userInfo.id}'`,
          sort: [{ field: "Submission Date", direction: "desc" }]
        });
        
        setPayments(providerPayments || []);
        setFilteredPayments(providerPayments || []);
        setPageCount(Math.ceil((providerPayments || []).length / PAYMENTS_PER_PAGE));
        setEarningsTitle(t("total-earnings")); // Initialize earnings title
        
        // Get available months for filtering
        getAvailableMonths(providerPayments || []);
      } catch (error) {
        console.error("Failed to fetch payments:", error);
      } finally {
        hideLoading();
        setLoading(false);
      }
    };

    if (userInfo?.id) {
      fetchPayments();
    }
  }, [userInfo, t]);
  
  // Function to get available months with payments
  const getAvailableMonths = (paymentData) => {
    if (!paymentData || paymentData.length === 0) {
      setAvailableMonths([]);
      return;
    }
    
    // Create a map to track months with payments
    const monthsMap = new Map();
    
    // Process each payment and extract its month
    paymentData.forEach(payment => {
      if (payment["Submission Date"]) {
        const date = new Date(payment["Submission Date"]);
        const monthKey = `${date.getFullYear()}-${date.getMonth()}`;
        const monthLabel = format(date, "MMMM yyyy");
        
        if (!monthsMap.has(monthKey)) {
          monthsMap.set(monthKey, {
            key: monthKey,
            label: monthLabel,
            year: date.getFullYear(),
            month: date.getMonth(),
            date: new Date(date.getFullYear(), date.getMonth(), 1)
          });
        }
      }
    });
    
    // Convert map to array and sort by date (newest first)
    const months = Array.from(monthsMap.values())
      .sort((a, b) => b.date - a.date)
      .slice(0, 6); // Current month + 5 previous months with records
    
    setAvailableMonths(months);
  };

  // Apply date filtering
  useEffect(() => {
    if (payments.length === 0) return;
    
    let filtered = [...payments];
    
    if (dateFilter === "all") {
      // No filtering needed for "all"
    } else if (dateFilter === "custom" && startDate && endDate) {
      // Custom date range filter
      filtered = payments.filter(payment => {
        const submissionDate = new Date(payment["Submission Date"]);
        return (
          (isAfter(submissionDate, startDate) || submissionDate.getTime() === startDate.setHours(0, 0, 0, 0)) && 
          (isBefore(submissionDate, endDate) || submissionDate.getTime() === endDate.setHours(23, 59, 59, 999))
        );
      });
    } else if (dateFilter.startsWith("month-")) {
      // Month-based filtering
      const monthKey = dateFilter.replace("month-", "");
      const selectedMonth = availableMonths.find(m => m.key === monthKey);
      
      if (selectedMonth) {
        const monthStart = new Date(selectedMonth.year, selectedMonth.month, 1);
        const monthEnd = new Date(selectedMonth.year, selectedMonth.month + 1, 0, 23, 59, 59, 999);
        
        filtered = payments.filter(payment => {
          const submissionDate = new Date(payment["Submission Date"]);
          return (
            submissionDate >= monthStart && submissionDate <= monthEnd
          );
        });
      }
    }
    
    setFilteredPayments(filtered);
    calculateTotalEarnings(filtered);
    setPageCount(Math.ceil(filtered.length / PAYMENTS_PER_PAGE));
  }, [dateFilter, payments, startDate, endDate, availableMonths, PAYMENTS_PER_PAGE]);

  // Update pagination based on filtered payments
  useEffect(() => {
    setPaymentsToShow(
      filteredPayments.slice(
        currentPage * PAYMENTS_PER_PAGE,
        (currentPage + 1) * PAYMENTS_PER_PAGE
      )
    );
  }, [currentPage, filteredPayments, PAYMENTS_PER_PAGE]);

  const handleInvoiceClick = (payment) => {
    if (payment["Attachments"] && payment["Attachments"].length > 0) {
      const attachment = payment["Attachments"][0];
      downloadAttachment(attachment.url, attachment.filename);
    }
  };
  
  const calculateTotalEarnings = (paymentData) => {
    if (!paymentData || paymentData.length === 0) {
      setTotalEarnings("0");
      return;
    }
    
    const total = paymentData.reduce((sum, payment) => {
      // Check if the payment has an amount value and it's a valid number
      const amountStr = payment["Amount"];
      if (!amountStr) return sum;
      
      // Ensure amountStr is a string before using replace
      const amountString = String(amountStr);
      
      // Remove the currency symbol and any commas, then convert to number
      const amount = parseFloat(amountString.replace(/[^0-9.-]+/g, ""));
      return isNaN(amount) ? sum : sum + amount;
    }, 0);
    
    // Format with commas for thousands and no decimals
    setTotalEarnings(total.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }));
  };
  
  const handleDateFilterChange = (event) => {
    const value = event.target.value;
    setDateFilter(value);
    setCurrentPage(0); // Reset to first page when filter changes
    
    // Update earnings title based on selected filter
    if (value === "all") {
      setEarningsTitle(t("total-earnings"));
    } else if (value.startsWith("month-")) {
      const monthKey = value.replace("month-", "");
      const selectedMonth = availableMonths.find(m => m.key === monthKey);
      if (selectedMonth) {
        setEarningsTitle(`${selectedMonth.label} ${t("earnings")}`);
      }
    }
    
    if (value === "custom") {
      // If we previously had custom dates selected, pre-fill the temp dates
      if (startDate && endDate) {
        setTempStartDate(startDate);
        setTempEndDate(endDate);
      } else {
        // Reset temp dates if no previous selection
        setTempStartDate(null);
        setTempEndDate(null);
      }
      
      // Open the modal and set the earnings title
      setCustomModalOpen(true);
      setEarningsTitle(t("custom-range-earnings"));
    }
  };
  
  const handleCustomDateApply = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setCustomModalOpen(false);
      
      // Update title for custom date range
      const startFormatted = format(tempStartDate, "MMM d");
      const endFormatted = format(tempEndDate, "MMM d, yyyy");
      setEarningsTitle(`${startFormatted} - ${endFormatted} ${t("earnings")}`);
    }
  };
  
  const handleCustomDateCancel = () => {
    // Close the modal without applying changes
    setCustomModalOpen(false);
    
    // If no custom dates were previously set, revert to "all" filter
    if (!startDate || !endDate) {
      setDateFilter("all");
      setEarningsTitle(t("total-earnings"));
    }
  };

  const formatSubtext = (dateString, isProcessingDate = false) => {
    if (!dateString) return null;
    try {
      // Parse the UTC date
      const date = new Date(dateString);
      
      // If it's a processing date, check if it's after 5pm Eastern
      if (isProcessingDate) {
        // Convert UTC to Eastern Time (UTC-5 for EST, UTC-4 for EDT)
        // For this simplified implementation, we'll use a fixed offset
        // In a production app, you might want to use a library that handles DST properly
        const easternHour = date.getUTCHours() - 5; // EST offset
        
        if (easternHour >= 17) { // After 5pm Eastern
          // Add one day to get the next day
          date.setDate(date.getDate() + 1);
          
          // If it's a weekend, adjust to Monday
          const dayOfWeek = date.getUTCDay();
          if (dayOfWeek === 0) { // Sunday
            date.setDate(date.getDate() + 1); // Move to Monday
          } else if (dayOfWeek === 6) { // Saturday
            date.setDate(date.getDate() + 2); // Move to Monday
          }
        }
      }
      
      return `on ${format(date, "MMMM d")}`;
    } catch (error) {
      console.error("Error formatting date:", error);
      return null;
    }
  };

  const getPaymentProgress = (payment) => {
    // Check if the payment has attachments
    const hasAttachment = payment["Attachments"] && payment["Attachments"].length > 0;
    const amount = payment["Amount"] ? `$${parseFloat(payment["Amount"]).toFixed(2)}` : null;
    const sessionStatus = payment["Session Status"] || "-";
    
    // Calculate progress steps
    const steps = [
      { 
        label: t("session"), 
        completed: true, 
        clickable: false,
        subtext: sessionStatus,
        icon: "calendar" // Calendar icon for session status
      },
      { 
        label: t("invoice-created"), 
        completed: true, 
        clickable: hasAttachment,
        onClick: hasAttachment ? () => handleInvoiceClick(payment) : null,
        subtext: amount,
        icon: "file-text" // Invoice icon for invoice created
      }, // Always completed for all payments
      { 
        label: t("payment-approved"), 
        completed: payment["Xero Invoice ID"],
        subtext: payment["Xero Date"] ? formatSubtext(payment["Xero Date"]) : null,
        icon: "check-circle" // Check circle icon for payment approved
      },
      { 
        label: t("payment-processed"), 
        completed: payment["Processed by Accounting"],
        subtext: payment["Processing Date"] ? formatSubtext(payment["Processing Date"], true) : null,
        icon: "money" // Money icon for payment processed
      }
    ];
    
    return steps;
  };
  
  // Check if an inquiry should be shown at all (hide if over 30 days since session)
  const shouldShowInquiry = (payment) => {
    // No inquiry option if payment hasn't been processed
    if (!payment["Processing Date"]) {
      return false;
    }
    
    // Check if more than 30 days have passed since the session date
    const sessionDate = payment["Session Date"] || payment["Date"] || payment["Event Date"];
    
    if (sessionDate) {
      const sessionDateObj = new Date(sessionDate);
      const today = new Date();
      const daysSinceSession = differenceInDays(today, sessionDateObj);
      
      // If more than 30 days have passed since the session, hide the inquiry link
      if (daysSinceSession > 30) {
        return false;
      }
    }
    
    // Not over 30 days, so show the inquiry link (enabled or disabled)
    return true;
  };
  
  // Check if payment inquiry is clickable (3 business days passed)
  const canSubmitInquiry = (payment) => {
    if (!payment["Processing Date"]) {
      return false;
    }
    
    const processingDate = new Date(payment["Processing Date"]);
    const today = new Date();
    
    // Check if at least 3 business days have passed since processing
    const businessDays = differenceInBusinessDays(today, processingDate);
    return businessDays >= 3;
  };
  
  // Handle payment inquiry click
  const handleInquiryClick = (payment) => {
    // Only send email if enough business days have passed
    if (canSubmitInquiry(payment)) {
      const sessionName = payment["Session Name"] || "Unknown Session";
      const subject = `Session Payment Inquiry - ${sessionName}`;
      const body = `Dear Connected North Team,\n\nI have not received the payment for this session (ID ${payment['SessionID']}). Please investigate with your payment provider.`;
      
      // Create mailto link
      const mailtoLink = `mailto:${PAYMENT_INQUIRY_EMAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      
      // Create a hidden anchor element to trigger the mailto
      const a = document.createElement('a');
      a.href = mailtoLink;
      a.target = '_blank'; // Try to open in new tab
      a.rel = 'noopener noreferrer';
      a.click(); // Programmatically click to activate
    }
    // Otherwise do nothing - the link should be disabled
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    try {
      return format(new Date(dateString), "MMMM d, yyyy");
    } catch (error) {
      return dateString;
    }
  };

  if (loading) {
    return (
      <div className="main-container">
        <h1>{t("my-payments")}</h1>
        <div className="text-center py-5">Loading...</div>
      </div>
    );
  }

  return (
    <div className="main-container">
      <div className="d-flex justify-content-between align-items-start mb-3">
        <h1>{t("my-payments")}</h1>
        <div className="total-earnings-callout p-3">
          <strong>{earningsTitle || t("total-earnings")}:</strong> ${totalEarnings}
        </div>
      </div>
      
      {payments.length === 0 ? (
        <div className="alert alert-info">{t("no-payment-records")}</div>
      ) : (
        <>
          <div className="progress-details">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4>{t("payment-progress")}</h4>
              
              {/* Date filter dropdown */}
              <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                <InputLabel id="date-filter-label">{t("filter-by")}</InputLabel>
                <Select
                  labelId="date-filter-label"
                  value={dateFilter}
                  onChange={handleDateFilterChange}
                  label={t("filter-by")}
                >
                  <MenuItem value="all">{t("all-time")}</MenuItem>
                  {availableMonths.map(month => (
                    <MenuItem key={month.key} value={`month-${month.key}`}>
                      {month.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="custom">{t("custom-range")}</MenuItem>
                </Select>
              </FormControl>
            </div>
            
            {/* Custom date range modal */}
            <Modal
              open={customModalOpen}
              onClose={handleCustomDateCancel}
              aria-labelledby="custom-date-modal-title"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2
              }}>
                <h2 id="custom-date-modal-title" className="mb-3">{t("select-date-range")}</h2>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="mb-3">
                    <DatePicker
                      label={t("start-date")}
                      value={tempStartDate}
                      onChange={(newValue) => setTempStartDate(newValue)}
                      maxDate={today} // Prevent selection of future dates
                      slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                    />
                  </div>
                  <div className="mb-4">
                    <DatePicker
                      label={t("end-date")}
                      value={tempEndDate}
                      onChange={(newValue) => setTempEndDate(newValue)}
                      slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                      minDate={tempStartDate || undefined}
                      maxDate={today} // Prevent selection of future dates
                    />
                  </div>
                </LocalizationProvider>
                <div className="d-flex justify-content-end">
                  <Button onClick={handleCustomDateCancel} color="secondary" sx={{ mr: 1 }}>
                    {t("cancel")}
                  </Button>
                  <Button 
                    onClick={handleCustomDateApply} 
                    variant="contained" 
                    color="primary"
                    disabled={!tempStartDate || !tempEndDate}
                  >
                    {t("apply")}
                  </Button>
                </div>
              </Box>
            </Modal>
            {filteredPayments.length === 0 ? (
              <div className="alert alert-info">{t("no-payments-found-for-date-range")}</div>
            ) : paymentsToShow.map((payment) => {
              const progressSteps = getPaymentProgress(payment);
              // These variable helps with the thermometer coloring and payment progress tracking
              const hasCompleteSteps = progressSteps.some(step => step.completed);
              
              return (
                <div key={payment.id} className="payment-progress-container mb-4 p-3 border rounded">
                  <div className="payment-header d-flex justify-content-between align-items-center mb-2">
                    <div className="payment-date">
                      <span className="date-value">{formatDate(payment["Submission Date"])}</span>
                    </div>
                    <div className="session-title">
                      <strong>
                        {payment["Session Title"]} for {payment["# of Schools"] > 1 ? 
                          `${payment["# of Schools"]} Schools` : 
                          payment["School Name"]}
                      </strong>
                    </div>
                  </div>

                  <div className="progress-container">
                    {/* Calculate which step is completed for styling */}
                    <div className={`progress-steps ${progressSteps[1].completed ? 'step-1-complete' : ''} ${progressSteps[2].completed ? 'step-2-complete' : ''} ${progressSteps[3].completed ? 'step-3-complete' : ''}`}>
                      {progressSteps.map((step, index) => (
                        <div key={index} className="progress-step-wrapper">
                          <div 
                            className={`progress-step ${step.completed ? 'completed' : ''} ${index === 0 ? 'first' : ''} ${index === progressSteps.length - 1 ? 'last' : ''} ${step.clickable ? 'clickable' : ''} ${hasCompleteSteps ? 'in-progress' : ''}`}
                            onClick={step.onClick}
                            title={step.clickable ? t("download-invoice") : ""}
                          >
                            <div className="progress-step-indicator">
                              {step.clickable ? 
                                <i className="fa fa-download"></i> : 
                                <i className={`fa fa-${step.icon}`}></i>
                              }
                            </div>
                            <div className="progress-step-content">
                              <div className="progress-step-label">{step.label}</div>
                              {step.completed && step.subtext && (
                                <div className="progress-step-subtext">{step.subtext}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  {/* Payment inquiry link - only shown for payments with a Processing Date and within 30 days */}
                  {shouldShowInquiry(payment) && (
                    <div className="d-flex justify-content-end mt-3">
                      <Tooltip 
                        title={!canSubmitInquiry(payment) ? t("payment-inquiry-tooltip") : ""}
                        placement="top"
                        arrow
                      >
                        <span> {/* Wrapper needed for disabled buttons in tooltips */}
                          <button 
                            onClick={() => { 
                              handleInquiryClick(payment); 
                            }}
                            disabled={!canSubmitInquiry(payment)}
                            className={`payment-inquiry-link ${!canSubmitInquiry(payment) ? 'disabled' : ''}`}
                            style={{
                              color: canSubmitInquiry(payment) ? '#6495ED' : '#6c757d', // Less bright blue for clickable links
                              textDecoration: 'none',
                              cursor: canSubmitInquiry(payment) ? 'pointer' : 'default',
                              opacity: canSubmitInquiry(payment) ? 1 : 0.6,
                              display: 'flex',
                              alignItems: 'center',
                              gap: '4px', // Add small space between text and icon
                              background: 'none',
                              border: 'none',
                              padding: 0
                            }}
                          >
                            {t("payment-not-received")}
                            {!canSubmitInquiry(payment) && (
                              <i className="fa fa-info-circle" aria-hidden="true"></i>
                            )}
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          
          {pageCount > 1 && (
            <div className="d-flex justify-content-center">
              <Pagination
                pageCount={pageCount}
                page={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MyPayments;
import AirtableTable from "../../AirtableTable";

export class DayPlanFeedbackTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Day Plan Feedback");

    this.fields = {
      "ID": "fldPGoYQZO2ja2TyV",
      "School": "fldmJjKGCjgMaMUU5",
      "School Calendar": "fldkuEgdQ6h44HDgS",
      "Feedback From": "fldkuEgdQ6h44HDgS",
      "Comments": "fldEcUo4OrzguXJok",
      "Reviewed On": "fldbL3mG6MZ11zGmS",
    };
  }
}

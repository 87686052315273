import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const NewBarChartIcon = () => (
    <SvgIcon width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg>
            <g id="Group 5316">
                <path id="Vector" d="M18.6787 25V21L14.7501 24.7546V25H18.6787Z" fill="#9DE0F8"/>
                <path id="Vector_2" d="M1 1V26H28.5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_3" d="M1 11H6.89286V26" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_4" d="M6.89307 17H13.7681V26" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_5" d="M19.6606 14H25.5535V26" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_6" d="M19.6604 26V7H13.7676V17.0781" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_7" d="M14.75 23L18.6786 18.245V12L14.75 16.7519V23Z" fill="#9DE0F8"/>
                <path id="Vector_8" d="M14.75 25H16.2379L18.6786 22.7439V18L14.75 21.5995V25Z" fill="white"/>
                <path id="Vector_9" d="M14.75 8V12L18.6786 8.24536V8H14.75Z" fill="#9DE0F8"/>
                <path id="Vector_10" d="M14.75 17L18.6786 13.0055V8L14.75 11.8177V17Z" fill="white"/>
            </g>
        </svg>
  </SvgIcon>
)

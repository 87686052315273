const TYPES = {
	SINGLE_VALUE: "SINGLE_VALUE", //options: none
	MULTI_VALUE_COUNT: "MULTI_VALUE_COUNT", //options: none
	MULTI_VALUE_SUM: "MULTI_VALUE_SUM", //options: none
	MULTI_VALUE_AVG: "MULTI_VALUE_AVG", //options: none
	DISTINCT_VALUE_COUNT: "DISTINCT_VALUE_COUNT", //options: options_id
	FILTERED_VALUE_COUNT: "FILTERED_VALUE_COUNT", //options: filter_value, filter_operator
	FILTERED_VALUE_SUM: "FILTERED_VALUE_SUM", //options: filter_value, filter_operator
	FILTERED_VALUE_AVG: "FILTERED_VALUE_AVG", //options: filter_value, filter_operator
	GROUPED_VALUE_COUNT: "GROUPED_VALUE_COUNT", //options: group_by_id, options_id
	GROUPED_VALUE_SUM: "GROUPED_VALUE_SUM", //options: group_by_id, options_id
	GROUPED_VALUE_AVG: "GROUPED_VALUE_AVG", //options: group_by_id, options_id
	FILTERED_GROUP_VALUE_COUNT: "FILTERED_GROUP_VALUE_COUNT", //options: group_by_id, options_id, filter_value, filter_operator
	FILTERED_GROUP_VALUE_SUM: "FILTERED_GROUP_VALUE_SUM", //options: group_by_id, options_id, filter_value, filter_operator
	FILTERED_GROUP_VALUE_AVG: "FILTERED_GROUP_VALUE_AVG", //options: group_by_id, options_id, filter_value, filter_operator
};
const FILTER_OPERATORS = {
	EQUAL: "===",
	NOT_EQUAL: "!==",
	GREATER_THAN: ">",
	LESS_THAN: "<",
	IN: "in",
	NOT_IN: "not-in",
};

// Fields List
export const FIELDS = [
	{
		id: "GRADUATING_STUDENTS",
		field_id: "27b3607e-f7e5-438f-b13f-83b275d1d4fb",
		options: { type: TYPES.SINGLE_VALUE },
	},
	{
		id: "STUDENT_POPULATION",
		field_id: "b5e16737-92a6-4783-9b81-e6b8e273b382",
		options: { type: TYPES.SINGLE_VALUE },
	},
	{
		id: "COMMUNITY_POPULATION",
		field_id: "eff08de2-daa1-4a78-b44a-18fd9ae1b449",
		options: { type: TYPES.SINGLE_VALUE },
	},
	{
		id: "SCHOOL_CLOSURE_REASON",
		field_id: "1c30070a-02c9-4be8-8af3-c617e48e9f21",
		options: {
			type: TYPES.DISTINCT_VALUE_COUNT,
			options_id: "db_fields.closureReason.options",
		},
	},
	{
		id: "SCHOOL_CLOSURE_COUNT",
		field_id: "1c30070a-02c9-4be8-8af3-c617e48e9f21",
		options: {
			type: TYPES.MULTI_VALUE_COUNT,
		},
	},
	{
		id: "SUSPENSION_COUNT",
		field_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
		options: {
			type: TYPES.MULTI_VALUE_COUNT,
		},
	},
	{
		id: "SUSPENSION_GRADE",
		field_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
		options: { type: TYPES.MULTI_VALUE_COUNT },
	},
	{
		id: "SUSPENSION_HOURS_IN_SCHOOL",
		field_id: "99b9a830-f28c-4e20-870c-f55103a062fd",
		options: {
			type: TYPES.GROUPED_VALUE_SUM,
			group_by_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
			options_id: "db_fields.suspensionGrade.options",
		},
	},
	{
		id: "SUSPENSION_HOURS_OUT_OF_SCHOOL",
		field_id: "e068a3e2-f8aa-40ca-983d-a7daf222c146",
		options: {
			type: TYPES.GROUPED_VALUE_SUM,
			group_by_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
			options_id: "db_fields.suspensionGrade.options",
		},
	},
	{
		id: "EXPULSION_COUNT",
		field_id: "e46a4d48-9588-474a-bc0c-476ddeffdddd",
		options: {
			type: TYPES.FILTERED_VALUE_COUNT,
			filter_value: "a",
			filter_operator: FILTER_OPERATORS.EQUAL,
		},
	},
	{
		id: "SUSPENSION_GRADE",
		field_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
		options: { type: TYPES.MULTI_VALUE_COUNT },
	},
	{
		id: "EXPULSION_GRADE",
		field_id: "e46a4d48-9588-474a-bc0c-476ddeffdddd",
		options: {
			type: TYPES.FILTERED_GROUP_VALUE_COUNT,
			group_by_id: "937930b8-df3f-4822-bc5e-6e4811fc903c",
			options_id: "db_fields.suspensionGrade.options",
			filter_value: "a",
			filter_operator: FILTER_OPERATORS.EQUAL,
		},
	},
	{
		id: "ELDER_GRADES",
		field_id: "1541c4cf-dbcb-445b-831f-0520a0ed97c4",
		options: {
			type: TYPES.DISTINCT_VALUE_COUNT,
			options_id: "db_fields.elderGrades.options",
		},
	},
	{
		id: "ELDER_VISIT_COUNT",
		field_id: "1541c4cf-dbcb-445b-831f-0520a0ed97c4",
		options: {
			type: TYPES.MULTI_VALUE_COUNT,
		},
	},
	{
		id: "SUSPENSION_VIOLENCE_STUDENTS",
		field_id: "437e3be0-10a7-4c7b-910b-d6a9f88d3d9b",
		options: {
			type: TYPES.FILTERED_VALUE_COUNT,
			filter_value: "a",
			filter_operator: FILTER_OPERATORS.EQUAL,
		},
	},
	{
		id: "SUSPENSION_VIOLENCE_STAFF",
		field_id: "fe95da80-acd5-4bf8-8890-e31edb8bb2ec",
		options: {
			type: TYPES.FILTERED_VALUE_COUNT,
			filter_value: "a",
			filter_operator: FILTER_OPERATORS.EQUAL,
		},
	},
	{
		id: "ATTENDANCE_RATE",
		field_id: "82635573-0bf3-47d2-a33a-9cb99baa3d8d",
		options: {
			type: TYPES.GROUPED_VALUE_AVG,
			group_by_id: "43f582cf-57c0-4474-9b61-754530fb0951",
			options_id: "db_fields.attendanceGrade.options",
		},
	},
	{
		id: "ATTENDANCE_RATE_AVG",
		field_id: "82635573-0bf3-47d2-a33a-9cb99baa3d8d",
		options: {
			type: TYPES.MULTI_VALUE_AVG,
		},
	},
];

import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Modal } from "reactstrap";
import useSessionBook from "../../../libs/hooks/use-session-book";
import SessionFullTimePicker from "../../../components/SessionFullTimePicker";
import airtable from "../../../airtables";
import Loading from "../../../components/Loading";
import SectionLoader from "../../../components/SectionLoader";

const ResheduleSessionModal = ({ session, onToggle, onDone }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [providerSession, setProviderSession] = useState(null);
  const [scheduling, setScheduling] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [sessionDate, setSessionDate] = useState(null);
  const [alterSessionDate, setAlterSessionDate] = useState(null);
  const [timeValid, setTimeValid] = useState("");

  const {
    loading: bookSessionLoading,
    provider,
    initialDates,
    shouldDisableDate
  } = useSessionBook({
    providerSession: providerSession,
    bookType: "reschedule",
    teachers: teachers,
    session: session
  });

  useEffect(() => {
    if (initialDates.length === 0) return;

    setSessionDate(initialDates[0]);
    setAlterSessionDate(initialDates[1]);
  }, [initialDates])

  useEffect(() => {
    if (!session) return;

    (async () => {
      const pSession = await airtable.providerSessions.select(session['Session Title'][0]);
      setProviderSession(pSession);
      const ts = await airtable.teachers.listByIds(session["Teacher"]);
      setTeachers(ts);
      setLoading(false);
    })();
  }, [session])

  const canSave = () => {
    if (loading) return false;
    if (timeValid !== "valid") return false;
    return true;
  }

  const submitData = () => {
    setScheduling(true);
    
    // Store the original session date and format it nicely
    const originalDate = session["Session Start Date/Time"] || session["Session Date"];
    let formattedOriginalDate = "";
    
    if (originalDate) {
      const date = new Date(originalDate);
      
      // Determine which timezone to use based on status
      let timezone = "America/Toronto"; // Default timezone
      
      if (status === "Pending Provider Response" && session["Provider Timezone"]) {
        timezone = session["Provider Timezone"];
      } else if (status === "Pending Teacher Response" && session["School Timezone"]) {
        timezone = session["School Timezone"];
      }
      
      // Format the date in the appropriate timezone
      formattedOriginalDate = date.toLocaleDateString('en-US', {
        timeZone: timezone,
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
      
      // Add the ordinal suffix to the day (1st, 2nd, 3rd, etc.)
      const day = new Date(date.toLocaleString('en-US', { timeZone: timezone })).getDate();
      const suffix = ["th", "st", "nd", "rd"][day % 10 > 3 ? 0 : (day % 100 - day % 10 !== 10 ? day % 10 : 0)];
      formattedOriginalDate = formattedOriginalDate.replace(` ${day},`, ` ${day}${suffix} at`);
    }
    
    const data = {
      "Status": status,
      "Session Start Date/Time": sessionDate,
      "Alternative Date/Time": alterSessionDate
    }

    airtable.sessions.update(session.id, data)
      .then(async (res) => {
        // Determine which webhook URL to use based on status
        const webhookUrl = status === "Pending Teacher Response" 
          ? "https://hooks.zapier.com/hooks/catch/89715/3nxpioj/"
          : "https://hooks.zapier.com/hooks/catch/89715/37q7as9";
          
        await fetch(`${webhookUrl}?sessionID=${session.id}&action=reschedule&originalDate=${encodeURIComponent(formattedOriginalDate)}`, {
          method: "GET",
          mode: "no-cors",
        });
        onToggle();
        setScheduling(false);
        onDone();
        onToggle();
      })
      .catch(() => {
        setScheduling(false);
      })
  }

  return (
    <Modal
      className="modal-dialog-centered reschedule-session-modal"
      isOpen={true}
      toggle={onToggle}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title">Reschedule Session</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <FormGroup>
            <label>Status</label>
            <Input
              id="status"
              type="select"
              className="form-control"
              value={status}
              onChange={e => setStatus(e.target.value)}
              disabled={loading}
            >
              <option></option>
              <option>Pending Provider Response</option>
              <option>Pending Teacher Response</option>
            </Input>
          </FormGroup>
          {bookSessionLoading ? (
            <SectionLoader />
          ) : (
            <SessionFullTimePicker
              preferTime={sessionDate}
              alterTime={alterSessionDate}
              sessionLen={session["Length (Minutes)"]}
              shouldDisableDate={shouldDisableDate}
              provider={provider}
              providerSession={session}
              bookedSessionId={session.id}
              teachers={teachers}
              labels={[
                "Session Date & Time",
                "Add Alternative Session Date & Time"
              ]}
              onChange={(date, which) => {
                if (which === "prefer") {
                  setSessionDate(date);
                } else {
                  setAlterSessionDate(date);
                }
              }}
              onValid={v => setTimeValid(v)}
            />
          )}
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Close</Button>
        <Button
          color="primary"
          type="button"
          disabled={!canSave()}
          onClick={() => submitData()}
        >
          {scheduling ? (<Loading size={14} />) : (null)}Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default ResheduleSessionModal;
import SupabaseTable from "../SupabaseTable";

export class HostAvailabilityTable extends SupabaseTable {
  getByHostAndDateRange = async (hostId, startDate, endDate) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("host", hostId)
        .gte("date", startDate)
        .lte("date", endDate)
        .order("date", { ascending: true });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };

  saveAvailability = async (hostId, date, availability) => {
    try {
      // Check if a record already exists for this host and date
      const { data: existingData, error: fetchError } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("host", hostId)
        .eq("date", date)
        .maybeSingle();

      if (fetchError) {
        throw new Error(fetchError.message);
      }

      let result;
      
      if (existingData) {
        // Update existing record
        const { data, error } = await this.supabase
          .from(this.tableName)
          .update({ availability })
          .eq("id", existingData.id)
          .select();

        if (error) {
          throw new Error(error.message);
        }
        
        result = data;
      } else {
        // Create new record
        const { data, error } = await this.supabase
          .from(this.tableName)
          .insert({ host: hostId, date, availability })
          .select();

        if (error) {
          throw new Error(error.message);
        }
        
        result = data;
      }

      return result;
    } catch (error) {
      throw error;
    }
  };

  deleteDateRange = async (hostId, startDate, endDate) => {
    try {
      const { error } = await this.supabase
        .from(this.tableName)
        .delete()
        .eq("host", hostId)
        .gte("date", startDate)
        .lte("date", endDate);

      if (error) {
        throw new Error(error.message);
      }

      return true;
    } catch (error) {
      throw error;
    }
  };
}
import AirtableTable from "../../AirtableTable";

export class ResourceTable extends AirtableTable {
	constructor(instance) {
		super(instance, "Resources");

		this.fields = {
			"Resource Item": "fld3xUbJqeYHgQ53j",
			"Item Number": "fldZ7GJqdWLa557WY",
			"Type": "fldqlyTJENfz4cUTp",
			"ISBN": "fldSWN0HQMFusm564",
			"Title": "fldZzJ8a2OhuJJffU",
			"Group": "fldEDvrRug8mZxgF8",
			"Description": "fldLXwi1Qgr4fLE04",
			"Author": "fldGGtNLa0rWeE2Op",
			"Grade Level": "fldO4SVAQH1XXrJXm",
			"Qty": "fldS5RhCmFWqC1Amg",
			"Copyright": "fldStkCUW6bhOcEr7",
			"Attachment": "fldZDjc3TAJ3xJbGx",
			"Strand": "fldLw9gHn3nDAa3Vw",
			"Image": "fld5AcfiYmmKOGn6f",
			"Order Type": "fldzUEBtFh15BNjtu",
			"Shelf Location": "fldVHOGziQoHENvg1",
			"Resource Requests": "fld9AtTcFMQFZ4nf7",
			"Related Sessions": "fldA7zenpKouYcKaf",
			"Related Resources": "fldWy9Behqrj9o8TS",
			"Resource Reviews": "fldB4A1Dzg1AYWo9C",
			"Resource File": "fldBYWirfwgOtqvUy",
			"Favourited By": "fldhtIcL5uZnKfC8q",
			"Language": "fldcnLm3h0fRj1Hgr",
			"Featured?": "fldaAg0wyI0uGwxB5",
			"GN Subject": "fldSulNAMOuSz5uQX",
			"Notes": "fldH2MRUvuiEL4SrD",
			"Source URL": "fldGmiIzQPZ5BpnUd",
			"Average Rating": "fldm4CzgP11pZNLIr",
			"Downloaded By": "fldfqn74k349Neope"
		};
	}

	getFeaturedResources = () => {
		return this.list({
			filterByFormula: `{Featured?} = TRUE()`
		})
	}

	getBrowserResources = (condition, search) => {
		const {
			grades, types, languages, strands
		} = condition;

		if (
			grades.length === 0 &&
			types.length === 0 &&
			languages.length === 0 &&
			strands.length === 0 &&
			!search
		) {
			return this.list({
				maxRecords: 8,
				sort: [
					{ field: 'Title', direction: 'asc' }
				]
			})
		}

		const filterConds = [];
		filterConds.push(`{Group} != "ELCC"`)

		if (grades && grades.length) {
			filterConds.push(`{Grade Level} != ""`)
			filterConds.push(`FIND({Grade Level}, "${grades.join(",")}")`)
		}

		if (types && types.length) {
			filterConds.push(`{Type} != ""`)
			filterConds.push(`FIND({Type}, "${types.join(",")}")`)
		}

		if (languages && languages.length) {
			const langConds = languages.map(lang => `FIND("${lang}", {Language})`);
			if (langConds.length === 1) {
				filterConds.push(langConds[0]);
			} else {
				filterConds.push(`OR(${langConds.join(",")})`);
			}
		}

		if (strands && strands.length) {
			filterConds.push(`{Strand} != ""`)
			filterConds.push(`FIND({Strand}, "${strands.join(",")}")`)
		}

		if (search && search.trim()) {
			const kWord = search.trim().toUpperCase();
			const searchConds = [
				`REGEX_MATCH(UPPER({Title}), "(^|.|,| )${kWord}( |.|,|$)")`,
				`REGEX_MATCH(UPPER({Group}), "(^|.|,| )${kWord}( |.|,|$)")`,
				`REGEX_MATCH(UPPER({Notes}), "(^|.|,| )${kWord}( |.|,|$)")`,
				`REGEX_MATCH(UPPER({Author}), "(^|.|,| )${kWord}( |.|,|$)")`,
			];
			filterConds.push(`OR(${searchConds.join(",")})`);
		}

		let filterByFormula = "";
		if (filterConds.length === 1) {
			filterByFormula = filterConds[0];
		} else {
			filterByFormula = `AND(${filterConds.join(",")})`
		}

		return this.list({
			filterByFormula: filterByFormula,
			maxRecords: 100
		})
	}

	getSimilarResources = (resourceId, strand, grade, language) => {
		const conditions = [];
		if (strand) conditions.push(`{Strand} = "${strand}"`);
		if (grade) conditions.push(`{Grade Level} = "${grade}"`);
		if (language) {
			const langConds = language.map(lang => `FIND("${lang}", {Language})`);
			conditions.push(`OR(${langConds.join(",")})`);
		}

		if (conditions.length) {
			return this.list({
				filterByFormula: `AND(${conditions.join(",")})`
			}, (res) => res.id !== resourceId);
		}
		return [];
	}
}

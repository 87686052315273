import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Row, Col } from "reactstrap";

dayjs.extend(customParseFormat);

const DailySchedule = ({
	times,
	setTimes,
	maxTime = 330,
	showRotationalMinutes = false,
	onErrorChange = () => {},
}) => {
	const [totalMinutes, setTotalMinutes] = useState(0);
	const [totalHours, setTotalHours] = useState(0);
	const [isError, setIsError] = useState(false);

	// Calculate totals whenever times change
	useEffect(() => {
		const calculateMinutes = (start, end) => {
			if (!start || !end) return 0;
			const [startHour, startMinute] = start.split(":");
			const [endHour, endMinute] = end.split(":");
			return (
				parseInt(endHour) * 60 +
				parseInt(endMinute) -
				(parseInt(startHour) * 60 + parseInt(startMinute))
			);
		};

		const total =
			calculateMinutes(times["AM Start"], times["AM Finish"]) +
			calculateMinutes(times["PM Start"], times["PM Finish"]) -
			calculateMinutes(times["Recess 1 Start"], times["Recess 1 Finish"]) -
			calculateMinutes(times["Recess 2 Start"], times["Recess 2 Finish"]) -
			(times["Rotational Minutes"] ? parseInt(times["Rotational Minutes"]) : 0);

		setTotalMinutes(total);
		const newIsError = total > maxTime;
		setIsError(newIsError);
		onErrorChange(newIsError);
		setTotalHours(Number((total / 60).toFixed(1)));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [times, maxTime]);

	const handleTimeChange = (type, value) => {
		const newTimes = {
			...times,
			[type]: value,
		};
		setTimes(newTimes);
	};

	const TimeInput = ({ label, value, onChange, defaultPM = false }) => {
		const [isOpen, setIsOpen] = useState(false);
		return (
			<div className="">
				<label className="font-weight-bold">{label}</label>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<TimePicker
						open={isOpen}
						sx={{ width: "100%", backgroundColor: "white" }}
						viewRenderers={{
							hours: (props) =>
								renderTimeViewClock({
									...props,
									ampm: true,
									view: "hours",
									value:
										props.value ||
										(defaultPM ? dayjs().hour(12).minute(0) : null),
									onChange: (newValue) => {
										if (!newValue) {
											props.onChange(newValue);
											return;
										}

										const hour = newValue.hour();
										
										// If 12, 1, 2, or 3 is selected, automatically set it to PM
										if (hour === 0 || // 12 AM
											hour === 1 ||
											hour === 2 ||
											hour === 3
										) {
											props.onChange(
												newValue.hour(
													hour === 0 ? 12 : hour + 12,
												),
											);
										}
										// If 8, 9, 10, or 11 is selected, automatically set it to AM
										else if (
											hour === 20 || // 8 PM
											hour === 21 || // 9 PM
											hour === 22 || // 10 PM
											hour === 23  // 11 PM
										) {
											props.onChange(
												newValue.hour(hour - 12),
											);
										} else {
											props.onChange(newValue);
										}
									},
								}),
							minutes: renderTimeViewClock,
						}}
						value={value ? dayjs(value, "HH:mm") : null}
						onAccept={(newValue) => {
							const formattedValue = newValue ? newValue.format("HH:mm") : "";
							onChange(formattedValue);
						}}
						onOpen={() => setIsOpen(true)}
						onClose={() => setIsOpen(false)}
						slotProps={{
							textField: {
								inputProps: {
									readOnly: true,
									onClick: () => setIsOpen(!isOpen),
								},
							},
							actionBar: {
								actions: ["clear", "cancel", "accept"],
							},
						}}
						componentsProps={{
							actionBar: {
								onClear: () => {
									onChange("");
									setIsOpen(false);
								},
							},
						}}
						minutesStep={5}
					/>
				</LocalizationProvider>
			</div>
		);
	};

	return (
		<>
			<p className="mb-0">Please select a maximum of {maxTime} minutes a day.</p>
			<p className="">
				Rotational Minutes & Recess Breaks are automatically subtracted from the daily total.
			</p>

			{/* A.M. Section */}
			<div className="mb-4">
				<h4 className="font-weight-bold mb-0">Morning (AM, Until Lunch)</h4>
				<Row>
					<Col sm={6}>
						<TimeInput
							label="Start Time"
							value={times["AM Start"]}
							onChange={(value) => handleTimeChange("AM Start", value)}
						/>
					</Col>
					<Col sm={6}>
						<TimeInput
							label="Finish Time"
							value={times["AM Finish"]}
							onChange={(value) => handleTimeChange("AM Finish", value)}
						/>
					</Col>
				</Row>
			</div>

			{/* Recess 1 Section */}
			<div className="mb-4">
				<h4 className="font-weight-bold mb-0">Recess 1 (Optional)</h4>
				<Row>
					<Col sm={6}>
						<TimeInput
							label="Start Time"
							value={times["Recess 1 Start"]}
							onChange={(value) => handleTimeChange("Recess 1 Start", value)}
						/>
					</Col>
					<Col sm={6}>
						<TimeInput
							label="Finish Time"
							value={times["Recess 1 Finish"]}
							onChange={(value) => handleTimeChange("Recess 1 Finish", value)}
						/>
					</Col>
				</Row>
			</div>

			{/* P.M. Section */}
			<div className="mb-4">
				<h4 className="font-weight-bold mb-0">Afternoon (PM, After Lunch)</h4>
				<Row>
					<Col sm={6}>
						<TimeInput
							label="Start Time"
							value={times["PM Start"]}
							onChange={(value) => handleTimeChange("PM Start", value)}
							defaultPM
						/>
					</Col>
					<Col sm={6}>
						<TimeInput
							label="Finish Time"
							value={times["PM Finish"]}
							onChange={(value) => handleTimeChange("PM Finish", value)}
							defaultPM
						/>
					</Col>
				</Row>
			</div>

			{/* Recess 2 Section */}
			<div className="mb-4">
				<h4 className="font-weight-bold mb-0">Recess 2 (Optional)</h4>
				<Row>
					<Col sm={6}>
						<TimeInput
							label="Start Time"
							value={times["Recess 2 Start"]}
							onChange={(value) => handleTimeChange("Recess 2 Start", value)}
							defaultPM
						/>
					</Col>
					<Col sm={6}>
						<TimeInput
							label="Finish Time"
							value={times["Recess 2 Finish"]}
							onChange={(value) => handleTimeChange("Recess 2 Finish", value)}
							defaultPM
						/>
					</Col>
				</Row>
			</div>

			{/* Rotational Minutes Section */}
			{showRotationalMinutes && (
				<div className="mb-4">
					<h4 className="font-weight-bold mb-0">Rotational Time (Optional)</h4>
					<Row>
						<Col sm={6}>
							<div className="">
								<label className="font-weight-bold">
									Enter number of daily rotational minutes
								</label>
								<input
									type="number"
									className="form-control"
									value={times["Rotational Minutes"] || ""}
									onChange={(e) =>
										handleTimeChange("Rotational Minutes", e.target.value)
									}
									min="0"
									step="1"
								/>
							</div>
						</Col>
					</Row>
				</div>
			)}

			{/* Totals Section */}
			<Row>
				<Col sm={6}>
					<h4 className={`font-weight-bold mb-0 ${isError && "text-danger"}`}>
						Total Daily Minutes
					</h4>
					<p className="mt-2 text-lg font-weight-bold">{totalMinutes}</p>
					{isError && (
						<p className="mt-2 text-danger">
							You're over the {maxTime} minute maximum, please review and adjust your time
							ranges!
						</p>
					)}
				</Col>
				<Col sm={6}>
					<h4 className="font-weight-bold mb-0">Total Daily Hours</h4>
					<p className="mt-2 text-lg font-weight-bold">{totalHours}</p>
				</Col>
			</Row>
		</>
	);
};

export default DailySchedule;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAwXuZRJcOsYbe9OAkZ8CgK8u3-LEitLUM",
  authDomain: "connected-north.firebaseapp.com",
  databaseURL: "https://connected-north.firebaseio.com",
  projectId: "connected-north",
  storageBucket: "connected-north.appspot.com",
  messagingSenderId: "371543446781",
  appId: "1:371543446781:web:02ae8e34c33795b90f9a94",
  measurementId: "G-3Y6CK2W7K0"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { Accordion } from "../../../PDPlan/components/Accordion";
import { StatusLabel } from "../../../PDPlan/components/StatusLabel";
import { GoalSelectField, GoalTextField } from "../../../PDPlan/components/GoalFields";

const dayPlan = {
  Topic: "",
  Alignment: "",
  Support: "",
  "Support Needed?": "",
};

export const Planner = ({
	readOnly = false,
  day,
	onToggleExpand,
	expanded = false,
  onUpdate,
	...props
}) => {
  	const [fields, setFields] = useState(day["Day Plans"] && day["Day Plans"].length > 0 ? day["Day Plans"][0] : dayPlan)
	const [fieldErrors] = useState([]);
	const [isUpdating, setIsUpdating] = useState(false);
	const { t } = useTranslation();


	const onUpdateGoalField = (e) => {
		if (readOnly) return;

		const { name, value } = e.target;
		setFields({ ...fields, [name]: value });
	};

  const onSubmitPlan = async (e) => {
    e.preventDefault();
    if (readOnly) return;
		setIsUpdating(true);

    await onUpdate(day.id, fields);
		setIsUpdating(false);
  }

  useEffect(() => {
    if (!day) return;

    const dayPlans = day["Day Plans"];
    const initialFields = dayPlans && dayPlans.length > 0 ? dayPlans[0] : dayPlan;
    setFields(initialFields);
  }, [day, day?.["Day Plans"]]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Accordion
			title={fields.Topic}
			status={<StatusLabel label={fields.id ? "Complete" : "Pending"} />}
      		description={day.Display}
			onChange={onToggleExpand}
			expanded={expanded}
			{...props}
		>
			<Box onSubmit={onSubmitPlan} component="form">
				<Box>
          <GoalTextField
						name="Topic"
						onChange={onUpdateGoalField}
						value={fields.Topic}
						error={fieldErrors.includes("Topic")}
						readOnly={readOnly}
						label="Topic"
					/>
          <GoalTextField
						name="Alignment"
						onChange={onUpdateGoalField}
						value={fields.Alignment}
						error={fieldErrors.includes("Alignment")}
						readOnly={readOnly}
						label="How does this topic align with current Department of Education expectations in this area"
            rows="5"
						multiline
					/>
					<GoalTextField
						name="Support"
						onChange={onUpdateGoalField}
						value={fields.Support}
						error={fieldErrors.includes("Support")}
						readOnly={readOnly}
						label="How will this topic support your school, staff, and/or students"
            rows="5"
						multiline
					/>
					<GoalSelectField
						name="Support Needed?"
						onChange={onUpdateGoalField}
						value={fields["Support Needed?"]}
            options={["Yes", "No"]}
						error={fieldErrors.includes("Support Needed?")}
						readOnly={readOnly}
						label="Do you require Ed Dev support"
					/>
				</Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "3rem",
          }}
        >
          <Button color="primary" type="submit" disabled={isUpdating}>
            {isUpdating ? "Saving..." : t("save")}
          </Button>
        </Box>
			</Box>
		</Accordion>
	);
};

import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from "@mui/material";
import { NavItem, NavLink as ReactStrapLink, UncontrolledTooltip } from 'reactstrap';

const CNNavItem = ({
  label,
  intercomLabel,
  tooltip,
  to,
  hideNav,
  onClick,
  iconImg,
  showTooltip,
  isNavOpen,
  openInNewTab,
  containerClass = 'container-placeholder',
}) => {
  const tooltipId = useRef(`tooltip${Math.floor(Math.random() * 100)}`).current;
  const tooltipLabel = tooltip ? tooltip : label;

  if (onClick || openInNewTab) {
    const handleClick = openInNewTab ? () => window.open(to, '_blank', 'noopener,noreferrer') : onClick;
    return (
      <NavItem data-intercom-target={intercomLabel} className={`${containerClass} ${isNavOpen ? 'menu-item' : 'menu-item collapsed-nav'}`}>
        <ReactStrapLink
          id={tooltipId}
          href="#"
          onClick={(e) => handleClick?.(e)}
        >
          <Box className='menu-item-container sidebar-item'>
            {iconImg && (
              <Box
                className="icon-container"
                sx={{
                  marginRight: '14px',
                  padding: "7px",
                }}>
                {iconImg}
              </Box>
            )}
            <Typography
              component="span"
              className="nav-link-inner--text other-platform"
              sx={{
                fontFamily: "Effra, sans-serif",
                display: "flex",
                gap: "0.94rem",
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                alignItems: 'center'
              }}
            >
              <span className="nav-link-inner--text other-platform" style={{fontSize: "1.13rem", fontWeight: "400"}}>{label}</span>
            </Typography>
          </Box>
        </ReactStrapLink>
        {!!tooltipLabel && showTooltip && (
          <UncontrolledTooltip
            delay={0}
            target={tooltipId}
          >{tooltipLabel}</UncontrolledTooltip>
        )}
      </NavItem>
    )
  }
 
  return (
    <NavItem
      data-intercom-target={intercomLabel}
      className={`${containerClass} ${isNavOpen ? 'menu-item' : 'menu-item collapsed-nav'}`}>
      {openInNewTab ? (
        <ReactStrapLink
          id={tooltipId}
          onClick={hideNav}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box className='menu-item-container sidebar-item'>
            {iconImg && (
              <Box className="icon-container" sx={{ marginRight: '24px', padding: "7px"}}>
                {iconImg}
              </Box>
            )}
            <Typography
              component="span"
              className="nav-link-inner--text other-platform"
              sx={{
                fontFamily: "Effra, sans-serif",
                display: "flex",
                gap: "0.94rem",
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                alignItems: 'center'
              }}
            >
              <span className="nav-link-inner--text other-platform" style={{fontSize: "1.13rem", fontWeight: "400"}}>{label}</span>
            </Typography>
          </Box>
        </ReactStrapLink>
      ) : (
        <NavLink
          id={tooltipId}
          onClick={hideNav}
          to={to}
          aria-current="page"
          isActive={(match, location) => {
            if (!match) return false;
            
            // If the URL has an anchor and the nav item has one too
            if (location.hash && to.includes('#')) {
              return location.pathname === to.split('#')[0] && location.hash === '#' + to.split('#')[1];
            }
            
            // If neither has an anchor or only the nav item has one (to allow default matching)
            return match.url === location.pathname;
          }}
        >
        <Box className='menu-item-container sidebar-item'>
            {iconImg && (
              <Box  className="icon-container" sx={{ marginRight: '24px', padding: "7px"}}>
                {iconImg}
              </Box>
            )}
            <Typography
              component="span"
              className="nav-link-inner--text other-platform"
            sx={{
                fontFamily: "Effra, sans-serif",
                display: "flex",
                gap: "0.94rem",
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                alignItems: 'center'
              }}
            >
              <span className="nav-link-inner--text other-platform" style={{fontSize: "1.13rem", fontWeight: "400"}}>{label}</span>
            </Typography>
        </Box>
      </NavLink>
      )}
      {showTooltip && (
        <UncontrolledTooltip
          delay={0}
          target={tooltipId}
        >{tooltipLabel}</UncontrolledTooltip>
      )}
    </NavItem>
  )
}

export default CNNavItem;

import React, { useEffect, useState } from "react";
import {
	Button,
	FormGroup,
	Input,
	InputGroupText,
	InputGroup,
} from "reactstrap";
import * as EmailValidator from "email-validator";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
	setAppIsSessionHost,
	setAppUserInfo,
	setAppUserType,
} from "../../redux/actions";
import { useTranslation } from "react-i18next";
import { analytics } from "../../firebase";
import { setSession } from "../../utils/session";
import {
	CONNECTEDNORTH_SIGNINFO,
	CONNECTEDNORTH_SIGNTYPE,
} from "../../config";
import Loading from "../../components/Loading.js";
import { useQuery } from "../../useQuery.js";
import { Typography } from "@mui/material";
import { ONBOARD_TEACHER_ROOT } from "../OnboardTeacher/routing.js";
import { getIsTeacherOnboardEnabled } from "../OnboardTeacher/utils/get-is-teacher-onboard-enabled.js";
import "./styles.scss";
import { getUser2FAMethod, getUserPhone, selectUserByEmail, updateUserInfo } from "./utils.js";
import TwoFactorAuthConfirmModal from "../modals/TwoFactorAuthConfirm/index.js";
import usePasskey from "../../libs/hooks/use-passkey.js";
import { generateAuthToken } from "../../utils/generateAuthToken.js";


const isTeacherOnboardEnabled = getIsTeacherOnboardEnabled();

const Login = () => {
	let query = useQuery();
	const url = query.get("url");
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [height, setHeight] = useState(0);
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [signing, setSigning] = useState(false);

	const [appLoading, setAppLoading] = useState(true);
	const [showNewTeacherPrompt, setShowNewTeacherPrompt] = useState(false);
	const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);

	const { signinWithPasskey } = usePasskey();

	const resizeListener = () => {
		setHeight(window.innerHeight);
		setAppLoading(false);
	};

	useEffect(() => {
		window.document.title = "Log in - Connected North";
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;

		setTimeout(() => resizeListener());
		window.addEventListener("resize", resizeListener);
		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	const logSignin = async (user, userType) => {
		await Promise.all([
			setUserId(analytics, user),
			setUserProperties(analytics, "user_type", userType),
			logEvent(analytics, "login", { email: user, userType }),
		]);
	};

	const completeLogin = async (userType, userInfo) => {
		let params = {};
		if (process.env.NODE_ENV === 'production') {
			params["Platform Logins"] = (userInfo["Platform Logins"] || 0) + 1;
		}
		if (userType === "Teacher" && !userInfo["Status"]) params.Status = "Active";

		let updatedUserInfo;
		if (Object.keys(params).length > 0) {
			updatedUserInfo = await updateUserInfo(userType, userInfo.id, params);
		} else {
			updatedUserInfo = { ...userInfo };
		}

		await logSignin(userInfo["Email"], userType);
		await setSession(CONNECTEDNORTH_SIGNINFO, updatedUserInfo);
		await setSession(CONNECTEDNORTH_SIGNTYPE, userType);
		dispatch(setAppUserInfo(updatedUserInfo));
		dispatch(setAppUserType(userType));

		if (userType === "Team") {
			dispatch(setAppIsSessionHost(userInfo["Status"] === "Session Host"));
		}

		// Check if it's a teacher's first login
		const token = await generateAuthToken(userInfo.id, userType);
		let query = new URLSearchParams(window.location.search);
		let redirectUrl = query.get("redirect");

		if (redirectUrl) {
			const tokenParam = encodeURIComponent(token);
			window.location.href = `${redirectUrl}?token=${tokenParam}`;
		} else {
			// 🔹 Preserve original logic: Handle teacher onboarding and normal redirection
			if (userType === "Teacher" && updatedUserInfo["Platform Logins"] === 1 && isTeacherOnboardEnabled) {
				history.push(`${ONBOARD_TEACHER_ROOT}?email=${encodeURIComponent(userInfo.Email)}&cluster=${userInfo.id}`);
			} else {
				const hash = window.location.hash;
				window.location.href = url ? `${url}${hash}` : `/cn${hash}`;
			}
		}
	}

	const verifyPost = async () => {
		const { userType, userInfo } = _2FAVerificationInfo.userData;
		completeLogin(userType, userInfo);
		set2FAVerificationInfo(null);
	}

	const onLogin = async () => {
		setShowNewTeacherPrompt(false);
		setError("");

		if (email === "") {
			setError(t("type-email-address"));
			return;
		}

		if (!EmailValidator.validate(email)) {
			setError(t("invalid-email"));
			return;
		}

		setSigning(true);
		try {
			const signinInfo = await selectUserByEmail(email);
			if (!signinInfo) {
				setError(t("no-exist-user-with-email"));
				setSigning(false);
				return;
			}

			const { external, userId, userType, userInfo } = signinInfo;
			if (external) {
				setSigning(false);
				if (userType === "Presenter") {
					history.push(`/register-presenter/${userId}/${userInfo.id}`);
				} else if (userType === "Teacher") {
					if (isTeacherOnboardEnabled) {
						history.push(
							`${ONBOARD_TEACHER_ROOT}?email=${encodeURIComponent(email)}&cluster=${userInfo.id}`,
						);
					} else {
						history.push(`/register-teacher/${userId}/${userInfo.id}`);
					}
				}

				return;
			}

			// Skip 2FA on localhost (for development purposes only)
			if (process.env.NODE_ENV !== 'production' && window.location.hostname === 'localhost') {
				console.warn('Skipping 2FA on localhost. Do not use in production!');
				await completeLogin(userType, userInfo);
				return;
			}

			const _2faMethod = getUser2FAMethod(userType, userInfo);
			const userPhone = getUserPhone(userType, userInfo);
			if (
				_2faMethod === "None" ||
				(_2faMethod === "SMS" && (!userPhone || !userInfo["Phone Number SMS Verified"])) ||
				(_2faMethod === "App" && !userInfo["2FA Secret"])
			) {
				await completeLogin(userType, userInfo);
				return;
			} else if (_2faMethod === "Passkeys") {
				try {
					const userId = await signinWithPasskey();
					if (userId === userInfo.id) {
						await completeLogin(userType, userInfo);
					} else {
						setSigning(false);
						setError("Sorry, we could not verify your identity.");
					}
				} catch (error) {
					setSigning(false);
					setError("Sorry, we could not verify your identity.");
				}
				return;
			}

			const name = userType === "Teacher" ? userInfo["Teacher Name"] : userInfo["Name"]
			set2FAVerificationInfo({
				type: _2faMethod.toLowerCase(),
				phone: userPhone,
				uInfo: {
					userSecret: userInfo["2FA Secret"],
					userEmail: userInfo["Email"],
					userName: name,
					userId: userInfo.id
				},
				userData: { userType, userInfo }
			})
			setSigning(false);

			// If we want to enable the new teacher prompt, this should be set to true here.
			setShowNewTeacherPrompt(false);
		} catch (error) {
			setError("The Cloud database service we use is currently experiencing issues. Please try logging in again later!");
			setSigning(false);
		}
	};

	if (appLoading)
		return (
			<div className="app-loader-wrap">
				<Loading size={64} />
			</div>
		);

	return (
		<main className="login-screen">
			<section className="continer" style={{ height: height }}>
				<div className="login-container">
					<div className="email-input">
						<FormGroup>
							<InputGroup className="input-group-alternative">
								<InputGroupText>
									<i className="ni ni-email-83" />
								</InputGroupText>
								<Input
									autoFocus
									bsSize="lg"
									placeholder={t("email")}
									type="email"
									defaultValue={email}
									onChange={(e) => setEmail(e.target.value)}
									onKeyDown={(event) => {
										if (event.keyCode === 13) onLogin();
									}}
								/>
							</InputGroup>
						</FormGroup>
					</div>
					<div className="error">
						{error ? <span>{error}</span> : null}
					</div>

					{isTeacherOnboardEnabled && showNewTeacherPrompt ? (
						<Typography
							variant="body1"
							textAlign="center"
							mt="0.5rem"
							mb="1rem"
						>
							<Typography
								variant="body1"
								component="span"
								display="block"
							>
								{t("teacherOnboard.loginPromptText")}
							</Typography>
							<Typography
								component={Link}
								to={`${ONBOARD_TEACHER_ROOT}?email=${email}`}
								fontWeight="bold"
							>
								{t("teacherOnboard.loginPromptLink")}
							</Typography>
						</Typography>
					) : null}

					<div className="action">
						<Button
							color="primary"
							type="button"
							onClick={() => onLogin()}
							disabled={signing}
							size="lg"
						>
							{signing ? <Loading size={14} /> : null}
							{t("sign_in")}
						</Button>
					</div>
				</div>
			</section>
			{!!_2FAVerificationInfo && (
				<TwoFactorAuthConfirmModal
					type={_2FAVerificationInfo.type}
					phone={_2FAVerificationInfo.phone}
					uInfo={_2FAVerificationInfo.uInfo}
					onDismiss={() => {
						setSigning(false);
						set2FAVerificationInfo(null)
					}}
					onVerifiedPost={verifyPost}
					canClose={false}
				/>
			)}
		</main>
	);
};

export default Login;

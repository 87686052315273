import React, { useEffect, useRef, useState } from "react";
import airtable from "../../../airtables";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import uploadFile from "../../../libs/aws/uploadfile";
import { CONNECTEDNORTH_SIGNINFO } from "../../../config";
import { setSession } from "../../../utils/session";
import { setAppUserInfo } from "../../../redux/actions";
import { toast } from 'react-toastify';
import TwoFactorAuthConfirmModal from "../../modals/TwoFactorAuthConfirm";
import LoadingBar from "../../../components/LoadingBar";
import usePasskey from "../../../libs/hooks/use-passkey";

const PersonalInfoTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userInfo } = useSelector(state => state.appInfo);
  const [communities, setCommunities] = useState([]);
  const [name, setName] = useState("");
  const [pronouns, setPronouns] = useState("")
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [logo, setLogo] = useState("");
  const [logoFile, setLogoFile] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [community, setCommunity] = useState("");
  const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')
  const [authMethod, setAuthMethod] = useState("None");
  const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);
  const [updating, setUpdating] = useState(false);
  const fileInputRef = useRef();

  const { createPasskey } = usePasskey();

  useEffect(() => {
    airtable.communities.getTotalCommunities().then(res => setCommunities(res))
  }, [])

  useEffect(() => {
    setName(userInfo['Contact Name']);
    setPronouns(userInfo["Pronouns"]);
    setEmail(userInfo['Email']);
    setPhoneNumber(userInfo['Phone']);
    setTimezone(userInfo["Timezone"]);
    setBio(userInfo["Introduction/Bio"]);
    setAuthMethod(userInfo["2FA"]);
    setCommunity(userInfo["Indigenous Communities"] ? userInfo["Indigenous Communities"][0] : "");
    if (userInfo["Logo/Photo"] && userInfo["Logo/Photo"].length) {
      if (userInfo["Logo/Photo"][0].thumbnails) {
        setLogo(userInfo["Logo/Photo"][0].thumbnails.large.url)
      } else {
        setLogo(userInfo["Logo/Photo"][0].url)
      }
    }
    setLanguage(userInfo["Preferred Language"] || 'English');
    setPhoneDisabled(userInfo["2FA"] === "SMS");
  }, [userInfo])

  const verifyPost = async (type, secret) => {
    let nUserInfo = null;
    if (type === "sms") {
      nUserInfo = await airtable.providers.update(userInfo.id, {
        "Phone": phoneNumber,
        "Phone Number SMS Verified": true,
        "2FA": "SMS"
      });
    } else if (type === "app") {
      nUserInfo = await airtable.providers.update(userInfo.id, {
        "2FA Secret": secret,
        "2FA": "App"
      });
    }

    if (nUserInfo) {
      await setSession(CONNECTEDNORTH_SIGNINFO, nUserInfo);
      dispatch(setAppUserInfo(nUserInfo));
      set2FAVerificationInfo(null);
      toast.success(t("mysettings.login-security-enabled"));
    }
  }

  const updateInfo = async () => {
    if (phoneNumber && phoneNumber.replace(/\D/g, '').length < 10) {
      setPhoneError(t("mysettings.phone-number-error-length"));
      return;
    }
    setPhoneError("");

    try {
      let phoneVerified = userInfo["Phone Number SMS Verified"] ? true : false;
      if (phoneNumber !== userInfo["Phone"]) {
        phoneVerified = false;
      }

      if (authMethod === "SMS" && !phoneVerified) {
        set2FAVerificationInfo({
          type: "sms",
          phone: phoneNumber,
          uInfo: {}
        });
      } else if (authMethod === "App" && !userInfo["2FA Secret"]) {
        set2FAVerificationInfo({
          type: "app",
          uInfo: { userEmail: userInfo["Email"] }
        });
      } else if (authMethod === "Passkeys" && userInfo["2FA"] !== authMethod) {
        try {
          await createPasskey(userInfo.id, userInfo["Email"], userInfo["Name"]);
        } catch (error) {
          console.log("Error:", error);
          return;
        }
      }

      setUpdating(true);

      let params = {
        "Contact Name": name,
        "Email": email.trim(),
        "Phone": phoneNumber || null,
        "Timezone": timezone,
        "Indigenous Communities": community ? [community] : [],
        "Introduction/Bio": bio,
        "Pronouns": pronouns,
        "Preferred Language": language,
        "2FA": authMethod,
        "Phone Number SMS Verified": phoneVerified,
      }

      if (authMethod !== "App" && userInfo["2FA"] !== authMethod) {
        params["2FA Secret"] = "";
      }

      if (logoFile) {
        const uRes = await uploadFile(logoFile);
        params["Logo/Photo"] = [{ url: uRes }];
        setLogoFile(null);
      }

      await updateUser(params);
      toast.success("Saved successfully!")
    } catch (error) { }
    setUpdating(false);
  }

  const updateUser = async (params) => {
    const info = await airtable.providers.update(userInfo.id, params);
    await setSession(CONNECTEDNORTH_SIGNINFO, info);
    dispatch(setAppUserInfo(info));
  }

  return (
    <div className="tab-container">
      {updating && <LoadingBar />}
      <div className="info-container">
        <div className="image-upload-section">
          <div className="image-preview">
            {logo ? (
              <img src={logo} alt="" />
            ) : <div className="no-img" />}
          </div>

          <input
            type="file"
            accept="application/image"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={e => {
              setLogoFile(e.target.files[0]);
              setLogo(URL.createObjectURL(e.target.files[0]));
            }}
          />

          <div className="image-upload-button">
            <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary">
              Select Profile Image to Upload
            </Button>
          </div>
        </div>
        <div className="form-section">
          <FormGroup row>
            <Label for="name" sm={4}>{t("contact-name")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Contact Name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="name" sm={4}>{t("pronouns")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="text"
                name="pronouns"
                id="pronouns"
                placeholder={t("pronouns")}
                defaultValue={pronouns}
                onChange={e => setPronouns(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>{t("email")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                defaultValue={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="email" sm={4}>Preferred Language</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                className='pronouns form-control'
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="English">English</option>
                <option value="French">French</option>
              </Input>
            </Col>
          </FormGroup>

          <div className="user-phone">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("phone-number")}</Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder={t("mysettings.phone-number-placeholder")}
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                    setPhoneError("");
                  }}
                  disabled={phoneDisabled}
                />
                {phoneError && <div className="text-danger mt-1">{phoneError}</div>}
              </Col>
            </FormGroup>
            {phoneDisabled && (
              <p className="phone-error">{t("mysettings.phone-number-disabled")}</p>
            )}
            {(!phoneNumber && authMethod === "SMS") ? (
              <p className="phone-error">{t("mysettings.phone-number-add-for-sms")}</p>
            ) : (!userInfo["Phone Number SMS Verified"] && authMethod === "SMS" && phoneNumber) && (
              <p className="phone-error">
                {t("mysettings.phone-number-not-verified")}{" "}
                <span
                  onClick={() => {
                    set2FAVerificationInfo({
                      type: "sms",
                      phone: phoneNumber,
                    });
                  }}
                >
                  {t("mysettings.phone-number-verify-here")}
                </span>.
              </p>
            )}
          </div>

          <div className="user-auth">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("mysettings.login-security")}</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="select"
                  className='_2fa-selector form-control'
                  value={authMethod}
                  onChange={(e) => setAuthMethod(e.target.value)}
                >
                  <option value="None">None</option>
                  <option value="SMS">SMS (Text Message)</option>
                  <option value="App">Authenticator App</option>
                  <option value="Passkeys">Passkeys</option>
                </Input>
              </Col>
            </FormGroup>
            {(!userInfo["2FA Secret"] && authMethod === "App") && (
              <p className="_2fa-error"><span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "app",
                    uInfo: { userEmail: userInfo["Email"] }
                  });
                }}
              >Click here to set up an authenticator app</span> to receive passcodes.</p>
            )}
          </div>
          <span style={{ fontSize: '0.85rem' }}>When logging in, to provide additional security you can verify your login request with a SMS Text Message to your mobile device, or a smartphone-based authentication app, such as Google Authenticator or Microsoft Authenticator.</span>

          <FormGroup row style={{ marginTop: '1rem' }}>
            <Label for="timezone" sm={4}>{t("timezone")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="timezone"
                id="timezone"
                className="form-control"
                value={timezone}
                onChange={e => setTimezone(e.target.value)}
              >
                <option></option>
                <option>Canada/Atlantic</option>
                <option>Canada/Central</option>
                <option>Canada/Eastern</option>
                <option>Canada/Mountain</option>
                <option>Canada/Newfoundland</option>
                <option>Canada/Pacific</option>
                <option>Canada/Saskatchewan</option>
                <option>Canada/Yukon</option>
                <option>US/Alaska</option>
                <option>Australia/Sydney</option>
                <option>Other</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="community" sm={4}>{t("community")}</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                name="community"
                id="community"
                className="form-control"
                value={community}
                onChange={e => setCommunity(e.target.value)}
              >
                <option></option>
                {communities.map((com, i) => <option value={com.id} key={i}>{com.Name}</option>)}
              </Input>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="bio" sm={12}>{t("introduction-bio")}</Label>
            <Col sm={12}>
              <Input
                type="textarea"
                name="bio"
                id="bio"
                placeholder="Introduction/Bio"
                value={bio}
                onChange={e => setBio(e.target.value)}
                rows={6}
                className="w-100"
              />
            </Col>
          </FormGroup>
        </div>
      </div>
      <div className="ms-action" data-intercom-target="Save">
        <Button
          color="primary"
          size="sm"
          onClick={() => updateInfo()}
          disabled={(!name || !email)}
        >{t("save-change")}</Button>
      </div>
      {!!_2FAVerificationInfo && (
        <TwoFactorAuthConfirmModal
          type={_2FAVerificationInfo.type}
          phone={_2FAVerificationInfo.phone}
          uInfo={_2FAVerificationInfo.uInfo}
          onDismiss={() => set2FAVerificationInfo(null)}
          onVerifiedPost={verifyPost}
        />
      )}
    </div>
  )
}

export default PersonalInfoTab;
export const translations = {
	en: {
		title: "Nunavut Territorial School Calendar",
		headers: {
			community: "Community",
			school: "School",
			returnLeaders: "Return of School Leaders",
			firstStaff: "First Staff Day",
			firstStudent: "First Student Day",
			lastBeforeChristmas: "Last Day Before Christmas",
			firstAfterChristmas: "First Day After Christmas",
			pdWeek: "PD Week",
			easterBreak: "Easter Break",
			lastStudent: "Last Student Day",
			lastStaff: "Last Staff Day",
			lastLeaders: "Last Day School Leaders"
		},
		regions: {
			qikiqtani: "Qikiqtani School Operations",
			commission: "Commission scolaire francophone du Nunavut",
			kitikmeot: "Kitikmeot School Operations",
			kivalliq: "Kivalliq School Operations"
		}
	},
	fr: {
		title: "Calendrier scolaire territorial du Nunavut pour",
		headers: {
			community: "Localité",
			school: "École",
			returnLeaders: "Premier jour de la direction de l’école",
			firstStaff: "Premier jour du personnel",
			firstStudent: "Premier jour des élèves",
			lastBeforeChristmas: "Dernier jour avant le congé des Fêtes",
			firstAfterChristmas: "Premier jour après le congé des Fêtes",
			pdWeek: "Semaine de perfectionnement en enseignement",
			easterBreak: "Relâche de Pâques",
			lastStudent: "Dernier jour des élèves",
			lastStaff: "Dernier jour du personnel",
			lastLeaders: "Dernier jour de la direction de l’école"
		},
		regions: {
			qikiqtani: "Bureau des opérations des écoles du Qikiqtani",
			commission: "Commission scolaire francophone du Nunavut",
			kitikmeot: "Bureau des opérations des écoles du Kitikmeot",
			kivalliq: "Bureau des opérations des écoles du Kivalliq"
		}
	},
	ikt: {
		title: "Nunavut Tamaani Sikuuqviit Tatqiqhiutait",
		headers: {
			community: "Nunallaaq",
			school: "Ilinniarvik",
			returnLeaders: "Utirviqhaat Sikuuqvingmi Hivuliqtiit",
			firstStaff: "Hivulliqpaaq Ilihaiyit Ublua",
			firstStudent: "Hivulliqpaaq Ilihaqtut Ublua",
			lastBeforeChristmas: "Kin’ngulliqpaaq Ublua Kulaisimaktinnagu",
			firstAfterChristmas: "Hivulliqpaaq Ublua Kulaisimangmit",
			pdWeek: "Ilihaiyit Ilihaidjutaa Havainiq",
			easterBreak: "Iistangnarmat Sikuunggittut",
			lastStudent: "Kin’ngulliqpaaq Ilihaqtut Ublua",
			lastStaff: "Kin’ngulliqpaaq Ilihaiyit Ublua ",
			lastLeaders: "Kin’ngulliqpaaq Sikuuqvingmi Hivuliqtiit"
		},
		regions: {
			qikiqtani: "Qikiqtani Sikuuqviinnut Aulapkaiyit",
			commission: "Commission scolaire francophone du Nunavut",
			kitikmeot: "Kitikmeonmi Sikuuqviinnut Aulapkaiyit",
			kivalliq: "Kivalliq Sikuuqviinnut Aulapkaiyit"
		},
		months: {
			january: "Qitiqqautijuq",
			february: "Imaruqtirvia",
			march: "Pingaasuut",
			april: "Upirngasaaq",
			may: "Upirngaviit",
			june: "Aasivak",
			july: "Aasiviit",
			august: "Ukiaqsaaq",
			september: "Niqiliqivik",
			october: "Apitilirvik",
			november: "Ubluirvia",
			december: "Iijjirurvia"
		}
	},
	iu: {
		title: "ᐃᓕᓐᓂᐊᕐᕕᐅᑉ ᐅᓪᓗᖅᓯᐅᑎᖓ ᓇᐃᓈᖅᓯᒪᔪᖅ",
		headers: {
			community: "ᓄᓇᓖᑦ",
			school: "ᐃᓕᓐᓂᐊᕐᕕᒃ",
			returnLeaders: "ᐅᑎᖅᑐᑦ ᐃᓕᓐᓂᐊᕐᕕᒻᒥ ᓯᕗᓕᖅᑏᑦ",
			firstStaff: "ᐃᖃᓇᐃᔭᖅᑎᓄᑦ ᓯᕗᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ ᐃᓕᓐᓂᐊᕐᕕᒃ ᐅᒃᑯᐃᖅᐳᖅ",
			firstStudent: "ᐃᓕᓐᓂᐊᖅᑎᓄᑦ ᓯᕗᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ",
			lastBeforeChristmas: "ᑭᖑᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ ᖁᕕᐊᓱᒡᕕᐅ ᑐᖔᓂ",
			firstAfterChristmas: "ᐃᓕᓐᓂᐊᖅᑐᓄᑦ ᓯᕗᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ ᖁᕕᐊᓱᒡᕕᐅᕌᓂᒃᐸᑦ",
			pdWeek: "ᐃᓕᓴᐃᔩᑦ ᐃᓕᓐᓂᐊᕐᓂᖓᑕ ᐱᓇᓱᐊᕈᓯᖓᑦ",
			easterBreak: "ᒪᑭᕕᐊᒧᑦ ᓄᖅᑲᖔᕐᕕᐅᓂᐊᖅᑐᖅ",
			lastStudent: "ᑭᖑᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ ᐃᓕᓐᓂᐊᑎᓄᑦ",
			lastStaff: "ᐃᖃᓇᐃᔭᖅᑎᓄᑦ ᑭᖑᓪᓕᖅᐹᖅ ᐅᓪᓗᖅ ᐃᓕᓐᓂᐊᕐᕕᒃ ᒪᑐᕗᖅ",
			lastLeaders: "ᑭᙳᓪᓕᑉᐹᖅ ᐅᓪᓗᖓ ᐃᓕᓐᓂᐊᕐᕕᒻᒥ ᓯᕗᓕᖅᑎᒧ"
		},
		regions: {
			qikiqtani: "ᕿᑭᖅᑕᓂ ᐃᓕᓐᓂᐊᕐᕕᖏᑦᑕ ᐊᓪᓚᒡᕕᖓᑦ",
			commission: "ᐅᐃᕖᒃᑯᑦ ᐃᓕᓐᓂᐊᖅᑐᓕᕆᔨᒃᑯᑦ ᑲᑎᒪᔨᖏᑦ ᓄᓇᕗᒻᒥ",
			kitikmeot: "ᕿᑎᖕᒥᐅᑦ ᐃᓕᓐᓂᐊᕐᕕᖏᑦᑕ ᐊᓪᓚᒡᕕᖓᑦ",
			kivalliq: "ᑭᕙᓪᓕᖅ ᐃᓕᓐᓂᐊᕐᕕᖏᑦᑕ ᐊᓪᓚᒡᕕᖓᑦ"
		},
		months: {
			january: "ᔮᓐᓄᐊᕆ",
			february: "ᕖᕝᕗᐊᕆ",
			march: "ᒫᑦᓯ",
			april: "ᐄᐳᕆ",
			may: "ᒪᐃ",
			june: "ᔫᓂ",
			july: "ᔪᓚᐃ",
			august: "ᐋᒡᒌᓯ",
			september: "ᓯᑎᐱᕆ",
			october: "ᐅᑐᐱᕆ",
			november: "ᓄᕕᐱᕆ",
			december: "ᑎᓯᐱᕆ"
		}
	}
};
